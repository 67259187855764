import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CountUp from "react-countup";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../../constant/StorageConstant";
import { UDMainDashboardTopRowItems } from "../../../common/data/dashboard";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ReactEcharts from "echarts-for-react";
import UserDrivingScoreController from "../../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../../util/ValueFormat";
import YearPopup from "../../ModelPopup/YearPopup";
import DrivingSafetyAreav3 from "./DrivingSafetyAreav3";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf";
import UBIController from "../../../controller/UBI Controllers/UBIController";
import Loader from "../../../components/Common/loader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { UBIPoints } from "./UBIPointsFunctions";
import Mobile from "./UBIDashboardPoints.json";
import UbiTopRowWidgets from "./UbiTopRowWidgets";
import UtilDateTime from "../../../util/UtilDateTime";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController";
import { displayText } from "../../../constant/MessageConstant";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClock } from "@fortawesome/free-regular-svg-icons";
// import DateTimePickerPopup from "../../ModelPopup/DateTimePopup";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const NewUBIDashboard = () => {
  const [filter, setFilter] = useState("CURRENT_YEAR");
  // const [modalIsOpen, setIsOpen] = useState(false);
  // const [startDateTime, setStartDateTime] = useState("");
  // const [endDateTime, setEndDateTime] = useState("");
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });
  const [showLoader, setShowLoader] = useState(0);
  const currentYear = dayjs().format("YYYY");
  // const [startYear, setStartYear] = useState(`${currentYear}-01-01 00:00:00`);
  // const [endYear, setEndYear] = useState(`${currentYear}-12-31 23:59:59`);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  const [drivStyleData, setDrivStyleData] = useState([]);
  const [drivBehavData, setDrivBehavData] = useState([]);
  const [aCatData, setACatData] = useState([]);
  const [sDistData, setSDistData] = useState([]);
  const [sSCData, setSSCData] = useState([]);
  const [mUHData, setMUHData] = useState([]);
  const [mobileUsagePoint, setmobileUsagePoint] = useState("");
  const [topRowStressStrainPoint, setTopRowStressStrainPoint] = useState("");
  const [drivingBehaviorPoint, setDrivingBehaviorPoint] = useState(0);

  const [dsp, setDsp] = useState(0);
  const [dstp, setDstp] = useState(0);

  const [mobileUsePoint, setMobileUsePoint] = useState(0);
  const [mobileScreenPoint, setMobileScreenPoint] = useState(0);

  const [scoreData, setScoreData] = useState([]);
  const [distData, setDistData] = useState([]);
  const [hoursData, setHoursData] = useState([]);
  const [totalkms, setTotalkms] = useState(null);
  const [yearlyDrivingScore, setYearlyDrivingScore] = useState(null);

  const [tenPoints, setTenPoints] = useState([
    {
      drivingScore: 0,
      anticipation: 0,
      selfConfidence: 0,
      drivingSkill: 0,
      DSP: 0,
      DSTP: 0,
      mobileScreenPointNew: 0,
      mobileUsePointNew: 0,
      totalKmSPoint: 0,
      ABCPoint: 0,
      totalEfficiency: 0,
    },
  ]);

  // const totalKmSPoint = UBIPoints.calculateDrivingKMSPoint(totalkms, Mobile);
  const totalKmSPoint = UBIPoints.getKMSPoints(filters.durationFilter, totalkms, startYear, endYear);

  // Helper function to convert month number to text
  const monthToText = (monthNumber) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[parseInt(monthNumber) - 1];
  };
  const filteredScoreData = months.map((month) => {
    const monthData = scoreData.find(
      (item) => monthToText(item.monthNumber) === month
    );
    return monthData
      ? { value: monthData.overallDrivingScore.toFixed(0) }
      : null;
  });
  // console.log("filteredScoreData",filteredScoreData);
  const ScoreDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: months,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Month",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        name: "Score",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 40, left: 50, right: 30, top: 30 },
    series: [
      {
        name: "Score",
        type: "bar",
        barWidth: 20,
        data: filteredScoreData,
        label: {
          show: true,
          position: "top",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: function ({ value }) {
            return value >= 0 && value <= 80
              ? "red"
              : value >= 81 && value <= 93
                ? "#FFBF00"
                : "#19543e";
          },
        },
      },
    ],
  };

  const filteredDistanceData = months.map((month) => {
    const monthData = distData.find(
      (item) => monthToText(item.monthNumber) === month
    );
    return monthData ? { value: monthData.tKiloMeter } : null;
  });

  const KilometerDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    yAxis: [
      {
        type: "category",
        data: months,
        nameLocation: "middle",
        name: "Month",
        nameTextStyle: {
          lineHeight: 50,
        },
      },
    ],
    xAxis: [
      {
        type: "value",
        min: 0,
        max: 1000,
        interval: 200,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Kilometer",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 40, left: 50, right: 60, top: 30 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Kilometer",
        data: filteredDistanceData,
        label: {
          show: true,
          position: "right",
        },
        itemStyle: {
          color: "#5b73e8",
        },
        emphasis: {
          focus: "series",
        },
      },
    ],
  };

  const filteredHoursData = months.map((month) => {
    const monthData = hoursData.find(
      (item) => monthToText(item.monthNumber) === month
    );
    return monthData ? { value: monthData.totalHours } : null;
  });

  const HourDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: months,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Month",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        name: "Hours",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 40, left: 50, right: 30, top: 30 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Hour",
        data: filteredHoursData,
        label: {
          show: true,
          position: "top",
        },
        itemStyle: {
          color: "#FF6619",
        },
        emphasis: {
          focus: "series",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: "{c}",
        },
      },
    ],
  };

  const convertToScaleOfTen = (value) => {
    if (value === undefined || value === null) {
      return 0;
    }
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : (parsedValue / 10).toFixed(0);
  };

  const getScoreKilometerHour = async () => {
    try {
      setShowLoader((current) => current + 1);
      var result = await UBIController.scoreKilometerHour(
        startYear,
        endYear,
        filters.durationFilter,
        // filters.startDateTime,
        // filters.endDateTime
      );
      // console.log("result",result)
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      // console.log("resultJSON", resultJSON);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("getScoreKilometerHour", resultJSONData);

        let ScoreDist = [];
        let Distance = [];
        let Hours = [];
        let totalKMS = null;
        let realYearlyDrivingScore = null;
        if (resultJSONData.length > 0) {
          resultJSONData.map((value) => {
            const deviceDetail = ValueFormat.parseAsArray(value.deviceDetail);
            realYearlyDrivingScore = ValueFormat.parseAsString(
              value.overallEfficiencyDetail.overallDrivingScore
            );

            deviceDetail.map((data) => {
              const monthNumber = ValueFormat.parseAsString(data.monthNumber);
              const overallDrivingScore = ValueFormat.parseAsString(
                data.overallDrivingScore
              );
              const tKiloMeter = ValueFormat.parseAsString(data.tKiloMeter);

              totalKMS += parseFloat(tKiloMeter);

              const totalHours = ValueFormat.parseAsString(data.totalHours);
              const roundedTotalHours = parseFloat(totalHours).toFixed(2);

              ScoreDist.push({
                monthNumber: monthNumber,
                overallDrivingScore: overallDrivingScore,
              });
              Distance.push({
                monthNumber: monthNumber,
                tKiloMeter: tKiloMeter,
              });
              Hours.push({
                monthNumber: monthNumber,
                totalHours: roundedTotalHours,
              });
            });
          });

          setScoreData(ScoreDist);
          setDistData(Distance);
          setHoursData(Hours);
          setTotalkms(totalKMS);
          setYearlyDrivingScore(realYearlyDrivingScore);
        } else {
          setScoreData([]);
          setDistData([]);
          setHoursData([]);
          setTotalkms(null);
          setYearlyDrivingScore(null);
        }
      } else {
        setTotalkms(null);
        setYearlyDrivingScore(null);
        const code = resultJSON.error.code;
        if (code == "E1206") {
          console.log("status failed");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const colors = [
    "#0AA700",
    "#67C86C",
    "#E9B506",
    "#F6D259",
    "#FF0505",
    "#AF0505",
  ];
  const [sPData, setSPData] = useState([]);
  const SafetyPointData = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        if (!sPData || sPData.length === 0) {
          return ""; // Return an empty string if no data
        }
        const data = sPData[params.seriesIndex];
        return `<strong>${data.name}</strong><br/>
                Self Confidence: ${data.selfConfidenceValue}<br/>
                Anticipatory Driving: ${data.anticipateDriveValue}<br/>
                Category: C${data.rideBox}`;
      },
    },
    legend: {
      show: true,
      top: -5,
      data: (sPData || []).map((data) => `C${data.rideBox}`), // Ensure the legend order
    },
    xAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Anticipatory Driving",
        nameTextStyle: {
          lineHeight: 50,
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        name: "Self Confidence",
        nameTextStyle: {
          lineHeight: 40,
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
    ],
    grid: { bottom: 50, left: 60, right: 30, top: 30 },
    series: [
      ...(sPData || [])
        .sort((a, b) => a.rideBox - b.rideBox) // Sort the data based on rideBox
        .map((data, index) => ({
          type: "scatter",
          symbol: "circle",
          symbolSize: 8,
          data: [[data.anticipateDriveValue, data.selfConfidenceValue]],
          name: `C${data.rideBox}`,
          itemStyle: {
            color: colors[data.rideBox - 1],
          },
        })),
    ],
  };

  const [forceRerender, setForceRerender] = useState(false);
  const getSafetyPointData = async () => {
    try {
      var result = await UBIController.getSafetyPoint(
        startYear,
        endYear,
        filters.durationFilter,
        // filters.startDateTime,
        // filters.endDateTime
      );

      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        var resultJSONData = resultJSON.data;

        if (resultJSONData && Array.isArray(resultJSONData)) {
          const SPData = resultJSONData.flatMap((value) =>
            value.rideBoxList.map((ride) => ({
              name: ride.name,
              anticipateDriveValue: parseFloat(ride.anticipateDriveValue, 2),
              selfConfidenceValue: parseFloat(ride.selfConfidenceValue, 2),
              rideBox: parseInt(ride.rideBox),
            }))
          );

          setSPData(SPData);
        } else {
          console.log("Data is missing or malformed");
          setSPData([]);
        }
      } else {
        console.log("Status Failed");
        setSPData([]);
      }
    } catch (err) {
      console.log(err);
      setSPData([]);
    }
  };
  const [indexStart, setIndexStart] = useState(0);
  const [subFilterText, setSubFilterText] = useState("");
  const [summaryDataList, setSummaryDataList] = useState([]);

  const getOverAllSummary = async () => {
    try {
      var result = await AdminDashboardController.overallSummaryUBI(
        indexStart,
        filters.durationFilter,
        subFilterText,
        startYear,
        endYear,
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        if (resultJSONData.length > 0) {
          console.log("getOverAllSummary: ", resultJSONData);
          // setDataLength(resultJSONData.length);
          var summaryData = [];
          resultJSONData.map((value) => {
            var firstName = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.firstName)) || "-";
            var username = ValueFormat.parseAsString(value.username) || "-";
            var totalRides = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.totalRides)) || "-";
            var userId = ValueFormat.parseAsString(value.userId);
            var overallDrivingScore = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || 0;
            var overallAnticipation = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || 0;
            var overallSelfConfidence = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || 0;
            var overallDrivingSkill = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || 0;
            var dStyleValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || 0;
            var dStateValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || 0;
            var kmsDriven = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || 0;
            var mobileCallValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || 0;
            var mobileScreenValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || 0;
            var abcPoint = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || 0;

            var overallDrivingScoreTooltipValue = ValueFormat.parseAsFloat(value.drivingScorePoint, 2) || "-";
            var overallAnticipationTooltipValue = ValueFormat.parseAsFloat(value.anticipationPoint, 2) || "-";
            var overallSelfConfidenceTooltipValue = ValueFormat.parseAsFloat(value.selfConfidencePoint, 2) || "-";
            var overallDrivingSkillTooltipValue = ValueFormat.parseAsFloat(value.drivingSkillPoint, 2) || "-";
            var dStyleValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 2) || "-";
            var dStateValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 2) || "-";
            var kmsDrivenTooltipValue = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 2) || "-";
            var mobileCallValueTooltipValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 2) || "-";
            var mobileScreenValueTooltipValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 2) || "-";
            var abcPointTooltipValue = ValueFormat.parseAsFloat(value.accBrakCorPoint, 2) || "-";

            var efficiency = ValueFormat.parseAsFloat(value.drivingEfficiency, 0) || "-";
            var efficiencyTooltipValue = ValueFormat.parseAsFloat(value.drivingEfficiency, 2) || "-";
            var totalEfficiencyRaw =
              parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
              parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));

            var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0) || 0;
            var totalEfficiencyTV = ValueFormat.parseAsFloat(totalEfficiencyRaw, 1);
            var resultObject = {
              firstName: firstName,
              username: username || "N/A",
              drivingScore: overallDrivingScore,
              totalRides: totalRides,
              userId: userId,
              ABCPoint: abcPoint,
              mobileScreenPointNew: mobileScreenValue,
              mobileUsePointNew: mobileCallValue,
              overallAnticipation: overallAnticipation,
              overallSelfConfidence: overallSelfConfidence,
              overallDrivingSkill: overallDrivingSkill,
              totalKmSPoint: kmsDriven,
              DSP: dStateValue,
              DSTP: dStyleValue,
              efficiency: efficiency,
              totalEfficiency: totalEfficiency,
              efficiencyTooltipValue: efficiencyTooltipValue,
              overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
              overallAnticipationTooltipValue: overallAnticipationTooltipValue,
              overallSelfConfidenceTooltipValue: overallSelfConfidenceTooltipValue,
              overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
              dStyleValueTooltipValue: dStyleValueTooltipValue,
              dStateValueTooltipValue: dStateValueTooltipValue,
              kmsDrivenTooltipValue: kmsDrivenTooltipValue,
              mobileCallValueTooltipValue: mobileCallValueTooltipValue,
              mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
              abcPointTooltipValue: abcPointTooltipValue,
              totalEfficiencyTV: totalEfficiencyTV
            };
            summaryData.push(resultObject);
            setTenPoints((prevTenPoints) => [
              {
                ...prevTenPoints[0],
                drivingScore: overallDrivingScore,
                anticipation: overallAnticipation,
                selfConfidence: overallSelfConfidence,
                drivingSkill: overallDrivingSkill,
                DSP: dStateValue,
                DSTP: dStyleValue,
                mobileScreenPointNew: mobileScreenValue,
                mobileUsePointNew: mobileCallValue,
                totalKmSPoint: kmsDriven,
                ABCPoint: abcPoint,
                totalEfficiency: totalEfficiency,
              },
            ]);
          });

          setSummaryDataList(summaryData);

        } else {
          // setDataDisplay(false);
        }
      } else if (status == "FAILED") {
        // setDataDisplay(false);
        var code = resultJSON.error.code;
        if (code == "E1206") {
          // setDataDisplay("failed");
        }
      }
    } catch (e) {
      console.log("Catch error: ", e);
    }
  };


  useEffect(() => {
    setForceRerender((prev) => !prev);
  }, [sPData]);

  useEffect(() => {
    getScoreKilometerHour();
    getSafetyPointData();
    getOverAllSummary();
  }, [
    startYear,
    endYear,
    filters.durationFilter,
    // filter
    // filters.startDateTime,
    // filters.endDateTime,
  ]);

  const dynamicData = [
    {
      title: "Driving Score Point",
      overallData: yearlyDrivingScore ? yearlyDrivingScore / 10 : 0,
      // row1Title: 'Row 1',
      secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      currentYearValue: mobileUsagePoint,
      postFix: "units",
      since: "Mobile Usage Point",
    },
    {
      title: "Safe Speed Point",
      overallData: 0,
      // row1Title: 'Row 1',
      secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      currentYearValue: drivingBehaviorPoint,
      postFix: "units",
      since: "Driving Behavior Point",
    },
    {
      // title: "Stress Strain Point",
      title: "Red Risk Point",
      overallData: "-",
      //  overallData: topRowStressStrainPoint,
      // row1Title: 'Row 1',
      secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      currentYearValue: totalKmSPoint,
      postFix: "units",
      since: "Driving Kms Point",
    },
    // {
    // title: "Stress Strain Point",
    // overallData: topRowStressStrainPoint,
    // row1Title: 'Row 1',
    // secondaryData: 567,
    // row2Title: 'Row 2',
    // currentMonthGraphData: [50, 40, 30, 20, 10],
    // statusColor: "danger",
    // currentYearValue: totalKmSPoint,
    // postFix: "units",
    // since: "Driving Kms Point",
    // },
    {
      title: "Stress Strain Point",
      overallData: topRowStressStrainPoint,
      // row1Title: 'Row 1',
      // secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      // currentYearValue: '₹9,111.39',
      postFix: "units",
      // since:"Your Insurance Amount"
    },
  ];

  const [shouldBreak, setShouldBreak] = useState(
    window.innerWidth >= 1200 && window.innerWidth <= 1375
  );

  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        setChartWidth("98vw");
      } else {
        setChartWidth("45vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Calculate total ride count for each category
  const totalRideCount = ["C1", "C2", "C3", "C4", "C5", "C6"].reduce(
    (acc, categoryKey) => {
      const count = sPData.filter(
        (data) => `C${data.rideBox}` === categoryKey
      ).length;
      acc[categoryKey] = count;
      return acc;
    },
    {}
  );

  // Calculate total number of rides
  const totalRides = Object.values(totalRideCount).reduce((sum, count) => sum + count, 0);

  // Calculate percentage for each category
  const ridePercentage = Object.keys(totalRideCount).reduce((acc, categoryKey) => {
    const percentage = (totalRideCount[categoryKey] / totalRides) * 100;
    acc[categoryKey] = isNaN(percentage) ? "0" : percentage.toFixed(2);  // keeping 2 decimal places for readability
    return acc;
  }, {});

  const descriptions = {
    C1: "C1 indicates the Safe Driver rides in the respective areas.",
    C2: "C2 indicates the Sportive Driver rides in the respective areas.",
    C3: "C3 indicates the Quite Safe Driver rides in the respective areas.",
    C4: "C4 indicates the Reckless Driver rides in the respective areas.",
    C5: "C5 indicates the Quite Dangerous Driver rides in the respective areas.",
    C6: "C6 indicates the Dangerous Driver rides in the respective areas.",
  };
  // Map and display total ride count in the table
  const rideCountRows = Object.keys(totalRideCount).map((categoryKey) => (
    <td key={categoryKey}>{totalRideCount[categoryKey]}</td>
  ));

  const getDatafromDrivingSafetyArea = (
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    mobileUsagePoint,
    topRowStressStrainPoint,
    drivingBehaviorPoint,
    DSP,
    DSTP,
    mobileUsePoint,
    mobileScreenPoint
  ) => {
    setDrivStyleData(data1);
    setDrivBehavData(data2);
    setACatData(data3);
    setSDistData(data4);
    setSSCData(data5);
    setMUHData(data6);
    setmobileUsagePoint(mobileUsagePoint);
    setTopRowStressStrainPoint(topRowStressStrainPoint);
    setDrivingBehaviorPoint(drivingBehaviorPoint);
    setDsp(DSP);
    setDstp(DSTP);
    setMobileUsePoint(mobileUsePoint);
    setMobileScreenPoint(mobileScreenPoint);
  };
  const history = useNavigate();
  const handlePointClick = (params) => {
    // console.log("vvvvv", params);
    if (params.data) {
      history("/ridedetails", {
        state: {
          divisionId: params.data[4],
          userId: params.data[5],
          rideId: params.data[6],
        },
      });
      // localStorage.setItem(SC_LOGIN_USER_FIRST_NAME,params.data[7])
    }
  };

  let dayDisplay;
  let description;
  if (filters.durationFilter == "CURRENT_DAY") {
    dayDisplay = UtilDateTime.getCurrentDay();
    description = `Showing results for today, ${dayDisplay}`
  } else if (filters.durationFilter == "CURRENT_WEEK") {
    dayDisplay = UtilDateTime.getCurrentWeek();
    description = `Showing results for the current week, ${dayDisplay}`
  } else if (filters.durationFilter == "CURRENT_MONTH") {
    dayDisplay = UtilDateTime.getCurrentMonth();
    description = `Showing results for the current month, ${dayDisplay}`
  } else if (filters.durationFilter == "CURRENT_YEAR") {
    dayDisplay = UtilDateTime.getCurrentYear();
    description = `Showing results for the current year, ${dayDisplay}`
  } else if (filters.durationFilter == "CUSTOM_DURATION") {
    dayDisplay = UtilDateTime.getSelectedYear(startYear);
    description = `Showing results for the selected year, ${dayDisplay}`
  } else {
    dayDisplay = "";
    description = `Showing results for all the time`
  }
  const handleChange = (filterType) => {
    setFilters({
      startDateTime: "",
      endDateTime: "",
      durationFilter: filterType,
    });
    if (filterType != "CUSTOM_DURATION") {
      setStartYear("");
      setEndYear("");
    }
  };

  // useEffect(() => {
  // if (count == 1) {
  // setFilters({
  //   startDateTime: startDateTime,
  //   endDateTime: endDateTime,
  //   durationFilter: filter,
  // });
  // }
  // setCount(1);
  // }, [filter, startDateTime, endDateTime]);

  return (
    <React.Fragment>
      <div className="page-content">
        {showLoader > 0 && <Loader />}
        <Row className="mb-3 mt-3">
          <Col
            xs={12}
            className="d-flex justify-content-between align-items-center text-black"
          >
            <div className="d-flex align-items-center" style={{ gap: 2 }}><h5 className="mb-1">Driving Behaviour Dashboard</h5><span>({description})</span></div>
            {/* <div className="ms-auto"> */}
            <div className="d-flex align-items-center" style={{ float: "right" }}>

              <div style={{ paddingTop: 8 }}>
                {/* <button
                  type="button"
                  className={
                    filters.durationFilter == ""
                      ? "btn btn-primary btn-sm me-2"
                      : "btn btn-soft-secondary btn-sm me-2"
                  }
                  onClick={() => handleChange("")}
                  data-tooltip-id="allBl"
                >
                  All
                  <ReactTooltip
                    id="allBl"
                    place="bottom"
                    content="Showing for all the time"
                    style={{ background: "black", zIndex: 100 }}
                  />
                </button> */}
                <button
                  type="button"
                  className={
                    filters.durationFilter == "CURRENT_DAY"
                      ? "btn btn-primary btn-sm me-2"
                      : "btn btn-soft-secondary btn-sm me-2"
                  }
                  onClick={() => handleChange("CURRENT_DAY")}
                  data-tooltip-id="cd"
                >
                  CD
                  <ReactTooltip
                    id="cd"
                    place="bottom"
                    content="Current Day"
                    style={{ background: "black", zIndex: 100 }}
                  />
                </button>
                <button
                  type="button"
                  className={
                    filters.durationFilter == "CURRENT_WEEK"
                      ? "btn btn-primary btn-sm me-1"
                      : "btn btn-soft-secondary btn-sm me-1"
                  }
                  onClick={() => handleChange("CURRENT_WEEK")}
                  data-tooltip-id="cw"
                >
                  CW
                  <ReactTooltip
                    id="cw"
                    place="bottom"
                    content="Current Week"
                    style={{ background: "black", zIndex: 100 }}
                  />
                </button>{" "}
                <button
                  type="button"
                  className={
                    filters.durationFilter == "CURRENT_MONTH"
                      ? "btn btn-primary btn-sm me-1"
                      : "btn btn-soft-secondary btn-sm me-1"
                  }
                  onClick={() => handleChange("CURRENT_MONTH")}
                  data-tooltip-id="cm"
                >
                  CM
                  <ReactTooltip
                    id="cm"
                    place="bottom"
                    content="Current Month"
                    style={{ background: "black", zIndex: 100 }}
                  />
                </button>{" "}
                <button
                  type="button"
                  className={
                    filters.durationFilter == "CURRENT_YEAR"
                      ? "btn btn-primary btn-sm me-2"
                      : "btn btn-soft-secondary btn-sm me-2"
                  }
                  onClick={() => handleChange("CURRENT_YEAR")}
                  data-tooltip-id="cy"
                >
                  CY
                  <ReactTooltip
                    id="cy"
                    place="bottom"
                    content="Current Year"
                    style={{ background: "black", zIndex: 100 }}
                  />
                </button>{" "}
              </div>
              {/* <button
                  type="button"
                  className={
                    filters.durationFilter == "CUSTOM_DURATION"
                      ? "btn btn-primary btn-sm me-2"
                      : "btn btn-soft-secondary btn-sm me-2"
                  }
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  data-tooltip-id="custom duration"
                >
                  <FontAwesomeIcon icon={faClock} />
                  <ReactTooltip
                    id="custom duration"
                    place="bottom"
                    content="Showing zone summary for custom duration"
                    style={{ background: "black", zIndex: 100 }}
                  />
                </button> */}
              <div className="me-2">
                <YearPopup
                  setStartYear={setStartYear}
                  setEndYear={setEndYear}
                  setFilter={handleChange}
                />
              </div>
              <div style={{ paddingTop: 8 }}>
                <ViewPdf ubiData={[filters, startYear, endYear, sPData, tenPoints, filteredScoreData, filteredDistanceData, filteredHoursData, drivStyleData, drivBehavData,
                  aCatData, sDistData, sSCData, mUHData]} />
              </div>
            </div>
            {/* </div> */}
          </Col>
        </Row>

        <Row className="mx-1">
          <Card className="" style={{ height: 'auto' }}>
            <CardHeader className="border-0">
              <p className="mb-0">{displayText.DRIVER_BEHAVIOUR_DESCRIPTION}</p>
            </CardHeader>
          </Card>
        </Row>

        <UbiTopRowWidgets summaryDataList={summaryDataList} />

        <Row className="mt-2">
          {/* <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Insurance Summary</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <Col xs={6} className="d-flex flex-column align-items-center">
                  <div className="pb-3">
                    <h6>Actual Insurance Amount</h6>
                  </div>
                  <div
                    data-tooltip-id="fd"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <CircularProgressbar
                      value={100}
                      text="₹ 10,00,000"
                      styles={buildStyles({
                        width: "20px",
                        textSize: "12px",
                        textColor: "block",
                        pathColor:
                          90 >= 0 && 90 <= 80
                            ? "#E21212"
                            : 90 >= 81 && 90 <= 93
                            ? "#DEA602"
                            : "#06CD91",
                        trailColor: "#0095FF",
                      })}
                    />
                  </div>
                  <ReactTooltip
                    id="fd"
                    place="top"
                    content="Actual Insurance Amount"
                  />
                </Col>
                <Col xs={6} className="d-flex flex-column align-items-center">
                  <div className="pb-3">
                    <h6>Your Insurance Amount</h6>
                  </div>
                  <div
                    data-tooltip-id="qw"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <CircularProgressbar
                      value={75}
                      text="₹ 7,50,000"
                      styles={buildStyles({
                        width: "20px",
                        textSize: "12px",
                        textColor: "block",
                        pathColor:
                          90 >= 0 && 90 <= 80
                            ? "#E21212"
                            : 90 >= 81 && 90 <= 93
                            ? "#DEA602"
                            : "#06CD91",
                        trailColor: "#0095FF",
                      })}
                    />
                  </div>
                  <ReactTooltip
                    id="qw"
                    place="top"
                    content="Your Insurance Amount"
                  />
                </Col>
              </CardBody>
            </Card>
          </Col> */}

          <Col lg={6} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Safety Point</h4>
              </CardHeader>
              <CardBody
                className="d-flex flex-column align-items-center"
                style={{ height: "450px" }}
              >
                <div className="d-flex justify-content-between w-100">
                  <h6 className="ms-4">Unsafe</h6>
                  <h6>Unsafe</h6>
                </div>
                <ReactEcharts
                  key={forceRerender}
                  option={SafetyPointData}
                  style={{ height: "100%", width: chartWidth }}
                // onEvents={{
                //   click: handlePointClick,
                // }}
                />
                <div className="d-flex justify-content-between m-0 p-0 w-100">
                  <h6 className="ms-4">Safe</h6>
                  <h6>Unsafe</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Safety Point Rides</h4>
              </CardHeader>
              <CardBody style={{ height: "450px" }}>
                <Row className="my-2">
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th className="">Category</th>
                        <th className="">Rides</th>
                        <th className=""> Percentage</th>
                        <th className="">Description</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                      {rideCountRows.map((row) => (
                        <tr key={row.key}>
                          <td>{row.key}</td>
                          <td>{row.props.children}</td>
                          <td>{descriptions[row.key]}</td>
                        </tr>
                      ))}
                    </tbody> */}
                    <tbody>
                      {Object.keys(totalRideCount).map((categoryKey) => (
                        <tr key={categoryKey}>
                          <td>{categoryKey}</td>
                          <td>{totalRideCount[categoryKey]}</td>
                          <td>{ridePercentage[categoryKey]}%</td>
                          <td style={{ fontSize: '12px' }}>{descriptions[categoryKey]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4 mt-4">
          <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Score Distribution</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <ReactEcharts
                  option={ScoreDistributionData}
                  style={{ height: "100%", width: chartWidth }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Kilometer Distribution</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <ReactEcharts
                  option={KilometerDistributionData}
                  style={{ height: "100%", width: chartWidth }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Hour Distribution</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <ReactEcharts
                  option={HourDistributionData}
                  style={{ height: "100%", width: chartWidth }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <DrivingSafetyAreav3
          startYear={startYear}
          endYear={endYear}
          getDatafromDrivingSafetyArea={getDatafromDrivingSafetyArea}
          filters={filters.durationFilter}
        // startDateTime={filters.startDateTime}
        // endDateTime={filters.endDateTime}
        />
        {/* <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
        /> */}
      </div>
    </React.Fragment>
  );
};

export default NewUBIDashboard;

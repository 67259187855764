// import React, { useEffect } from "react";
// import ReactPDF, {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFViewer,
//   Image,
//   Rect,
//   Svg,
//   Line,
// } from "@react-pdf/renderer";
// import RuduLogo from "../../../assets/images/logo/motivAiLogoPng.png";
// import MotivaiLogo from "../../../assets/images/logo.motivAI.png";
// import { useState } from "react";
// import LoaderPdf from "../../Common/loaderPdf";
// import {
//   SC_USERNAME,
//   SC_LOGIN_USER_FIRST_NAME,
//   SC_COMPANY_NAME,
//   SC_PROFILE_OPEN,
//   SC_USER_LOGIN_TYPE,
//   SC_DIVISION_NAME,
//   SC_REGISTRATION_DATE,
//   SC_USER_FIRST_NAME,
// } from "../../../constant/StorageConstant";
// import { number } from "echarts";
// import ValueFormat from "../../../util/ValueFormat";

// // Create Document Component
// export default function RideDetailPdfNew({
//   propsDataRd,
//   safetyData,
//   drivingStatus,
//   dayNightPercentage,
//   otherRiskData,
//   DistributionData,
//   driverStateList,
//   driverStyleList,
//   stressStrainData,
//   riskDataList,
//   overSpeedTableData,
//   mobileUseTableData,
//   mobileScreenTableData,
//   riskDataTable,
//   stressData,
// }) {
//   const [showLoader, setShowLoader] = useState(1);
//   const [summary, setSummary] = useState("N/A");
//   const [pageNumber, setPageNumber] = useState(1);

//   const companyName = localStorage.getItem(SC_COMPANY_NAME);
//   const firstName = (ValueFormat.capitalizeFirstLetter(localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)));
//   const userStatus = localStorage.getItem(SC_PROFILE_OPEN);
//   const userName = localStorage.getItem(SC_USERNAME);
//   const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE);
//   const unitName = localStorage.getItem(SC_DIVISION_NAME);
//   const [otherRiskDataTotal, setOtherRiskDataTotal] = useState([]);
//   const createdDate = localStorage.getItem(SC_REGISTRATION_DATE);
//   useEffect(() => {
//     const extractedtableData = (propsDataRd || []).map((item) => ({
//       number: item.type == "RIDE" ? item.overallData : 0,
//       // count: item.count,
//       // duration: item.duration,
//       // kilometer: item.kilometer
//     }));
//     // console.log(propsDataRd, "propsDataRd");
//     // console.log(drivingStatus, "drivingStatus");
//     // console.log(dayNightPercentage, "dayNightPercentage");
//     // console.log(otherRiskData, "otherRiskData");
//     // console.log(stressStrainData, "stressStrainData");
//     // console.log(DistributionData, "DistributionData");
//     // console.log(
//     //   ...extractedtableData.map((item) => item.number),
//     //   "extractedtableData"
//     // );
//   }, []);

//   function displayUserName() {
//     if (userName && userName.length >= 4) {
//       const firstTwo = userName.slice(0, 2);
//       const lastTwo = userName.slice(-2);
//       const middleXs = "x".repeat(userName.length - 4);

//       const displayedUserName = firstTwo + middleXs + lastTwo;
//       return displayedUserName;
//     } else {
//       return userName; // or handle the invalid case as needed
//     }
//   }

//   const drivingScore =
//     propsDataRd && propsDataRd.length > 0 ? propsDataRd[0].overallData : null;
//   useEffect(() => {
//     // ... your logic

//     if (drivingScore !== null && drivingScore !== undefined) {
//       if (drivingScore > 90) {
//         setSummary(
//           "You drive safely. You have excellent driving practice and you have a nature of very cautious"
//         );
//       } else if (drivingScore > 60 && drivingScore <= 90) {
//         setSummary(
//           "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
//         );
//       } else {
//         setSummary(
//           "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
//         );
//       }
//     } else {
//       // Handle the case when drivingScore is null or undefined
//       setSummary("Unable to determine driving score. Please check your data.");
//     }
//   }, [propsDataRd]);
//   // const tableData = [
//   //     summaryData.slice(0, 5).map(item => item.firstName),
//   //     summaryData.slice(0, 5).map(item => item.rank),
//   //     summaryData.slice(0, 5).map(item => item.totalRides),
//   //     summaryData.slice(0, 5).map(item => item.totalAlertDataCount),
//   //     summaryData.slice(0, 5).map(item => item.totalManualDataCount),
//   //     summaryData.slice(0, 5).map(item => item.drivingScore),
//   // ];
//   const extractedtableData = otherRiskData.map((item) => ({
//     name: item.name,
//     count: item.count,
//     duration: item.duration,
//     kilometer: item.kilometer,
//   }));

//   useEffect(() => {
//     let otherRisk = [];
//     let count = 0;
//     let duration = 0;
//     let distance = 0;
//     const ff = otherRiskData.map((val) => {
//       count += val.count;
//       duration += val.duration;
//       distance += val.kilometer;
//       otherRisk.push([val.count, val.duration, val.kilometer]);
//     });
//     otherRisk.push([count, duration, distance]);
//     setOtherRiskDataTotal(otherRisk);
//     // console.log(otherRisk, "otherRiskDataotherRiskData");
//   }, []);

//   // Generating the tableData array
//   const tableData = [
//     [" ", ...extractedtableData.map((item) => item.name)],
//     ["Count", ...extractedtableData.map((item) => item.count)],
//     ["Minutes", ...extractedtableData.map((item) => item.duration)],
//     ["Kilometer", ...extractedtableData.map((item) => item.kilometer)],
//   ];

//   const tableDataRideSummary = [
//     ["Number"],
//     ["Score"],
//     ["Skill"],
//     ["Anticipation"],
//     ["Self Confidence"],
//     ["Category"],
//   ];

//   const tableData1 = [
//     ["State", "Abnormal State", "Count"],
//     ["0", "Unknown", driverStateList.driverUnknown],
//     ["1", "Normal", driverStateList.driverNormal],
//     ["2", "Tired", driverStateList.driverTried],
//     ["3", "Angry", driverStateList.driverAngry],
//   ];

//   const accTableData = [
//     [" ", "Low", "Medium", "High"],
//     [
//       "Acceleration",
//       stressStrainData.sAccelerationLow,
//       stressStrainData.sAccelerationMedium,
//       stressStrainData.sAccelerationHigh,
//     ],
//     [
//       "Cornering",
//       stressStrainData.sCorneringLow,
//       stressStrainData.sCorneringMedium,
//       stressStrainData.sCorneringHigh,
//     ],
//     [
//       "Braking",
//       stressStrainData.sBrakingLow,
//       stressStrainData.sBrakingMedium,
//       stressStrainData.sBrakingHigh,
//     ],
//   ];

//   const tableData2 = [
//     ["State", "Driving Style", "Count"],
//     ["0", "Unknown", driverStyleList.drivingUnknown],
//     ["1", "Normal", driverStyleList.drivingNormal],
//     ["2", "Sportive", driverStyleList.drivingSportive],
//     ["3", "Eco", driverStyleList.drivingEco],
//     ["4", "Chaotic", driverStyleList.drivingChaotic],
//     ["5", "Aggressive", driverStyleList.drivingAggressive],
//   ];

//   const modifiedRiskAlertData = riskDataList
//     .sort((a, b) => a.title.localeCompare(b.title))
//     .map((item) => ({
//       type: "body",
//       title: item.title,
//       value: item.overallData,
//     }));
//   // const [riskAlterDatas
//   // console.log(modifiedRiskAlertData, "modifiedRiskAlertData");
//   const [riskAlertData, setRiskAlertData] = useState([]);
//   // Split the data into chunks of 6 items per row
//   const chunkSize = 5;

//   // for (let i = 0; i < modifiedRiskAlertData.length; i += chunkSize) {
//   //   riskAlertData.push(modifiedRiskAlertData.slice(i, i + chunkSize));
//   // }

//   const filterArrayForGivenLetters = (data, letter) => {
//     // let firstLetter = letter.split("-")[0];
//     // let secondLetter = letter.split("-")[1];
//     // let filtered =  data.filter(
//     //   (val) =>
//     //     firstLetter <= val.title[0].toLowerCase() &&
//     //     val.title[0].toLowerCase() <= secondLetter
//     // );

//     let filtered = data.slice(letter * 8, letter * 8 + 8); // 0,8 | 8, 16 | 16, 24

//     return filtered;
//   };

//   useEffect(() => {
//     var riskAlertDataaa = [];

//     [0, 1, 2].map((val) => {
//       let FilterData = filterArrayForGivenLetters(modifiedRiskAlertData, val);
//       riskAlertDataaa.push([
//         { type: "header", title: "Name", value: "Value" },
//         ...FilterData,
//       ]);
//       // console.log(FilterData, "riskAlertDatssa");
//     });
//     setRiskAlertData(riskAlertDataaa);
//     // console.log(riskAlertDataaa, "riskAlertDatssa1");
//   }, []);

//   // for (let i = 0; i < modifiedRiskAlertData.length; i += chunkSize) {
//   //   riskAlertData.push(modifiedRiskAlertData.slice(i, i + chunkSize));
//   // }

//   const mobileScreenTable = [
//     ["Date/Time", "Distance (km)", "Speed (kmph)"],
//     ...(mobileScreenTableData || []).map((item, index) => [
//       item.date,
//       item.distance,
//       item.speed,
//     ]),
//   ];

//   const mobileUseTable = [
//     ["Date/Time", "Distance (km)", "Speed (kmph)"],
//     ...(mobileUseTableData || []).map((item, index) => [
//       item.date,
//       item.distance,
//       item.speed,
//     ]),
//   ];

//   const overSpeedTable = [
//     ["Date/Time", "Distance (km)", "Speed Limit (kmph)", "Speed (kmph)"],
//     ...(overSpeedTableData || []).map((item, index) => [
//       item.date,
//       item.distance,
//       item.speedLimit,
//       item.speed,
//     ]),
//   ];

//   const formatSubCategory = (subCategory) => {
//     return subCategory
//       .toLowerCase()
//       .split("_")
//       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//       .join(" ");
//   };

//   const riskAlertTable = [
//     ["Alert", "Time (HH:MM)", "Speed (kmph)", "Distance (km)", "ZIP Code"],
//     ...riskDataTable
//       .slice(0, 5)
//       .map((item, index) => [
//         item.category === "START_DATA" || item.category === "END_DATA"
//           ? formatSubCategory(item.category)
//           : formatSubCategory(item.subCategory),
//         item.timeVal,
//         item.distanceVal,
//         item.speedVal,
//         item.zipcodeVal,
//       ]),
//   ];

//   const stressDataTable = [
//     ["Date/Time", "Type", "Sub Type", "Speed (kmph)"],
//     ...stressData
//       .slice(0, 6)
//       .map((item, index) => [
//         item.date,
//         item.type,
//         item.subCategoryLevel,
//         item.speed,
//       ]),
//   ];

//   const fontBold = {
//     fontFamily: "Helvetica-Bold",
//   };
//   const fontItalic = {
//     fontFamily: "Times-BoldItalic",
//   };
//   const months = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   const months1 = [
//     "01",
//     "02",
//     "03",
//     "04",
//     "05",
//     "06",
//     "07",
//     "08",
//     "09",
//     "10",
//     "11",
//     "12",
//   ];
//   const currentDate = new Date();

//   const reversedDateFormat =
//     currentDate.getDate().toString().padStart(2, "0") +
//     "-" +
//     months1[currentDate.getMonth()] +
//     "-" +
//     currentDate.getFullYear();

//   const header = [
//     { title: "App Name", name: "Motiv AI" },
//     { title: "Company Name", name: companyName },
//     { title: "Date", name: reversedDateFormat },
//     { title: "First Name", name: firstName },
//   ];

//   const footer = [
//     { f: "https://rudu.motivai.tech" },
//     { f: "https://motivai.tech" },
//     { f: '"Drive Safe, Arrive Safe"' },
//     { f: "A MotivAI Product" },
//   ];

//   const safeData = [
//     {
//       texts:
//         "An effective driver stays consistently alert and careful, making safety the main focus throughout the entire ride.",
//     },
//     {
//       texts:
//         "A driver stays very attentive and confident, ensuring a high level of safeness but with a sporty attitude throughout the entire journey.",
//     },
//     {
//       texts:
//         "A driver who is moderately safe with moderate alertness, making sure the journey is moderately safe all the way.",
//     },
//     {
//       texts:
//         "A driver being reckless, holds a moderate level of alertness and high confidence, leading to a journey that is less moderate in safeness throughout the Ride.",
//     },
//     {
//       texts:
//         "A Risky Driver with less attention and who is prone to red risks, making journey unsafe throughout the Ride.",
//     },
//     {
//       texts:
//         "A Daring Driver being less attentive and Over confident, making the journey highly Unsafe.",
//     },
//   ];

//   useEffect(() => {
//     setTimeout(() => {
//       setShowLoader(0);
//     }, 4000);
//   }, []);

//   const title = (value) => {
//     let regex = /\((.*?)\)/g;
//     return value.replace(regex, "").trim();
//   };

//   const titleValue = (value) => {
//     let regex = /\((.*?)\)/g;
//     let matches = [];
//     let match;
//     while ((match = regex.exec(value))) {
//       matches.push(match[1]);
//     }
//     return matches[0];
//   };

//   const riskHistogram = DistributionData.xaxis.map((xAxisValue, index) => ({
//     title: title(xAxisValue),
//     titleValue: titleValue(xAxisValue),
//     value: DistributionData.data[index].value || 0,
//     customData: DistributionData.customData[index],
//   }));


//   const modifiedRiskHistogram = () => {
//     return riskHistogram.map((item) => {
//       // Create a new object to avoid modifying the original data
//       const sItem = { ...item };

//       // Apply your conditions to each item
//       if (sItem.value > 0) {
//         if (sItem.value < 10) {
//           sItem.value = ((sItem.value / 10) * 390).toFixed(1);
//         } else {
//           sItem.value = 390;
//         }
//       } else {
//         sItem.value = 0;
//       }

//       return sItem;
//     });
//   };

//   const modifiedriskHistogram = modifiedRiskHistogram();

//   const styles = StyleSheet.create({
//     tablestyle: {
//       border: "1.5px solid black",
//       borderTop: "none",
//       borderRight: "none",
//       padding: "5px",
//       fontSize: "10px",
//       textAlign: "center",
//       flex: 1,
//     },
//     tablestyleNew: {
//       border: "1.5px solid black",
//       borderTop: "none",
//       borderRight: "none",
//       padding: "5px",
//       fontSize: "10px",
//       textAlign: "center",
//       width: "60px",
//     },
//     tablestyleNewRide: {
//       border: "1.5px solid black",
//       borderTop: "none",
//       borderRight: "none",
//       padding: "5px",
//       fontSize: "10px",
//       textAlign: "center",
//       width: "90px",
//     },
//     page: {
//       paddingLeft: 30,
//       paddingRight: 30,
//       width: "100%",
//     },
//     headerCell: {
//       textAlign: "center",
//       fontFamily: "Helvetica-Bold",
//     },
//   });

//   const watermarkText = "Motiv AI";
//   const watermarkStyles = StyleSheet.create({
//     watermark: {
//       position: "absolute",
//       fontSize: 95,
//       opacity: 0.3,
//       color: "gray",
//       transform: "rotate(-52deg)",
//       justifyContent: "center",
//       textAlign: "center",
//       alignItems: "center",
//       top: "42%",
//       left: "22%",
//     },
//   });

//   const totalPages = 0;

//   const colors = ["blue", "red", "green"];

//   return (
//     <React.Fragment>
//       {showLoader > 0 ? <LoaderPdf /> : ""}

//       <PDFViewer
//         style={{
//           height: "96%",
//           width: "100%",
//           marginTop: "20px",
//           marginBottom: "5px",
//         }}
//       >
//         <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
//           <Page pageNumber={pageNumber}>
//             <Text style={{ ...watermarkStyles.watermark }}>
//               {watermarkText}
//             </Text>
//             <View style={{ height: "5.3%", margin: "0px 30px 0px 30px" }}>
//               <View
//                 style={{
//                   flexDirection: "row",
//                   borderBottom: "1px solid black",
//                   backgroundColor: "lightgrey",
//                 }}
//               >
//                 {/* <View style={{ width: "77%", marginBottom: "5px"}}> */}

//                 <View
//                   style={{
//                     marginTop: "5px",
//                     width: "100%",
//                     flexDirection: "row",
//                     // justifyContent: "space-between",
//                   }}
//                 >
//                   <Image
//                     src={RuduLogo}
//                     style={{
//                       height: "21px",
//                       width: "100px",
//                       marginTop: "2px",
//                       marginBottom: "5px",
//                       marginLeft: "10px",
//                     }}
//                   />
//                   <View
//                     style={{
//                       flexDirection: "Row",
//                       marginBottom: "5px",
//                       marginLeft: "30px",
//                     }}
//                   >
//                     <View style={{ flexDirection: "row", width: "80%" }}>
//                       <Text
//                         style={[fontBold, { fontSize: 11, width: "123px" }]}
//                       >
//                         {header[1].title}:
//                       </Text>
//                       <Text style={{ fontSize: 11, width: "100%" }}>
//                         {header[1].name}
//                       </Text>
//                     </View>
//                     <View style={{ flexDirection: "row" }}>
//                       <View
//                         style={{
//                           flexDirection: "row",
//                           marginTop: "2px",
//                           width: "60%",
//                         }}
//                       >
//                         <Text
//                           style={[fontBold, { fontSize: 11, width: "85px" }]}
//                         >
//                           {header[3].title}:
//                         </Text>
//                         <Text style={{ fontSize: 11, width: "100%" }}>
//                           {header[3].name}
//                         </Text>
//                       </View>
//                       <View
//                         style={{
//                           flexDirection: "row",
//                           marginTop: "2px",
//                           marginLeft: "60px",
//                         }}
//                       >
//                         <View style={{ flexDirection: "row" }}>
//                           <Text style={[fontBold, { fontSize: 11 }]}>
//                             {header[2].title}:{" "}
//                           </Text>
//                           <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
//                         </View>
//                       </View>
//                     </View>

//                     {/* <View style={{flexDirection:"row"}}>
//                        <Text
//                    style={[fontBold, { fontSize: 11 }]}
//                   >
//                     {header[0].title}: 
//                   </Text>
//                   <Text
//                    style={{fontSize: 11,marginLeft:"3px" }}
//                   >
//                     {header[0].name}
//                   </Text>

//                        </View> */}

//                     {/* <View style={{flexDirection:"row",marginTop:"2px"}}>
//                        <Text style={[fontBold, { fontSize: 11 }]}>
//                         {header[2].title}: </Text>
//                         <Text style={{ fontSize: 11,marginLeft:"1px"}}>
//                         {header[2].name}</Text>

//                        </View> */}
//                   </View>

//                   {/* <View
//                       style={{
//                         flexDirection:"column",
//                         marginBottom: "5px",
//                         marginRight: 30,

//                       }}
//                     >

//                       <View style={{flexDirection:"row",marginTop:"2px"}}>
//                       <Text style={[fontBold, { fontSize: 11 }]}>
//                         {header[3].title}: 
//                         </Text>
//                         <Text style={{ fontSize: 11,marginLeft:"3px" }}>
//                         {header[3].name}
//                         </Text>

//                       </View>


//                         </View> */}
//                 </View>

//                 {/* </View> */}
//               </View>
//             </View>

//             <View style={{ marginTop: "10px", width: "100%" }}>
//               <Text
//                 style={[
//                   fontBold,
//                   {
//                     fontSize: "12px",
//                     margin: "3px",
//                     textAlign: "center",
//                     color: "#003cb3",
//                     textDecoration: "underline",
//                   },
//                 ]}
//               >
//                 Ride Detail Dashboard
//               </Text>
//             </View>

//             <View style={{ margin: 30, marginTop: 10, marginBottom: 5 }}>
//               <Text style={[fontBold, { fontSize: 11 }]}>1. Ride Summary </Text>
//             </View>

//             <View
//               style={{
//                 marginTop: "10px",
//                 borderTop: "1.5px solid black",
//                 borderRight: "1.5px solid black",
//                 marginLeft: 30,
//                 marginRight: 30,
//               }}
//             >
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyleNew,
//                     {
//                       textAlign: "center",
//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Number
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyleNew,
//                     ,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Score
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyleNew,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Skill
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyleNewRide,

//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Anticipation
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyleNewRide,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Self Confidence
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Category
//                 </Text>
//               </View>
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyleNew,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.floor(propsDataRd[1].overallData) || 0}
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyleNew,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.floor(propsDataRd[0].overallData) || ""}
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyleNew,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.ceil(drivingStatus.drivingSkill)}
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyleNewRide,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.ceil(drivingStatus.anticipation) || 0}
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyleNewRide,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.ceil(drivingStatus.selfConfidence) || 0}
//                 </Text>
//                 {(propsDataRd || [])
//                   .slice(1)
//                   .filter((item) => item.type === "RIDE")
//                   .map((item, key) => (
//                     <Text
//                       key={key}
//                       style={[
//                         styles.tablestyle,
//                         {
//                           textAlign: "center",

//                           textAlign: "center",
//                         },
//                       ]}
//                     >
//                       {item.bottomValue ? item.bottomValue : 0}
//                     </Text>
//                   ))}
//               </View>
//             </View>

//             <View style={{ margin: 20, marginTop: 2, marginBottom: 2 }}>
//               <View
//                 style={{
//                   flexDirection: "row",
//                   marginTop: 2,
//                   marginLeft: 10,
//                   height: "50px",
//                   alignItems: "center",
//                 }}
//               >
//                 <Text style={{ fontSize: 10 }}>"{summary}"</Text>
//               </View>
//             </View>

//             <View style={{ margin: 30, marginTop: 2, marginBottom: 5 }}>
//               <Text style={[fontBold, { fontSize: 11 }]}>2. Ride History</Text>
//             </View>

//             <View
//               style={{
//                 marginTop: "5px",
//                 borderTop: "1.5px solid black",
//                 borderRight: "1.5px solid black",
//                 marginLeft: 30,
//                 marginRight: 30,
//               }}
//             >
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Minutes
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Kilometers
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Alert
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Other Alert
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Start Time
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   End Time
//                 </Text>
//               </View>

//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.floor(propsDataRd[2].overallData) || 0}
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.ceil(drivingStatus.totalKilometer) || 0}
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.floor(propsDataRd[3].overallData) || 0}
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                     },
//                   ]}
//                 >
//                   {Math.floor(propsDataRd[5].overallData) || 0}
//                 </Text>

//                 {(propsDataRd || [])
//                   .slice(1)
//                   .filter((item) => item.type === "RANK")
//                   .map((item, key) => (
//                     <>
//                       <Text
//                         key={key}
//                         style={[
//                           styles.tablestyle,
//                           {
//                             textAlign: "center",

//                             textAlign: "center",
//                           },
//                         ]}
//                       >
//                         {item.startTime ? item.startTime : 0}
//                       </Text>

//                       <Text
//                         style={[
//                           styles.tablestyle,
//                           {
//                             textAlign: "center",

//                             textAlign: "center",
//                           },
//                         ]}
//                       >
//                         {item.endTime ? item.endTime : 0}
//                       </Text>
//                     </>
//                   ))}
//               </View>
//             </View>

//             <View style={{ margin: 30, marginTop: "20px", marginBottom: 5 }}>
//               <Text style={[fontBold, { fontSize: 11 }]}>
//                 3. Safety Protection{" "}
//               </Text>
//               <View
//                 style={{
//                   flexDirection: "row",
//                   marginTop: "5px",
//                   height: "60px",
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//               >
//                 <View style={{ width: "20%", borderRight: "1px solid black" }}>
//                   <Image
//                     src={require(`../../../assets/images/safetyProtection/${safetyData.subImage}`)}
//                     style={{ width: 35 }}
//                   />
//                 </View>
//                 <Text
//                   style={{
//                     fontSize: 10,
//                     width: "70%",
//                     marginLeft: 10,
//                     color: "black",
//                     textAlign: "justify",
//                   }}
//                 >
//                   {safetyData.text}
//                 </Text>
//               </View>
//             </View>

//             <View
//               style={{
//                 margin: 30,
//                 marginTop: 15,
//                 marginBottom: 5,
//                 flexDirection: "row",
//               }}
//             >
//               <Text style={[fontBold, { fontSize: 11 }]}>
//                 4. Day / Night Summary
//               </Text>
//               <Text style={[fontBold, { fontSize: 11, marginLeft: "111px" }]}>
//                 5. Rural / Urban / Highway Summary
//               </Text>
//             </View>

//             <View style={{ marginTop: "5px", marginLeft: 30, marginRight: 30 }}>
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       borderTop: "1.5px solid black",
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Type
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       borderTop: "1.5px solid black",
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Day
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",
//                       borderTop: "1px solid black",
//                       borderRight: "1.5px solid black",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Night
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",
//                       borderTop: "1.5px solid black",
//                       marginLeft: "10px",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Type
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       borderTop: "1.5px solid black",
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Rural
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",
//                       borderTop: "1.5px solid black",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Urban
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",
//                       borderTop: "1.5px solid black",
//                       borderRight: "1.5px solid black",
//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Highway
//                 </Text>
//               </View>

//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Kilometers
//                 </Text>

//                 <View style={[styles.tablestyle, {}]}>
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {dayNightPercentage?.day}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       borderRight: "1.5px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {dayNightPercentage?.night}
//                   </Text>
//                 </View>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       marginLeft: "10px",
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Kilometers
//                 </Text>

//                 <View style={[styles.tablestyle, {}]}>
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {dayNightPercentage?.ruralKilometers}
//                   </Text>
//                 </View>

//                 <View style={[styles.tablestyle, {}]}>
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {dayNightPercentage?.urbanKilometers}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       borderRight: "1.5px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {dayNightPercentage?.highwayKilometers}
//                   </Text>
//                 </View>
//               </View>
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Percentage
//                 </Text>

//                 <View style={[styles.tablestyle, {}]}>
//                   <Text style={{ textAlign: "right" }}>
//                     {dayNightPercentage?.day}%
//                   </Text>
//                 </View>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       borderRight: "1.5px solid black",
//                       textAlign: "right",
//                     },
//                   ]}
//                 >
//                   {dayNightPercentage?.night}%
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       marginLeft: "10px",
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Percentage
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "right",
//                     },
//                   ]}
//                 >
//                   {dayNightPercentage?.rural}%
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "right",
//                     },
//                   ]}
//                 >
//                   {dayNightPercentage?.urban}%
//                 </Text>

//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",
//                       borderRight: "1.5px solid black",

//                       textAlign: "right",
//                     },
//                   ]}
//                 >
//                   {dayNightPercentage?.highway}%
//                 </Text>
//               </View>
//             </View>

//             <View style={{ margin: 30, marginTop: "25px", marginBottom: 5 }}>
//               <Text style={[fontBold, { fontSize: 11 }]}>
//                 6. Other Alerts Summary
//               </Text>
//             </View>

//             <View
//               style={{
//                 marginTop: "5px",
//                 borderTop: "1.5px solid black",
//                 borderRight: "1.5px solid black",
//                 marginLeft: 30,
//                 marginRight: 30,
//               }}
//             >
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Type
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Over Speed
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Mobile Use
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Mobile Screen
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Total
//                 </Text>
//               </View>

//               {["Count", "Duration", "Distance"].map((val, index) => (
//                 <View key={index} style={{ flexDirection: "row" }}>
//                   <Text
//                     style={[
//                       styles.tablestyle,
//                       {
//                         textAlign: "left",
//                       },
//                     ]}
//                   >
//                     {val}
//                   </Text>

//                   {otherRiskDataTotal?.map((item, inc) => (
//                     <View
//                       key={inc}
//                       style={[
//                         styles.tablestyle,
//                         {
//                           //   borderBottom: "1px solid black",
//                           //   borderRight: "1px solid black",
//                           //   borderLeft: "1px solid black",
//                         },
//                       ]}
//                     >
//                       <Text
//                         style={{ paddingRight: "10px", textAlign: "right" }}
//                       >
//                         {item[index]}
//                       </Text>
//                     </View>
//                   ))}
//                 </View>
//               ))}
//             </View>

//             <View
//               style={{
//                 flexDirection: "row",
//                 margin: 30,
//                 marginTop: 20,
//                 marginBottom: 5,
//                 fontSize: "11px",
//               }}
//             >
//               <Text style={[fontBold, { fontSize: 11 }]}>
//                 7. Acceleration / Braking / Cornering Summary
//               </Text>
//             </View>

//             <View
//               style={{
//                 marginTop: "10px",
//                 // border: "1px solid black",
//                 borderTop: "1px solid black",
//                 borderRight: "1px solid black",
//                 marginLeft: 30,
//                 marginRight: 30,
//               }}
//             >
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Type
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Low
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Medium
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   High
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "center",

//                       textAlign: "center",
//                       ...styles.headerCell,
//                     },
//                   ]}
//                 >
//                   Total
//                 </Text>
//               </View>
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Acceleration
//                 </Text>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sAccelerationLow
//                       ? stressStrainData.sAccelerationLow
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sAccelerationMedium
//                       ? stressStrainData.sAccelerationMedium
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sAccelerationHigh
//                       ? stressStrainData.sAccelerationHigh
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sAccelerationHigh +
//                       stressStrainData.sAccelerationMedium +
//                       stressStrainData.sAccelerationLow}
//                   </Text>
//                 </View>
//               </View>
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Braking
//                 </Text>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sBrakingLow
//                       ? stressStrainData.sBrakingLow
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sBrakingMedium
//                       ? stressStrainData.sBrakingMedium
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sBrakingHigh
//                       ? stressStrainData.sBrakingHigh
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sBrakingLow +
//                       stressStrainData.sBrakingMedium +
//                       stressStrainData.sBrakingHigh}
//                   </Text>
//                 </View>
//               </View>
//               <View style={{ flexDirection: "row" }}>
//                 <Text
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                       textAlign: "left",
//                     },
//                   ]}
//                 >
//                   Cornering
//                 </Text>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sCorneringLow
//                       ? stressStrainData.sCorneringLow
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sCorneringMedium
//                       ? stressStrainData.sCorneringMedium
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sCorneringHigh
//                       ? stressStrainData.sCorneringHigh
//                       : 0}
//                   </Text>
//                 </View>

//                 <View
//                   style={[
//                     styles.tablestyle,
//                     {
//                       //   borderBottom: "1px solid black",
//                       //   borderRight: "1px solid black",
//                       //   borderLeft: "1px solid black",
//                     },
//                   ]}
//                 >
//                   <Text style={{ textAlign: "right", paddingRight: "10px" }}>
//                     {stressStrainData.sCorneringLow +
//                       stressStrainData.sCorneringMedium +
//                       stressStrainData.sCorneringHigh}
//                   </Text>
//                 </View>
//               </View>
//             </View>

//             <View
//               style={{
//                 position: "absolute",
//                 bottom: 0,
//                 left: 0,
//                 right: 0,
//                 height: "44.5px",
//               }}
//             >
//               <View style={{ borderBottom: "1px solid black" }} />
//               <View
//                 style={{
//                   flexDirection: "row",
//                   marginLeft: 30,
//                   marginRight: 30,
//                   bottom: "0px",
//                 }}
//               >
//                 <View
//                   style={{
//                     marginTop: "5px",
//                     width: "30%",
//                     marginBottom: "5px",
//                   }}
//                 >
//                   <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
//                     {footer[0].f}
//                   </Text>
//                   <Text style={[fontItalic, { fontSize: "10px" }]}>
//                     {footer[2].f}
//                   </Text>
//                 </View>

//                 <View
//                   style={{
//                     marginTop: "10px",
//                     width: "40%",
//                     textAlign: "center",
//                   }}
//                 >
//                   <Text style={{ fontSize: "11px" }}>
//                     Page {pageNumber} of 2
//                   </Text>
//                 </View>
//                 <View
//                   style={{
//                     marginTop: "5px",
//                     width: "30%",
//                     flexDirection: "column",
//                   }}
//                 >
//                   <Text
//                     style={{
//                       fontSize: "11px",
//                       marginBottom: "3px",
//                       textAlign: "right",
//                     }}
//                   >
//                     {footer[1].f}
//                   </Text>
//                   <Text style={{ fontSize: "10px", textAlign: "right" }}>
//                     {footer[3].f}
//                   </Text>
//                 </View>
//               </View>
//             </View>
//           </Page>
//           <Page pageNumber={pageNumber}>
//             <Text style={{ ...watermarkStyles.watermark }}>
//               {watermarkText}
//             </Text>
//             <View style={{ height: "5.3%", margin: "0px 30px 0px 30px" }}>
//               <View
//                 style={{
//                   flexDirection: "row",
//                   borderBottom: "1px solid black",
//                   backgroundColor: "lightgrey",
//                 }}
//               >
//                 {/* <View style={{ width: "77%", marginBottom: "5px"}}> */}

//                 <View
//                   style={{
//                     marginTop: "5px",
//                     width: "100%",
//                     flexDirection: "row",
//                     // justifyContent: "space-between",
//                   }}
//                 >
//                   <Image
//                     src={RuduLogo}
//                     style={{
//                       height: "21px",
//                       width: "100px",
//                       marginTop: "2px",
//                       marginBottom: "5px",
//                       marginLeft: "10px",
//                     }}
//                   />
//                   <View
//                     style={{
//                       flexDirection: "Row",
//                       marginBottom: "5px",
//                       marginLeft: "30px",
//                     }}
//                   >
//                     <View style={{ flexDirection: "row", width: "80%" }}>
//                       <Text
//                         style={[fontBold, { fontSize: 11, width: "123px" }]}
//                       >
//                         {header[1].title}:
//                       </Text>
//                       <Text style={{ fontSize: 11, width: "100%" }}>
//                         {header[1].name}
//                       </Text>
//                     </View>
//                     <View style={{ flexDirection: "row" }}>
//                       <View
//                         style={{
//                           flexDirection: "row",
//                           marginTop: "2px",
//                           width: "60%",
//                         }}
//                       >
//                         <Text
//                           style={[fontBold, { fontSize: 11, width: "85px" }]}
//                         >
//                           {header[3].title}:
//                         </Text>
//                         <Text style={{ fontSize: 11, width: "100%" }}>
//                           {header[3].name}
//                         </Text>
//                       </View>
//                       <View
//                         style={{
//                           flexDirection: "row",
//                           marginTop: "2px",
//                           marginLeft: "60px",
//                         }}
//                       >
//                         <View style={{ flexDirection: "row" }}>
//                           <Text style={[fontBold, { fontSize: 11 }]}>
//                             {header[2].title}:{" "}
//                           </Text>
//                           <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
//                         </View>
//                       </View>
//                     </View>

//                     {/* <View style={{flexDirection:"row"}}>
//                        <Text
//                    style={[fontBold, { fontSize: 11 }]}
//                   >
//                     {header[0].title}: 
//                   </Text>
//                   <Text
//                    style={{fontSize: 11,marginLeft:"3px" }}
//                   >
//                     {header[0].name}
//                   </Text>

//                        </View> */}

//                     {/* <View style={{flexDirection:"row",marginTop:"2px"}}>
//                        <Text style={[fontBold, { fontSize: 11 }]}>
//                         {header[2].title}: </Text>
//                         <Text style={{ fontSize: 11,marginLeft:"1px"}}>
//                         {header[2].name}</Text>

//                        </View> */}
//                   </View>

//                   {/* <View
//                       style={{
//                         flexDirection:"column",
//                         marginBottom: "5px",
//                         marginRight: 30,

//                       }}
//                     >

//                       <View style={{flexDirection:"row",marginTop:"2px"}}>
//                       <Text style={[fontBold, { fontSize: 11 }]}>
//                         {header[3].title}: 
//                         </Text>
//                         <Text style={{ fontSize: 11,marginLeft:"3px" }}>
//                         {header[3].name}
//                         </Text>

//                       </View>


//                         </View> */}
//                 </View>

//                 {/* </View> */}
//               </View>
//             </View>
//             <View style={{ margin: 30, marginTop: "5px", marginBottom: 10 }}>
//               <View>
//                 <Text style={[fontBold, { fontSize: 11 }]}>8. Risk Alerts</Text>
//               </View>
//               <View
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   marginTop: "10px",
//                   // border: "1.5px solid black",
//                 }}
//               >
//                 {riskAlertData.map((row, rowIndex) => (
//                   <View
//                     key={rowIndex}
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       height: "auto",
//                     }}
//                   >
//                     {row.map((item, columnIndex) => (
//                       <View
//                         key={columnIndex}
//                         style={{
//                           flexDirection: "row",
//                           justifyContent: "space-between",
//                           borderTop: "1.5px solid black",
//                           borderLeft: "1.5px solid black",
//                           borderRight: "1.5px solid black",
//                           borderBottom:
//                             columnIndex == row.length - 1 &&
//                             "1.5px solid black",
//                         }}
//                       >
//                         <View
//                           style={{
//                             width: "110px",
//                             borderRight: "1.5px solid black",
//                             textAlign: "center",
//                           }}
//                         >
//                           {/* item.type == "header" */}
//                           <Text
//                             style={[
//                               item.type == "header"
//                                 ? {
//                                     ...styles.headerCell,
//                                     fontSize: "11px",
//                                     padding: "5px",
//                                   }
//                                 : {
//                                     fontSize: "11px",
//                                     textAlign: "left",
//                                     padding: "5px",
//                                   },
//                             ]}
//                           >
//                             {item.title}
//                           </Text>
//                         </View>
//                         <View style={{ width: "60px", textAlign: "right" }}>
//                           <Text
//                             style={[
//                               item.type == "header"
//                                 ? {
//                                     ...styles.headerCell,
//                                     fontSize: "11px",
//                                     padding: "5px",
//                                   }
//                                 : {
//                                     textAlign: "right",
//                                     paddingRight: "2px",
//                                     fontSize: "11px",
//                                     padding: "5px",
//                                   },
//                             ]}
//                           >
//                             {item.value}
//                           </Text>
//                         </View>
//                       </View>
//                     ))}

//                     {/* {rowIndex === riskAlertData.length - 1 &&
//                       row.length < chunkSize &&
//                       Array.from({ length: chunkSize - row.length }).map(
//                         (_, emptyIndex) => (
//                           <Text
//                             key={`empty-${emptyIndex}`}
//                             style={[
//                               styles.tablestyle,
//                               {
//                                 borderBottom:
//                                   rowIndex !== 4 ? "1.5px solid black" : "none",
//                                 borderRight: "none",
//                                 textAlign: "center",
//                                 height: 40,
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                               },
//                             ]}
//                           >
//                             {"-"}
//                           </Text>
//                         )
//                       )} */}
//                   </View>
//                 ))}
//               </View>
//             </View>

//             <View
//               style={{
//                 flexDirection: "row",
//                 height: 130,
//                 margin: 30,
//                 marginTop: 5,
//                 marginBottom: 5,
//                 fontSize: "11px",
//               }}
//             >
//               <View style={{ width: "50%", height: 160 }}>
//                 {/* <View style={{ fontSize: "11px" }}> */}

//                 <View
//                   style={{
//                     flexDirection: "row",
//                     fontSize: "11px",
//                   }}
//                 >
//                   <Text style={[fontBold, { fontSize: 11 }]}>
//                     9. Driving Style Estimation
//                   </Text>
//                 </View>
//                 <View
//                   style={{
//                     marginTop: "11px",
//                     borderTop: "1.5px solid black",
//                     borderRight: "1.5px solid black",
//                   }}
//                 >
//                   {tableData2.map((row, rowIndex) => (
//                     <View key={rowIndex} style={{ flexDirection: "row" }}>
//                       {row.map((item, columnIndex) => (
//                         <View
//                           key={columnIndex}
//                           style={[
//                             styles.tablestyle,
//                             {
//                               //   borderBottom:"1px solid black",
//                               //   borderRight:"1px solid black",
//                               //   borderLeft:"1px solid black",
//                               //   borderTop:"1px solid black",
//                               textAlign: (() => {
//                                 if (rowIndex === 0) {
//                                   return "center";
//                                 } else if (columnIndex === 0) {
//                                   return "center";
//                                 } else if (columnIndex === 1) {
//                                   return "left";
//                                 } else {
//                                   return "right";
//                                 }
//                               })(),

//                               ...(rowIndex === 0 ? styles.headerCell : {}),
//                             },
//                           ]}
//                         >
//                           <Text
//                             style={{
//                               paddingRight: (() => {
//                                 if (rowIndex === 0) {
//                                   return "none";
//                                 } else if (columnIndex === 0) {
//                                   return "none";
//                                 } else if (columnIndex === 1) {
//                                   return "none";
//                                 } else {
//                                   return "10px";
//                                 }
//                               })(),
//                             }}
//                           >
//                             {`${item}\u00A0`}
//                           </Text>
//                         </View>
//                       ))}
//                     </View>
//                   ))}
//                 </View>
//               </View>

//               <View style={{ width: "50%" }}>
//                 {/* <View style={{ fontSize: "11px" }}> */}

//                 <View style={{ fontSize: "11px" }}>
//                   <Text
//                     style={[fontBold, { fontSize: 11, marginLeft: "20px" }]}
//                   >
//                     10. Driver State Detection
//                   </Text>
//                 </View>
//                 <View
//                   style={{
//                     marginTop: "10px",
//                     marginLeft: "20px",
//                     borderTop: "1.5px solid black",
//                     borderRight: "1.5px solid black",
//                   }}
//                 >
//                   {tableData1.map((row, rowIndex) => (
//                     <View key={rowIndex} style={{ flexDirection: "row" }}>
//                       {row.map((item, columnIndex) => (
//                         <View
//                           key={columnIndex}
//                           style={[
//                             styles.tablestyle,
//                             {
//                               textAlign: columnIndex >= 1 ? "center" : "center",
//                               flex: columnIndex === 1 ? 1 : 0.8,
//                               textAlign: (() => {
//                                 if (rowIndex === 0) {
//                                   return "center";
//                                 } else if (columnIndex === 0) {
//                                   return "center";
//                                 } else if (columnIndex === 1) {
//                                   return "left";
//                                 } else {
//                                   return "right";
//                                 }
//                               })(),

//                               ...(rowIndex === 0 ? styles.headerCell : {}),
//                             },
//                           ]}
//                         >
//                           <Text
//                             style={{
//                               paddingRight: (() => {
//                                 if (rowIndex === 0) {
//                                   return "none";
//                                 } else if (columnIndex === 0) {
//                                   return "none";
//                                 } else if (columnIndex === 1) {
//                                   return "none";
//                                 } else {
//                                   return "10px";
//                                 }
//                               })(),
//                             }}
//                           >
//                             {`${item}\u00A0`}
//                           </Text>
//                         </View>
//                       ))}
//                     </View>
//                   ))}
//                 </View>
//               </View>
//             </View>

//             <View
//               style={{
//                 flexDirection: "column",
//                 marginTop: 40,
//                 marginLeft: 30,
//                 marginRight: 30,
//                 height: "38%",
//               }}
//             >
//               <Svg
//                 viewBox="0 0 600 20"
//                 style={{ flexDirection: "row", marginTop: 5, marginBottom: 2 }}
//               >
//                 <Text
//                   x="0"
//                   y="50%"
//                   style={{
//                     height: "10",
//                     fontSize: "13px",
//                     fontFamily: "Helvetica-Bold",
//                   }}
//                 >
//                   11. Risk Histogram
//                 </Text>
//                 <Rect
//                   x="150"
//                   rx="2"
//                   ry="2"
//                   width="20"
//                   height="12"
//                   fill="#19543E"
//                 />
//                 <Text
//                   x="175"
//                   y="50%"
//                   style={{ height: "10", fontSize: "11px" }}
//                 >
//                   {" "}
//                   Low Risk (0% - 70%)
//                 </Text>
//                 <Rect
//                   x="290"
//                   rx="2"
//                   ry="2"
//                   width="20"
//                   height="12"
//                   fill="#ffbf00"
//                 />
//                 <Text
//                   x="315"
//                   y="50%"
//                   style={{ height: "10", fontSize: "11px" }}
//                 >
//                   {" "}
//                   Medium Risk (71% - 90%)
//                 </Text>
//                 <Rect
//                   x="456"
//                   rx="2"
//                   ry="2"
//                   width="20"
//                   height="12"
//                   fill="#FD0100"
//                 />
//                 <Text
//                   x="480"
//                   y="50%"
//                   style={{ height: "10", fontSize: "11px" }}
//                 >
//                   {" "}
//                   High Risk (91% - 100%)
//                 </Text>
//               </Svg>

//               <Svg width="100%" height="250" style={{ marginTop: 10 }}>
//                 <Line
//                   x1="49"
//                   y1="0"
//                   x2="49"
//                   y2={riskHistogram.length === 10 ? "220" : "197"}
//                   stroke="black"
//                   strokeWidth="1"
//                 />

//                 {modifiedriskHistogram.map((item, index) => (
//                   <Rect
//                     key={index}
//                     x="50"
//                     y={index * 23}
//                     width={360}
//                     height={13}
//                     fill={"#e6e6e6"}
//                   />
//                 ))}

//                 {modifiedriskHistogram.map((item, index) => (
//                   <Rect
//                     key={index}
//                     x="50"
//                     y={index * 23}
//                     width={item.value || 0}
//                     height={13}
//                     fill={
//                       index < 1 ? "#FD0100" : index < 3 ? "#ffbf00" : "#19543E"
//                     }
//                   />
//                 ))}

//                 {modifiedriskHistogram.map((item, index) => (
//                   <Text
//                     key={index}
//                     // x={Math.floor(item.value) + 120}
//                     x={425}
//                     y={index * 23 + 9}
//                     fontSize="10"
//                     fill="black"
//                   >
//                     {(riskHistogram[index].value !== undefined
//                       ? riskHistogram[index].value
//                       : 0) +
//                       "%" +
//                       " (" +
//                       riskHistogram[index].customData.substring(6) +
//                       ")"}
//                   </Text>
//                 ))}

//                 {riskHistogram.map((item, index) => (
//                   <Text
//                     key={index}
//                     x={0}
//                     y={index * 23 + 10}
//                     fontSize="11"
//                     fill="black"
//                     textAlign="right"
//                   >
//                     {item.title || ""}
//                   </Text>
//                 ))}
//               </Svg>
//             </View>

//             <View
//               style={{
//                 position: "absolute",
//                 bottom: 0,
//                 left: 0,
//                 right: 0,
//                 height: "44.5px",
//               }}
//             >
//               <View style={{ borderBottom: "1px solid black" }} />
//               <View
//                 style={{
//                   flexDirection: "row",
//                   marginLeft: 30,
//                   marginRight: 30,
//                   bottom: "0px",
//                 }}
//               >
//                 <View
//                   style={{
//                     marginTop: "5px",
//                     width: "30%",
//                     marginBottom: "5px",
//                   }}
//                 >
//                   <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
//                     {footer[0].f}
//                   </Text>
//                   <Text style={[fontItalic, { fontSize: "10px" }]}>
//                     {footer[2].f}
//                   </Text>
//                 </View>

//                 <View
//                   style={{
//                     marginTop: "10px",
//                     width: "40%",
//                     textAlign: "center",
//                   }}
//                 >
//                   <Text style={{ fontSize: "11px" }}>
//                     Page {pageNumber + 1} of 2
//                   </Text>
//                 </View>
//                 <View
//                   style={{
//                     marginTop: "5px",
//                     width: "30%",
//                     flexDirection: "column",
//                   }}
//                 >
//                   <Text
//                     style={{
//                       fontSize: "11px",
//                       marginBottom: "3px",
//                       textAlign: "right",
//                     }}
//                   >
//                     {footer[1].f}
//                   </Text>
//                   <Text style={{ fontSize: "10px", textAlign: "right" }}>
//                     {footer[3].f}
//                   </Text>
//                 </View>
//               </View>
//             </View>
//           </Page>
//         </Document>
//       </PDFViewer>
//     </React.Fragment>
//   );
// }



import React, { useEffect } from "react";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Rect,
  Svg,
  Line,
} from "@react-pdf/renderer";
import RuduLogo from "../../../assets/images/logo/motivAiLogoPng.png";
import MotivaiLogo from "../../../assets/images/logo.motivAI.png";
import { useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import {
  SC_USERNAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_COMPANY_NAME,
  SC_PROFILE_OPEN,
  SC_USER_LOGIN_TYPE,
  SC_DIVISION_NAME,
  SC_REGISTRATION_DATE,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { number } from "echarts";
import ValueFormat from "../../../util/ValueFormat";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfFooterV1 from "../Components/pdfFooterV1";
import { useLocation } from "react-router-dom";
import PdfHeading from "../Components/pdfHeading";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";

// Create Document Component
export default function RideDetailPdfNew({
  propsDataRd,
  topWidgetDataPdf,
  safetyData,
  drivingStatus,
  dayNightPercentage,
  otherRiskData,
  DistributionData,
  driverStateList,
  driverStyleList,
  stressStrainData,
  riskDataList,
  overSpeedTableData,
  mobileUseTableData,
  mobileScreenTableData,
  riskDataTable,
  stressData,
}) {
  const pathname = useLocation();
  const [showLoader, setShowLoader] = useState(1);
  const [summary, setSummary] = useState("N/A");
  const [pageNumber, setPageNumber] = useState(1);

  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  const firstName = (ValueFormat.capitalizeFirstLetter(localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)));
  const userStatus = localStorage.getItem(SC_PROFILE_OPEN);
  const userName = localStorage.getItem(SC_USERNAME);
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const unitName = localStorage.getItem(SC_DIVISION_NAME);
  const [otherRiskDataTotal, setOtherRiskDataTotal] = useState([]);
  const createdDate = localStorage.getItem(SC_REGISTRATION_DATE);

  console.log(propsDataRd, "propsDataRdpropsDataRd");
  

  function displayUserName() {
    if (userName && userName.length >= 4) {
      const firstTwo = userName.slice(0, 2);
      const lastTwo = userName.slice(-2);
      const middleXs = "x".repeat(userName.length - 4);

      const displayedUserName = firstTwo + middleXs + lastTwo;
      return displayedUserName;
    } else {
      return userName; // or handle the invalid case as needed
    }
  }

  const drivingScore =
    propsDataRd && propsDataRd.length > 0 ? propsDataRd[0].overallData : null;
  useEffect(() => {
    // ... your logic

    if (drivingScore !== null && drivingScore !== undefined) {
      if (drivingScore > 90) {
        setSummary(
          "You drive safely. You have excellent driving practice and you have a nature of very cautious"
        );
      } else if (drivingScore > 60 && drivingScore <= 90) {
        setSummary(
          "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
        );
      } else {
        setSummary(
          "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
        );
      }
    } else {
      // Handle the case when drivingScore is null or undefined
      setSummary("Unable to determine driving score. Please check your data.");
    }
  }, [propsDataRd]);

  useEffect(() => {
    let otherRisk = [];
    let count = 0;
    let duration = 0;
    let distance = 0;
    const ff = otherRiskData.map((val) => {
      count += val.count;
      duration += val.duration;
      distance += val.kilometer;
      otherRisk.push([val.count, val.duration, val.kilometer]);
    });
    otherRisk.push([count, duration, distance]);
    setOtherRiskDataTotal(otherRisk);
  }, []);

  const tableData1 = [
    ["State", "Abnormal State", "Count", "Distance(Kms)", "Duration(Mins)"],
    [
      "0", "Unknown",
      driverStateList?.driverUnknown || 0,
      driverStateList?.distanceStateUnknown || 0,
      driverStateList?.durationStateUnknown?.toFixed(2) || "0.00"
    ],
    [
      "1", "Normal",
      driverStateList?.driverNormal || 0,
      driverStateList?.distanceStateNormal || 0,
      driverStateList?.durationStateNormal?.toFixed(2) || "0.00"
    ],
    [
      "2", "Tired",
      driverStateList?.driverTired || 0,
      driverStateList?.distanceStateTired || 0,
      driverStateList?.durationStateTired?.toFixed(2) || "0.00"
    ],
    [
      "3", "Angry",
      driverStateList?.driverAngry || 0,
      driverStateList?.distanceStateAngry || 0,
      driverStateList?.durationStateAngry?.toFixed(2) || "0.00"
    ],
  ];

  const tableData2 = [
    ["State", "Driving Style", "Count", "Distance(Kms)", "Duration(Mins)"],
    [
      "0", "Unknown",
      driverStyleList?.drivingUnknown || 0,
      driverStyleList?.distanceStyleUnknown || 0,
      driverStyleList?.durationStyleUnknown?.toFixed(2) || "0.00"
    ],
    [
      "1", "Normal",
      driverStyleList?.drivingNormal || 0,
      driverStyleList?.distanceStyleNormal || 0,
      driverStyleList?.durationStyleNormal?.toFixed(2) || "0.00"
    ],
    [
      "2", "Sportive",
      driverStyleList?.drivingSportive || 0,
      driverStyleList?.distanceStyleSportive || 0,
      driverStyleList?.durationStyleSportive?.toFixed(2) || "0.00"
    ],
    [
      "3", "Eco",
      driverStyleList?.drivingEco || 0,
      driverStyleList?.distanceStyleEco || 0,
      driverStyleList?.durationStyleEco?.toFixed(2) || "0.00"
    ],
    [
      "4", "Chaotic",
      driverStyleList?.drivingChaotic || 0,
      driverStyleList?.distanceStyleChaotic || 0,
      driverStyleList?.durationStyleChaotic?.toFixed(2) || "0.00"
    ],
    [
      "5", "Aggressive",
      driverStyleList?.drivingAggressive || 0,
      driverStyleList?.distanceStyleAggressive || 0,
      driverStyleList?.durationStyleAggressive?.toFixed(2) || "0.00"
    ],
  ];


  const modifiedRiskAlertData = riskDataList
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((item) => ({
      type: "body",
      title: item.title,
      value: item.overallData,
    }));
  // const [riskAlterDatas
  // console.log(modifiedRiskAlertData, "modifiedRiskAlertData");
  const [riskAlertData, setRiskAlertData] = useState([]);
  const filterArrayForGivenLetters = (data, letter) => {
    let filtered = data.slice(letter * 4, letter * 4 + 4); // 0,8 | 8, 16 | 16, 24

    return filtered;
  };

  useEffect(() => {
    var riskAlertDataaa = [];

    [0, 1, 2, 3, 4, 5].map((val) => {
      let FilterData = filterArrayForGivenLetters(modifiedRiskAlertData, val);
      riskAlertDataaa.push([
        { type: "header", title: "Name", value: "Value" },
        ...FilterData,
      ]);
      // console.log(FilterData, "riskAlertDatssa");
    });
    setRiskAlertData(riskAlertDataaa);
    // console.log(riskAlertDataaa, "riskAlertDatssa1");
  }, []);

  const fontBold = {
    fontFamily: "Helvetica-Bold",
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(0);
    }, 4000);
  }, []);

  const title = (value) => {
    let regex = /\((.*?)\)/g;
    return value.replace(regex, "").trim();
  };

  const titleValue = (value) => {
    let regex = /\((.*?)\)/g;
    let matches = [];
    let match;
    while ((match = regex.exec(value))) {
      matches.push(match[1]);
    }
    return matches[0];
  };

  const riskHistogram = DistributionData.xaxis.map((xAxisValue, index) => ({
    title: title(xAxisValue),
    titleValue: titleValue(xAxisValue),
    value: DistributionData.data[index].value || 0,
    customData: DistributionData.customData[index],
  }));


  const modifiedRiskHistogram = () => {
    return riskHistogram.map((item) => {
      // Create a new object to avoid modifying the original data
      const sItem = { ...item };

      // Apply your conditions to each item
      if (sItem.value > 0) {
        if (sItem.value < 10) {
          sItem.value = ((sItem.value / 10) * 390).toFixed(1);
        } else {
          sItem.value = 390;
        }
      } else {
        sItem.value = 0;
      }

      return sItem;
    });
  };

  const modifiedriskHistogram = modifiedRiskHistogram();

  const styles = StyleSheet.create({
    tablestyle: {
      border: "1px solid lightgrey",
      borderTop: "none",
      borderRight: "none",
      padding: "5px",
      fontSize: "10px",
      textAlign: "center",
      flex: 1,
    },
    tablestyleNew: {
      border: "1px solid lightgrey",
      borderTop: "none",
      borderRight: "none",
      padding: "5px",
      fontSize: "10px",
      textAlign: "center",
      width: "60px",
    },
    tablestyleNewRide: {
      border: "1px solid lightgrey",
      borderTop: "none",
      borderRight: "none",
      padding: "5px",
      fontSize: "10px",
      textAlign: "center",
      width: "90px",
    },
    page: {
      paddingLeft: 30,
      paddingRight: 30,
      width: "100%",
    },
    headerCell: {
      textAlign: "center",
      fontFamily: "Helvetica-Bold",
    },
  });


  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  const heading = "Ride Detail Dashboard";
  var currentDate = new Date();
  const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

  return (
    <React.Fragment>
      {showLoader > 0 ? <LoaderPdf /> : ""}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>

          <Page size={'A2'} pageNumber={pageNumber}>
            <PdfHeaderV1 />
            <View style={{ paddingHorizontal: 30, marginTop: 10 }}>
              <PdfHeading heading={heading} periodFormat={Math.floor(topWidgetDataPdf.rideName) || 0} reversedDateFormat={reversedDateFormat} />
            </View>

            <View style={{ paddingHorizontal: 30 }}>

              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>

                <View style={{ width: '49.5%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>1. Ride Summary</Text>
                  </View>
                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                    <View style={{ height: '60px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                      <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[fontBold, { fontSize: 9, width: '16.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Driving Behaviour Score</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Ratings</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '14.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Driving Points</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '14.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Anticipation Points</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '14.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Driving Skill Points</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '14.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Self Confidence Points</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '14.3%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Category</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '16.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.driverBehaviorScore}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.ratings}</Text></View>
                        <View style={[{ fontSize: 9, width: '14.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.drivingPoints}</Text></View>
                        <View style={[{ fontSize: 9, width: '14.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.anticipationPoints}</Text></View>
                        <View style={[{ fontSize: 9, width: '14.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.drivingSkillPoints}</Text></View>
                        <View style={[{ fontSize: 9, width: '14.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.selfConfidencePoints}</Text></View>
                        <View style={[{ fontSize: 9, width: '14.3%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.driverCategory}</Text></View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ width: '49.5%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>2. Ride History</Text>
                  </View>
                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                    <View style={{ height: '60px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                      <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[fontBold, { fontSize: 9, width: '16.6%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Minutes</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '16.6%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Kilometers</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '16.6%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Alert</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '16.6%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Other Alert</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '16.6%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Start Time</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '16.6%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>End Time</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '16.6%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.travelTime}</Text></View>
                        <View style={[{ fontSize: 9, width: '16.6%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.distance}</Text></View>
                        <View style={[{ fontSize: 9, width: '16.6%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.riskAlertCount}</Text></View>
                        <View style={[{ fontSize: 9, width: '16.6%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.otherAlertCount}</Text></View>
                        <View style={[{ fontSize: 9, width: '16.6%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.startTime}</Text></View>
                        <View style={[{ fontSize: 9, width: '16.6%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.endTime}</Text></View>
                      </View>
                    </View>
                  </View>
                </View>

              </View>

              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>

                <View style={{ width: '100%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>3. Ride Report</Text>
                  </View>
                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                    <View style={{ height: '60px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                      <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Red Risk Histogram</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Red Risk Duration</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Red Risk Kms</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Crash Probability Point</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>CPP Level</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Battery Level Start</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Battery Level End</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '12.5%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Event</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.redRiskHistogram}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.redRiskDuration}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.redRiskDistance}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.cppValue}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.cppLevel}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.batteryLevelStart}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.batteryLevelEnd}</Text></View>
                        <View style={[{ fontSize: 9, width: '12.5%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{topWidgetDataPdf.event}</Text></View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ width: '100%', marginTop: 15 }}>
                <View style={{ paddingBottom: 10 }}>
                  <Text style={[fontBold, { fontSize: 12 }]}>4. Safety Protection</Text>
                </View>
                <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: "5px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View style={{ width: "20%", borderRight: "1px solid lightgrey" }}>
                      <Image
                        src={require(`../../../assets/images/safetyProtection/${safetyData.subImage}`)}
                        style={{ width: 35 }}
                      />
                    </View>
                    <Text
                      style={{
                        fontSize: 10,
                        width: "70%",
                        marginLeft: 10,
                        color: "black",
                        textAlign: "justify",
                      }}
                    >
                      {safetyData.text}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
                <View style={{ width: '49.5%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>5. Day / Night Summary</Text>
                  </View>

                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                    <View style={{ height: '85px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                      <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Type</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Day</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Night</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Kilometers</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{((dayNightPercentage?.totalKilometer)*(dayNightPercentage?.day))/100}</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{((dayNightPercentage?.totalKilometer)*(dayNightPercentage?.night))/100}</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Percentage</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.day}%</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.night}%</Text></View>
                      </View>
                    </View>
                  </View>

                </View>

                <View style={{ width: '49.5%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>6. Rural / Urban / Highway Summary </Text>
                  </View>

                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                    <View style={{ height: '85px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                      <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Type</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Rural</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Urban</Text></View>
                        <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Highway</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Kilometers</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.ruralKilometers}</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.urbanKilometers}</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', borderBottom: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.highwayKilometers}</Text></View>
                      </View>

                      <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Percentage</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.rural}%</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.urban}%</Text></View>
                        <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{dayNightPercentage?.highway}%</Text></View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ width: '100%', marginTop: 15 }}>
                <View style={{ paddingBottom: 10 }}>
                  <Text style={[fontBold, { fontSize: 12 }]}>7. Other Alerts Summary</Text>
                </View>
                <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                  <View style={{ height: '110px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                    <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Type</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Over Speed</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mobile Call Use</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mobile Screen Use</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Total</Text></View>
                    </View>

                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{"Count"}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[0] && otherRiskDataTotal[0][0]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[1] && otherRiskDataTotal[1][0]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[2] && otherRiskDataTotal[2][0]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[3] && otherRiskDataTotal[3][0]) || 0}</Text></View>
                    </View>

                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{"Duration"}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[0] && otherRiskDataTotal[0][1]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[1] && otherRiskDataTotal[1][1]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[2] && otherRiskDataTotal[2][1]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[3] && otherRiskDataTotal[3][1]) || 0}</Text></View>
                    </View>

                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{"Distance"}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[0] && otherRiskDataTotal[0][2]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[1] && otherRiskDataTotal[1][2]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[2] && otherRiskDataTotal[2][2]) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(otherRiskDataTotal && otherRiskDataTotal[3] && otherRiskDataTotal[3][2]) || 0}</Text></View>
                    </View>

                  </View>
                </View>
              </View>

              <View style={{ width: '100%', marginTop: 15 }}>
                <View style={{ paddingBottom: 10 }}>
                  <Text style={[fontBold, { fontSize: 12 }]}>8. Acceleration / Braking / Cornering Summary</Text>
                </View>
                <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                  <View style={{ height: '110px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                    <View style={{ height: '35px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Type</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium </Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
                      <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '35px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Total</Text></View>
                    </View>

                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{"Acceleration"}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sAccelerationLow) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sAccelerationMedium) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sAccelerationHigh) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && (stressStrainData.sAccelerationLow + stressStrainData.sAccelerationMedium + stressStrainData.sAccelerationHigh)) || 0}</Text></View>
                    </View>

                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{"Braking"}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sBrakingLow) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sBrakingMedium) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sBrakingHigh) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && (stressStrainData.sBrakingLow + stressStrainData.sBrakingMedium + stressStrainData.sBrakingHigh)) || 0}</Text></View>
                    </View>

                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{"Cornering"}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sCorneringLow) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sCorneringMedium) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && stressStrainData.sCorneringHigh) || 0}</Text></View>
                      <View style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(stressStrainData && (stressStrainData.sCorneringLow + stressStrainData.sCorneringMedium + stressStrainData.sCorneringHigh)) || 0}</Text></View>
                    </View>


                  </View>
                </View>
              </View>

              <View style={{ marginTop: 15 }}>
                <Text style={[fontBold, { fontSize: 11 }]}>9. Risk Alerts</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  border: "1px solid lightgrey",
                  padding: 10,
                  borderRadius: 10
                }}
              >
                {riskAlertData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                    }}
                  >
                    {row.map((item, columnIndex) => (
                      <View
                        key={columnIndex}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderTop: "1px solid lightgrey",
                          borderLeft: "1px solid lightgrey",
                          borderRight: "1px solid lightgrey",
                          borderBottom:
                            columnIndex == row.length - 1 &&
                            "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            width: "110px",
                            borderRight: "1px solid lightgrey",
                            textAlign: "center",
                            backgroundColor:
                              item.type == "header" ? globalPDF.GREY_COLOR
                                : "",
                            paddingVertical: item.type == "header" ? "4px" : ""
                          }}
                        >
                          {/* item.type == "header" */}
                          <Text
                            style={[
                              item.type == "header"
                                ? {
                                  ...styles.headerCell,
                                  fontSize: "9px",
                                  padding: "5px",
                                }
                                : {
                                  fontSize: "9px",
                                  textAlign: "left",
                                  padding: "5px",
                                },
                            ]}
                          >
                            {item.title}
                          </Text>
                        </View>
                        <View style={{
                          width: "60px", textAlign: "center", backgroundColor: item.value == "Value" ? globalPDF.GREY_COLOR : "",
                          paddingVertical: item.value == "Value" ? "4px" : ""
                        }}>
                          <Text
                            style={[
                              item.type == "header"
                                ? {
                                  ...styles.headerCell,
                                  fontSize: "9px",
                                  padding: "5px",
                                }
                                : {
                                  textAlign: "center",
                                  paddingRight: "2px",
                                  fontSize: "9px",
                                  padding: "5px",
                                },
                            ]}
                          >
                            {item.value}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                ))}
              </View>


              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
                <View style={{ width: '49.5%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 11 }]}>10. Driving Style Estimation</Text>
                  </View>

                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10 }}>
                    <View
                      style={{
                        borderTop: "1px solid lightgrey",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {tableData2.map((row, rowIndex) => (
                        <View key={rowIndex} style={{ flexDirection: "row" }}>
                          {row.map((item, columnIndex) => (
                            <View
                              key={columnIndex}
                              style={[
                                styles.tablestyle,
                                {
                                  textAlign: 'center',
                                  ...(rowIndex === 0 ? styles.headerCell : {}),
                                  backgroundColor: rowIndex === 0 && globalPDF.GREY_COLOR,
                                  paddingVertical: rowIndex === 0 ? "10px" : '8px'
                                },
                              ]}
                            >
                              <Text >
                                {`${item}\u00A0`}
                              </Text>
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  </View>
                </View>

                <View style={{ width: '49.5%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>11. Driver State Detection</Text>
                  </View>

                  <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, paddingBottom: 65 }}>
                    <View
                      style={{
                        borderTop: "1px solid lightgrey",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {tableData1.map((row, rowIndex) => (
                        <View key={rowIndex} style={{ flexDirection: "row" }}>
                          {row.map((item, columnIndex) => (
                            <View
                              key={columnIndex}
                              style={[
                                styles.tablestyle,
                                {
                                  textAlign: columnIndex >= 1 ? "center" : "center",
                                  flex: columnIndex === 1 ? 1 : 0.8,
                                  textAlign: "center",
                                  ...(rowIndex === 0 ? styles.headerCell : {}),
                                  backgroundColor: rowIndex === 0 && globalPDF.GREY_COLOR,
                                  paddingVertical: rowIndex === 0 ? "10px" : '8px'
                                },
                              ]}
                            >
                              <Text>
                                {`${item}\u00A0`}
                              </Text>
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              </View>

            </View>

            <PdfFooterV1 pageNumber={1} totalPages={2} pathname={pathname} />
          </Page>

          <Page size={"A2"} pageNumber={pageNumber}>
            <PdfHeaderV1 />

            <View style={{ paddingHorizontal: 30, marginTop: 10 }}>
              <PdfHeading heading={heading} periodFormat={Math.floor(propsDataRd[0].overallData) || 0} reversedDateFormat={reversedDateFormat} />
            </View>

            <View style={{ paddingHorizontal: 30 }}>

              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>

                <View style={{ width: '54%' }}>
                  <View style={{ paddingBottom: 10 }}>
                    <Text style={[fontBold, { fontSize: 12 }]}>12. Risk Histogram</Text>
                  </View>
                  <View style={{ border: '1px solid lightgrey', borderRadius: 10,paddingHorizontal: 10, paddingVertical: 20 }}>
                    <Svg
                      viewBox="0 0 700 20"
                      style={{ flexDirection: "row", marginTop: 5, marginBottom: 2 }}
                    >
                      <Rect
                        x="150"
                        rx="2"
                        ry="2"
                        width="20"
                        height="12"
                        fill="#19543E"
                      />
                      <Text
                        x="175"
                        y="50%"
                        style={{ height: "10", fontSize: "11px" }}
                      >
                        {" "}
                        Low Risk (0% - 70%)
                      </Text>
                      <Rect
                        x="290"
                        rx="2"
                        ry="2"
                        width="20"
                        height="12"
                        fill="#ffbf00"
                      />
                      <Text
                        x="315"
                        y="50%"
                        style={{ height: "10", fontSize: "11px" }}
                      >
                        {" "}
                        Medium Risk (71% - 90%)
                      </Text>
                      <Rect
                        x="456"
                        rx="2"
                        ry="2"
                        width="20"
                        height="12"
                        fill="#FD0100"
                      />
                      <Text
                        x="480"
                        y="50%"
                        style={{ height: "10", fontSize: "11px" }}
                      >
                        {" "}
                        High Risk (91% - 100%)
                      </Text>

                    </Svg>

                    <Svg width="100%" height="220" style={{ marginTop: 10 }}>
                      <Line
                        x1="49"
                        y1="0"
                        x2="49"
                        y2={riskHistogram.length === 10 ? "220" : "197"}
                        stroke="black"
                        strokeWidth="1"
                      />

                      {modifiedriskHistogram.map((item, index) => (
                        <Rect
                          key={index}
                          x="50"
                          y={index * 23}
                          width={390}
                          height={13}
                          fill={"#e6e6e6"}
                        />
                      ))}

                      {modifiedriskHistogram.map((item, index) => (
                        <Rect
                          key={index}
                          x="50"
                          y={index * 23}
                          width={item.value || 0}
                          height={13}
                          fill={
                            index < 1 ? "#FD0100" : index < 3 ? "#ffbf00" : "#19543E"
                          }
                        />
                      ))}

                      {modifiedriskHistogram.map((item, index) => (
                        <Text
                          key={index}
                          // x={Math.floor(item.value) + 120}
                          x={450}
                          y={index * 23 + 9}
                          fontSize="9"
                          fill="black"
                        >
                          {(riskHistogram[index].value !== undefined
                            ? riskHistogram[index].value
                            : 0) +
                            "%" +
                            " (" +
                            riskHistogram[index].customData.substring(6) +
                            ")"}
                        </Text>
                      ))}

                      {riskHistogram.map((item, index) => (
                        <Text
                          key={index}
                          x={0}
                          y={index * 23 + 10}
                          fontSize="11"
                          fill="black"
                          textAlign="right"
                        >
                          {item.title || ""}
                        </Text>
                      ))}
                    </Svg>
                  </View>
                </View>
              </View>

            </View>

            <PdfFooterV1 pageNumber={2} totalPages={2} pathname={pathname} />
          </Page>

        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import "./bulkuser.css";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, Button
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "bootstrap/dist/css/bootstrap.min.css";
import { CSVLink } from "react-csv";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userController from "../../../controller/User/UserController.js";
import ValueFormat from "../../../util/ValueFormat";
import {
  SC_USER_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_DASHBOARD_TYPE,
  SC_USER_FIRST_NAME,
  SC_USER_USERNAME,
  SC_DIVISION_ID,
  DEVICE_TYPE,
  VEHICLE_TYPE,
} from "../../../constant/StorageConstant";
import "../Myusers/myusersStyle.css";
import { CgCloseO } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { myUserSearch } from "../../../store/actions";
import MyRidesController from "../../../controller/Rides/MyRides.js";
import { validatePassword } from "../../../util/UtilFieldValidation.js";
import MyuserdataList from "./myUserTableData";
import { excelParser } from "../../../components/Parser/ExportJson.js";
import Loader from "../../../components/Common/loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../components/Common/toastService";
import { PiDownloadSimple } from "react-icons/pi";
import { displayText } from "../../../constant/MessageConstant";
import ClearIcon from "@mui/icons-material/Clear";
import Converter from "../../../components/Common/EncodeDecode.js";
import {
  AiOutlineExclamationCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import ReactModal from "react-modal";
import BulkUserModal from "./bulkUserModal.js";
import AddIcon from "../../../../src/assets/images/userAdd.svg";
import ConformationPopup from "../../../components/Common/ConfirmationPopup.js";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { deviceOptionConfig, optionConfig } from "../../../config/OptionConfig.js";
import TW from "../../../assets/images/two_wheeler.svg"
import FW from "../../../assets/images/four_wheeler.svg"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    maxHeight: "95%",
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

function MyUsersNewTable() {
  const history = useNavigate();
  const [searchFilter, setSearchFilter] = useState("");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searchFilterSub, setSearchFilterSub] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [indexStart, setIndexStart] = useState(0);
  const [filterDays, setFilterDays] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");

  const [filterDataList, setFilterDataList] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [addFirstName, setAddFirstName] = useState();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [mobileValid, setMobileValid] = React.useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [prevShow, setPrevShow] = useState();
  const [nextShow, setNextShow] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const [userNameType, setuserNameType] = useState("MOBILE_NUMBER");
  const [customValidation, setCustomValidation] = useState("ALPHA_NUMERIC");
  const [defCountry, setDefCountry] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({
    mobile: false,
    password: false,
  });
  const location = useLocation();
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );

  const [reLoadGet, setReLoadGet] = useState(0);

  const [availableOptions, setAvailableOptions] = useState([]);
  const [avaiableDeviceTypeOptions, setAvaiableDeviceTypeOptions] = useState([]);
  const divId = localStorage.getItem(SC_DIVISION_ID);
  const validDivIds = ["divi00000000000000000000", "prod0000divi000000000001"]; useEffect(() => {
    if (validDivIds.includes(divId)) {
      setAvailableOptions(optionConfig.MOBILE_NUMBER);
      setAvaiableDeviceTypeOptions(deviceOptionConfig.ANDROID_IOS);
    } else {
      setAvailableOptions(optionConfig.CUSTOM_MOBILE_NUMBER_EMAIL_ADDRESS_IMEI_NUMBER);
      setAvaiableDeviceTypeOptions(deviceOptionConfig.ANDROID_IOS_IOS_SDK_ANDROID_SDK);
    }
  }, []);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const acceptNumbers = /^[0-9]*$/;
  const acceptAlphanumeric = /^[a-zA-Z0-9]*$/;
  const acceptCustomAlphanumeric = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]*$/;
  const acceptEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const acceptNumberMessage = "Only numbers are allowed";
  const acceptAlphanumericMessage = "Only alphanumeric characters are allowed";
  const acceptAlphaCustomnumericMessage =
    "Please enter a combination of alphabets and numbers";
  const acceptDeviceIDMessage = "Invalid IMEI Number";
  const acceptEmailMessage = "Invalid Email";
  const [passwordShow, setPasswordShow] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [twoFA, setTwoFA] = useState("NO");
  const [vehicleType, setVehicleType] = useState("TWO_WHEELER");
  // const [defPass, setdefPass] = useState(true);
  // const [dpass, setDpass] = useState(userNameType === "IMEI_NUMBER" ? "NO" : "YES");
  const [dpass, setDpass] = useState("YES");
  // const dpass = userNameType === "IMEI_NUMBER" ? "NO" : "YES";
  const [countryId, setCountryId] = useState("coun00000000000000000004");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 0,
    androidCount: 0,
    iosCount: 0,
    androidCountSDK: 0,
    iosCountSDK: 0,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
    countrylist();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      addUsername: "",
      addUserPassword: "Test@123",
      addFirstName: "",
    },
    validationSchema: Yup.object({
      addUsername: Yup.string()
        .min(
          userNameType === "MOBILE_NUMBER"
            ? 10
            : userNameType === "IMEI_NUMBER"
              ? 11
              : userNameType === "EMAIL_ADDRESS"
                ? 11
                : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? 8 : (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? 8 : 8,
          userNameType === "MOBILE_NUMBER" ? "Mobile number must have 10 digits" :
            userNameType === "IMEI_NUMBER" ? "Minimum 11 characters required" :
              userNameType === "EMAIL_ADDRESS" ? "Minimum 11 characters required" : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? "Minimum 8 characters required"
                : (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? "Minimum 8 characters required" : "Minimum 8 characters required"
        )
        .max(
          userNameType === "MOBILE_NUMBER"
            ? 10
            : userNameType === "IMEI_NUMBER"
              ? 20
              : userNameType === "EMAIL_ADDRESS"
                ? 50
                : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? 10 : (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? 20 : 20,
          userNameType === "MOBILE_NUMBER" ? "Mobile number must have only 10 digits" :
            userNameType === "IMEI_NUMBER" ? "Maximum 20 characters only" :
              userNameType === "EMAIL_ADDRESS" ? "Maximum 50 characters only" : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? "Maximum 10 characters required"
                : (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? "Maximum 20 characters required" : "Maximum 20 characters required"
        )
        .matches(
          userNameType === "MOBILE_NUMBER"
            ? acceptNumbers
            : userNameType === "IMEI_NUMBER"
              ? acceptAlphanumeric
              : userNameType === "EMAIL_ADDRESS"
                ? acceptEmail
                : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? acceptNumbers
                  : (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? acceptCustomAlphanumeric : acceptCustomAlphanumeric,
          userNameType === "MOBILE_NUMBER"
            ? acceptNumberMessage
            : userNameType === "IMEI_NUMBER"
              ? acceptDeviceIDMessage
              : userNameType === "EMAIL_ADDRESS"
                ? acceptEmailMessage
                : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? acceptNumberMessage
                  : (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? acceptAlphaCustomnumericMessage : acceptAlphaCustomnumericMessage)
        .required(userNameType === "MOBILE_NUMBER"
          ? "Mobile number is required"
          : userNameType === "IMEI_NUMBER"
            ? "IMEI number is required"
            : userNameType === "EMAIL_ADDRESS"
              ? "Email address is required"
              : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? "Custom name is required" :
                (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? "Custom name is required" : "Custom name is required"),
      addUserPassword: Yup.string()
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{5,}/, "Invalid Password")
        .required("Password is required"),
      addFirstName: Yup.string()
        .matches(/^[a-zA-Z][a-zA-Z\s]*$/, "Enter a valid combination of alphabets and spaces")
        .min(2, "Min 2 characters required")
        .max(30, "Max only 30 characters allowed")
      // .required("Firstname is required"),
    }),
    onSubmit: async (data) => {
      setIsSubmitting((prev) => {
        return { ...prev, mobile: true, password: true };
      });
      setShowLoader((current) => current + 1);
      var encryptPassword = Converter.convertMd5(data.addUserPassword);
      // var fName = "";
      // if (validation.values.addFirstName.length === 0){
      //   fName = ValueFormat.maskStringByPercentage(data.addUsername);
      // } else {
      //   fName = validation.values.addFirstName;
      // }
      let imeiDP = "";
      if (userNameType === "IMEI_NUMBER") {
        imeiDP = "NO"
      } else {
        imeiDP = dpass
      }
      var result = await userController.createUserRegistration(
        data.addUsername,
        encryptPassword,
        userNameType,
        deviceType,
        countryId,
        twoFA,
        imeiDP,
        validation.values.addFirstName,
        // fName,
        vehicleType
      );
      try {
        var resultJSON = JSON.parse(result);
        var status = resultJSON.status;
        var resultJSONData = resultJSON.data;
        if (status == "SUCCESS") {
          // var resultJSONData = resultJSON.data;
          ToastService.successmsg("User Registered Successfully!");
          getUserListData();
          setTimeout(() => {
            setIsOpen(false);
          }, 3500);
        } else if (resultJSON.status == "FAILED") {
          ToastService.errormsg(resultJSON.error.message);
          setMobileNumber("");
          setPassword("");
        }
        setShowLoader((current) => current - 1);
      } catch (errMess) {
        // console.log("Exception: ", errMess);
      }
      // dispatch(loginUser(values, props.router.navigate));
    },
  });

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  function openModal() {
    setTimeout(() => {
      validation.setFieldValue("addUsername", "");
      validation.setFieldValue("addUserPassword", "");
      validation.setFieldValue("addFirstName", "");
      validation.handleReset();
    }, 100);
    setIsOpen(true);
    setMobileNumber("");
    setPassword("");
    subscriptionData();
  }

  function NumberChange(e) {
    // console.log(e.target.value);
    const value = e.target.value.replace(/\D/g, "");
    setMobileNumber(value);
    setMobileValid(true);
  }

  const resetFormFields = () => {
    setuserNameType("MOBILE_NUMBER");
    setCustomValidation("ALPHA_NUMERIC")
    setDeviceType("");
    setTwoFA("NO");
    setDpass("YES");
    setVehicleType("TWO_WHEELER");
    setCountryId("coun00000000000000000004");
    validation.setFieldValue("addUserPassword", "Test@123");
    validation.setFieldValue("addUsername", "");
    validation.setFieldValue("addFirstName", "");
    validation.resetForm();
    setMobileValid(true);
    setPasswordValid(true);
    setIsSubmitting(false);
    setPasswordShow(false);
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
    openPopup("Cancel");
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const handleConfirmClose = () => {
    setIsSubmitting((prev) => ({ ...prev, mobile: false, password: false }));
    validation.handleReset();
    validation.setFieldValue("addUsername", "");
    validation.setFieldValue("addUserPassword", "Test@123");
    validation.setFieldValue("addFirstName", "");
    setIsOpen(false);
    setMobileValid(true);
    setPasswordValid(true);
    setDeviceType("");
    setTwoFA("NO");
    setDpass("YES");
    setVehicleType("TWO_WHEELER");
    setCountryId("coun00000000000000000004");
    setuserNameType("MOBILE_NUMBER");
    setCustomValidation("ALPHA_NUMERIC")
    // Close the confirmation modal
    closeConfirmationModal();
    setPasswordShow(false);
  };

  function closeModal() {
    // Open the confirmation modal
    openConfirmationModal();
  }

  const getUserListData = async (indexStartOfSet) => {
    setShowLoader(true);
    var result = await userController.getUser(
      indexStart,
      filterDays,
      filterDataList,
      searchFilter,
      pageSize,
      deviceFilter
    );
    var userList = [];

    try {
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      setDataLength(resultJSONData.length);
      // console.log("getUserList :" + JSON.stringify(resultJSONData));
      for (let i = 0; i < resultJSONData.length; i++) {
        var userStatus = resultJSONData[i];
        var totalRide = 0;
        var safeRide = 0;
        var safeRidePercentage = 0;
        var vehicleType = "";
        // console.log("userStatus", userStatus);
        var userId = ValueFormat.parseAsString(userStatus.userId);
        var firstName = ValueFormat.parseAsString(
          ValueFormat.capitalizeAllLetter(userStatus.firstName)
        );
        var lastName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.lastName)
        );
        var email = ValueFormat.parseAsString(
          ValueFormat.sliceStringAndDot(userStatus.email, 10)
        );
        var emailToolTip = userStatus.email.length > 0 ? userStatus.email : "-";
        var status = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.status)
        );
        var username = ValueFormat.parseAsString(userStatus.username);

        let deviceTypeTemp = "";
        if (userStatus.deviceType === "ANDROID") {
          deviceTypeTemp = "Android"
        } else if (userStatus.deviceType === "ANDROID_SDK") {
          deviceTypeTemp = "Android SDK"
        } else if (userStatus.deviceType === "IOS") {
          deviceTypeTemp = "iOS"
        } else if (userStatus.deviceType === "IOS_SDK") {
          deviceTypeTemp = "iOS SDK"
        } else {
          deviceTypeTemp = "N/A"
        }

        // var devicetype =
        //   ValueFormat.parseAsString(
        //     ValueFormat.capitalizeFirstLetterAndFormat(userStatus.deviceType)
        //   ) || "N/A";
        var password = ValueFormat.parseAsString(userStatus.password);
        var createdAt = ValueFormat.parseAsString(
          userStatus.createdAtToTimeZone
        );
        var createData = createdAt.split(" ");
        var createdDate = createData[0];

        var lastRideDateTimeToTimeZone = ValueFormat.parseAsString(
          userStatus.lastRideDateTimeToTimeZone
        );
        var lastRideDateTime = lastRideDateTimeToTimeZone.split(" ");
        var lastRideTimeZone = lastRideDateTime[0];

        var currentMonthRideCount = ValueFormat.parseAsInt(
          userStatus.currentMonthRideCount
        );
        var rides = ValueFormat.parseAsInt(userStatus.rideCount);
        status =
          status == "Active" && rides == 0
            ? "Inactive"
            : status == "Initiated"
              ? "Unregistered"
              : status == "Registered"
                ? "Pending"
                : status == "Deactivated"
                  ? "Deactivated"
                  : "Active";
        var currentYearRideCount = ValueFormat.parseAsInt(
          userStatus.currentYearRideCount
        );
        var currentDayRideCount = ValueFormat.parseAsInt(
          userStatus.currentDayRideCount
        );
        var currentWeekRideCount = ValueFormat.parseAsInt(
          userStatus.currentWeekRideCount
        );
        var iosRideCountList = ValueFormat.parseAsInt(userStatus.iosRideCount);
        var androaidRideCountList = ValueFormat.parseAsInt(
          userStatus.androidRideCount
        );
        var androidSdkRideCountList = ValueFormat.parseAsInt(
          userStatus.androidSdkRideCount
        ); var iosSdkRideCountList = ValueFormat.parseAsInt(
          userStatus.iosSdkRideCount
        );
        var totalCounts = ValueFormat.parseAsInt(userStatus.totalCount);
        setTotalPageCount(Math.ceil(totalCounts / pageSize));
        setNextShow((totalCounts - indexStartOfSet) > pageSize);
        var overallRideBoxList = ValueFormat.parseAsArray(
          userStatus.overallRideBoxList
        );

        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });
        // console.log("totalRide",totalRide);
        safeRidePercentage =
          ValueFormat.parseAsFloat((safeRide / totalRide) * 100, 0) + "%";

        vehicleType = ValueFormat.parseAsString(userStatus.vehicleType || "-");

        var userDetails = {
          firstName: firstName,
          lastName: lastName,
          username: username,
          password: password,
          emailId: emailToolTip,
          status: status,
          createdTime: createdAt,
          createdDate: createdDate,
          lastRideTime: lastRideDateTimeToTimeZone,
          lastRideTimeZone: lastRideTimeZone,
          iosRideCount: iosRideCountList,
          androidRideCount: androaidRideCountList,
          androidSdkRideCountList: androidSdkRideCountList,
          iosSdkRideCountList: iosSdkRideCountList,
          totalRides: rides,
          CYRides: currentYearRideCount,
          CMRides: currentMonthRideCount,
          CDRides: currentDayRideCount,
          CWRides: currentWeekRideCount,
          ExportRides: "CY",
          userId: userId,
          safeRide: safeRidePercentage,
          devicetype: deviceTypeTemp,
          vehicleType: vehicleType
        };
        userList.push(userDetails);
      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }
    setData(userList);

    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 0) {
      setPrevShow(true);
    }
    setShowLoader(false);
  };

  const [countryList, setCountryList] = useState([]);
  const handleCountrySelection = (selectedCountryId) => {
    setCountryId(selectedCountryId);
  };
  const countrylist = async () => {
    setShowLoader(true);
    var result = await userController.getCountryList();
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        setCountryList(resultJSONData);
      } else {
        console.log("Error in fetching Country List");
      }
    } catch (e) {
      console.log("Catch error", e);
    } finally {
      setShowLoader(false);
    }
  };

  const getUserExcelListData = async () => {
    // setShowLoader(true);
    var result = await userController.getUserExcelData(
      filterDays,
      filterDataList,
      deviceFilter,
      searchFilter
    );
    var userList = [];

    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("getUserListEL :" + JSON.stringify(resultJSONData));
        for (let i = 0; i < resultJSONData.length; i++) {
          var userStatus = resultJSONData[i];
          var userId = ValueFormat.parseAsString(userStatus.userId);
          var firstName = ValueFormat.parseAsString(
            ValueFormat.capitalizeAllLetter(userStatus.firstName)
          );
          var lastName = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.lastName)
          );
          var email = ValueFormat.parseAsString(
            ValueFormat.sliceStringAndDot(userStatus.email, 10)
          );
          var emailToolTip =
            userStatus.email.length > 0 ? userStatus.email : "-";
          var status = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.status)
          );
          var username = ValueFormat.parseAsString(userStatus.username);
          var password = ValueFormat.parseAsString(userStatus.password);
          var createdAt = ValueFormat.parseAsString(
            userStatus.createdAtToTimeZone
          );
          var createData = createdAt.split(" ");
          var createdDate = createData[0];

          var lastRideDateTimeToTimeZone = ValueFormat.parseAsString(
            userStatus.lastRideDateTimeToTimeZone
          );
          var lastRideDateTime = lastRideDateTimeToTimeZone.split(" ");
          var lastRideTimeZone = lastRideDateTime[0];

          var currentMonthRideCount = ValueFormat.parseAsInt(
            userStatus.currentMonthRideCount
          );
          var rides = ValueFormat.parseAsInt(userStatus.rideCount);
          var currentYearRideCount = ValueFormat.parseAsInt(
            userStatus.currentYearRideCount
          );
          var currentWeekRideCount = ValueFormat.parseAsInt(
            userStatus.currentWeekRideCount
          );
          var currentDayRideCount = ValueFormat.parseAsInt(
            userStatus.currentDayRideCount
          );
          var iosRideCountList = ValueFormat.parseAsInt(
            userStatus.iosRideCount
          );
          var devicetype = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.deviceType)
          );
          var androaidRideCountList = ValueFormat.parseAsInt(
            userStatus.androidRideCount
          );
          var status = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.status)
          );

          var userDetails = {
            "First Name": firstName,
            Username: username,
            "Device Type": devicetype,
            Status: status == "Active" && rides == 0
              ? "Inactive"
              : status == "Initiated"
                ? "Unregistered"
                : status == "Registered"
                  ? "Pending"
                  : "Active",
            Activation: createdDate,
            "Last Ride Time": lastRideDateTimeToTimeZone,
            "Total Rides": rides,
            IOS: iosRideCountList,
            And: androaidRideCountList,
            "CY Rides": currentYearRideCount,
            "CM Rides": currentMonthRideCount,
            "CW Rides": currentWeekRideCount,
            "CD Rides": currentDayRideCount,
          };
          userList.push(userDetails);
        }
      } else {
        // var code = resultJSON.error.code;
        // if (code == "E1206") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        //   setShowLoader(true);
        // }
        console.log("Status failed");
      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    } finally {
      // setShowLoader(false);
    }
    if (!resultJSONData || resultJSONData.length === 0) {
      setDataExcel("Data not available")
    } else {
      setDataExcel(userList);
    }

  };

  const exportExcel = async (userId, firstName) => {
    var dataExcel = [];
    var result = await MyRidesController.myRidesListExport(userId);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;

    // console.log(
    //   "resultJSONData myRidesListExport : " + JSON.stringify(resultJSONData)
    // );

    for (let i = 0; i < resultJSONData.length; i++) {
      var drivingScore = "0",
        rideName = "N/A",
        /*status = 'N/A', */
        origin = "N/A",
        alerts = 0,
        createdTime = "N/A",
        /*subType = 'N/A',*/ dayPercentage = "0",
        nightPercentage = "0" /*accessLevel = 'N/A',*/,
        endTime = "N/A",
        city = "N/A",
        state = "N/A",
        country = "N/A",
        /*locationName = 'N/A',*/ zipCode = "N/A",
        anticipation = "0",
        drivingSkill = "0",
        selfConfidence = "0",
        travelTime = "-",
        totalKiloMeter = "0 Kms",
        urbanPercent = "0%",
        ruralPercent = "0%",
        highwayPercent = "0%";
      // androidCount = "0";

      try {
        // var totalPercentage = 100;
        var rideObject = resultJSONData[i];

        rideName = rideObject.name.length > 0 ? rideObject.name : "N/A";
        // status         = rideObject.status.length > 0 ?  rideObject.status : 'N/A';
        origin = rideObject.origin.length > 0 ? rideObject.origin : "N/A";
        alerts = rideObject.alertDataCount > 0 ? rideObject.alertDataCount : 0;
        createdTime =
          rideObject.startDateTimeToTimeZone.length > 0
            ? rideObject.startDateTimeToTimeZone
            : "N/A";
        endTime =
          rideObject.endDateTimeToTimeZone != null
            ? rideObject.endDateTimeToTimeZone
            : "N/A";

        // subType        = rideObject.subType.length > 0 ?  rideObject.subType : 'N/A';
        dayPercentage =
          rideObject.dayPercentage > 0
            ? parseFloat(rideObject.dayPercentage).toFixed(0)
            : 0;
        nightPercentage =
          rideObject.dayPercentage > 0 ? 100 - dayPercentage + "%" : 0 + "%";
        // locationName    =  rideObject.locationName.length > 0 ? rideObject.locationName : 'N/A';
        // accessLevel     = rideObject.accessLevel.length > 0 ? rideObject.accessLevel : 'N/A';
      } catch (errMess) {
        // console.log("Exception Error Message :" + errMess);
      }

      try {
        var deviceDataAddressField = rideObject.lastDeviceField;
        var deviceDataField = rideObject;
        city =
          deviceDataAddressField.city != null &&
            deviceDataAddressField.city.length > 0
            ? deviceDataAddressField.city
            : "N/A";
        state =
          deviceDataAddressField.state != null &&
            deviceDataAddressField.state.length > 0
            ? deviceDataAddressField.state
            : "N/A";
        country =
          deviceDataAddressField.country != null &&
            deviceDataAddressField.country.length > 0
            ? deviceDataAddressField.country
            : "N/A";
        zipCode =
          deviceDataAddressField.zipCode != null &&
            deviceDataAddressField.zipCode > 0
            ? deviceDataAddressField.zipCode
            : "N/A";
        anticipation =
          deviceDataField.anticipation != null &&
            deviceDataField.anticipation > 0
            ? parseFloat(deviceDataField.anticipation).toFixed(0)
            : 0;
        drivingSkill =
          deviceDataField.drivingSkill != null &&
            deviceDataField.drivingSkill > 0
            ? parseFloat(deviceDataField.drivingSkill).toFixed(0)
            : 0;
        drivingScore =
          deviceDataField.drivingScore != null &&
            deviceDataField.drivingScore > 0
            ? parseFloat(deviceDataField.drivingScore).toFixed(0)
            : 0;
        selfConfidence =
          deviceDataField.selfConfidence != null &&
            deviceDataField.selfConfidence > 0
            ? parseFloat(deviceDataField.selfConfidence).toFixed(2)
            : 0;
        travelTime =
          deviceDataField.travelTime != null && deviceDataField.travelTime > 0
            ? parseFloat(deviceDataField.travelTime).toFixed(2) + " Mins"
            : 0 + "Mins";
        totalKiloMeter =
          deviceDataField.kiloMeter != null && deviceDataField.kiloMeter > 0
            ? parseFloat(deviceDataField.kiloMeter).toFixed(2) + "Kms"
            : 0 + "Kms";
        urbanPercent =
          deviceDataField.urbanPercent != null &&
            deviceDataField.urbanPercent > 0
            ? parseFloat(deviceDataField.urbanPercent).toFixed(2) + "%"
            : 0 + "%";
        ruralPercent =
          deviceDataField.ruralPercent != null &&
            deviceDataField.ruralPercent > 0
            ? parseFloat(deviceDataField.ruralPercent).toFixed(2) + "%"
            : 0 + "%";
        highwayPercent =
          deviceDataField.highwayPercent != null &&
            deviceDataField.highwayPercent > 0
            ? parseFloat(deviceDataField.highwayPercent).toFixed(2) + "%"
            : 0 + "%";
      } catch (errMess) {
        // console.log("Exception Error Message :" + errMess);
      }

      var userDetails = {
        "Ride Name": rideName,
        // 'Ride Status': status,
        "Driving Score": drivingScore,
        "Driving Skill": drivingSkill,
        Anticipation: anticipation,
        "Self Confidence": selfConfidence,
        "Start Time": createdTime,
        "End Time": endTime,
        "No Of Alerts": alerts,
        "Day Percentage": dayPercentage + "%",
        "Night Percentage": nightPercentage,
        // 'From - Location Name' : locationName,
        "To-City": city,
        "To-State": state,
        "To-Country": country,
        "To-Zip Code": zipCode,
        "Urban Percentage": urbanPercent,
        "Rural Percentage": ruralPercent,
        "Highway Percentage": highwayPercent,
        Mobile: origin,
        // 'Ride Type' : subType,
        Distance: totalKiloMeter,
        Duration: travelTime,
      };
      dataExcel.push(userDetails);
    }

    excelParser().exportDataFromJSON(
      dataExcel,
      firstName + "'s" + " " + "ride" + " " + "detail",
      "csv"
    );
  };

  const createUser = async () => {
    let encryptPassword = "Test@123"
    if (userNameType !== "CUSTOM") {
      return encryptPassword;
    } else {
      encryptPassword = Converter.convertMd5(password);
    }
    var result = await userController.createUserRegistration(
      mobileNumber,
      encryptPassword,
      userNameType
    );
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        // var resultJSONData = resultJSON.data;
        ToastService.successmsg("User Registered Successfully!");
        getUserListData();
        setTimeout(() => {
          setIsOpen(false);
          resetFormFields();
        }, 3500);
      } else if (resultJSON.status == "FAILED") {
        ToastService.errormsg(resultJSON.error.message);
        setMobileNumber("");
        setPassword("");
      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }
  };

  const subscriptionData = async () => {
    var updatedTableData = [];

    var response = await MyRidesController.MySubscription();
    // console.log("response : ", response);
    try {
      var responseJSON = JSON.parse(response);
      // console.log("CountData : ", responseJSON)
      if (responseJSON.status == "SUCCESS") {
        var responseDATA = responseJSON.data;
        if (responseDATA.length > 0) {
          for (let i = 0; i < responseDATA.length; i++) {
            const getData = responseDATA[i];
            // const divisionDetail = ValueFormat.parseAsObject(getData.divisionDetail);
            const subscriptionName = ValueFormat.parseAsString(
              getData.subscriptionName
            );
            const availableLicenseCount = ValueFormat.parseAsString(
              getData.availableLicenseCount
            );
            const startDateTimeToTimeZone = ValueFormat.parseAsString(
              getData.startDateTimeToTimeZone
            );
            const endDateTimeToTimeZone = ValueFormat.parseAsString(
              getData.endDateTimeToTimeZone
            );
            const androidCount = ValueFormat.parseAsString(
              getData.androidLicenseCount
            );
            const androidCountSDK = ValueFormat.parseAsString(
              getData.androidSdkLicenseCount
            );
            const iosCountSDK = ValueFormat.parseAsString(
              getData.iosSdkLicenseCount
            );
            const iosCount = ValueFormat.parseAsString(getData.iosLicenseCount);
            const orderCount = ValueFormat.parseAsString(getData.orderCount);
            setSubcriptionDataList((prev) => {
              return {
                ...prev,
                subscriptionName: subscriptionName,
                orderCount: orderCount,
                totalCount: availableLicenseCount,
                startDate: startDateTimeToTimeZone,
                endDate: endDateTimeToTimeZone,
                androidCount: androidCount,
                iosCount: iosCount,
                androidCountSDK: androidCountSDK,
                iosCountSDK: iosCountSDK,
              };
            });
            const orderHistoryList = ValueFormat.parseAsArray(
              getData.orderHistoryList
            );
            const subscriptionId = ValueFormat.parseAsString(
              getData.subscriptionId
            );
            setSubscriptionId(subscriptionId);
            for (let r = 0; r < orderHistoryList.length; r++) {
              const getOrderData = orderHistoryList[r];
              const androidLicenseCount = ValueFormat.parseAsInt(
                getOrderData.androidLicenseCount
              );
              const iosLicenseCount = ValueFormat.parseAsInt(
                getOrderData.iosLicenseCount
              );
              const orderName = ValueFormat.parseAsString(getOrderData.name);
              const paymentMode = ValueFormat.capitalizeFirstLetterAndFormat(
                ValueFormat.parseAsString(getOrderData.paymentMode)
              );
              const id = ValueFormat.parseAsString(getOrderData.id);
              const subscriptionId = ValueFormat.parseAsString(
                getOrderData.subscriptionId
              );
              const totalLicenseCount = ValueFormat.parseAsInt(
                getOrderData.totalLicenseCount
              );
              const date = ValueFormat.parseAsString(
                getOrderData.createdAtToTimeZone
              );
              var userDetails = {
                id: id,
                date: date,
                orderName: orderName,
                androidLicenseCount: androidLicenseCount,
                iosLicenseCount: iosLicenseCount,
                paymentMode: paymentMode,
                subscriptionId: subscriptionId,
                totalLicenseCount: totalLicenseCount,
              };
              updatedTableData.push(userDetails);
            }
            setShowLoader(false);
          }
          setShowLoader(false);
        } else {
          setShowLoader(false);

          // console.log("resultJSONData.length is not greater than 0");
        }
      } else {
        setShowLoader(false);

        console.error("Failed to fetch data");
      }
    } catch (e) {
      setShowLoader(false);

      console.error("Catch Error : ", e);
    }
  };
  useEffect(() => {
    subscriptionData();
  }, []);

  const validPhone = () => {
    const phrex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (
      phrex.test(mobileNumber) &&
      mobileNumber.length < 11 &&
      mobileNumber.length > 9
    ) {
      setMobileValid(true);
      return true;
    } else {
      setMobileValid(false);
      return false;
    }
  };
  const validPassword = () => {
    const rep = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{5,}/;
    if (rep.test(password)) {
      setPasswordValid(true);
      return true;
    } else {
      setPasswordValid(false);
      return false;
    }
  };

  // const submit = () => {
  //   if (validPhone() && validPassword()) {
  //     setShowLoader(true);
  //     createUser();
  //   }
  // };

  const submit = () => {
    if (userNameType === "CUSTOM") {
      if (validPhone() && validPassword()) {
        setShowLoader(true);
        createUser();

      }
    } else {
      if (validPhone()) {
        setShowLoader(true);
        createUser();
      }
    }
  };

  const handleFilterValues = (filterDataList) => {
    setFilterDataList(filterDataList);
    setIndexStart((current) => current - current);
    setPageNumber((prev) => prev - prev + 1);
  };

  useEffect(() => {
    setTimeout(() => {
      if (location.state != null) {
        const { filterLink } = location.state;
        setFilterDataList(filterLink);
      }
    }, 900);
  }, []);

  useEffect(() => {
    getUserListData(indexStart);
  }, [indexStart, filterDataList, deviceFilter, reLoadGet]);
  useEffect(() => {
    getUserExcelListData();
  }, [filterDataList, searchFilter, deviceFilter])

  const searchSubmit = () => {
    setIndexStart(0);
    setPageNumber(1);
    getUserListData();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchSubmit();
    }
  };

  // _____________________________

  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        ...popupData,
        Purpose: "Cancel",
        message: "you want to close ?",
      });
    } else {
      setPopUpData({
        ...popupData,
        Purpose: "Delete",
        message: "This action cannot be undone. The station will be removed from the list.",
        DataDetails: Data ? Data : "",
      });
    }
  };

  // const PopUpClose = () => {
  //   setOpen(false);
  // };

  // _______________________________________________


  //meta title
  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <ToastContainer style={{ width: "auto" }} />
      <Container fluid>
        <Breadcrumbs title=" My Users" breadcrumbItem=" My Users" />
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start text-black">
            <h5 className="mb-0">All Users</h5>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader style={{ padding: "0px", paddingBottom: 0 }}>
                <Row>
                  <Col xl={2} lg={2} style={{
                    borderRight: "1px solid #DFDEDE",
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        data-tooltip-id="actio"
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Action
                        <ReactTooltip
                          id="actio"
                          place="top"
                          content="Action"
                          style={{ backgroundColor: "black" }}
                        />
                      </h4>
                    </div>

                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "40px", marginTop: "16px" }}
                    >

                      <button
                        type="button"
                        data-tooltip-id="AddUserNewTool"
                        className="btn btn-soft-secondary btn-sm m-1"
                        onClick={openModal}
                      >
                        <img src={AddIcon} alt="addIcons" style={{ height: "25px", width: "25px" }} />
                        <ReactTooltip
                          id="AddUserNewTool"
                          place="top"
                          content="Add User"
                          style={{ background: "black" }}
                        />
                      </button>

                      <BulkUserModal setReLoadGet={setReLoadGet} />

                      {/* <div className="my-3 d-flex justify-content-center align-items-center">
                        <button
                          className="button m-2"
                          onClick={openModal}
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid #555555",
                          }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ fontSize: "10px" }}
                          ></i>
                          &nbsp;Add User
                        </button>
                      </div> */}

                    </div>
                  </Col>

                  <Col
                    xl={2}
                    lg={2}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    {/* <div className="d-flex justify-content-center align-items-center" style={{ height: "40px", marginTop:"16px"}}> 
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        IOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="IOS"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID_SDK"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        style={{whiteSpace:'nowrap'}}
                        onClick={() => setDeviceFilter("ANDROID_SDK")}
                        data-tooltip-id="ASDK"
                      >
                        Android SDK
                        <ReactTooltip
                          id="ASDK"
                          place="top"
                          content="Android SDK"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS_SDK"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        style={{whiteSpace:'nowrap'}}
                        onClick={() => setDeviceFilter("IOS_SDK")}
                        data-tooltip-id="ISDK"
                      >
                        IOS SDK
                        <ReactTooltip
                          id="ISDK"
                          place="top"
                          content="IOS SDK"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div> */}
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "40px", marginTop: "16px" }}>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret type="button" className='btn-sm btnColorStylesNew m-1' 
                        // color="primary"
                        style={{color:"black",border:"0px"}}
                         >
                          {deviceFilter === '' ? 'All' : deviceFilter === 'ANDROID' ? 'Android' : deviceFilter === 'IOS' ? 'iOS' : deviceFilter === 'ANDROID_SDK' ? 'Android SDK' : deviceFilter === 'IOS_SDK' ? 'iOS SDK' : 'N/A'}<i className="mdi mdi-chevron-down ms-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setDeviceFilter('')} > All </DropdownItem>
                          <DropdownItem onClick={() => setDeviceFilter('ANDROID')} > Android </DropdownItem>
                          <DropdownItem onClick={() => setDeviceFilter('IOS')} > iOS </DropdownItem>
                          <DropdownItem onClick={() => setDeviceFilter('ANDROID_SDK')} > Android SDK </DropdownItem>
                          <DropdownItem onClick={() => setDeviceFilter('IOS_SDK')} > iOS SDK </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>

                  <Col
                    xl={4}
                    lg={4}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="status"
                      >
                        User Status
                        <ReactTooltip
                          id="status"
                          place="top"
                          content="Status"
                          style={{ backgroundColor: "black" }}
                        />
                      </h4>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "40px", marginTop: "16px" }}
                    >
                      <button
                        type="button"
                        data-tooltip-id="aall"
                        className={
                          filterDataList == ""
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleFilterValues("")}
                      >
                        All
                        <ReactTooltip
                          id="aall"
                          place="top"
                          content="License Issued"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="acti"
                        className={
                          filterDataList == "ACTIVE"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => handleFilterValues("ACTIVE")}
                      >
                        Active
                        <ReactTooltip
                          id="acti"
                          place="top"
                          content="Minimum 1 Ride"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="inacti"
                        className={
                          filterDataList == "INACTIVE"
                            ? "btn btnColorStylesNew btn-sm m-2"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleFilterValues("INACTIVE")}
                      >
                        Inactive
                        <ReactTooltip
                          id="inacti"
                          place="top"
                          content="0 Ride"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        data-tooltip-id="INITIATED"
                        type="button"
                        className={
                          filterDataList == "INITIATED"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => handleFilterValues("INITIATED")}
                      >
                        Unregistered
                      </button>
                      <ReactTooltip
                        id="INITIATED"
                        place="top"
                        content="Profile Not Registered"
                        style={{ background: "black" }}
                      />
                      <button
                        data-tooltip-id="REGISTERED"
                        type="button"
                        className={
                          filterDataList == "REGISTERED"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleFilterValues("REGISTERED")}
                      >
                        Pending
                        <ReactTooltip
                          id="REGISTERED"
                          place="top"
                          content="Verification Pending Users"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        data-tooltip-id="DEACTIVATED"
                        type="button"
                        className={
                          filterDataList == "DEACTIVATED"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleFilterValues("DEACTIVATED")}
                      >
                        Deactivated
                        <ReactTooltip
                          id="DEACTIVATED"
                          place="top"
                          content="Deactivated Users"
                          style={{ background: "black" }}
                        />
                      </button>
                      {/* <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => handleFilterValues("CURRENT_YEAR")}>
                                        Export As Excel
                                    </button>{" "} */}
                    </div>
                  </Col>

                  <Col xl={3} lg={3} style={{ borderRight: "1px solid #DFDEDE", padding: 0 }} >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="GlobalSearch"
                      >
                        Global Search
                        <ReactTooltip
                          id="GlobalSearch"
                          place="top"
                          content="Global Search"
                          style={{ background: "black", zIndex: "100" }}
                        />
                      </h4>
                    </div>



                    <div
                      className="my-3 SearchUiAlignment"
                      style={{ height: "40px", display: "flex", justifyContent: "center", }}
                    >

                      <input
                        type="search"
                        style={{ border: " 1px solid #EEEBEB", width: "70%", height: "39px" }}
                        value={searchFilter}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setSearchFilter(e.target.value);
                        }}
                        placeholder="Mobile number or First name"
                      />
                      <button
                        style={{ marginLeft: "5px" }}
                        className="btn btnColorStylesNew me-3"
                        type="button"
                        onClick={searchSubmit}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                    </div>




                  </Col>

                  <Col xl={1} lg={1} style={{ paddingLeft: "0px" }}>
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="dwn"
                      >
                        Download
                        <ReactTooltip
                          id="dwn"
                          place="top"
                          content="Download"
                          style={{ background: "black", zIndex: "100" }}
                        />
                      </h4>
                    </div>
                    <div
                      className="mt-3 mb-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <span className="mt-1">Excel:</span> */}

                      <CSVLink
                        data={dataExcel}
                        type="button"
                        // onClick={onCloseClick}
                        className="btn btn-secondary btn-sm m-1"
                        filename={userFirstName + "'s" + " Users list.csv"}
                      // id="delete-record"
                      >
                        <PiDownloadSimple
                          data-tooltip-id="currentyears"
                          size="18px"
                        />
                        <ReactTooltip
                          id="currentyears"
                          place="top"
                          content="Current Year Users as Excel"
                          style={{ background: "black" }}
                        />
                        {/* <span  style={{ fontSize: 10 }}>CY</span> */}
                      </CSVLink>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                {/* <Row>
                  <Col xl={12} lg={12} md={12} sm={12} sx={12}>
                    <form className="d-none d-lg-block">
                      <div
                        className="mb-3 mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <label className="mt-2 " style={{ fontSize: "15px" }}>
                          &nbsp;Global Search:
                        </label>
                        <input
                          type="search"
                          style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                          value={searchFilter}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => {
                            setSearchFilter(e.target.value);
                          }}
                          placeholder="Mobile number or First name"
                        />
                        <button
                          style={{ marginLeft: "5px" }}
                          className="btn btn-primary me-3"
                          type="button"
                          onClick={searchSubmit}
                        >
                          <i className="bx bx-search-alt align-middle"></i>
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row> */}
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered border-primary mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="fname"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', padding: "8px" }}
                            >
                              First Name
                              <ReactTooltip
                                id="fname"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="uname"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Username
                              <ReactTooltip
                                id="uname"
                                place="top"
                                content="UserName"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="vt"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Vehicle Type
                              <ReactTooltip
                                id="vt"
                                place="top"
                                content="Vehicle Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="devicet"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Device Type
                              <ReactTooltip
                                id="devicet"
                                place="top"
                                content="Device Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            {/* <th data-tooltip-id="usern" style={{ textAlign: "center", padding: "8px" }}>
                              Username
                              <ReactTooltip
                                id="usern"
                                place="top"
                                content="Username"
                              />
                            </th> */}
                            <th
                              data-tooltip-id="stat"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Status
                              <ReactTooltip
                                id="stat"
                                place="top"
                                content="Status"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cdate"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Activation
                              <ReactTooltip
                                id="cdate"
                                place="top"
                                content="Created Date"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="lrd"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px 25px" }}
                            >
                              Last Ride
                              <ReactTooltip
                                id="lrd"
                                place="top"
                                content="Last Ride Date"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="sdp"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              C1 (%)
                              <ReactTooltip
                                id="sdp"
                                place="top"
                                content="Safe Driver Category Percentage"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="all"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Total
                              <ReactTooltip
                                id="all"
                                place="top"
                                content="Total Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="io"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              iOS
                              <ReactTooltip
                                id="io"
                                place="top"
                                content="iOS Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="iofg"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              iOS SDK
                              <ReactTooltip
                                id="iofg"
                                place="top"
                                content="iOS SDK Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="andr"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Android
                              <ReactTooltip
                                id="andr"
                                place="top"
                                content="Android Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="andgdgr"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Android SDK
                              <ReactTooltip
                                id="andgdgr"
                                place="top"
                                content="Android SDK Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>

                            <th
                              data-tooltip-id="cy"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              CY
                              <ReactTooltip
                                id="cy"
                                place="top"
                                content="Current Year Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>

                            <th
                              data-tooltip-id="cm"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              CM
                              <ReactTooltip
                                id="cm"
                                place="top"
                                content="Current Month Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cw"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              CW
                              <ReactTooltip
                                id="cw"
                                place="top"
                                content="Current Week Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cd"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              CD
                              <ReactTooltip
                                id="cd"
                                place="top"
                                content="Current Day Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="downlo"
                              style={{ fontSize: "13px", fontWeight: 600, verticalAlign: 'middle', textAlign: "center", padding: "8px" }}
                            >
                              Download
                              <ReactTooltip
                                id="downlo"
                                place="top"
                                content="Download"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            (data || []).map((datas, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  <Link
                                    to={
                                      datas.status == "Active" &&
                                      "/user-dashboard"
                                    }
                                    onMouseEnter={() => {
                                      localStorage.setItem(
                                        SC_USER_ID,
                                        datas.userId
                                      );
                                      localStorage.setItem(
                                        SC_LOGIN_USER_FIRST_NAME,
                                        datas.firstName
                                      );
                                      localStorage.setItem(
                                        DEVICE_TYPE,
                                        datas.devicetype
                                      );
                                      localStorage.setItem(
                                        VEHICLE_TYPE,
                                        datas.vehicleType
                                      );
                                      if (
                                        datas.username !== "" &&
                                        datas.username !== undefined
                                      ) {
                                        localStorage.setItem(
                                          SC_USER_USERNAME,
                                          datas.username
                                        );
                                      } else {
                                        localStorage.setItem(
                                          SC_USER_USERNAME,
                                          "-"
                                        );
                                      }
                                    }}
                                  >
                                    {datas.firstName}
                                  </Link>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {datas.username}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.vehicleType == "TWO_WHEELER" ? <img src={TW} width={25} height={25} /> : datas.vehicleType == "FOUR_WHEELER" ? <img src={FW} width={25} height={25} /> : "-"}</div>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {datas.devicetype}
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {datas.status}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    data-tooltip-id={"register" + i}
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.createdDate}
                                  </span>
                                  <ReactTooltip
                                    id={"register" + i}
                                    place="top"
                                    content={datas.createdTime}
                                    style={{ backgroundColor: "black" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    data-tooltip-id={"lastride" + i}
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.lastRideTimeZone}
                                  </span>
                                  <ReactTooltip
                                    id={"lastride" + i}
                                    place="top"
                                    content={datas.lastRideTime}
                                    style={{ backgroundColor: "black" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {datas.safeRide}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.totalRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.iosRideCount}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.iosSdkRideCountList}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.androidRideCount}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.androidSdkRideCountList}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.CYRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.CMRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {" "}
                                    {datas.CWRides}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                    // textAlign: "end",
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.CDRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "5px",
                                    }}
                                    onClick={() =>
                                      exportExcel(datas.userId, datas.firstName)
                                    }
                                  >
                                    <button
                                      data-tooltip-id="CurrentYear"
                                      type="button"
                                      className="btn btn-soft-secondary btn-sm "
                                    >
                                      <PiDownloadSimple size="18px" />
                                    </button>
                                    <ReactTooltip
                                      id="CurrentYear"
                                      place="top"
                                      content="Current Year Rides as Excel"
                                      style={{ backgroundColor: "black" }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="18">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="18">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="pagination">
                    <a>{pageNumber}</a>
                  </div>
                  <p style={{ marginTop: "6px", marginLeft: "5px", marginRight: "5px" }} >of</p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                  </div>
                </div>
              ) : null}
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow && dataLength > 0 ? "visible" : "hidden",
                  float: "right",
                }}
              >
                Next
              </button>
            </div>
            <br />
          </Col>
        </Row>

        <div style={{ backgroundColor: "red" }}>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={resetFormFields}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            style={{ overflowY: "scroll", ...customStyles }}
            overlayClassName="custom-modal-overlay"
            contentLabel="Example Modal"
          >
            <ModalHeader
              toggle={openConfirmationModal}
              style={{
                padding: "20px",
                paddingBottom: "10px",
                background: "#5156be1a",
                alignItems: "center",
              }}
            >
              <h5 style={{ fontSize: "26px" }}>Add User</h5>
            </ModalHeader>
            <Form
              className="custom-form mt-0 pt-2"
              onSubmit={(e) => {
                e.preventDefault();
                setIsSubmitting((prev) => {
                  return { ...prev, mobile: true, password: true };
                });
                validation.handleSubmit();
                return false;
              }}
            >
              <ModalBody
                style={{
                  padding: "20px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Row className="mt-3" style={{ borderBottom: '1px solid lightgrey' }}>
                  <Col xs={6}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      Total License Remaining : <span style={{ fontWeight: 400, }}>{subScriptionDataList.totalCount}</span>
                    </label>
                    <AiOutlineExclamationCircle
                      style={{ marginTop: "-2.5px", marginLeft: '2px' }}
                      data-tooltip-id="currentAvailabilityTooltipContent"
                    />
                    <ReactTooltip
                      id="currentAvailabilityTooltipContent"
                      place="top"
                      content={<span>Current Availability</span>}
                      style={{ backgroundColor: "black" }}
                    />
                  </Col>
                  <Col xs={6}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      Expiry Date : <span style={{ fontWeight: 400, }}>{subScriptionDataList.endDate}</span>
                    </label>
                  </Col>
                  <Col xs={6}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      iOS License Remaining : <span style={{ fontWeight: 400, }}>{subScriptionDataList.iosCount}</span>
                    </label>
                  </Col>
                  <Col xs={6}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      Android License Remaining : <span style={{ fontWeight: 400, }}>{subScriptionDataList.androidCount}</span>
                    </label>
                  </Col>
                  {!validDivIds.includes(divId) && (
                    <>
                      <Col xs={6}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "sans-serif",
                          }}
                        >
                          iOS SDK License Remaining : <span style={{ fontWeight: 400, }}>{subScriptionDataList.iosCountSDK}</span>
                        </label>
                      </Col>

                      <Col xs={6}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "sans-serif",
                          }}
                        >
                          Android SDK License Remaining : <span style={{ fontWeight: 400, }}>{subScriptionDataList.androidCountSDK}</span>
                        </label>
                      </Col>
                    </>
                  )}
                </Row>
                <Row className="mt-3">
                  <Col xs={6}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      Device Type
                    </label>
                    <select
                      className="form-select"
                      value={deviceType}
                      required
                      onChange={(e) => setDeviceType(e.target.value)}
                    >
                      <option value={""} disabled>
                        Select Mobile Type
                      </option>
                      {/* <option value={"ANDROID"}>Android</option>
                      <option value={"ANDROID_SDK"}> Android SDK </option>
                      <option value={"IOS"}> iOS</option>
                      <option value={"IOS_SDK"}> iOS SDK</option> */}

                      {avaiableDeviceTypeOptions.includes('ANDROID') && <option value="ANDROID">Android</option>}
                      {avaiableDeviceTypeOptions.includes('ANDROID_SDK') && <option value="ANDROID_SDK">Android SDK</option>}
                      {avaiableDeviceTypeOptions.includes('IOS') && <option value="IOS">iOS</option>}
                      {avaiableDeviceTypeOptions.includes('IOS_SDK') && <option value="IOS_SDK">iOS SDK</option>}
                    </select>
                  </Col>
                  <Col xs={6}>
                    <div style={{ display: "flex" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "sans-serif",
                        }}
                      >
                        First Name
                      </label>
                      <div>
                        <AiOutlineExclamationCircle
                          style={{ marginTop: "-3px", marginLeft: "5px" }}
                          data-tooltip-id="firstnameTooltipContent"
                        />
                        <ReactTooltip
                          id="firstnameTooltipContent"
                          place="top"
                          content={
                            <span>
                              First Name should be a combination of alphabets
                              and spaces
                            </span>
                          }
                          style={{ backgroundColor: "black" }}
                        />
                      </div>
                    </div>
                    <div className="input-group auth-pass-inputgroup">
                      <Input
                        type="text"
                        name="addFirstName"
                        id="addFirstName"
                        placeholder="Enter first name"
                        value={validation.values.addFirstName}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.errors.addFirstName &&
                            validation.touched.addFirstName
                            ? true
                            : false
                        }
                      />
                      {validation.errors.addFirstName &&
                        validation.touched.addFirstName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addFirstName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={userNameType === "CUSTOM" ? 3 : 6}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      Username Type
                    </label>
                    <select
                      className="form-select"
                      value={userNameType}
                      onChange={(e) => setuserNameType(e.target.value)}
                    >
                      {/* <option value={"CUSTOM"}>Custom</option>
                      <option value={"EMAIL_ADDRESS"}>Email Address</option>
                      <option value={"IMEI_NUMBER"}>IMEI Number</option>
                      <option value={"MOBILE_NUMBER"}>Mobile Number</option> */}
                      {availableOptions.includes('CUSTOM') && <option value="CUSTOM">Custom</option>}
                      {availableOptions.includes('EMAIL_ADDRESS') && <option value="EMAIL_ADDRESS">Email Address</option>}
                      {availableOptions.includes('IMEI_NUMBER') && <option value="IMEI_NUMBER">IMEI Number</option>}
                      {availableOptions.includes('MOBILE_NUMBER') && <option value="MOBILE_NUMBER">Mobile Number</option>}
                    </select>
                  </Col>
                  {userNameType === "CUSTOM" ? (
                    <Col xs={3}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "sans-serif",
                        }}
                      >
                        Custom Username Format
                      </label>
                      <select
                        className="form-select"
                        value={customValidation}
                        onChange={(e) => setCustomValidation(e.target.value)}
                      >
                        <option value={"ALPHA_NUMERIC"}>Alphanumeric</option>
                        <option value={"NUMERIC"}>Numeric</option>
                      </select>
                    </Col>
                  ) : null}
                  {userNameType !== "IMEI_NUMBER" && (
                    <Col xs={6}>
                      <div style={{ display: "flex" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "sans-serif",
                          }}
                        >
                          Two-factor authentication
                        </label>
                        <div>
                          <AiOutlineExclamationCircle
                            style={{ marginTop: "-3px", marginLeft: "5px" }}
                            data-tooltip-id="2faTooltipContent"
                          />
                          <ReactTooltip
                            id="2faTooltipContent"
                            place="top"
                            content={
                              <span>
                                Enable or Disable (Two-factor authentication)
                              </span>
                            }
                            style={{ backgroundColor: "black", zIndex: 100 }}
                          />
                        </div>
                      </div>
                      <select
                        className="form-select"
                        value={twoFA}
                        onChange={(e) => setTwoFA(e.target.value)}
                      >
                        <option value={"YES"}>Yes</option>
                        <option value={"NO"}>No</option>
                      </select>

                    </Col>
                  )}
                </Row>
                <Row className="mt-0 pt-0">
                  <Col xs={6}>
                    {(userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? (
                      <p style={{ color: "black", marginTop: "4px", marginBottom: "0px" }}>
                        <strong>Note: </strong>Custom username should be a combination of alphabets and numbers.
                      </p>
                    ) : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? (
                      <p style={{ color: "black", marginTop: "4px", marginBottom: "0px" }}>
                        <strong>Note: </strong>Custom username should contain only numbers.
                      </p>
                    ) : userNameType === "IMEI_NUMBER" ? (
                      <p style={{ color: "black", marginTop: "4px", marginBottom: "0px" }}>
                        <strong>Note: </strong> IMEI Number must have min-11 and max-20 characters.
                      </p>
                    ) : userNameType === "EMAIL_ADDRESS" ? (
                      <p style={{ color: "black", marginTop: "4px", marginBottom: "0px" }}>
                        <strong>Note: </strong>Email should be like (e.g., example@example.com) with min-11 and max-50 characters.
                      </p>
                    ) : userNameType === "MOBILE_NUMBER" ? (
                      <p style={{ color: "black", marginTop: "4px", marginBottom: "0px" }}>
                        <strong>Note: </strong>Mobile number must have 10 digits.
                      </p>
                    ) : null}
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col xs={6}>
                    <div style={{ display: "flex" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "sans-serif",
                        }}
                      >
                        Vehicle Type
                      </label>
                    </div>
                    <select
                      className="form-select"
                      value={vehicleType}
                      required
                      onChange={(e) => setVehicleType(e.target.value)}
                    >
                      {/* <option value={""} disabled> Select Vehicle Type </option> */}
                      <option value={"TWO_WHEELER"}>2 Wheeler</option>
                      <option value={"FOUR_WHEELER"}>4 Wheeler</option>
                      <option value={"OTHERS"}>Others</option>
                    </select>
                  </Col>
                  {userNameType === "CUSTOM" && (
                    <Col xs={6}>
                      <div style={{ display: "flex" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "sans-serif",
                          }}
                        >
                          Does user want to change default password at very first login?
                        </label>
                        <div>
                          <AiOutlineExclamationCircle
                            style={{ marginTop: "-3px", marginLeft: "5px" }}
                            data-tooltip-id="defaultpasswordTooltipContent"
                          />
                          <ReactTooltip
                            id="defaultpasswordTooltipContent"
                            place="top"
                            content={
                              <span>
                                If NO system sets a default password, if YES set a custom password
                              </span>
                            }
                            style={{ backgroundColor: "black" }}
                          />
                        </div>
                      </div>
                      <select
                        className="form-select"
                        value={dpass}
                        onChange={(e) => setDpass(e.target.value)}
                      >
                        <option value={"YES"}>Yes</option>
                        <option value={"NO"}>No</option>
                      </select>
                    </Col>
                  )}
                </Row>

                <Row className="my-3">
                  <Col xs={6}>
                    <div style={{ display: "flex" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "sans-serif",
                        }}
                      >
                        {userNameType === "MOBILE_NUMBER" ? "Mobile Number" : userNameType === "EMAIL_ADDRESS" ? "Email Address" :
                          userNameType === "IMEI_NUMBER" ? "IMEI Number" : "Custom Username"}
                      </label>
                      {/* <div>
                        <AiOutlineExclamationCircle
                          style={{ marginTop: "-3px", marginLeft: "5px" }}
                          data-tooltip-id="usernameTooltipContent"
                        />
                        <ReactTooltip
                          id="usernameTooltipContent"
                          place="top"
                          content={<span>Username is case insensitive</span>}
                          style={{ backgroundColor: "black" }}
                        />
                      </div> */}
                    </div>
                    <div className="input-group auth-pass-inputgroup">
                      <Input
                        type={userNameType === "MOBILE_NUMBER" ? "tel" : "text"}
                        id="addUsername"
                        name="addUsername"
                        autoFocus
                        onChange={(e) => {
                          const { value } = e.target;
                          if (userNameType === "MOBILE_NUMBER" && !/^\d*$/.test(value)) { return; }
                          validation.handleChange(e); setIsSubmitting((prev) => ({ ...prev, mobile: false }));
                        }}

                        onBlur={validation.handleBlur}
                        value={validation.values.addUsername}
                        placeholder={userNameType === "MOBILE_NUMBER" ? "Enter mobile number" : userNameType === "EMAIL_ADDRESS" ? "Enter email address" :
                          userNameType === "IMEI_NUMBER" ? "Enter IMEI number" : "Enter custom username"}
                        invalid={isSubmitting.mobile && validation.errors.addUsername ? true : false}
                      />
                      {validation.values.addUsername !== "" && (
                        <button
                          onClick={() => {
                            validation.setFieldValue("addUsername", "");
                            setIsSubmitting((prev) => ({
                              ...prev,
                              mobile: false,
                            }));
                          }}
                          className="btn btn-light shadow-none ms-0"
                          type="button"
                          id="password-addon"
                        >
                          <i className="mdi mdi-close"></i>
                        </button>
                      )}

                      {isSubmitting.mobile && validation.errors.addUsername ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addUsername}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="my-3">
                  {userNameType === "CUSTOM" && (
                    <Col xs={6}>
                      <div style={{ display: "flex" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "sans-serif",
                          }}
                        >
                          Password
                        </label>
                        <div>
                          <AiOutlineExclamationCircle
                            style={{ marginTop: "-3px", marginLeft: "5px" }}
                            data-tooltip-id="passwordTooltipContent"
                          />
                          <ReactTooltip
                            id="passwordTooltipContent"
                            place="top"
                            content={
                              <span>
                                Password must have a minimum of 8 characters.
                                <br />
                                Include at least 1 lowercase, 1 uppercase,
                                <br />1 numeric & 1 special character!
                              </span>
                            }
                            style={{ backgroundColor: "black" }}
                          />
                        </div>
                      </div>
                      <div className="input-group auth-pass-inputgroup d-flex">
                        <Input
                          name="addUserPassword"
                          value={validation.values.addUserPassword}
                          style={{
                            WebkitTextSecurity: passwordShow ? "none" : "disc",
                          }}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            validation.handleChange(e);
                            setIsSubmitting((prev) => ({
                              ...prev,
                              password: false,
                            }));
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            isSubmitting.password &&
                              validation.errors.addUserPassword
                              ? true
                              : false
                          }
                          disabled={userNameType === "IMEI_NUMBER" ? true : false}
                        />

                        {passwordShow ? (
                          <button
                            onClick={() => setPasswordShow((prev) => !prev)}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye"></i>
                          </button>
                        ) : (
                          <button
                            onClick={() => setPasswordShow((prev) => !prev)}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-off"></i>
                          </button>
                        )}
                        {validation.values.addUserPassword !== "" && (
                          <button
                            onClick={() => {
                              validation.setFieldValue("addUserPassword", "");
                              setIsSubmitting((prev) => ({
                                ...prev,
                                password: false,
                              }));
                            }}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        )}
                        {isSubmitting.password &&
                          validation.errors.addUserPassword && (
                            <FormFeedback type="invalid">
                              {validation.errors.addUserPassword}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                  )}
                  {defCountry && (
                    <Col xs={6}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "sans-serif",
                        }}
                      >
                        Country
                      </label>
                      <select
                        required
                        className="form-select"
                        value={countryId}
                        onChange={(e) => handleCountrySelection(e.target.value)}
                      >
                        <option value={" "} disabled>
                          Select Country
                        </option>
                        {countryList.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  )}
                </Row>
              </ModalBody>
              <Modal
                isOpen={confirmationModalOpen}
                // onRequestClose={closeConfirmationModal}
                toggle={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                style={{
                  content: { height: "190px", width: "340px", margin: "auto", borderRadius: "10px", padding: "0px" },
                  overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
                }}
              >
                <ModalHeader style={{ height: "40px", backgroundColor: "#5156BE", width: "100%", display: "felx", justifyContent: "center" }} >
                  <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>Confirm Action </span>
                </ModalHeader>
                <ConformationPopup
                  Data={popupData}
                  formSubmit={handleConfirmClose}
                  cancelSubmit={closeConfirmationModal}
                />
              </Modal>

              <ModalFooter
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#5156be1a",
                  padding: "20px",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary btn-clr text-white"
                  onClick={submit}
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => resetFormFields()}
                >
                  Clear
                </button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default MyUsersNewTable;

import PropTypes from "prop-types";
import React, {  useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import UserSessionController from "../../controller/UserSession/UserSessionController.js";
import {
  SC_USERNAME,
  SC_USER_EMAIL,
  SC_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_LAST_NAME,
  SC_USER_ID,
  SC_FCM_NOTIFICATION_COUNT,
  SC_FCM_VEHICLECRASHES_COUNT,
  SC_LOGIN_USER_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_MODULE_ID,
  SC_LOGIN_DIVISION_ID,
  SC_LOGIN_MODULE_ID,
  SC_USER_SESSION_ID,
  SC_EXPIRATION_TIME,
  SC_USER_LOGIN_TYPE,
  SC_FCM_NOTIFICATION_TOKEN,
  SC_LANGUAGES,
  SC_GROUP_ID,
  SC_COMPANY_NAME,
  SC_LOGIN_GROUP_ID,
  SC_DASHBOARD_TYPE,
  SC_LOCK_STATUS,
  SC_PASSWORD,
  SC_TOTAL_RIDE_COUNT,
  SC_SECRET_KEY,
  SC_DIVISION_NAME,
  SC_REGISTRATION_DATE,
  SC_LANGUAGE_ID, 
  SC_TIME_ZONE_ID,
  SC_COUNTRY_ID,
  SC_TIMEZONE_ABBREVIATION,
  SC_TIMEZONE_DISPLAY_NAME,
  SC_TIMEZONE_GMT_OFFSET,
  SC_TIMEZONE_ZONE_NAME,
  SC_TIMEZONE_DST_OFFSET,
  DEVICE_TYPE,
  VEHICLE_TYPE,
} from "../../constant/StorageConstant.js";
import Loader from "../../components/Common/loader";
import MotivaiLogo from "../../assets/images/logo/motivAiLogo.svg";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { GlobalConst } from "../../constant/GlobalConstant.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import Converter from "../../components/Common/EncodeDecode";
import "../../assets/css/general.css";
import userSessionController from "../../controller/UserSession/UserSessionController.js";
import ValueFormat from "../../util/ValueFormat.js";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../../assets/css/general.css"

const Login = (props) => {
  const [showLoader, setShowLoader] = useState(0);
  const [divisionIds, setDivisionIds] = useState("");
  const [companyIds, setCompanyIds] = useState(
    localStorage.getItem(SC_COMPANY_ID)
  );
  const [moduleIds, setModuleIds] = useState("");
  const [userIds, setUserIds] = useState("");
  const [groupIds, setGroupIds] = useState("");
  const [answer, setAnswer] = useState("");
  const [randomQuestion, setRandomQuestion] = useState(null);
  const [randomIndex, setRandomIndex] = useState(null);
  const [categorys, setCategorys] = useState("");
  const [groupCodes, setGroupCodes] = useState("");
  const history = useNavigate();
  const [xMobileNumber, setXMobileNumber] = useState("");
  const [xEmailAddress, setXEmailAddress] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShow1, setPasswordShow1] = useState(false);
  const [passwordShow2, setPasswordShow2] = useState(false);
  const [passwordShow3, setPasswordShow3] = useState(false);
  const [passwordShow4, setPasswordShow4] = useState(false);
  const [passwordShow5, setPasswordShow5] = useState(false);
  const [passwordShow6, setPasswordShow6] = useState(false);
  const [userPrimaryType, setUserPrimaryType] = useState("");
  const [primaryMobileNumberStatus, setPrimaryMobileNumberStatus] = useState("");
  const [primaryEmailAddressStatus, setprimaryEmailAddressStatus] = useState("");
  const [primarySequrityQuestionStatus, setPrimarySequrityQuestionStatus] = useState("");
  const [usernameVerifiedStatus, setUsernameVerifiedStatus] = useState("");
  const [userType, setUserType] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [newSendOTPButton, setNewSendOTPButton] = useState(false);
  const [newVerifyOTPButton, setNewVerifyOTPButton] = useState(false);
  const [newSaveButton, setNewSaveButton] = useState(false);
  const [newEmailOtpField, setNewEmailOtpField] = useState(false);
  const [newMobileOtpField, setNewMobileOtpField] = useState(false);
  const [setPwd, setSetPwd] = useState(false);
  const [showPassField, setShowPassField] = useState(false);
  const [mobileOtpExistingShow, setMobileOtpExistingShow] = useState(false);
  const [emailOtpExistingShow, setEmailOtpExistingShow] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showSendOtpButtonForActive, setShowSendOtpButtonForActive] = useState(false);
  const [defaultPwd, setDefaultPwd] = useState("");
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [customOtp, setCustomOtp] = useState(false);
  const [newCustomQuestions, setNewCustomQuestions] = useState(false);
  const [newCustomSubmitButton, setNewCustomSubmitButton] = useState(false);
  const [newCustomSaveButton, setNewCustomSaveButton] = useState(false);
  const [existingCustomOtpSubmitButton, setExistingCustomOtpSubmitButton] = useState(false);
  const [existingCustomSubmitButton, setExistingCustomSubmitButton] = useState(false);
  const [existingCustomPassword, setExistingCustomPassword] = useState(false);
  const [existingCustomPasswordShow, setExistingCustomPasswordShow] = useState(false);
  const [existingCustomQA, setExistingCustomQA] = useState(false);
  const [passwordShow7, setPasswordShow7] = useState(false);
  const [passwordShow8, setPasswordShow8] = useState(false);
  const [changePasswordForce, setChangePasswordForce] = useState("");
  const [password, setPassword] = useState("");
  const [forcepasswordModal, setForcepasswordModal] = useState(false);
  const [agreeModal, setAgreeModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);  
  const [autoFocus, setAutoFocus] = useState(false);
  const passwordInputRef = useRef(null);

  // username validation and api call to find the type of user by its id
  const userNameValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(8, "Username is required")
        .max(70, "Maximum 70 charecters only")
        .required("Username is required"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (data.username.length > 0 && data.username != "") {
          var userName = data.username;
          var loginResult = await UserSessionController.UserLoginButtonApi(
            userName
          );
          setShowLoader((current) => current - 1);
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          console.log("login result:" + JSON.stringify(resultJSONData));
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            let mfaPrimaryType = "";
            let mfaMobileNumberStatus = ";";
            let mfaEmailAddressStatus = "";
            let mfaSecurityQuestionStatus = "";
            let usernameType = "";

            let id = "";

            let userId = "";
            let type = "";
            let category = "";
            let usernameVerified = "";
            let status = "";
            let divId = "";
            let modId = "";
            let grpId = "";
            let defaultPassword = "";
            let nexActivity = "";
            let lId = '';
            let tzId = '';
            let cId = '';
            let password = '';

            try {
              divId = resultJSONData.user.divisionId;
              modId = resultJSONData.user.moduleId;
              grpId = resultJSONData.user.groupId;
              tzId = resultJSONData.user.timeZoneId;
              lId = resultJSONData.user.languageId;
              cId = resultJSONData.user.countryId;
              password = resultJSONData.user.password;
              mfaPrimaryType = resultJSONData.userSecret.mfaPrimaryType;
              mfaMobileNumberStatus =
                resultJSONData.userSecret.mfaMobileNumberStatus;
              mfaEmailAddressStatus =
                resultJSONData.userSecret.mfaEmailAddressStatus;
              mfaSecurityQuestionStatus =
                resultJSONData.userSecret.mfaSecurityQuestionStatus;

              id = resultJSONData.userSession.id;

              userId = resultJSONData.user.id;
              type = resultJSONData.user.type;
              usernameType = resultJSONData.user.usernameType;
              category = resultJSONData.user.category;
              usernameVerified = resultJSONData.user.usernameVerified;
              status = resultJSONData.user.status;
              defaultPassword = resultJSONData.user.defaultPassword;
              nexActivity = resultJSONData.user.forceChangePassword;
            } catch (err) {
              // console.log("Error : " + err);
            }
            localStorage.setItem(SC_USER_SESSION_ID, id);


            localStorage.setItem(SC_USER_ID, userId);
            localStorage.setItem(SC_LOGIN_USER_ID, userId);

            localStorage.setItem(SC_LOGIN_DIVISION_ID, divId);
            localStorage.setItem(SC_DIVISION_ID, divId);

            localStorage.setItem(SC_LOGIN_MODULE_ID, modId);
            localStorage.setItem(SC_MODULE_ID, modId);

            localStorage.setItem(SC_LOGIN_GROUP_ID, grpId);
            localStorage.setItem(SC_GROUP_ID, grpId);
            localStorage.setItem(SC_LANGUAGE_ID, lId);
            localStorage.setItem(SC_TIME_ZONE_ID, tzId);
            localStorage.setItem(SC_COUNTRY_ID, cId);
            localStorage.setItem(SC_PASSWORD, password)

            setUserIds(localStorage.getItem(SC_USER_ID));
            setUserPrimaryType(mfaPrimaryType);
            setPrimaryMobileNumberStatus(mfaMobileNumberStatus);
            setprimaryEmailAddressStatus(mfaEmailAddressStatus);
            setPrimarySequrityQuestionStatus(mfaSecurityQuestionStatus);
            setUsernameVerifiedStatus(usernameVerified);
            setDefaultPwd(defaultPassword);
            setUserType(usernameType);
            setCategorys(category);
            setModuleIds(modId);
            setGroupIds(grpId);
            setDivisionIds(divId);
            setChangePasswordForce(nexActivity);
            setPassword(password);

            if (
              (category === "USER" &&
                defaultPassword === "YES" &&
                (mfaPrimaryType === "EMAIL_ADDRESS" ||
                  mfaPrimaryType === "MOBILE_NUMBER") &&
                (mfaEmailAddressStatus === "PENDING" ||
                  mfaMobileNumberStatus === "PENDING")) ||
              (category === "USER" &&
                mfaPrimaryType === "NONE" &&
                defaultPassword === "YES" &&
                (usernameType === "MOBILE_NUMBER" ||
                  usernameType === "EMAIL_ADDRESS")) ||
              (category === "USER" &&
                defaultPassword === "YES" &&
                (mfaEmailAddressStatus === "ACTIVE" ||
                  mfaMobileNumberStatus === "ACTIVE"))
            ) {
              setShowLoginButton(false);
              setNewSendOTPButton(true);
            }
            // if (((usernameVerified === "NO" && mfaPrimaryType === "EMAIL_ADDRESS" && mfaEmailAddressStatus === "PENDING") ||
            //    (usernameVerified === "NO" && mfaPrimaryType === "NONE" && usernameType === "EMAIL_ADDRESS") ||
            //    (defaultPassword === "YES" && (mfaEmailAddressStatus === "ACTIVE" || mfaMobileNumberStatus === "ACTIVE")))) {
            //    setShowLoginButton(false);
            //    setNewSendOTPButton(true);
            //  }

            //  if ((usernameVerified === "NO" && mfaPrimaryType === "MOBILE_NUMBER" && mfaMobileNumberStatus === "PENDING") ||
            //   (usernameVerified === "NO" && mfaPrimaryType === "NONE" && usernameType === "MOBILE_NUMBER") ||
            //   (defaultPassword === "YES" && (mfaEmailAddressStatus === "ACTIVE" || mfaMobileNumberStatus === "ACTIVE"))) {
            //   setShowLoginButton(false);
            //   setNewSendOTPButton(true);
            // }
            if (
              category === "USER" &&
              defaultPassword === "NO" &&
              (mfaPrimaryType === "EMAIL_ADDRESS" ||
                mfaPrimaryType === "MOBILE_NUMBER") &&
              (mfaEmailAddressStatus === "ACTIVE" ||
                mfaMobileNumberStatus === "ACTIVE")
            ) {
              setShowLoginButton(false);
              setShowPassField(true);
              setShowSubmitButton(true);
            }

            if (
              usernameVerified === "YES" &&
              mfaPrimaryType === "SECURITY_QUESTIONS" &&
              mfaSecurityQuestionStatus === "ACTIVE"
            ) {
              setShowLoginButton(false);
              setExistingCustomOtpSubmitButton(true);
              setExistingCustomPassword(true);
            }

            if (
              (mfaPrimaryType === "SECURITY_QUESTIONS" &&
                mfaSecurityQuestionStatus === "PENDING") ||
              (defaultPassword === "YES" &&
                category === "USER" &&
                mfaPrimaryType === "NONE" &&
                usernameType === "CUSTOM")
            ) {
              setShowLoginButton(false);
              setCustomOtp(true);
              setNewCustomSubmitButton(true);
            }

            if (
              usernameVerified === "NO" &&
              (mfaPrimaryType === "SECURITY_QUESTIONS" ||
                mfaPrimaryType === "NONE") &&
              (usernameType === "CUSTOM" ||
                category === "ADMIN" ||
                category === "SUPER_ADMIN")
            ) {
              ToastService.errormsg(
                "Username not yet verified! please contact admin"
              );
              setShowLoginButton(true);
              setShowPassField(false);
              setExistingCustomPassword(false);
              setNewSendOTPButton(false);
              setNewMobileOtpField(false);
              setNewEmailOtpField(false);
              setCustomOtp(false);
            }

            if (usernameType === "IMEI_NUMBER") {
              ToastService.errormsg(
                "You do not have access"
              );
              setShowLoginButton(true);
              setShowPassField(false);
              setExistingCustomPassword(false);
              setNewSendOTPButton(false);
              setNewMobileOtpField(false);
              setNewEmailOtpField(false);
              setCustomOtp(false);
            }

            if (
              defaultPassword === "NO" &&
              category === "USER" &&
              mfaPrimaryType === "NONE" &&
              usernameType === "CUSTOM"
            ) {
              setShowLoginButton(false);
            }

            if (
              usernameVerified === "YES" &&
              mfaPrimaryType === "NONE" &&
              defaultPassword === "NO" &&
              (usernameType === "EMAIL_ADDRESS" ||
                usernameType === "MOBILE_NUMBER")
            ) {
              setShowLoginButton(false);
            }

            if (category === "ADMIN" || category === "SUPER_ADMIN") {
              setShowLoginButton(false);
            }
          } else {
            ToastService.errormsg(resultTemp.error.message);
          }
        }
      } catch (e) {
        // console.log("catch error:", e);
      }
    },
  });

  const handleNewLoginButton = () => {
    userNameValidation.handleSubmit();
  };

  // send otp api for mobile and email
  const sendOtp = async () => {
    setShowLoader((current) => current + 1);
    try {
      const send = "SEND";
      var result = await userSessionController.sendOtpMobileEmail(
        userIds,
        send
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        ToastService.successmsg("OTP sent successfully!");
        if (
          primaryMobileNumberStatus === "PENDING" ||
          (defaultPwd === "YES" && primaryMobileNumberStatus === "ACTIVE")
        ) {
          setNewSendOTPButton(false);
          setNewMobileOtpField(true);
          setNewVerifyOTPButton(true);
        } else if (
          primaryEmailAddressStatus === "PENDING" ||
          (defaultPwd === "YES" && primaryEmailAddressStatus === "ACTIVE")
        ) {
          setNewSendOTPButton(false);
          setNewEmailOtpField(true);
          setNewVerifyOTPButton(true);
        } else if (primaryEmailAddressStatus === "ACTIVE") {
          setShowSubmitButton(false);
          setEmailOtpExistingShow(true);
          setShowVerifyButton(true);
          setShowSendOtpButtonForActive(false);
        } else if (primaryMobileNumberStatus === "ACTIVE") {
          setShowSubmitButton(false);
          setMobileOtpExistingShow(true);
          setShowVerifyButton(true);
          setShowSendOtpButtonForActive(false);
        }
      } else {
        ToastService.errormsg(resultJSON.error);
      }
    } catch (e) {
      // console.log("Catch error: ",e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };
  // resend otp api for mobile and email
  const reSendOtp = async () => {
    setShowLoader((current) => current + 1);
    try {
      const resend = "RE_SEND";
      var result = await userSessionController.sendOtpMobileEmail(
        userIds,
        resend
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        ToastService.successmsg("OTP resent Successfully!");
        if (
          primaryMobileNumberStatus === "PENDING" ||
          (defaultPwd === "YES" && primaryMobileNumberStatus === "ACTIVE")
        ) {
          setNewSendOTPButton(false);
          setNewMobileOtpField(true);
          setNewVerifyOTPButton(true);
        } else if (
          primaryEmailAddressStatus === "PENDING" ||
          (defaultPwd === "YES" && primaryEmailAddressStatus === "ACTIVE")
        ) {
          setNewSendOTPButton(false);
          setNewEmailOtpField(true);
          setNewVerifyOTPButton(true);
        } else if (primaryEmailAddressStatus === "ACTIVE") {
          setShowSubmitButton(false);
          setEmailOtpExistingShow(true);
          setShowVerifyButton(true);
          setShowSendOtpButtonForActive(false);
        } else if (primaryMobileNumberStatus === "ACTIVE") {
          setShowSubmitButton(false);
          setMobileOtpExistingShow(true);
          setShowVerifyButton(true);
          setShowSendOtpButtonForActive(false);
        }
      } else {
        ToastService.errormsg(resultJSON.error);
      }
    } catch (e) {
      // console.log("Catch error: ",e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  const handleNewSendOTPButton = () => {
    sendOtp();
  };

  // Active mobile and email otp
  const [existingMobileemailOtp, setExistingMobileemailOtp] = useState("");
  const [newOtpEmailMobile, setNewOtpEmailMobile] = useState("");

  const handleMobileEmailOtpChange = (event) => {
    setExistingMobileemailOtp(event.target.value);
  };

  const handleNewMobileEmailOtpChange = (event) => {
    setNewOtpEmailMobile(event.target.value);
  };

  // verify mobile and email otp for new(Existing) users
  const verifyOtpMobileEmail = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.VerifyOTP(
        userIds,
        existingMobileemailOtp
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        ToastService.successmsg("OTP verified successfully!");
        if (
          primaryMobileNumberStatus === "ACTIVE" &&
          changePasswordForce === "NO"
        ) {
          getUserResponse();
        } else if (
          primaryEmailAddressStatus === "ACTIVE" &&
          changePasswordForce === "NO"
        ) {
          getUserResponse();
        } else if (changePasswordForce === "YES") {
          setForcepasswordModal(true);
        }
      } else {
        if (resultJSON.error.message) {
          ToastService.errormsg(resultJSON.error.message);
        } else if (
          existingMobileemailOtp.length < 4 ||
          isNaN(existingMobileemailOtp)
        ) {
          ToastService.errormsg("Invalid OTP!");
        }
      }
    } catch (e) {
      // console.log("Catch error in verifying otp",e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  const [showresendVerifyOtp, setshowResendVerifyOtp] = useState(true);
  
  // verify mobile and email otp for new(PENDING) users
  const verifyNewOtpMobileEmail = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.VerifyOTP(
        userIds,
        newOtpEmailMobile
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        setSetPwd(true);
        setNewVerifyOTPButton(false);
        setNewSaveButton(true);
        setshowResendVerifyOtp(false);
      } else {
        if (resultJSON.error.message) {
          ToastService.errormsg(resultJSON.error.message);
        } else if (newOtpEmailMobile.length < 4 || isNaN(newOtpEmailMobile)) {
          ToastService.errormsg("Invalid OTP!");
        }
      }
    } catch (e) {
      console.log("catch error: ", e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  const handleNewVerifyOTPButton = () => {
    verifyNewOtpMobileEmail();
  };

  // old password for email and mobile which is provided by admin and changing to new password which is setted by user
  const changePWDpatch = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.newUserLoginChangePasswordPatch(
        userIds,
        Converter.convertMd5(newPasswordValidation.values.confirmNewPassword),
        newPasswordValidation.values.customPassword,
        divisionIds,
        moduleIds
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        getUserResponse();
        ToastService.successmsg("Registered successfully");
      } else {
        ToastService.errormsg(resultJSON.error.message);
      }
    } catch (e) {
      console.log("Catch Error: ", e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  // old password for security questions which is provided by admin and changing to new password which is setted by user
  const changePWDpatchSQ = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.newUserLoginChangePasswordPatch(
        userIds,
        Converter.convertMd5(
          newPasswordValidationSecurityQuestions.values.confirmNewPassword
        ),
        Converter.convertMd5(
          ExistingCustomPasswordValidation.values.existingCustomPassword
        ),
        divisionIds,
        moduleIds
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        setNewCustomSubmitButton(false);
        setNewCustomQuestions(true);
        setNewCustomSaveButton(true);
        if (userPrimaryType === "SECURITY_QUESTIONS") {
          toggle();
        } else {
          getUserResponse();
        }

        console.log("Password changed successfully!");
      } else {
        ToastService.errormsg(resultJSON.error.message);
      }
    } catch (e) {
      console.log("Catch Error: ", e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  // mobile email new password setup
  const newPasswordValidation = useFormik({
    initialValues: {
      customPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      // customPassword: Yup.string()
      //   .matches(
      //     /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
      //     "Invalid Password characters"
      //   )
      //   .required("Please enter your Current password"),
      newPassword: Yup.string()
        .min(8, "Password should have minimum 8 characters")
        .max(20, "Password should have maximum 20 characters")
        .matches( /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{7,100}$/, "Invalid password characters" )
        .required("Please enter your new password"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "New password and confirm password does not match")
        .required("Please enter confirm new password"),
    }),
    onSubmit: () => {
      changePWDpatch();
    },
  });

  const onSubmitHandler = () => {
    newPasswordValidation.handleSubmit();
  };

  // sequrity questions new password setup
  const newPasswordValidationSecurityQuestions = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password should have minimum 8 characters")
        .max(20, "Password should have maximum 20 characters")
        .matches( /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{7,30}$/, "Invalid password characters" )
        .required("Please enter your new password"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "New password and confirm password does not match")
        .required("Please enter confirm new password"),
    }),
    onSubmit: () => {
      changePWDpatchSQ();
    },
  });

  const onSubmitHandlerSequrityQuestions = () => {
    newPasswordValidationSecurityQuestions.handleSubmit();
  };

  // mobile email existing password check
  const ExistingMobEmailPasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      existingMobEmailPassword: "",
    },
    validationSchema: Yup.object({
      existingMobEmailPassword: Yup.string()
        // .min(8, "Password should have minimum 8 characters")
        // .max(20, "Password should have maximum 20 characters")
        // .matches( /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{7,30}$/, "Invalid password characters" )
        .required("Please enter your password"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (
          data.existingMobEmailPassword.length > 0 &&
          data.existingMobEmailPassword != ""
        ) {
          var encryptPassword = Converter.convertMd5(
            data.existingMobEmailPassword
          );
          var loginResult =
            await UserSessionController.existingPasswordCheckPatch(
              userIds,
              encryptPassword,
              divisionIds
            );
          setShowLoader((current) => current - 1);
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            const encodePassword =  Converter.encodeBase64(data.existingMobEmailPassword, "password")
            localStorage.setItem(SC_PASSWORD, encodePassword);
            if (changePasswordForce === "NO" && userPrimaryType === "NONE") {
              getUserResponse();
            } else if (
              changePasswordForce === "YES" &&
              userPrimaryType === "NONE" &&
              categorys === "USER"
            ) {
              setForcepasswordModal(true);
            } else if (
              categorys === "USER" &&
              (userPrimaryType === "EMAIL_ADDRESS" ||
                userPrimaryType === "MOBILE_NUMBER") &&
              (primaryEmailAddressStatus === "ACTIVE" ||
                primaryMobileNumberStatus === "ACTIVE")
            ) {
              setShowSubmitButton(false);
              setShowSendOtpButtonForActive(true);
            } else {
              setNewSaveButton(true);
            }
          } else {
            ToastService.errormsg(resultTemp.error.message);
          }
        }
      } catch (e) {
        console.log("catch error:", e);
      }
    },
  });

  const handleSubmitButtonClick = () => {
    ExistingMobEmailPasswordValidation.handleSubmit();
  };

  const [setCustomNewPassword, setSetCustomNewPassword] = useState(false);

  // sequrity questions existing password check
  const ExistingCustomPasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      existingCustomPassword: "",
    },
    validationSchema: Yup.object({
      existingCustomPassword: Yup.string()
      // .min(8, "Password should have minimum 8 characters")
      // .max(20, "Password should have maximum 20 characters")
      // .matches( /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{7,30}$/, "Invalid password characters" )
      .required("Please enter your current password"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (
          data.existingCustomPassword.length > 0 &&
          data.existingCustomPassword != ""
        ) {
          var encryptPassword = Converter.convertMd5(
            data.existingCustomPassword
          );
          var loginResult =
            await UserSessionController.existingPasswordCheckPatch(
              userIds,
              encryptPassword,
              divisionIds
            );
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            
            const encodePassword =  Converter.encodeBase64(data.existingCustomPassword, "password")
            localStorage.setItem(SC_PASSWORD, encodePassword);

            if (primarySequrityQuestionStatus === "PENDING") {
              if (defaultPwd === "NO") {
                setNewCustomQuestions(true);
                toggle();
              } else {
                setNewCustomSubmitButton(false);
                setSetCustomNewPassword(true);
              }
            } else if (primarySequrityQuestionStatus === "ACTIVE") {
              setExistingCustomOtpSubmitButton(false);
              setExistingCustomSubmitButton(true);
              setExistingCustomQA(true);
              setNewCustomQuestions(true);
            }
          } else {
            ToastService.errormsg(resultTemp.error.message);
          }
        }
      } catch (e) {
        // console.log("catch error:", e);
      } finally {
        setShowLoader((current) => current - 1);
      }
    },
  });

  const handleExistingCustomOtpSubmitButton = () => {
    ExistingCustomPasswordValidation.handleSubmit();
  };

  // Sequrity Questions get change verify Flow
  const [getSecurityQuestion, setGetSecurityQuestion] = useState([]);
  const handleSecurityAnswerChange = (index, answer) => {
    const updatedAnswers = [...getSecurityQuestion];
    updatedAnswers[index].answer = answer.trimStart();
    setGetSecurityQuestion(updatedAnswers);
  };

  useEffect(() => {
    const getQuesAns = async () => {
      try {
        var result = await userSessionController.questionAnsPopup(divisionIds);
        var resultJSON = JSON.parse(result);
        var status = resultJSON.status;
        if (status === "SUCCESS") {
          const resultJSONData = resultJSON.data;
          var questionsArray = [];
          let securityQQCA = [];
          resultJSONData.forEach((value) => {
            var questions = ValueFormat.parseAsArray(value.securityQuestions);
            questions.forEach((data) => {
              var question = ValueFormat.parseAsString(data.question);
              questionsArray.push(question);
              securityQQCA.push({
                questionCode: data.questionCode,
                question: data.question,
                answer: data.answer,
              });
            });
          });
          const randomIndex = Math.floor(Math.random() * questionsArray.length);
          const randomQuestion = questionsArray[randomIndex];
          setRandomQuestion(randomQuestion);
          setRandomIndex(randomIndex + 1);
          setGetSecurityQuestion(securityQQCA);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getQuesAns();
  }, [divisionIds]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const changeAnswer = async () => {
    try {
      var result = await userSessionController.SaveAnswerForSQpatch(
        userIds,
        getSecurityQuestion,
        moduleIds,
        divisionIds
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        getUserResponse();
        ToastService.successmsg("Registered successfully");
      } else if (status === "FAILED") {
        // const errorMessages = resultJSON.error.map((error) => error.message);
        // const formattedErrorMessage = errorMessages.join(", ");
        // ToastService.errormsg(formattedErrorMessage);
        ToastService.errormsg("Please answer for all questions");
      }
    } catch (e) {
      console.log("Catch error: " + e);
    }
  };

  const getQuestionsSecrectKey = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.QndAVerifyOTP1(
        userIds,
        randomIndex,
        answer
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        if (changePasswordForce === "YES") {
          setForcepasswordModal(true);
        } else {
          getUserResponse();
        }
      } else {
        ToastService.errormsg(resultJSON.error.message);
      }
    } catch (e) {
      // console.log("catch error for verifying Q&A: ",e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (!answer) {
      ToastService.errormsg("Please enter security answer");
      return;
    }
    getQuestionsSecrectKey();
  };

  const getUserResponse = async () => {
    setShowLoader((current) => current + 1);
    var result = await userSessionController.getResponseToMoveDashboard(
      userIds,
      divisionIds
    );
    try {
      var resultTemp = JSON.parse(result);
      var resultStatus = resultTemp.status;
      var resultJSONData = resultTemp.data;
      console.log(resultJSONData, "SECRET___: " )
      if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
        var userSecret = resultJSONData.userSecret;
        var secretKeyEncoded = userSecret.message;
        var decodedsecertKey = atob(secretKeyEncoded);
        localStorage.setItem(SC_SECRET_KEY, decodedsecertKey);
        var getLocalStorage = localStorage.getItem(SC_SECRET_KEY);
        var category = resultJSONData.user.category;
        var groupCode = resultJSONData.group.code;
        var userFirstName = "",
          userLastName = "",
          loginUserFirstName = "",
          fcmCount = "0",
          vehicleCrash = "0";
        var companyId = "",
          companyName = resultJSONData.division.name,
          languages = "English",
          dashboardType = "",
          rideCount = "",
          email = "",
          name = "",
          deviceType = "",
          vehicleType = "";
        var timeZoneAbbreviation = "",
            timeZoneGmtOffset = "",
            timeZoneDisplayName = "",
            timeZoneZoneName= "",
            timeZonedstOffset ="";

        userFirstName = resultJSONData.user.firstName;
        userLastName = resultJSONData.user.lastName;
        companyId = resultJSONData.user.companyId;
        email = resultJSONData.user.email;
        deviceType = resultJSONData.user.deviceType;
        vehicleType = resultJSONData.user.vehicleType;
        name = resultJSONData.group.name;
        timeZoneAbbreviation = resultJSONData.timeZone.abbreviation;
        timeZoneDisplayName = resultJSONData.timeZone.displayName;
        timeZoneGmtOffset = resultJSONData.timeZone.gmtOffset;
        timeZoneZoneName = resultJSONData.timeZone.zoneName;
        timeZonedstOffset = resultJSONData.timeZone.gmtOffsetDst;

        var currentDateTime = new Date();
        currentDateTime.setHours(currentDateTime.getHours() + 24);
        localStorage.setItem(SC_EXPIRATION_TIME, currentDateTime.getTime());
        localStorage.setItem(SC_USERNAME, userNameValidation.values.username);
        localStorage.setItem(SC_NAME, name);
        localStorage.setItem(SC_USER_EMAIL, email);
        localStorage.setItem(SC_USER_FIRST_NAME, userFirstName);
        localStorage.setItem(SC_USER_LAST_NAME, userLastName);
        localStorage.setItem(SC_FCM_NOTIFICATION_COUNT, fcmCount);
        localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT, vehicleCrash);
        localStorage.setItem(SC_LOGIN_USER_FIRST_NAME, loginUserFirstName);
        localStorage.setItem(SC_COMPANY_ID, companyId);
        localStorage.setItem(SC_COMPANY_NAME, companyName);
        localStorage.setItem(SC_LANGUAGES, languages);
        localStorage.setItem(SC_DASHBOARD_TYPE, dashboardType);
        localStorage.setItem(SC_TOTAL_RIDE_COUNT, rideCount);
        localStorage.setItem(SC_TIMEZONE_ABBREVIATION, timeZoneAbbreviation);
        localStorage.setItem(SC_TIMEZONE_DISPLAY_NAME, timeZoneDisplayName);
        localStorage.setItem(SC_TIMEZONE_GMT_OFFSET, timeZoneGmtOffset);
        localStorage.setItem(SC_TIMEZONE_ZONE_NAME, timeZoneZoneName);
        localStorage.setItem(SC_TIMEZONE_DST_OFFSET, timeZonedstOffset);
        localStorage.setItem(DEVICE_TYPE, deviceType);
        localStorage.setItem(VEHICLE_TYPE, vehicleType);
        try {
          if (getLocalStorage != "" && getLocalStorage.length > 0) {
            if (category == "USER") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "USER");
              history("/dashboard");
            } else if (category == "ADMIN") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "ADMIN");
              history("/adminDashboard");
            } else if (category === "DEFAULT" || category === "SUPER_ADMIN") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN");
              history("/superAdminClientDashboard");
            } else if (groupCode != "DEFAULT") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN");
              history("/superAdminClientDashboard");
            }
          }
        } catch (err) {
          // console.log("Error : " + err);
        }
      } else {
        // ToastService.errormsg(resultTemp.error);
      }
    } catch (e) {
      // console.log("catch error:", e);
    } finally {
      setShowLoader((current) => current + 1);
    }
  };

  //force change password
  const changeForcePassword = async () => {
    let oldPasswordForce = "";
    if (
      primaryMobileNumberStatus === "ACTIVE" ||
      primaryEmailAddressStatus === "ACTIVE"
    ) {
      oldPasswordForce = Converter.convertMd5(
        ExistingMobEmailPasswordValidation.values.existingMobEmailPassword
      );
    } else if (
      changePasswordForce === "YES" &&
      userPrimaryType === "NONE" &&
      categorys === "USER"
    ) {
      oldPasswordForce = Converter.convertMd5(
        ExistingMobEmailPasswordValidation.values.existingMobEmailPassword
      );
    } else {
      oldPasswordForce = Converter.convertMd5(
        ExistingCustomPasswordValidation.values.existingCustomPassword
      );
    }
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.forceChangePassword(
        userIds,
        Converter.convertMd5(
          forceChangePasswordValidation.values.forceConfirmNewPassword
        ),
        oldPasswordForce,
        divisionIds,
        moduleIds
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        ToastService.successmsg("Password changed successfully");
        // setTimeout(() => {
        //     window.location.reload();
        // }, 3000);
        getUserResponse();
      } else {
        ToastService.errormsg(resultJSON.error.message);
      }
    } catch (e) {
      console.log("Catch Error: ", e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  // force pasword for mobile email
  const forceChangePasswordValidation = useFormik({
    initialValues: {
      forceNewPassword: "",
      forceConfirmNewPassword: "",
    },
    validationSchema: Yup.object({
      forceNewPassword: Yup.string()
      .min(8, "Password should have minimum 8 characters")
      .max(20, "Password should have maximum 20 characters")
      .matches( /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{7,30}$/, "Invalid Password characters" )
      .required("Please enter your new password"),

      forceConfirmNewPassword: Yup.string()
        .oneOf([Yup.ref("forceNewPassword"), null], "New password and confirm password does not match")
        .required("Please confirm your new password"),
    }),
    onSubmit: () => {
      setAgreeModal(true);
    },
  });

  const handleForcePasswordSubmit = () => {
    forceChangePasswordValidation.handleSubmit();
  };

  const agreePopup = () => {
    changeForcePassword();   
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const initialStates = () => {
    setShowLoader(0);
    setDivisionIds("");
    setCompanyIds("");
    setModuleIds("");
    setUserIds("");
    setGroupIds("");
    setAnswer("");
    setRandomQuestion(null);
    setRandomIndex(null);
    setCategorys("");
    setXMobileNumber("");
    setXEmailAddress("");
    setPasswordShow(false);
    setPasswordShow1(false);
    setPasswordShow2(false);
    setPasswordShow3(false);
    setPasswordShow4(false);
    setPasswordShow5(false);
    setPasswordShow6(false);
    setUserPrimaryType("");
    setPrimaryMobileNumberStatus("");
    setprimaryEmailAddressStatus("");
    setPrimarySequrityQuestionStatus("");
    setUsernameVerifiedStatus("");
    setUserType("");
    setShowLoginForm(true);
    setNewSendOTPButton(false);
    setNewVerifyOTPButton(false);
    setNewSaveButton(false);
    setNewEmailOtpField(false);
    setNewMobileOtpField(false);
    setSetPwd(false);
    setShowPassField(false);
    setMobileOtpExistingShow(false);
    setEmailOtpExistingShow(false);
    setShowLoginButton(true);
    setShowSubmitButton(false);
    setShowSendOtpButtonForActive(false);
    setDefaultPwd("");
    setShowVerifyButton(false);
    setCustomOtp(false);
    setNewCustomQuestions(false);
    setNewCustomSubmitButton(false);
    setNewCustomSaveButton(false);
    setExistingCustomOtpSubmitButton(false);
    setExistingCustomSubmitButton(false);
    setExistingCustomPassword(false);
    setExistingCustomPasswordShow(false);
    setExistingCustomQA(false);
    setPasswordShow7(false);
    setPasswordShow8(false);
    setChangePasswordForce("");
    setForcepasswordModal(false);
    setExistingMobileemailOtp("");
    setNewOtpEmailMobile("");
    setSetCustomNewPassword(false);
    setGetSecurityQuestion([]);
    setModal(false);
    setAgreeModal(false);
    setIsChecked(false);
    setshowResendVerifyOtp(true);
    userNameValidation.resetForm();
    newPasswordValidation.resetForm();
    newPasswordValidationSecurityQuestions.resetForm();
    ExistingMobEmailPasswordValidation.resetForm();
    ExistingCustomPasswordValidation.resetForm();
    forceChangePasswordValidation.resetForm();
    localStorage.removeItem(SC_USER_SESSION_ID);
    localStorage.removeItem(SC_USER_ID);
    localStorage.removeItem(SC_LOGIN_USER_ID);
    localStorage.removeItem(SC_LOGIN_DIVISION_ID);
    localStorage.removeItem(SC_DIVISION_ID);
    localStorage.removeItem(SC_LOGIN_MODULE_ID);
    localStorage.removeItem(SC_MODULE_ID);
    localStorage.removeItem(SC_LOGIN_GROUP_ID);
    localStorage.removeItem(SC_GROUP_ID);
    localStorage.removeItem(SC_TIMEZONE_ABBREVIATION);
    localStorage.removeItem(SC_TIMEZONE_DISPLAY_NAME);
    localStorage.removeItem(SC_TIMEZONE_GMT_OFFSET);
    localStorage.removeItem(SC_TIMEZONE_ZONE_NAME);
    localStorage.removeItem(SC_TIMEZONE_DST_OFFSET);
    localStorage.removeItem(SC_LANGUAGE_ID);
    localStorage.removeItem(SC_TIME_ZONE_ID);
    localStorage.removeItem(SC_COUNTRY_ID);
    setShowLoader(0);
    setAutoFocus(false);
  };

  const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
    
          if (newVerifyOTPButton) {
            handleNewVerifyOTPButton();
          }  else if(showLoginButton){
            handleNewLoginButton();
          } else if(showSubmitButton){
            handleSubmitButtonClick();
          } else if(showSendOtpButtonForActive){
            sendOtp();
          } else if(newSendOTPButton){
            handleNewSendOTPButton();
          } else if((userPrimaryType === "NONE" && usernameVerifiedStatus === "YES" && userType !== "IMEI_NUMBER" && defaultPwd === "NO") || categorys === "ADMIN" || categorys === "SUPER_ADMIN") {
            handleSubmitButtonClick();
          } else if (showVerifyButton) {
            verifyOtpMobileEmail();
          } else if(newCustomSubmitButton) {
            handleExistingCustomOtpSubmitButton();
          } else if (setCustomNewPassword && !modal) {
            onSubmitHandlerSequrityQuestions();
          } else if( modal) {
            changeAnswer();
          } else if (newSaveButton) {
            onSubmitHandler();
          } else if (existingCustomOtpSubmitButton){
            handleExistingCustomOtpSubmitButton();
          }
      };
    }

  useEffect(() => {
    if (forcepasswordModal) {
      const timer = setTimeout(() => {
        setAutoFocus(true);
        passwordInputRef.current.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [forcepasswordModal]);

  document.title = "Login | MotivAI ";

  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <ToastContainer style={{ width: "auto" }} />
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex  p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2 mb-md-2 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={MotivaiLogo} alt="" height="40" />
                      </Link>

                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">
                            Log in to proceed with Motiv AI.
                        </p>
                      </div>
                      
                      <div style={{maxHeight: '52vh', overflowY: 'auto', paddingBottom: '20px'}}>
                        <Form
                          className="custom-form mt-4 pt-2"
                          onKeyDown={handleKeyDown}
                        >
                          {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                          {/* User name  input */}

                          {showLoginForm && (
                            <>
                              <h5>User Login</h5>
                              <div className="mb-3">
                                <Label className="form-label">Username</Label>
                                <Input
                                  name="username"
                                  className="form-control"
                                  placeholder="Enter username"
                                  type="tel"
                                  id="username"
                                  autoFocus
                                  disabled={
                                    // showPassField ||
                                    // existingCustomPassword ||
                                    // userPrimaryType === "NONE" ||
                                    // newSendOTPButton ||
                                    // (primaryMobileNumberStatus === "PENDING" &&
                                    //   newMobileOtpField) ||
                                    // (primaryEmailAddressStatus === "PENDING" &&
                                    //   newEmailOtpField) ||
                                    // customOtp
                                    !showLoginButton ? true : false
                                  }
                                  onChange={userNameValidation.handleChange}
                                  onBlur={userNameValidation.handleBlur}
                                  value={userNameValidation.values.username}
                                  invalid={
                                    userNameValidation.touched.username &&
                                    userNameValidation.errors.username
                                      ? true
                                      : false
                                  }
                                />
                                {userNameValidation.touched.username &&
                                userNameValidation.errors.username ? (
                                  <FormFeedback type="invalid">
                                    {userNameValidation.errors.username}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </>
                          )}

                          {/* mfaPrimaryType is MOBILE_NUMBER or EMAIL_ADDRESS and their status is ACTIVE means this screen */}
                          {categorys === "USER" && defaultPwd === "NO" && (userPrimaryType === "EMAIL_ADDRESS" || userPrimaryType === "MOBILE_NUMBER") &&
                          (primaryEmailAddressStatus === "ACTIVE" || primaryMobileNumberStatus === "ACTIVE") && (
                              <>
                                {showPassField && (
                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Password
                                        </Label>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div className="">
                                          {/*<Link
                                            to="/page-recoverpw"
                                            className="text-muted"
                                          >
                                            Forgot password?
                                          </Link>*/}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="existingMobEmailPassword"
                                        autoFocus
                                        disabled={
                                          (primaryMobileNumberStatus ===
                                            "ACTIVE" &&
                                            mobileOtpExistingShow) ||
                                          (primaryEmailAddressStatus ===
                                            "ACTIVE" &&
                                            emailOtpExistingShow) ||
                                          showSendOtpButtonForActive
                                            ? true
                                            : false
                                        }
                                        value={
                                          ExistingMobEmailPasswordValidation
                                            .values.existingMobEmailPassword
                                        }
                                        type={passwordShow ? "text" : "password"}
                                        placeholder="Enter password"
                                        onChange={
                                          ExistingMobEmailPasswordValidation.handleChange
                                        }
                                        onBlur={
                                          ExistingMobEmailPasswordValidation.handleBlur
                                        }
                                        invalid={
                                          ExistingMobEmailPasswordValidation
                                            .touched.existingMobEmailPassword &&
                                          ExistingMobEmailPasswordValidation
                                            .errors.existingMobEmailPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow(!passwordShow)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        {!passwordShow ? (
                                          <i className="mdi mdi-eye-outline"></i>
                                        ) : (
                                          <i className="mdi mdi-eye-off"></i>
                                        )}
                                      </button>
                                      {ExistingMobEmailPasswordValidation.touched
                                        .existingMobEmailPassword &&
                                      ExistingMobEmailPasswordValidation.errors
                                        .existingMobEmailPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            ExistingMobEmailPasswordValidation
                                              .errors.existingMobEmailPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                )}

                                {userPrimaryType === "MOBILE_NUMBER" &&
                                  mobileOtpExistingShow && (
                                    <div className="mb-3">
                                      <h5>User Verify</h5>
                                      <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                          <Label className="form-label">
                                            Mobile OTP
                                          </Label>
                                        </div>
                                      </div>
                                      <div className="input-group auth-pass-inputgroup">
                                        <Input
                                          name="existingMobileemailOtp"
                                          autoFocus
                                          placeholder="Enter mobile OTP"
                                          maxLength={4}
                                          value={existingMobileemailOtp}
                                          onChange={handleMobileEmailOtpChange}
                                          autoComplete="off"
                                        />
                                      </div>
                                      <div className="p-0 m-0 d-flex justify-content-end">
                                        <Button
                                          className="p-0"
                                          color="link"
                                          onClick={reSendOtp}
                                        >
                                          Resend OTP
                                        </Button>
                                      </div>
                                    </div>
                                  )}

                                {emailOtpExistingShow &&
                                  userPrimaryType === "EMAIL_ADDRESS" && (
                                    <div className="mb-3">
                                      <h5>User Verify</h5>
                                      <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                          <Label className="form-label">
                                            Email OTP
                                          </Label>
                                        </div>
                                      </div>
                                      <div className="input-group auth-pass-inputgroup">
                                        <Input
                                          name="existingMobileemailOtp"
                                          autoFocus
                                          placeholder="Enter email OTP"
                                          maxLength={4}
                                          value={existingMobileemailOtp}
                                          onChange={handleMobileEmailOtpChange}
                                          autoComplete="off"
                                        />
                                      </div>
                                      <div className="p-0 m-0 d-flex justify-content-end">
                                        <Button
                                          className="p-0"
                                          color="link"
                                          onClick={reSendOtp}
                                        >
                                          Resend OTP
                                        </Button>
                                      </div>
                                    </div>
                                  )}

                                {/*
                              <ReCAPTCHA className="g-recaptcha"
                              sitekey="6LeKOgMoAAAAAJ0M4ZGweGsFtXZsfIStnFo3QyYF"
                              name="reCaptcha"
                              onChange={(value) => {
                              if (value != "" && value != null) setVerifyRecaptcha(true);
                              else setVerifyRecaptcha(false);
                              }}
                              /> */}
                                {/* <HCaptcha
                            size="small"
                            sitekey="e66343c2-aa9b-42a6-93df-00554796f170"
                            onVerify={setToken}
                          /> */}
                                {/*
                          <GoogleReCaptchaProvider reCaptchaKey="6LeVbAgpAAAAAEyOkJfPMbHUpcblX07Mq_CVc8x1" useRecaptchaNet={true} useEnterprise={true}>
                          <GoogleReCaptcha onVerify={captchaValue1} />
                          </GoogleReCaptchaProvider> */}
                                {/* <Reaptcha sitekey="6LeVbAgpAAAAAEyOkJfPMbHUpcblX07Mq_CVc8x1" onLoad={captchaValue} onVerify={captchaValue1 } /> */}

                                {/* <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="remember-check"
                            >
                              Remember me
                            </label>
                          </div> */}

                                {showSubmitButton && (
                                  <>
                                    <div className="mt-3 d-grid">
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        onClick={handleSubmitButtonClick}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </>
                                )}

                                {showSendOtpButtonForActive && (
                                  <>
                                    <div className="mt-3 d-grid">
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        onClick={sendOtp}
                                      >
                                        Send OTP
                                      </button>
                                    </div>
                                  </>
                                )}

                                {showVerifyButton && (
                                  <div className="mt-3 d-grid">
                                    <button
                                    disabled={existingMobileemailOtp.length >= 1 ? false : true}
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={verifyOtpMobileEmail}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                )}
                              </>
                          )}

                          {/* mfaPrimaryType is MOBILE_NUMBER or EMAIL_ADDRESS and their status is PENDING means this screen */}
                          {((categorys === "USER" && defaultPwd === "YES" && (userPrimaryType === "EMAIL_ADDRESS" || userPrimaryType === "MOBILE_NUMBER") && 
                            (primaryEmailAddressStatus === "PENDING" || primaryMobileNumberStatus === "PENDING")) ||
                            (categorys === "USER" && userPrimaryType === "NONE" && defaultPwd === "YES" && (userType === "MOBILE_NUMBER" || userType === "EMAIL_ADDRESS")) ||
                            (categorys === "USER" && defaultPwd === "YES" && (primaryEmailAddressStatus === "ACTIVE" || primaryMobileNumberStatus === "ACTIVE"))) && (
                            <>
                              {newMobileOtpField &&
                                (userPrimaryType === "MOBILE_NUMBER" ||
                                  userType === "MOBILE_NUMBER") && (
                                  <div className="mb-3">
                                    <h5>User Verify</h5>
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Mobile OTP
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="newMobileOtp"
                                        autoFocus
                                        placeholder="Enter mobile OTP"
                                        maxLength={4}
                                        disabled={setPwd ? true : false}
                                        value={newOtpEmailMobile}
                                        onChange={handleNewMobileEmailOtpChange}
                                        autoComplete="off"
                                      />
                                    </div>
                                    {showresendVerifyOtp && (
                                    <div className="p-0 m-0 d-flex justify-content-end">
                                      <Button
                                        className="p-0"
                                        color="link"
                                        onClick={reSendOtp}
                                      >
                                        Resend OTP
                                      </Button>
                                    </div>
                                    )}
                                  </div>
                                )}

                              {newEmailOtpField &&
                                (userPrimaryType === "EMAIL_ADDRESS" ||
                                  userType === "EMAIL_ADDRESS") && (
                                  <div className="mb-3">
                                    <h5>User Verify</h5>
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Email OTP
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="newEmailOtp"
                                        autoFocus
                                        placeholder="Enter email OTP"
                                        maxLength={4}
                                        disabled={setPwd ? true : false}
                                        value={newOtpEmailMobile}
                                        onChange={handleNewMobileEmailOtpChange}
                                        autoComplete="off"
                                      />
                                    </div>
                                    {showresendVerifyOtp && (
                                    <div className="p-0 m-0 d-flex justify-content-end">
                                      <Button
                                        className="p-0"
                                        color="link"
                                        onClick={reSendOtp}
                                      >
                                        Resend OTP
                                      </Button>
                                    </div>
                                    )}
                                  </div>
                                )}

                              {setPwd && (
                                <>
                                  <h5>Password Setup</h5>
                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="d-flex flex-grow-1">
                                        <Label className="form-label">
                                          Set New Password
                                        </Label>
                                        <div>
                                          <AiOutlineExclamationCircle
                                            style={{
                                              marginTop: "-3px",
                                              marginLeft: "5px",
                                            }}
                                            data-tooltip-id="snpTooltipContent"
                                          />
                                          <ReactTooltip
                                            id="snpTooltipContent"
                                            place="top"
                                            content={
                                              <span>
                                                Password must have a minimum of 8
                                                characters.
                                                <br />
                                                Include at least 1 lowercase, 1
                                                uppercase,
                                                <br />1 numeric & 1 special
                                                character!
                                              </span>
                                            }
                                            style={{ backgroundColor: "black" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input 
                                        name="newPassword"
                                        autoFocus
                                        type={passwordShow4 ? "text" : "password"}
                                        placeholder="Enter new password"
                                        onChange={
                                          newPasswordValidation.handleChange
                                        }
                                        onBlur={newPasswordValidation.handleBlur}
                                        value={
                                          newPasswordValidation.values.newPassword
                                        }
                                        invalid={
                                          newPasswordValidation.touched
                                            .newPassword &&
                                          newPasswordValidation.errors.newPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow4(!passwordShow4)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        {!passwordShow4 ? (
                                          <i className="mdi mdi-eye-outline"></i>
                                        ) : (
                                          <i className="mdi mdi-eye-off"></i>
                                        )}
                                      </button>
                                      {newPasswordValidation.touched
                                        .newPassword &&
                                      newPasswordValidation.errors.newPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            newPasswordValidation.errors
                                              .newPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="d-flex flex-grow-1">
                                        <Label className="form-label">
                                          Confirm New Password
                                        </Label>
                                        <div>
                                          <AiOutlineExclamationCircle
                                            style={{
                                              marginTop: "-3px",
                                              marginLeft: "5px",
                                            }}
                                            data-tooltip-id="cnpTooltipContent"
                                          />
                                          <ReactTooltip
                                            id="cnpTooltipContent"
                                            place="top"
                                            content={
                                              <span>
                                                Password must be same as the one
                                                entered in the field above.
                                              </span>
                                            }
                                            style={{ backgroundColor: "black" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="confirmNewPassword"
                                        type={passwordShow5 ? "text" : "password"}
                                        placeholder="Re-enter new password"
                                        onChange={
                                          newPasswordValidation.handleChange
                                        }
                                        onBlur={newPasswordValidation.handleBlur}
                                        value={
                                          newPasswordValidation.values
                                            .confirmNewPassword
                                        }
                                        invalid={
                                          newPasswordValidation.touched
                                            .confirmNewPassword &&
                                          newPasswordValidation.errors
                                            .confirmNewPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow5(!passwordShow5)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        {!passwordShow5 ? (
                                          <i className="mdi mdi-eye-outline"></i>
                                        ) : (
                                          <i className="mdi mdi-eye-off"></i>
                                        )}
                                      </button>
                                      {newPasswordValidation.touched
                                        .confirmNewPassword &&
                                      newPasswordValidation.errors
                                        .confirmNewPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            newPasswordValidation.errors
                                              .confirmNewPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              )}

                              {newSendOTPButton && (
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={handleNewSendOTPButton}
                                  >
                                    Send OTP
                                  </button>
                                </div>
                              )}

                              {newVerifyOTPButton && (
                                <div className="mt-3 d-grid">
                                  <button
                                  disabled={newOtpEmailMobile.length >= 1 ? false : true}
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={handleNewVerifyOTPButton}
                                  >
                                    Verify
                                  </button>
                                </div>
                              )}

                              {newSaveButton && (
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={onSubmitHandler}
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </>
                          )}

          {/* mfaPrimaryType is SECURITY_QUESTIONS and its status is PENDING means this screen */}
          {categorys === "USER" && userPrimaryType === "SECURITY_QUESTIONS" && primarySequrityQuestionStatus === "PENDING" && userType === "CUSTOM" && (
          <>
          <div style={{ overflowY: "auto", maxHeight: "400px" }} >
            {customOtp && (
            <>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <Label className="form-label">
                      Current Password
                    </Label>
                  </div>
                </div>
                <div className="input-group auth-pass-inputgroup">
                  <Input name="existingCustomPassword" disabled={ setCustomNewPassword ? true : false }
                    type={ passwordShow6 ? "text" : "password" }
                    autoFocus
                    placeholder="Enter current password"
                    onChange={ ExistingCustomPasswordValidation.handleChange }
                    onBlur={ ExistingCustomPasswordValidation.handleBlur }
                    value={ ExistingCustomPasswordValidation.values.existingCustomPassword }
                    invalid={ ExistingCustomPasswordValidation.touched.existingCustomPassword && ExistingCustomPasswordValidation.errors.existingCustomPassword ? true : false  } />
                  <button onClick={() => setPasswordShow6(!passwordShow6) } className="btn btn-light shadow-none ms-0" type="button" id="password-addon" >
                    {!passwordShow6 ? ( <i className="mdi mdi-eye-outline"></i> ) : ( <i className="mdi mdi-eye-off"></i> )}
                  </button>
                  {ExistingCustomPasswordValidation.touched.existingCustomPassword && ExistingCustomPasswordValidation.errors.existingCustomPassword ? (
                  <FormFeedback type="invalid"> { ExistingCustomPasswordValidation.errors.existingCustomPassword } </FormFeedback> ) : null}
                </div>
              </div>
            </>
          )}

          {setCustomNewPassword && (
            <>
              <div className="mb-3">
                <h5>Password Setup</h5>
                <div className="d-flex align-items-start">
                  <div className="d-flex flex-grow-1">
                    <Label className="form-label">
                      Set New Password
                    </Label>
                    <div>
                      <AiOutlineExclamationCircle style={{ marginTop: "-3px", marginLeft: "5px" }} data-tooltip-id="snpTooltipContent" />
                      <ReactTooltip id="snpTooltipContent" place="top" content={ <span> Password must have a minimum of 8 characters. <br />
                        Include at least 1 lowercase, 1 uppercase, <br />1 numeric & 1 special character! </span> }
                        style={{ backgroundColor: "black" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    name="newPassword"
                    type={ passwordShow2 ? "text" : "password" }
                    autoFocus
                    placeholder="Enter new password"
                    onChange={ newPasswordValidationSecurityQuestions.handleChange }
                    onBlur={ newPasswordValidationSecurityQuestions.handleBlur }
                    value={ newPasswordValidationSecurityQuestions.values.newPassword }
                    invalid={ newPasswordValidationSecurityQuestions.touched.newPassword && newPasswordValidationSecurityQuestions.errors.newPassword ? true : false }
                  />
                  <button onClick={() => setPasswordShow2(!passwordShow2) } className="btn btn-light shadow-none ms-0" type="button" id="password-addon" >
                    {!passwordShow2 ? ( <i className="mdi mdi-eye-outline"></i> ) : ( <i className="mdi mdi-eye-off"></i> )}
                  </button>
                  {newPasswordValidationSecurityQuestions.touched.newPassword && newPasswordValidationSecurityQuestions.errors.newPassword ? (
                    <FormFeedback type="invalid"> { newPasswordValidationSecurityQuestions.errors.newPassword } </FormFeedback> ) : null}
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="d-flex flex-grow-1">
                    <Label className="form-label">
                      Confirm New Password
                    </Label>
                    <div>
                      <AiOutlineExclamationCircle style={{ marginTop: "-3px", marginLeft: "5px" }} data-tooltip-id="cnpTooltipContent" />
                      <ReactTooltip id="cnpTooltipContent" place="top" content={ <span> Password must be same as the one entered in the field above. </span> } 
                      style={{ backgroundColor: "black" }} />
                    </div>
                  </div>
                </div>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    name="confirmNewPassword"
                    type={ passwordShow3 ? "text" : "password" }
                    placeholder="Re-enter new password"
                    onChange={ newPasswordValidationSecurityQuestions.handleChange }
                    onBlur={ newPasswordValidationSecurityQuestions.handleBlur }
                    value={ newPasswordValidationSecurityQuestions.values.confirmNewPassword }
                    invalid={ newPasswordValidationSecurityQuestions.touched.confirmNewPassword && newPasswordValidationSecurityQuestions.errors.confirmNewPassword ? true : false } />
                  <button onClick={() => setPasswordShow3(!passwordShow3) } className="btn btn-light shadow-none ms-0" type="button" id="password-addon" >
                    {!passwordShow3 ? ( <i className="mdi mdi-eye-outline"></i> ) : ( <i className="mdi mdi-eye-off"></i> )}
                  </button>
                  {newPasswordValidationSecurityQuestions.touched.confirmNewPassword && newPasswordValidationSecurityQuestions.errors.confirmNewPassword ? (
                    <FormFeedback type="invalid"> { newPasswordValidationSecurityQuestions.errors.confirmNewPassword } </FormFeedback> ) : null}
                </div>
              </div>
            </>
          )}

          {newCustomQuestions && (
            <>
              <Modal isOpen={modal} toggle={toggle} centered backdrop='static'>
                <ModalHeader className="border-0 pb-0" > Security Questions </ModalHeader>
                <ModalBody className="pb-0">
                  {getSecurityQuestion.map(
                    (question, index) => (
                      <FormGroup key={index}>
                        <Label for={`securityQuestion${ index + 1 }`} ><span dangerouslySetInnerHTML={{ __html: `${question.question}<span style="color: black;">*</span>` }} /></Label>
                        <Input type="text" autoFocus name={`securityQuestion${ index + 1 }`} id={`securityQuestion${ index + 1 }`} placeholder={`Enter your answer for question ${ index + 1 }`}
                              value={question.answer || ""} onChange={(e) => handleSecurityAnswerChange( index, e.target.value ) } />
                      </FormGroup>
                    )
                  )}
                </ModalBody>
                <ModalFooter className="border-0 pt-0 d-flex justify-content-center">
                  <div> <button className="btn btn-primary btn-block" type="button" onClick={changeAnswer} > Save </button> </div>
                </ModalFooter>
              </Modal>
            </>
          )}

          {newCustomSubmitButton && (
            <div className="mt-3 mb-2 d-grid"> <button className="btn btn-primary btn-block" type="button" onClick={ handleExistingCustomOtpSubmitButton } > Submit </button> </div>
          )}
          {setCustomNewPassword && (
            <div className="mt-3 d-grid"> <button className="btn btn-primary btn-block" type="button" onClick={onSubmitHandlerSequrityQuestions} > Submit </button> </div>
          )}
          </div>
          </>
          )}

                          {/* for custom user who chosen mfa no while creating , for the first time it has to verify the current password and set new password */}
                          {defaultPwd === "YES" && categorys === "USER" && userPrimaryType === "NONE" && userType === "CUSTOM" && (
                              <div style={{ overflowY: "auto", maxHeight: "400px" }} >
                                {customOtp && (
                                  <>
                                    <div className="mb-3">
                                      <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                          <Label className="form-label">
                                            Current Password
                                          </Label>
                                        </div>
                                      </div>
                                      <div className="input-group auth-pass-inputgroup">
                                        <Input
                                          name="existingCustomPassword"
                                          autoFocus
                                          disabled={
                                            setCustomNewPassword ? true : false
                                          }
                                          type={
                                            passwordShow6 ? "text" : "password"
                                          }
                                          placeholder="Enter current password"
                                          onChange={
                                            ExistingCustomPasswordValidation.handleChange
                                          }
                                          onBlur={
                                            ExistingCustomPasswordValidation.handleBlur
                                          }
                                          value={
                                            ExistingCustomPasswordValidation
                                              .values.existingCustomPassword
                                          }
                                          invalid={
                                            ExistingCustomPasswordValidation
                                              .touched.existingCustomPassword &&
                                            ExistingCustomPasswordValidation
                                              .errors.existingCustomPassword
                                              ? true
                                              : false
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            setPasswordShow6(!passwordShow6)
                                          }
                                          className="btn btn-light shadow-none ms-0"
                                          type="button"
                                          id="password-addon"
                                        >
                                          {!passwordShow6 ? (
                                            <i className="mdi mdi-eye-outline"></i>
                                          ) : (
                                            <i className="mdi mdi-eye-off"></i>
                                          )}
                                        </button>
                                        {ExistingCustomPasswordValidation.touched
                                          .existingCustomPassword &&
                                        ExistingCustomPasswordValidation.errors
                                          .existingCustomPassword ? (
                                          <FormFeedback type="invalid">
                                            {
                                              ExistingCustomPasswordValidation
                                                .errors.existingCustomPassword
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                )}

                                {setCustomNewPassword && (
                                  <>
                                    <div className="mb-3">
                                      <h5>Password Setup</h5>
                                      <div className="d-flex align-items-start">
                                        <div className="d-flex flex-grow-1">
                                          <Label className="form-label">
                                            Set New Password
                                          </Label>
                                          <div>
                                            <AiOutlineExclamationCircle
                                              style={{
                                                marginTop: "-3px",
                                                marginLeft: "5px",
                                              }}
                                              data-tooltip-id="snpTooltipContent"
                                            />
                                            <ReactTooltip
                                              id="snpTooltipContent"
                                              place="top"
                                              content={
                                                <span>
                                                  Password must have a minimum of
                                                  8 characters.
                                                  <br />
                                                  Include at least 1 lowercase, 1
                                                  uppercase,
                                                  <br />1 numeric & 1 special
                                                  character!
                                                </span>
                                              }
                                              style={{ backgroundColor: "black" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="input-group auth-pass-inputgroup">
                                        <Input
                                          name="newPassword"
                                          autoFocus
                                          type={
                                            passwordShow2 ? "text" : "password"
                                          }
                                          placeholder="Enter new password"
                                          onChange={
                                            newPasswordValidationSecurityQuestions.handleChange
                                          }
                                          onBlur={
                                            newPasswordValidationSecurityQuestions.handleBlur
                                          }
                                          value={
                                            newPasswordValidationSecurityQuestions
                                              .values.newPassword
                                          }
                                          invalid={
                                            newPasswordValidationSecurityQuestions
                                              .touched.newPassword &&
                                            newPasswordValidationSecurityQuestions
                                              .errors.newPassword
                                              ? true
                                              : false
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            setPasswordShow2(!passwordShow2)
                                          }
                                          className="btn btn-light shadow-none ms-0"
                                          type="button"
                                          id="password-addon"
                                        >
                                          {!passwordShow2 ? (
                                            <i className="mdi mdi-eye-outline"></i>
                                          ) : (
                                            <i className="mdi mdi-eye-off"></i>
                                          )}
                                        </button>
                                        {newPasswordValidationSecurityQuestions
                                          .touched.newPassword &&
                                        newPasswordValidationSecurityQuestions
                                          .errors.newPassword ? (
                                          <FormFeedback type="invalid">
                                            {
                                              newPasswordValidationSecurityQuestions
                                                .errors.newPassword
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <div className="d-flex align-items-start">
                                        <div className="d-flex flex-grow-1">
                                          <Label className="form-label">
                                            Confirm New Password
                                          </Label>
                                          <div>
                                            <AiOutlineExclamationCircle
                                              style={{
                                                marginTop: "-3px",
                                                marginLeft: "5px",
                                              }}
                                              data-tooltip-id="cnpTooltipContent"
                                            />
                                            <ReactTooltip
                                              id="cnpTooltipContent"
                                              place="top"
                                              content={
                                                <span>
                                                  Password must be same as the one
                                                  entered in the field above.
                                                </span>
                                              }
                                              style={{ backgroundColor: "black" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="input-group auth-pass-inputgroup">
                                        <Input
                                          name="confirmNewPassword"
                                          type={
                                            passwordShow3 ? "text" : "password"
                                          }
                                          placeholder="Re-enter new password"
                                          onChange={
                                            newPasswordValidationSecurityQuestions.handleChange
                                          }
                                          onBlur={
                                            newPasswordValidationSecurityQuestions.handleBlur
                                          }
                                          value={
                                            newPasswordValidationSecurityQuestions
                                              .values.confirmNewPassword
                                          }
                                          invalid={
                                            newPasswordValidationSecurityQuestions
                                              .touched.confirmNewPassword &&
                                            newPasswordValidationSecurityQuestions
                                              .errors.confirmNewPassword
                                              ? true
                                              : false
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            setPasswordShow3(!passwordShow3)
                                          }
                                          className="btn btn-light shadow-none ms-0"
                                          type="button"
                                          id="password-addon"
                                        >
                                          {!passwordShow3 ? (
                                            <i className="mdi mdi-eye-outline"></i>
                                          ) : (
                                            <i className="mdi mdi-eye-off"></i>
                                          )}
                                        </button>
                                        {newPasswordValidationSecurityQuestions
                                          .touched.confirmNewPassword &&
                                        newPasswordValidationSecurityQuestions
                                          .errors.confirmNewPassword ? (
                                          <FormFeedback type="invalid">
                                            {
                                              newPasswordValidationSecurityQuestions
                                                .errors.confirmNewPassword
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                )}
                                {newCustomSubmitButton && (
                                  <div className="mt-3 mb-2 d-grid">
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={
                                        handleExistingCustomOtpSubmitButton
                                      }
                                    >
                                      Submit
                                    </button>
                                  </div>
                                )}
                                {setCustomNewPassword && (
                                  <div className="mt-3 d-grid">
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={onSubmitHandlerSequrityQuestions}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}

                          {/* mfaPrimaryType is SECURITY_QUESTIONS  and its status is ACTIVE means this screen */}
                          {categorys === "USER" && userPrimaryType === "SECURITY_QUESTIONS" && primarySequrityQuestionStatus === "ACTIVE" && (
                              <>
                                {existingCustomPassword && (
                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Enter Password
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="existingCustomPassword"
                                        autoFocus
                                        disabled={existingCustomQA ? true : false}
                                        type={
                                          existingCustomPasswordShow
                                            ? "text"
                                            : "password"
                                        }
                                        placeholder="Enter password"
                                        onChange={
                                          ExistingCustomPasswordValidation.handleChange
                                        }
                                        onBlur={
                                          ExistingCustomPasswordValidation.handleBlur
                                        }
                                        value={
                                          ExistingCustomPasswordValidation.values
                                            .existingCustomPassword
                                        }
                                        invalid={
                                          ExistingCustomPasswordValidation.touched
                                            .existingCustomPassword &&
                                          ExistingCustomPasswordValidation.errors
                                            .existingCustomPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setExistingCustomPasswordShow(
                                            !existingCustomPasswordShow
                                          )
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        {!existingCustomPasswordShow ? (
                                          <i className="mdi mdi-eye-outline"></i>
                                        ) : (
                                          <i className="mdi mdi-eye-off"></i>
                                        )}
                                      </button>
                                      {ExistingCustomPasswordValidation.touched
                                        .existingCustomPassword &&
                                      ExistingCustomPasswordValidation.errors
                                        .existingCustomPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            ExistingCustomPasswordValidation
                                              .errors.existingCustomPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                )}

                                {existingCustomQA && (
                                  <div className="mb-3">
                                    <h5>User Verify</h5>
                                    <Label className="form-label">
                                      {randomQuestion}
                                    </Label>
                                    <Input
                                      placeholder="Enter your answer"
                                      autoFocus
                                      className="form-control"
                                      onChange={(e) => setAnswer(e.target.value)}
                                      onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') { handleModalSubmit(e); } }}

                                    />
                                  </div>
                                )}

                                {existingCustomOtpSubmitButton && (
                                  <div className="mt-3 d-grid">
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={
                                        handleExistingCustomOtpSubmitButton
                                      }
                                    >
                                      Submit
                                    </button>
                                  </div>
                                )}

                                {existingCustomSubmitButton && (
                                  <div className="mt-3 d-grid">
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={handleModalSubmit}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                )}
                              </>
                          )}

                          {/* mfaPrimaryType is NONE means this screen */}
                          {((userPrimaryType === "NONE" && usernameVerifiedStatus === "YES" && userType !== "IMEI_NUMBER" && defaultPwd === "NO") || categorys === "ADMIN" ||
                            categorys === "SUPER_ADMIN") && (
                            <>
                              <div className="mb-3">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1">
                                    <Label className="form-label">Password</Label>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="">
                                      {/* <Link
                                        to="/page-recoverpw"
                                        className="text-muted"
                                      >
                                        Forgot password?
                                      </Link> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="existingMobEmailPassword"
                                    autoFocus
                                    value={
                                      ExistingMobEmailPasswordValidation.values
                                        .existingMobEmailPassword
                                    }
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="Enter password"
                                    onChange={
                                      ExistingMobEmailPasswordValidation.handleChange
                                    }
                                    onBlur={
                                      ExistingMobEmailPasswordValidation.handleBlur
                                    }
                                    invalid={
                                      ExistingMobEmailPasswordValidation.touched
                                        .existingMobEmailPassword &&
                                      ExistingMobEmailPasswordValidation.errors
                                        .existingMobEmailPassword
                                        ? true
                                        : false
                                    }
                                  />
                                  <button
                                    onClick={() => setPasswordShow(!passwordShow)}
                                    className="btn btn-light shadow-none ms-0"
                                    type="button"
                                    id="password-addon"
                                  >
                                    {!passwordShow ? (
                                      <i className="mdi mdi-eye-outline"></i>
                                    ) : (
                                      <i className="mdi mdi-eye-off"></i>
                                    )}
                                  </button>
                                  {ExistingMobEmailPasswordValidation.touched
                                    .existingMobEmailPassword &&
                                  ExistingMobEmailPasswordValidation.errors
                                    .existingMobEmailPassword ? (
                                    <FormFeedback type="invalid">
                                      {
                                        ExistingMobEmailPasswordValidation.errors
                                          .existingMobEmailPassword
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={handleSubmitButtonClick}
                                >
                                  Log In
                                </button>
                              </div>
                            </>
                          )}

                          {/* Login button which is used to check the user type */}
                          {showLoginButton && (
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={handleNewLoginButton}
                              >
                                Log In
                              </button>
                            </div>
                          )}
                        </Form>
                      </div>  

                      <div className="text-center mt-3">
                        <p className="text-muted mb-0">
                          Try with a different account?
                          <Button className="p-0" style={{ marginBottom: "4px" }} color="link" onClick={initialStates}>&nbsp;click here</Button>
                        </p>
                      </div>      
                    </div>

                    <div className="mt-2 text-center">
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <a href="https://motivai.tech/terms_and_conditions" className="fw-semibold" style={{ marginRight: "2px" }} target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                          </a>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <a href="https://motivai.tech/privacy_policy" className="fw-semibold" style={{ marginRight: "2px" }} target="_blank" rel="noopener noreferrer">
                            Privacy Policy |
                          </a>
                          <a href="https://motivai.tech/cookies_policy" className="fw-semibold" style={{ marginRight: "2px" }} target="_blank" rel="noopener noreferrer">
                            Cookie Policy |
                          </a>
                          <a href="https://motivai.tech/faq" className="fw-semibold" target="_blank" rel="noopener noreferrer">
                            FAQ
                          </a>
                      </div>
                    </div>

                    <div className="mt-2 text-center">
                      <p className="mb-0">
                        {new Date().getFullYear()} © Motiv AI. All rights
                        reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <CarouselPage />

            {/* force change pasword modal */}
            <Modal isOpen={forcepasswordModal} centered backdrop="static">
              <ModalHeader className="border-0" style={{background: "#5156be1a", display:'flex', alignItems:'center'}}>
                Force Change Password
              </ModalHeader>
              <ModalBody>
                <>
                  <div className="mb-3">
                    <div className="d-flex align-items-start">
                      <div className="d-flex flex-grow-1">
                        <Label className="form-label">Set New Password</Label>
                        <div>
                          <AiOutlineExclamationCircle
                            style={{ marginTop: "-3px", marginLeft: "5px" }}
                            data-tooltip-id="snpTooltipContent"
                          />
                          <ReactTooltip
                            id="snpTooltipContent"
                            place="top"
                            content={
                              <span>
                                Password must have a minimum of 8 characters.
                                <br />
                                Include at least 1 lowercase, 1 uppercase,
                                <br />1 numeric & 1 special character!
                              </span>
                            }
                            style={{ backgroundColor: "black" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-group auth-pass-inputgroup">
                      <Input
                        autoFocus={autoFocus}
                        name="forceNewPassword"
                        type={passwordShow7 ? "text" : "password"}
                        placeholder="Enter new password"
                        value={
                          forceChangePasswordValidation.values.forceNewPassword
                        }
                        onChange={forceChangePasswordValidation.handleChange}
                        onBlur={forceChangePasswordValidation.handleBlur}
                        invalid={
                          forceChangePasswordValidation.touched
                            .forceNewPassword &&
                          forceChangePasswordValidation.errors.forceNewPassword
                            ? true
                            : false
                        }
                        innerRef={passwordInputRef}
                      />
                      <button
                        onClick={() => setPasswordShow7(!passwordShow7)}
                        className="btn btn-light shadow-none ms-0"
                        type="button"
                        id="password-addon"
                      >
                        {!passwordShow7 ? (
                          <i className="mdi mdi-eye-outline"></i>
                        ) : (
                          <i className="mdi mdi-eye-off"></i>
                        )}
                      </button>
                      {forceChangePasswordValidation.touched.forceNewPassword &&
                      forceChangePasswordValidation.errors.forceNewPassword ? (
                        <FormFeedback type="invalid">
                          {
                            forceChangePasswordValidation.errors
                              .forceNewPassword
                          }
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>


                  <div className="mb-3">
                    <div className="d-flex align-items-start">
                      <div className="d-flex flex-grow-1">
                        <Label className="form-label">
                          Confirm New Password
                        </Label>
                        <div>
                          <AiOutlineExclamationCircle
                            style={{ marginTop: "-3px", marginLeft: "5px" }}
                            data-tooltip-id="cnpTooltipContent"
                          />
                          <ReactTooltip
                            id="cnpTooltipContent"
                            place="top"
                            content={
                              <span>
                                Password must be same as the one entered in the
                                field above.
                              </span>
                            }
                            style={{ backgroundColor: "black" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-group auth-pass-inputgroup">
                      <Input
                        name="forceConfirmNewPassword"
                        type={passwordShow8 ? "text" : "password"}
                        placeholder="Enter new password"
                        value={
                          forceChangePasswordValidation.values
                            .forceConfirmNewPassword
                        }
                        onChange={forceChangePasswordValidation.handleChange}
                        onBlur={forceChangePasswordValidation.handleBlur}
                        invalid={
                          forceChangePasswordValidation.touched
                            .forceConfirmNewPassword &&
                          forceChangePasswordValidation.errors
                            .forceConfirmNewPassword
                            ? true
                            : false
                        }
                      />

                      <button
                        onClick={() => setPasswordShow8(!passwordShow8)}
                        className="btn btn-light shadow-none ms-0"
                        type="button"
                        id="password-addon"
                      >
                        {!passwordShow8 ? (
                          <i className="mdi mdi-eye-outline"></i>
                        ) : (
                          <i className="mdi mdi-eye-off"></i>
                        )}
                      </button>
                      {forceChangePasswordValidation.touched
                        .forceConfirmNewPassword &&
                      forceChangePasswordValidation.errors
                        .forceConfirmNewPassword ? (
                        <FormFeedback type="invalid">
                          {
                            forceChangePasswordValidation.errors
                              .forceConfirmNewPassword
                          }
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-center align-items-center" style={{background: "#5156be1a"}}>
                <button
                  className="btn btn-primary"
                  onClick={handleForcePasswordSubmit}
                  type="button"
                >
                  Submit
                </button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={agreeModal}>
              <ModalHeader>
                Changing your password will log you out of all <br />
                devices:
              </ModalHeader>
              <ModalBody>
              <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 8, fontSize: '20px', marginBottom:'4px' }}>•</span>
                  <span>This action is necessary to ensure the security of your account.</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                  <span style={{ marginRight: 8, fontSize: '20px', marginBottom:'4px' }}>•</span>
                  <span>Including the device you are on now</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                  <span style={{ marginRight: 8, fontSize: '20px', marginBottom:'4px' }}>•</span>
                  <span>Please make sure you agree to relogin & proceed before continuing.</span>
              </div>
              </div>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onChange={handleCheckboxChange} style={{border:"1px solid black"}}/> I
                    Agree to the above conditions
                  </Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter
                style={{ display: "flex", justifyContent: "center", marginBottom:"10px" }}
              >
                <button
                  onClick={() => setAgreeModal(false)}
                  className="btn btn-primary btn-blockr"
                >
                  Cancel
                </button>
                <button
                  onClick={agreePopup}
                  className={`btn btn-primary btn-block ${
                    isChecked ? "" : "disabled"
                  }`}
                  disabled={!isChecked}
                >
                  Proceed
                </button>
              </ModalFooter>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
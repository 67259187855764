import React from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import { SC_LOGIN_USER_FIRST_NAME } from "../../../constant/StorageConstant";
import Converter from "../../../components/Common/EncodeDecode";

export default function Bubble({safetyData}) {
  const history = useNavigate();

    console.log("safetyData: ",safetyData)
  
    const handlePointClick = (params) => {
      if(params.data ){
        const userId = encodeURIComponent(Converter.encrypt(params.data[5]));
        const divisionId = encodeURIComponent(Converter.encrypt(params.data[4]));
        const rideId = encodeURIComponent(Converter.encrypt(params.data[6]));
        
        history(`/ridedetails?userId=${userId}&divisionId=${divisionId}&rideId=${rideId}`);
        localStorage.setItem(SC_LOGIN_USER_FIRST_NAME, params.data[7]);
      }
    };
  
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "410px" }} option={safetyData} onEvents={{
        click: handlePointClick,
      }}/>
    </React.Fragment>
  );
}

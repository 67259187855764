import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
  Row,
  CardHeader,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../util/ValueFormat";
import * as echarts from "echarts";
import { Tooltip as ReactTooltip } from "react-tooltip";

import Bubble from "../AllCharts/echart/bubblechart";
import Transactions from "./Transactions";
import { displayText } from "../../constant/MessageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import { useDispatch } from "react-redux";
import { getTradingData } from "../../store/actions";

const Trading = ({filter, loaderCount, getRideNameList}) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const data = [
    [[null, null, 15, "NoData"]],
    [[null, null, 15, "NoData"]],
    [[null, null, 15, "NoData"]],
    [[null, null, 15, "NoData"]],
    [[null, null, 15, "NoData"]],
    [[null, null, 15, "NoData"]],
  ];

  const getOption = {
    tooltip: {
      show: true,
      trigger: "item",
      formatter: function (param) {
        return param.data[3];
      },
    },
    toolbox: {
      show: false,
    },
    grid: {
      bottom: "20%",
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
    },
    legend: {
      right: 10,
      show: true,
    },
    xAxis: {
      min: 0,
      max: 100,
      name: "Anticipatory Driving",
      nameLocation: "middle",
      // nameTextStyle: {
      //   // align: 'center',
      //   // verticalAlign: 'top',
      //   padding: [58, 0, 0, 0],
      // },
      nameGap: 0,
      nameTextStyle: {
        align: "center",
        verticalAlign: "top",       
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
        padding: [30, 0, 0, 0],
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    yAxis: {
      name: "Self Confidence",
      nameTextStyle: {            
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
      },
      nameLocation: "middle",
      nameGap: 35,
      min: 0,
      max: 100,
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      scale: true,
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    series: [
      {
        name: "C1",
        data: data[0],
        type: "scatter",
        symbolSize: function (data) {
          return data[2];
        },
        // label: {
        //   emphasis: {
        //     show: true,
        //     formatter: function (param) {
        //       return param.data[3];
        //     },
        //     position: "top",
        //   },
        // },
        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(94, 148, 60, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#0AA700",
              },
              {
                offset: 1,
                color: "#0AA700",
              },
            ]),
          },
        },
      },
      {
        name: "C2",
        data: data[1],
        type: "scatter",
        symbolSize: function (data) {
          return data[2];
        },
        // label: {
        //   emphasis: {
        //     show: true,
        //     formatter: function (param) {
        //       return param.data[3];
        //     },
        //     position: "top",
        //   },
        // },
        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(60, 76, 207, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#67C86C",
              },

              {
                offset: 1,
                color: "#67C86C",
              },
            ]),
          },
        },
      },
      {
        name: "C3",
        data: data[2],
        type: "scatter",
        symbolSize: function (data) {
          return data[2];
        },
        // label: {
        //   emphasis: {
        //     show: true,
        //     formatter: function (param) {
        //       return param.data[3];
        //     },
        //     position: "top",
        //   },
        // },
        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(2, 164, 153, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#E9B506",
              },
              {
                offset: 1,
                color: "#E9B506",
              },
            ]),
          },
        },
      },
      {
        name: "C4",
        data: data[3],
        type: "scatter",
        symbolSize: function (data) {
          return data[2];
        },
        // label: {
        //   emphasis: {
        //     show: true,
        //     formatter: function (param) {
        //       return param.data[3];
        //     },
        //     position: "top",
        //   },
        // },
        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(121, 60, 148, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#F6D259",
              },
              {
                offset: 1,
                color: "#F6D259",
              },
            ]),
          },
        },
      },
      {
        name: "C5",
        data: data[4],
        type: "scatter",
        symbolSize: function (data) {
          return data[2];
        },
        //   label: {
        //   emphasis: {
        //     show: true,
        //     formatter: function (param) {
        //       return param.data[3];
        //     },
        //     position: "top",
        //   },
        // },

        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(224, 126, 59, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#FF0505",
              },
              {
                offset: 1,
                color: "#FF0505",
              },
            ]),
          },
        },
      },
      {
        name: "C6",
        data: data[5],
        type: "scatter",
        symbolSize: function (data) {
          return data[2];
        },
        // label: {
        //   emphasis: {
        //     show: true,
        //     formatter: function (param) {
        //       return param.data[3];
        //     },
        //     position: "top",
        //   },
        // },
        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(120, 36, 50, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#AF0505",
              },

              {
                offset: 1,
                color: "#AF0505",
              },
            ]),
          },
        },
      },
    ],
  };

  const tableData = [
    {
      type: "SAFE_DRIVER",
      name: "Safe Driver",
      prefix: "C1",
      antiIcon: "up",
      selfIcon: "down",
      totalRides: 0,
      percentage: 0,
      rideNameList: [],
    },
    {
      type: "SPORTIVE_DRIVER",
      name: "Sportive Driver",
      prefix: "C2",
      antiIcon: "up",
      selfIcon: "up",
      totalRides: 0,
      percentage: 0,
      rideNameList: [],
    },
    {
      type: "QUIET_SAFE_DRIVER",
      name: "Quite Safe Driver",
      prefix: "C3",
      antiIcon: "mod",
      selfIcon: "down",
      totalRides: 0,
      percentage: 0,
      rideNameList: [],
    },
    {
      type: "RECKLESS_DRIVER",
      name: "Reckless Driver",
      prefix: "C4",
      antiIcon: "mod",
      selfIcon: "up",
      totalRides: 0,
      percentage: 0,
      rideNameList: [],
    },
    {
      type: "QUIET_DANGEROUS_DRIVER",
      name: "Quite Dangerous Driver",
      prefix: "C5",
      antiIcon: "down",
      selfIcon: "down",
      totalRides: 0,
      percentage: 0,
      rideNameList: [],
    },
    {
      type: "DANGEROUS_DRIVER",
      name: "Dangerous Driver",
      prefix: "C6",
      antiIcon: "down",
      selfIcon: "up",
      totalRides: 0,
      percentage: 0,
      rideNameList: [],
    },
  ];

  const [safetyDataList, setSafetyDataList] = useState(getOption);
  const [safetyTable, setSafetyTable] = useState(tableData);
  // const [filter, setFilter] = useState("CURRENT_YEAR");
  const [safeCateRidesData, setSafeCateRidesData] = useState(false);
  const [dataDisplay, setDataDisplay] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const dispatch = useDispatch();

  const getSafetyData = async () => {
    setDataDisplay(null);
    loaderCount((current) => current + 1);
    var result = await UserDrivingScoreController.userSafetyCloudPoint(filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,);
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if(status == "SUCCESS"){

    var resultJSONData = resultJSON.data;
    // alert(resultJSONData.length)
    var safeDriverData = [];
    var sportiveDriverData = [];
    var quietSafeDriverData = [];
    var recklessDriverData = [];
    var quietDangerousDriverData = [];
    var dangerousDriverData = [];
    let goodDriverCount = 0;
    let sportiveDriverCount = 0;
    let qSafeDriverCount = 0;
    let recklessDriverCount = 0;
    let qDangerousDriverCount = 0;
    let dangerousDriverCount = 0;
    var totalRides = 0;
    if(resultJSONData.length > 0) {
      // console.log("getSafetyCategoryList: " + JSON.stringify(resultJSONData));
    setDataDisplay(Object.values(resultJSONData).some((value) => value !== 0));

    resultJSONData.map((safeData) => {
      var safeDriverDataTemp = [];
      var sportiveDriverDataTemp = [];
      var quietSafeDriverDataTemp = [];
      var recklessDriverDataTemp = [];
      var quietDangerousDriverDataTemp = [];
      var dangerousDriverDataTemp = [];

      var safeDriverRideList = [];
      var sportiveDriverRideList = [];
      var quietSafeDriverRideList = [];
      var recklessDriverRideList = [];
      var quietDangerousDriverRideList = [];
      var dangerousDriverRideList = [];

      var divisionId = ValueFormat.parseAsString(safeData.divisionId);
      var userId = ValueFormat.parseAsString(safeData.userId);
      var firstName = ValueFormat.parseAsString(safeData.firstName);

      var rideBoxList = ValueFormat.parseAsArray(safeData.rideBoxList);
      rideBoxList.map((rideData) => {
        var rideBox = ValueFormat.parseAsInt(rideData.rideBox);
        var symbolSize = 10;
        var anticipateDriveValue = ValueFormat.parseAsFloat(
          rideData.anticipateDriveValue,
          2
        );
        var selfConfidenceValue = ValueFormat.parseAsFloat(
          rideData.selfConfidenceValue,
          2
        );

        var rideName = ValueFormat.parseAsString(rideData.name);
        var tooltip = rideName;
        var rideId = ValueFormat.parseAsString(rideData.deviceId);
        
        if (rideBox == 1) {
          let rideNameValue = {rideName:rideName, selfConfidenceValue:selfConfidenceValue,anticipateDriveValue:anticipateDriveValue,divisionId:divisionId,userId:userId,rideId:rideId,firstName:firstName}
          safeDriverRideList.push(rideNameValue);
          goodDriverCount = goodDriverCount + 1;
          safeDriverDataTemp = [];
          tooltip = tooltip + " (C1)";
          safeDriverDataTemp.push(
            anticipateDriveValue,
            selfConfidenceValue,
            symbolSize,
            tooltip,
            divisionId,
            userId,
            rideId
          );
        } else if (rideBox == 2) {
          let rideNameValue = {rideName:rideName, selfConfidenceValue:selfConfidenceValue,anticipateDriveValue:anticipateDriveValue,divisionId:divisionId,userId:userId,rideId:rideId,firstName:firstName}
          sportiveDriverRideList.push(rideNameValue);
          sportiveDriverCount = sportiveDriverCount + 1;
          sportiveDriverDataTemp = [];
          tooltip = tooltip + " (C2`)";
          sportiveDriverDataTemp.push(
            anticipateDriveValue,
            selfConfidenceValue,
            symbolSize,
            tooltip,
            divisionId,
            userId,
            rideId
          );
        } else if (rideBox == 3) {
          let rideNameValue = {rideName:rideName, selfConfidenceValue:selfConfidenceValue,anticipateDriveValue:anticipateDriveValue,divisionId:divisionId,userId:userId,rideId:rideId,firstName:firstName}
          quietSafeDriverRideList.push(rideNameValue);
          qSafeDriverCount = qSafeDriverCount + 1;
          quietSafeDriverDataTemp = [];
          tooltip = tooltip + " (C3)";
          quietSafeDriverDataTemp.push(
            anticipateDriveValue,
            selfConfidenceValue,
            symbolSize,
            tooltip,
            divisionId,
            userId,
            rideId
          );
        } else if (rideBox == 4) {
          let rideNameValue = {rideName:rideName, selfConfidenceValue:selfConfidenceValue,anticipateDriveValue:anticipateDriveValue,divisionId:divisionId,userId:userId,rideId:rideId,firstName:firstName}
          recklessDriverRideList.push(rideNameValue);
          recklessDriverCount = recklessDriverCount + 1;
          recklessDriverDataTemp = [];
          tooltip = tooltip + " (C4)";
          recklessDriverDataTemp.push(
            anticipateDriveValue,
            selfConfidenceValue,
            symbolSize,
            tooltip,
            divisionId,
            userId,
            rideId
          );
        } else if (rideBox == 5) {
          let rideNameValue = {rideName:rideName, selfConfidenceValue:selfConfidenceValue,anticipateDriveValue:anticipateDriveValue,divisionId:divisionId,userId:userId,rideId:rideId,firstName:firstName}
          console.log(rideNameValue,"rideNameValue")
          quietDangerousDriverRideList.push(rideNameValue);
          qDangerousDriverCount = qDangerousDriverCount + 1;
          quietDangerousDriverDataTemp = [];
          tooltip = tooltip + " (C5)";
          quietDangerousDriverDataTemp.push(
            anticipateDriveValue,
            selfConfidenceValue,
            symbolSize,
            tooltip,
            divisionId,
            userId,
            rideId
          );
        } else if (rideBox == 6) {
          let rideNameValue = {rideName:rideName, selfConfidenceValue:selfConfidenceValue,anticipateDriveValue:anticipateDriveValue,divisionId:divisionId,userId:userId,rideId:rideId,firstName:firstName}
          dangerousDriverRideList.push(rideNameValue);
          dangerousDriverCount = dangerousDriverCount + 1;
          dangerousDriverDataTemp = [];
          tooltip = tooltip + " (C6)";
          dangerousDriverDataTemp.push(
            anticipateDriveValue,
            selfConfidenceValue,
            symbolSize,
            tooltip,
            divisionId,
            userId,
            rideId
          );
        }

        safeDriverData.push(safeDriverDataTemp);
        sportiveDriverData.push(sportiveDriverDataTemp);
        quietSafeDriverData.push(quietSafeDriverDataTemp);
        recklessDriverData.push(recklessDriverDataTemp);
        quietDangerousDriverData.push(quietDangerousDriverDataTemp);
        dangerousDriverData.push(dangerousDriverDataTemp);
      });
      totalRides =
        goodDriverCount +
        sportiveDriverCount +
        qSafeDriverCount +
        recklessDriverCount +
        qDangerousDriverCount +
        dangerousDriverCount;

      var goodDriverPer = parseFloat(
        (goodDriverCount / totalRides) * 100
      ).toFixed(2);
      var sportiveDriverPer = parseFloat(
        (sportiveDriverCount / totalRides) * 100
      ).toFixed(2);
      var qSafeDriverPer = parseFloat(
        (qSafeDriverCount / totalRides) * 100
      ).toFixed(2);
      var recklessDriverPer = parseFloat(
        (recklessDriverCount / totalRides) * 100
      ).toFixed(2);
      var qDangerousDriverPer = parseFloat(
        (qDangerousDriverCount / totalRides) * 100
      ).toFixed(2);
      var dangerousDriverPer = parseFloat(
        (dangerousDriverCount / totalRides) * 100
      ).toFixed(2);

      setSafetyTable(
        safetyTable.map((staticObjectTemp) => {
          var isMatched = false;
          var totalRidesTemp = 0;
          var percentageTemp = 0;
          var rideNameListTemp = [];

          if (staticObjectTemp.type === "SAFE_DRIVER") {
            isMatched = true;
            totalRidesTemp = goodDriverCount;
            percentageTemp = goodDriverPer;
            rideNameListTemp = safeDriverRideList;
          } else if (staticObjectTemp.type === "SPORTIVE_DRIVER") {
            isMatched = true;
            totalRidesTemp = sportiveDriverCount;
            percentageTemp = sportiveDriverPer;
            rideNameListTemp = sportiveDriverRideList;
          } else if (staticObjectTemp.type === "QUIET_SAFE_DRIVER") {
            isMatched = true;
            totalRidesTemp = qSafeDriverCount;
            percentageTemp = qSafeDriverPer;
            rideNameListTemp = quietSafeDriverRideList;
          } else if (staticObjectTemp.type === "RECKLESS_DRIVER") {
            isMatched = true;
            totalRidesTemp = recklessDriverCount;
            percentageTemp = recklessDriverPer;
            rideNameListTemp = recklessDriverRideList;
          } else if (staticObjectTemp.type === "QUIET_DANGEROUS_DRIVER") {
            isMatched = true;
            totalRidesTemp = qDangerousDriverCount;
            percentageTemp = qDangerousDriverPer;
            rideNameListTemp = quietDangerousDriverRideList;
          } else if (staticObjectTemp.type === "DANGEROUS_DRIVER") {
            isMatched = true;
            totalRidesTemp = dangerousDriverCount;
            percentageTemp = dangerousDriverPer;
            rideNameListTemp = dangerousDriverRideList;
          }

          if (isMatched === true) {
            var result = {
              totalRides: totalRidesTemp,
              percentage: percentageTemp,
              rideNameList: rideNameListTemp,
            };
            return { ...staticObjectTemp, ...result };
          } else {
            return staticObjectTemp;
          }
        })
      );
      let dashboardTradingData = safetyTable.map((staticObjectTemp) => {
        var isMatched = false;
        var totalRidesTemp = 0;
        var percentageTemp = 0;
        var rideNameListTemp = [];

        if (staticObjectTemp.type === "SAFE_DRIVER") {
          isMatched = true;
          totalRidesTemp = goodDriverCount;
          percentageTemp = goodDriverPer;
          rideNameListTemp = safeDriverRideList;
        } else if (staticObjectTemp.type === "SPORTIVE_DRIVER") {
          isMatched = true;
          totalRidesTemp = sportiveDriverCount;
          percentageTemp = sportiveDriverPer;
          rideNameListTemp = sportiveDriverRideList;
        } else if (staticObjectTemp.type === "QUIET_SAFE_DRIVER") {
          isMatched = true;
          totalRidesTemp = qSafeDriverCount;
          percentageTemp = qSafeDriverPer;
          rideNameListTemp = quietSafeDriverRideList;
        } else if (staticObjectTemp.type === "RECKLESS_DRIVER") {
          isMatched = true;
          totalRidesTemp = recklessDriverCount;
          percentageTemp = recklessDriverPer;
          rideNameListTemp = recklessDriverRideList;
        } else if (staticObjectTemp.type === "QUIET_DANGEROUS_DRIVER") {
          isMatched = true;
          totalRidesTemp = qDangerousDriverCount;
          percentageTemp = qDangerousDriverPer;
          rideNameListTemp = quietDangerousDriverRideList;
        } else if (staticObjectTemp.type === "DANGEROUS_DRIVER") {
          isMatched = true;
          totalRidesTemp = dangerousDriverCount;
          percentageTemp = dangerousDriverPer;
          rideNameListTemp = dangerousDriverRideList;
        }

        if (isMatched === true) {
          var result = {
            totalRides: totalRidesTemp,
            percentage: percentageTemp,
            rideNameList: rideNameListTemp,
          };
          return { ...staticObjectTemp, ...result };
        } else {
          return staticObjectTemp;
        }
      })

      dispatch(getTradingData(dashboardTradingData))

      getRideNameList(safetyTable.map((staticObjectTemp) => {
        var isMatched = false;
        var totalRidesTemp = 0;
        var percentageTemp = 0;
        var rideNameListTemp = [];

        if (staticObjectTemp.type === "SAFE_DRIVER") {
          isMatched = true;
          totalRidesTemp = goodDriverCount;
          percentageTemp = goodDriverPer;
          rideNameListTemp = safeDriverRideList;
        } else if (staticObjectTemp.type === "SPORTIVE_DRIVER") {
          isMatched = true;
          totalRidesTemp = sportiveDriverCount;
          percentageTemp = sportiveDriverPer;
          rideNameListTemp = sportiveDriverRideList;
        } else if (staticObjectTemp.type === "QUIET_SAFE_DRIVER") {
          isMatched = true;
          totalRidesTemp = qSafeDriverCount;
          percentageTemp = qSafeDriverPer;
          rideNameListTemp = quietSafeDriverRideList;
        } else if (staticObjectTemp.type === "RECKLESS_DRIVER") {
          isMatched = true;
          totalRidesTemp = recklessDriverCount;
          percentageTemp = recklessDriverPer;
          rideNameListTemp = recklessDriverRideList;
        } else if (staticObjectTemp.type === "QUIET_DANGEROUS_DRIVER") {
          isMatched = true;
          totalRidesTemp = qDangerousDriverCount;
          percentageTemp = qDangerousDriverPer;
          rideNameListTemp = quietDangerousDriverRideList;
        } else if (staticObjectTemp.type === "DANGEROUS_DRIVER") {
          isMatched = true;
          totalRidesTemp = dangerousDriverCount;
          percentageTemp = dangerousDriverPer;
          rideNameListTemp = dangerousDriverRideList;
        }

        if (isMatched === true) {
          var result = {
            totalRides: totalRidesTemp,
            percentage: percentageTemp,
            rideNameList: rideNameListTemp,
          };
          return { ...staticObjectTemp, ...result };
        } else {
          return staticObjectTemp;
        }
    }));
    });

    setSafetyDataList((updateData) => {
      const updatedParentObject = {
        ...updateData,
        series: updateData.series.map((item) =>
          item.name === "C1"
            ? { ...item, data: safeDriverData }
            : item.name === "C2"
            ? { ...item, data: sportiveDriverData }
            : item.name === "C3"
            ? { ...item, data: quietSafeDriverData }
            : item.name === "C4"
            ? { ...item, data: recklessDriverData }
            : item.name === "C5"
            ? { ...item, data: quietDangerousDriverData }
            : item.name === "C6"
            ? { ...item, data: dangerousDriverData }
            : item
        ),
      };

      return {
        ...updateData,
        ...updatedParentObject,
      };
    });
  } else {
    setSafetyTable(tableData);
    setSafetyDataList(getOption);
    setDataDisplay(false);
  }
} else {
    const code = resultJSON.error.code;
    if(code == "E1206"){
      setDataDisplay("failed")
    } else {
      setDataDisplay(false);
    }
  }
  };

  useEffect(() => {
    setSafeCateRidesData();
    getSafetyData();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={6}>
          <Card style={{height: 568}}>
            <CardHeader
              className="align-items-center d-flex cardHeader"
            >
              <h4 className="sub_heading flex-grow-1 my-0">Safety Category Rides</h4>
              {/* <div className="ms-auto">
                <div>
                  <button
                    data-tooltip-id="curdcurd"
                    type="button"
                    className={
                      filter == "CURRENT_DAY"
                        ? "btn btn-secondary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_DAY")}
                  >
                    CD
                    <ReactTooltip
                      id="curdcurd"
                      place="top"
                      content="Current Day"
                      style={{ background: "black" }}
                    />
                  </button>{" "}
                  <button
                    data-tooltip-id="curwcurw"
                    type="button"
                    className={
                      filter == "CURRENT_WEEK"
                        ? "btn btn-secondary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_WEEK")}
                  >
                    CW
                    <ReactTooltip
                      id="curwcurw"
                      place="top"
                      content="Current Week"
                      style={{ background: "black" }}
                    />
                  </button>{" "}
                  <button
                    data-tooltip-id="curmcurm"
                    type="button"
                    className={
                      filter == "CURRENT_MONTH"
                        ? "btn btn-secondary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_MONTH")}
                  >
                    CM
                    <ReactTooltip
                      id="curmcurm"
                      place="top"
                      content="Current Month"
                      style={{ background: "black" }}
                    />
                  </button>{" "}
                  <button
                    data-tooltip-id="curycury"
                    type="button"
                    className={
                      filter == "CURRENT_YEAR"
                        ? "btn btn-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_YEAR")}
                  >
                    CY
                    <ReactTooltip
                      id="curycury"
                      place="top"
                      content="Current Year"
                      style={{ background: "black" }}
                    />
                  </button>{" "}
                  <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-secondary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{
                            setIsOpen(true);
                            // setFilter("CUSTOM_DURATION");
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                </div>
              </div> */}
            </CardHeader>
          
              <CardBody>
              {dataDisplay == "failed" ? (
          <p className="data_not_available">{displayText.ER_1206}</p>
        ): dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
        ) : dataDisplay == null ? (<p className="fetch_data">{displayText.FETCHING_DATA}</p>) : "" }
                <div id="bubble-chart" className="e-chart">
                  <Bubble safetyData={safetyDataList} />
                </div>
                {/* <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">   
                                <div className="float-end ms-2">
                                    <h5 className="font-size-14"><i className="bx bx-wallet text-primary font-size-16 align-middle me-1"></i> <a href="#!" className="text-reset text-decoration-underline">$4335.23</a></h5>
                                </div>
                                <h5 className="font-size-14 mb-4">Buy Coins</h5>
                                <div>
                                    <div className="form-group mb-3">
                                        <label>Payment method :</label>
                                        <select className="form-select">
                                            <option>Direct Bank Payment</option>
                                            <option>Credit / Debit Card</option>
                                            <option>Paypal</option>
                                            <option>Payoneer</option>
                                            <option>Stripe</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label>Add Amount :</label>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text">Amount</label>
                                            <select className="form-select" style={{ maxWidth: "90px" }}>
                                                <option defaultValue="BT">BTC</option>
                                                <option value="ET">ETH</option>
                                                <option value="LT">LTC</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder="0.00121255" />
                                        </div>

                                        <div className="input-group mb-3">
                                            <label className="input-group-text">Price</label>
                                            <input type="text" className="form-control" placeholder="$58,245" />
                                            <label className="input-group-text">$</label>
                                        </div>

                                        <div className="input-group mb-3">
                                            <label className="input-group-text">Total</label>
                                            <input type="text" className="form-control" placeholder="$36,854.25" />
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button type="button" className="btn btn-success w-md">Buy Coin</button>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="float-end ms-2">
                                    <h5 className="font-size-14"><i className="bx bx-wallet text-primary font-size-16 align-middle me-1"></i> <a href="#!" className="text-reset text-decoration-underline">$4235.23</a></h5>
                                </div>
                                <h5 className="font-size-14 mb-4">Sell Coins</h5>

                                <div>

                                    <div className="form-group mb-3">
                                        <label>Wallet ID :</label>
                                        <input type="email" className="form-control" placeholder="1cvb254ugxvfcd280ki" />
                                    </div>

                                    <div>
                                        <label>Add Amount :</label>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text">Amount</label>

                                            <select className="form-select" style={{ maxWidth: "90px" }}>
                                                <option defaultValue="BT">BTC</option>
                                                <option value="ET">ETH</option>
                                                <option value="LT">LTC</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder="0.00121255" />
                                        </div>

                                        <div className="input-group mb-3">

                                            <label className="input-group-text">Price</label>

                                            <input type="text" className="form-control" placeholder="$23,754.25" />

                                            <label className="input-group-text">$</label>
                                        </div>

                                        <div className="input-group mb-3">
                                            <label className="input-group-text">Total</label>
                                            <input type="text" className="form-control" placeholder="$6,852.41" />
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button type="button" className="btn btn-danger w-md">Sell Coin</button>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent> */}
              </CardBody>
         
           
          
          </Card>
        </Col>

        <Col xl={6}>
          <Transactions safetyTable={safetyTable} />
        </Col>
      </Row>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime}/>

    </React.Fragment>
  );
};

export default Trading;

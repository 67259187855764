import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import UserSessionController from "../../controller/UserSession/UserSessionController.js";
import {
  SC_USERNAME,
  SC_USER_EMAIL,
  SC_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_LAST_NAME,
  SC_USER_ID,
  SC_FCM_NOTIFICATION_COUNT,
  SC_FCM_VEHICLECRASHES_COUNT,
  SC_LOGIN_USER_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_MODULE_ID,
  SC_LOGIN_DIVISION_ID,
  SC_LOGIN_MODULE_ID,
  SC_USER_SESSION_ID,
  SC_EXPIRATION_TIME,
  SC_USER_LOGIN_TYPE,
  SC_FCM_NOTIFICATION_TOKEN,
  SC_LANGUAGES,
  SC_GROUP_ID,
  SC_COMPANY_NAME,
  SC_LOGIN_GROUP_ID,
  SC_DASHBOARD_TYPE,
  SC_LOCK_STATUS,
  SC_PASSWORD,
  SC_TOTAL_RIDE_COUNT,
  SC_SECRET_KEY,
  SC_DIVISION_NAME,
  SC_REGISTRATION_DATE,
} from "../../constant/StorageConstant.js";
import RuduLogo from "../../assets/images/rudu_main_logo.png";
import Loader from "../../components/Common/loader";
import MotivaiLogo from "../../assets/images/logo/motivAiLogo.svg";
import closeIcon from "../../assets/images/qa-popupImages/closeIcon.svg";
// import images

//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { GlobalConst } from "../../constant/GlobalConstant.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import Converter from "../../components/Common/EncodeDecode";
import img1 from "../../assets/images/qa-popupImages/qapopupImage.png";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "../../assets/css/general.css";
import SecretKeyController from "../../controller/SecretKey/secrectKeyController.js";
import dayjs from "dayjs";
import userSessionController from "../../controller/UserSession/UserSessionController.js";
import ValueFormat from "../../util/ValueFormat.js";
import { indexOf } from "lodash";
import { FaBullseye, FaCheck } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";


const Login = (props) => {
  const [showLoader, setShowLoader] = useState(0);
  const [divisionIds, setDivisionIds] = useState("");
  const [companyIds, setCompanyIds] = useState(
    localStorage.getItem(SC_COMPANY_ID)
  );
  const [moduleIds, setModuleIds] = useState("");
  const [userIds, setUserIds] = useState("");
  const [answer, setAnswer] = useState("");
  const [randomQuestion, setRandomQuestion] = useState(null);
  const [randomIndex, setRandomIndex] = useState(null);
  const [categorys, setCategorys] = useState("");
  const [groupCodes, setGroupCodes] = useState("");
  const history = useNavigate();
  const [xMobileNumber, setXMobileNumber] = useState("");
  const [xEmailAddress, setXEmailAddress] = useState("");

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShow1, setPasswordShow1] = useState(false);
  const [passwordShow2, setPasswordShow2] = useState(false);
  const [passwordShow3, setPasswordShow3] = useState(false);
  const [passwordShow4, setPasswordShow4] = useState(false);
  const [passwordShow5, setPasswordShow5] = useState(false);
  const [passwordShow6, setPasswordShow6] = useState(false);
  const [userPrimaryType, setUserPrimaryType] = useState("");
  const [primaryMobileNumberStatus, setPrimaryMobileNumberStatus] =
    useState("");
  const [primaryEmailAddressStatus, setprimaryEmailAddressStatus] =
    useState("");
  const [primarySequrityQuestionStatus, setPrimarySequrityQuestionStatus] =
    useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [newSendOTPButton, setNewSendOTPButton] = useState(false);
  const [newVerifyOTPButton, setNewVerifyOTPButton] = useState(false);
  const [newSaveButton, setNewSaveButton] = useState(false);
  const [newEmailOtpField, setNewEmailOtpField] = useState(false);
  const [newMobileOtpField, setNewMobileOtpField] = useState(false);
  const [setPwd, setSetPwd] = useState(false);
  const [showPassField, setShowPassField] = useState(false);
  const [mobileOtpExistingShow, setMobileOtpExistingShow] = useState(false);
  const [emailOtpExistingShow, setEmailOtpExistingShow] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [customOtp, setCustomOtp] = useState(false);
  const [newCustomQuestions, setNewCustomQuestions] = useState(false);
  const [newCustomSubmitButton, setNewCustomSubmitButton] = useState(false);
  const [newCustomSaveButton, setNewCustomSaveButton] = useState(false);
  const [existingCustomOtpSubmitButton, setExistingCustomOtpSubmitButton] =
    useState(false);
  const [existingCustomSubmitButton, setExistingCustomSubmitButton] =
    useState(false);
  const [existingCustomPassword, setExistingCustomPassword] = useState(false);
  const [existingCustomPasswordShow, setExistingCustomPasswordShow] =
    useState(false);
  const [existingCustomQA, setExistingCustomQA] = useState(false);

  const getQuesSecrectKey = useCallback(async (category, groupCode) => {
    setShowLoader((current) => current + 1);
    var result = await SecretKeyController.getSecrect();
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var data = resultJSON.data;
    let message = data.message;
    // console.log("encodeSecretKey: " , message)
    var base64Decode = atob(message);
    var resultJSONBase64 = JSON.parse(base64Decode);
    // console.log(JSON.stringify(base64Decode));
    // console.log("SECRET KEY: ",resultJSON);
    resultJSONBase64.map((value, i) => {
      localStorage.setItem(SC_SECRET_KEY, value.secretKey);
    });
    var getLocalStorage = localStorage.getItem(SC_SECRET_KEY);
    if (getLocalStorage != "" && getLocalStorage.length > 0) {
      if (category == "USER") {
        localStorage.setItem(SC_USER_LOGIN_TYPE, "USER");
        history("/dashboard");
      } else if (category == "ADMIN") {
        localStorage.setItem(SC_USER_LOGIN_TYPE, "ADMIN");
        history("/adminDashboard");
      } else if (category === "DEFAULT" || category === "SUPER_ADMIN") {
        localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN");
        history("/superAdminClientDashboard");
      } else if (groupCode != "DEFAULT") {
        localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN");
        history("/superAdminClientDashboard");
      }
    }
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(10, "Minimum 10 digits or characters required !")
        .max(40, "Maximum 40 Charecters Only")
        // .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric characters are allowed")
        .required("This field is required"),
      password: Yup.string()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
          "Invalid Password characters"
        )
        .required("Please Enter Your Password"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (
          data.username.length > 0 &&
          data.password.length > 0 &&
          data.username != "" &&
          data.password != ""
        ) {
          var encryptPassword = Converter.convertMd5(data.password);
          var values = { username: data.username, password: encryptPassword };
          var loginResult = await UserSessionController.createUserSession(
            values
          );
          setShowLoader((current) => current - 1);
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          console.log("login result:" + JSON.stringify(resultJSONData));
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            var category = "";
            var groupCode = "";
            var groupName = "";
            var divisionName = "";
            var divisionId = "";
            var companyId = "";
            var createdDate = "";
            let mfaPrimaryType = "";
            let mfaMobileNumber = "";
            let mfaEmailAddress = "";
            let mfaMobileNumberStatus = ";";
            let mfaEmailAddressStatus = "";
            let mfaSecurityQuestionStatus = "";
            try {
              category = resultJSONData.user.category;
              groupCode = resultJSONData.group.code;
              groupName = resultJSONData.group.name;
              divisionName = resultJSONData.division.name;
              divisionId = resultJSONData.division.id;
              companyId = resultJSONData.division.companyId;
              createdDate = resultJSONData.user.createdAt;
              mfaPrimaryType = resultJSONData.userSecret.mfaPrimaryType;
              mfaMobileNumber = resultJSONData.userSecret.mfaMobileNumber;
              mfaEmailAddress = resultJSONData.userSecret.mfaEmailAddress;
              mfaMobileNumberStatus =
                resultJSONData.userSecret.mfaMobileNumberStatus;
              mfaEmailAddressStatus =
                resultJSONData.userSecret.mfaEmailAddressStatus;
              mfaSecurityQuestionStatus =
                resultJSONData.userSecret.mfaSecurityQuestionStatus;

              // Extract only the date part
              createdDate = dayjs(createdDate).format("YYYY-MM-DD");

              localStorage.setItem(SC_COMPANY_NAME, groupName);
              // localStorage.setItem(SC_DIVISION_NAME, divisionName);
              localStorage.setItem(SC_REGISTRATION_DATE, createdDate);
              localStorage.setItem(SC_DIVISION_ID, divisionId);
              localStorage.setItem(SC_COMPANY_ID, companyId);
              localStorage.setItem(SC_DIVISION_NAME, divisionName);

              setDivisionIds(localStorage.getItem(SC_DIVISION_ID));
              setCompanyIds(localStorage.getItem(SC_COMPANY_ID));
              setModuleIds(localStorage.getItem(SC_MODULE_ID));
              setUserIds(localStorage.getItem(SC_USER_ID));
            } catch (err) {
              // console.log("Error : " + err);
            }
            // if (token != null && token != "" && token != undefined) {
            // getSecrectKey(category, groupCode)
            setCategorys(category);
            setGroupCodes(groupCode);
            setXMobileNumber(mfaMobileNumber);
            setXEmailAddress(mfaEmailAddress);
            setPrimaryMobileNumberStatus(mfaMobileNumberStatus);
            setprimaryEmailAddressStatus(mfaEmailAddressStatus);
            setPrimarySequrityQuestionStatus(mfaSecurityQuestionStatus);
            // }
            // else ToastService.errormsg("Please verify captcha");
            // getQuesSecrectKey(category, groupCode);
          } else {
            // console.log(resultTemp)
            ToastService.errormsg(
              resultTemp.error[0]
                ? resultTemp.error[0].message
                : resultTemp.error.message
            );
          }
        }
      } catch (e) {
        console.log("catch error:", e);
      }
    },
  });

  // username validation and api call to find the type of user by its id
  const userNameValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(10, "Minimum 10 digits or characters required !")
        .max(40, "Maximum 40 Charecters Only")
        .required("This field is required"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (data.username.length > 0 && data.username != "") {
          var userName = data.username;
          var loginResult = await UserSessionController.UserLoginButtonApi(
            userName
          );
          setShowLoader((current) => current - 1);
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          console.log("login result:" + JSON.stringify(resultJSONData));
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            let mfaPrimaryType = "";
            let mfaMobileNumberStatus = ";";
            let mfaEmailAddressStatus = "";
            let mfaSecurityQuestionStatus = "";

            let id = "";

            let userId = "";
            let type = "";
            let category = "";
            let usernameVerified = "";
            let status = "";

            try {
              mfaPrimaryType = resultJSONData.userSecret.mfaPrimaryType;
              mfaMobileNumberStatus =
                resultJSONData.userSecret.mfaMobileNumberStatus;
              mfaEmailAddressStatus =
                resultJSONData.userSecret.mfaEmailAddressStatus;
              mfaSecurityQuestionStatus =
                resultJSONData.userSecret.mfaSecurityQuestionStatus;

              id = resultJSONData.userSession.id;

              userId = resultJSONData.user.id;
              type = resultJSONData.user.type;
              category = resultJSONData.user.category;
              usernameVerified = resultJSONData.user.usernameVerified;
              status = resultJSONData.user.status;
            } catch (err) {
              console.log("Error : " + err);
            }
            localStorage.setItem(SC_USER_SESSION_ID, id);
            localStorage.setItem(SC_USER_ID, userId);

            setUserIds(localStorage.getItem(SC_USER_ID));
            setUserPrimaryType(mfaPrimaryType);
            setPrimaryMobileNumberStatus(mfaMobileNumberStatus);
            setprimaryEmailAddressStatus(mfaEmailAddressStatus);
            setPrimarySequrityQuestionStatus(mfaSecurityQuestionStatus);
            setCategorys(category);

            if (
              mfaPrimaryType === "SECURITY_QUESTIONS" &&
              mfaSecurityQuestionStatus === "ACTIVE"
            ) {
              setShowLoginButton(false);
              setExistingCustomOtpSubmitButton(true);
              setExistingCustomPassword(true);
            } else if (
              mfaPrimaryType === "MOBILE_NUMBER" &&
              mfaMobileNumberStatus === "ACTIVE"
            ) {
              setShowLoginButton(false);
              setShowPassField(true);
              setShowSubmitButton(true);
            } else if (
              mfaPrimaryType === "EMAIL_ADDRESS" &&
              mfaEmailAddressStatus === "ACTIVE"
            ) {
              setShowLoginButton(false);
              setShowPassField(true);
              setShowSubmitButton(true);
            } else if (
              mfaPrimaryType === "MOBILE_NUMBER" &&
              mfaMobileNumberStatus === "PENDING"
            ) {
              setShowLoginButton(false);
              setNewSendOTPButton(true);
            } else if (
              mfaPrimaryType === "EMAIL_ADDRESS" &&
              mfaEmailAddressStatus === "PENDING"
            ) {
              setShowLoginButton(false);
              setNewSendOTPButton(true);
            } else if (
              mfaPrimaryType === "SECURITY_QUESTIONS" &&
              mfaSecurityQuestionStatus === "PENDING"
            ) {
              setShowLoginButton(false);
              setCustomOtp(true);
              setNewCustomSubmitButton(true);
            } else if (mfaPrimaryType === "NONE") {
              setShowLoginButton(false);
            } else {console.log("No Condition Met"); }
          } else {
            console.log("failed", resultTemp);
          }
        }
      } catch (e) {
        console.log("catch error:", e);
      }
    },
  });

  const handleNewLoginButton = () => {
    userNameValidation.handleSubmit();
  };

  // send otp api for mobile and email
  const sendOtp = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.sendOtpMobileEmail(userIds);
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        if (primaryMobileNumberStatus === "PENDING") {
          setNewSendOTPButton(false);
          setNewMobileOtpField(true);
        } else if (primaryEmailAddressStatus === "PENDING") {
          setNewSendOTPButton(false);
          setNewEmailOtpField(true);
        } else if (primaryEmailAddressStatus === "ACTIVE") {
          setShowSubmitButton(false);
          setEmailOtpExistingShow(true);
          setShowVerifyButton(true);
        } else if (primaryMobileNumberStatus === "ACTIVE") {
          setShowSubmitButton(false);
          setMobileOtpExistingShow(true);
          setShowVerifyButton(true);
        }
        setNewVerifyOTPButton(true);
      } else {
      }
    } catch (e) {}
  };
  const handleNewSendOTPButton = () => {
    sendOtp();
  };

  // Active mobile and email otp
  const [existingMobileemailOtp, setExistingMobileemailOtp] = useState("");
  const [newOtpEmailMobile, setNewOtpEmailMobile] = useState("");

  const handleMobileEmailOtpChange = (event) => {
    setExistingMobileemailOtp(event.target.value);
  };

  const handleNewMobileEmailOtpChange = (event) => {
    setNewOtpEmailMobile(event.target.value);
  };

  const [checkMobEmPwd, setCheckMobEmPwd] = useState(false);

  // verify mobile and email otp for new(Existing) users
  const verifyOtpMobileEmail = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.VerifyOTP(
        userIds,
        existingMobileemailOtp
      );
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        if(primaryMobileNumberStatus === "ACTIVE"){
        getUserResponse();
        }else if (primaryEmailAddressStatus === "ACTIVE") {
          getUserResponse();
        }else if (primaryMobileNumberStatus === "PENDING"){
          setCheckMobEmPwd(true);
        }else if (primaryEmailAddressStatus === "PENDING"){
          setCheckMobEmPwd(true);
        }
      }
    } catch (e) {}
  };

  // verify mobile and email otp for new(ACTIVE) users
  const verifyNewOtpMobileEmail = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.VerifyOTP(
        userIds,
        newOtpEmailMobile
      );
      setShowLoader((current) => current - 1);
      console.log(result);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        setSetPwd(true);
        setNewVerifyOTPButton(false);
        setNewSaveButton(true);
      } else {
        console.log("error in verifying otp");
      }
    } catch (e) {}
  };
  const handleNewVerifyOTPButton = () => {
    verifyNewOtpMobileEmail();
  };

  // old password for email and mobile which is provided by admin and changing to new password which is setted by user
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const changePWDpatch = async () => {
    try {
      var result = await userSessionController.newUserLoginChangePasswordPatch(
        userIds,
        Converter.convertMd5(newPasswordValidation.values.confirmNewPassword),
        Converter.convertMd5(newPasswordValidation.values.customPassword),
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        getUserResponse();
      }else{

      }
    } catch (e) {}
  };

  // old password for security questions which is provided by admin and changing to new password which is setted by user
  const [newPasswordSQ, setNewPasswordSQ] = useState("");
  const [oldPasswordSQ, setOldPasswordSQ] = useState("");

  const changePWDpatchSQ = async () => {
    try {
      var result = await userSessionController.newUserLoginChangePasswordPatch(
        userIds,
        // Converter.convertMd5(newPasswordSQ),
        // Converter.convertMd5(oldPasswordSQ)
        Converter.convertMd5(newPasswordValidationSecurityQuestions.values.confirmNewPassword),
        Converter.convertMd5(ExistingCustomPasswordValidation.values.existingCustomPassword),
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        setNewCustomSubmitButton(false);
        setNewCustomQuestions(true);
        setNewCustomSaveButton(true);
        toggle();
        console.log("Password changed successfully!");
      } else {
        console.log("Sq failue");
      }
    } catch (e) {}
  };

  // mobile email new password setup
  const newPasswordValidation = useFormik({
    initialValues: {
      customPassword:"",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      customPassword: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
      "Invalid Password characters"
    )
    .required("Please Enter Your Password"),
      newPassword: Yup.string()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
          "Invalid Password characters"
        )
        .required("Please Enter Your New Password"),

      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password do not match")
        .required("Please confirm your new password"),
    }),
    onSubmit: () => {
      changePWDpatch();
    },
  });

  const onSubmitHandler = () => {
    newPasswordValidation.handleSubmit();
  };

  // sequrity questions new password setup
  const newPasswordValidationSecurityQuestions = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
          "Invalid Password characters"
        )
        .required("Please Enter Your New Password"),

      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password do not match")
        .required("Please confirm your new password"),
    }),
    onSubmit: () => {
     changePWDpatchSQ();
    },
  });

  const onSubmitHandlerSequrityQuestions = () => {
    newPasswordValidationSecurityQuestions.handleSubmit();
  };

  // mobile email existing password check
  const ExistingMobEmailPasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      existingMobEmailPassword: "",
    },
    validationSchema: Yup.object({
      existingMobEmailPassword: Yup.string()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
          "Invalid Password characters"
        )
        .required("Please Enter Your Password"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (
          data.existingMobEmailPassword.length > 0 &&
          data.existingMobEmailPassword != ""
        ) {
          var encryptPassword = Converter.convertMd5(
            data.existingMobEmailPassword
          );
          var loginResult =
            await UserSessionController.existingPasswordCheckPatch(
              userIds,
              encryptPassword
            );
          setShowLoader((current) => current - 1);
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            if (primaryMobileNumberStatus === "ACTIVE") {
              sendOtp();
            } else if (primaryEmailAddressStatus === "ACTIVE") {
              sendOtp();
            } else if (userPrimaryType === "NONE") {
              getUserResponse();
            } else {
              sendOtp();
              setShowSubmitButton(false);
              setNewSaveButton(true);
              setCheckMobEmPwd(false);
            }
          } else {
            console.log("failed", resultTemp);
          }
        }
      } catch (e) {
        console.log("catch error:", e);
      }
    },
  });

  const handleSubmitButtonClick = () => {
    ExistingMobEmailPasswordValidation.handleSubmit();
  };

  const [setCustomNewPassword, setSetCustomNewPassword] = useState(false);

  // sequrity questions existing password check
  const ExistingCustomPasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      existingCustomPassword: "",
    },
    validationSchema: Yup.object({
      existingCustomPassword: Yup.string()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
          "Invalid Password characters"
        )
        .required("Please Enter Your Password"),
    }),
    onSubmit: async (data) => {
      setShowLoader((current) => current + 1);
      try {
        if (
          data.existingCustomPassword.length > 0 &&
          data.existingCustomPassword != ""
        ) {
          var encryptPassword = Converter.convertMd5(
            data.existingCustomPassword
          );
          var loginResult =
            await UserSessionController.existingPasswordCheckPatch(
              userIds,
              encryptPassword
            );
          setShowLoader((current) => current - 1);
          var resultTemp = JSON.parse(loginResult);
          var resultStatus = resultTemp.status;
          var resultJSONData = resultTemp.data;
          if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
            setExistingCustomOtpSubmitButton(false);
            setExistingCustomSubmitButton(true);
            setExistingCustomQA(true);
            setSetCustomNewPassword(true);
            setNewCustomSubmitButton(false);
          } else {
            console.log("failed", resultTemp);
          }
        }
      } catch (e) {
        console.log("catch error:", e);
      }
    },
  });

  const handleExistingCustomOtpSubmitButton = () => {
    ExistingCustomPasswordValidation.handleSubmit();
  };

  // Sequrity Questions get change verify Flow
  const [getSecurityQuestion, setGetSecurityQuestion] = useState([]);
  const handleSecurityAnswerChange = (index, answer) => {
    const updatedAnswers = [...getSecurityQuestion];
    updatedAnswers[index].answer = answer;
    setGetSecurityQuestion(updatedAnswers);
  };

  useEffect(() => {
    const getQuesAns = async () => {
      try {
        var result = await userSessionController.questionAnsPopup();
        var resultJSON = JSON.parse(result);
        var status = resultJSON.status;
        if (status === "SUCCESS") {
          const resultJSONData = resultJSON.data;
          var questionsArray = [];
          let securityQQCA = [];
          resultJSONData.forEach((value) => {
            var questions = ValueFormat.parseAsArray(value.securityQuestions);
            questions.forEach((data) => {
              var question = ValueFormat.parseAsString(data.question);
              questionsArray.push(question);
              securityQQCA.push({
                questionCode: data.questionCode,
                question: data.question,
                answer: data.answer,
              });
            });
          });
          const randomIndex = Math.floor(Math.random() * questionsArray.length);
          const randomQuestion = questionsArray[randomIndex];
          setRandomQuestion(randomQuestion);
          setRandomIndex(randomIndex + 1);
          setGetSecurityQuestion(securityQQCA);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getQuesAns();
  }, [userIds]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const changeAnswer = async () => {
    try {
      var result = await userSessionController.SaveAnswerForSQpatch(
        userIds,
        getSecurityQuestion
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        getUserResponse();
      } else if (status === "FAILED") {
        const errorMessages = resultJSON.error.map((error) => error.message);
        const formattedErrorMessage = errorMessages.join(", ");
        ToastService.errormsg(formattedErrorMessage);
      }
    } catch (e) {
      console.log("Catch error: " + e);
    }
  };

  const getQuestionsSecrectKey = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await userSessionController.QndAVerifyOTP1(
        userIds,
        randomIndex,
        answer
      );
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status === "SUCCESS") {
        getUserResponse();
      }
    } catch (e) {}
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (!answer) {
      ToastService.errormsg("Field cannot be empty");
      return;
    }
    getQuestionsSecrectKey();
  };

  const getUserResponse = async () => {
    setShowLoader((current) => current + 1);
    var result = await userSessionController.getResponseToMoveDashboard(
      userIds,
    );
    try {
      var resultTemp = JSON.parse(result);
      var resultStatus = resultTemp.status;
      var resultJSONData = resultTemp.data;
      console.log("resultJSONDataresultJSONDataresultJSONData",resultJSONData)
      var userSecret = resultJSONData.userSecret;
      var secretKeyEncoded = userSecret.message;
      var decodedsecertKey = atob(secretKeyEncoded);
      localStorage.setItem(SC_SECRET_KEY, decodedsecertKey);
      var getLocalStorage = localStorage.getItem(SC_SECRET_KEY);
      var category = resultJSONData.user.category;
      var groupCode = resultJSONData.group.code;
      var userFirstName = "",
        userLastName = "",
        loginUserFirstName = "",
        fcmCount = "0",
        vehicleCrash = "0";
      var companyId = "",
        divisionId = "",
        moduleId = "",
        groupId = "",
        companyName = "",
        languages = "English",
        dashboardType = "",
        rideCount = "",
        email = "",
        name = "";

      userFirstName = resultJSONData.user.firstName;
      userLastName = resultJSONData.user.lastName;
      companyId = resultJSONData.user.companyId;
      divisionId = resultJSONData.user.divisionId;
      moduleId = resultJSONData.user.moduleId;
      groupId = resultJSONData.user.groupId;
      email = resultJSONData.user.email;
      name = resultJSONData.group.name;

      if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
        var currentDateTime = new Date();
        currentDateTime.setHours(currentDateTime.getHours() + 24);
        localStorage.setItem(SC_EXPIRATION_TIME, currentDateTime.getTime());
        localStorage.setItem(SC_USERNAME, userNameValidation.values.username);
        localStorage.setItem(SC_NAME, name);
        localStorage.setItem(SC_USER_EMAIL, email);
        localStorage.setItem(SC_USER_FIRST_NAME, userFirstName);
        localStorage.setItem(SC_USER_LAST_NAME, userLastName);
        localStorage.setItem(SC_USER_ID, userIds);
        localStorage.setItem(SC_FCM_NOTIFICATION_COUNT, fcmCount);
        localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT, vehicleCrash );
        localStorage.setItem(SC_LOGIN_USER_ID, userIds);
        localStorage.setItem(SC_LOGIN_USER_FIRST_NAME, loginUserFirstName);
        localStorage.setItem(SC_COMPANY_ID, companyId);
        localStorage.setItem(SC_DIVISION_ID, divisionId);
        localStorage.setItem(SC_MODULE_ID, moduleId);
        localStorage.setItem(SC_GROUP_ID, groupId);
        localStorage.setItem(SC_COMPANY_NAME, companyName);
        localStorage.setItem(SC_LOGIN_DIVISION_ID, divisionId);
        localStorage.setItem(SC_LOGIN_MODULE_ID, moduleId);
        localStorage.setItem(SC_LOGIN_GROUP_ID, groupId);
        localStorage.setItem(SC_LANGUAGES, languages);
        localStorage.setItem(SC_DASHBOARD_TYPE, dashboardType);
        localStorage.setItem(SC_TOTAL_RIDE_COUNT, rideCount);
        try {
          if (getLocalStorage != "" && getLocalStorage.length > 0) {
            if (category == "USER") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "USER");
              history("/dashboard");
            } else if (category == "ADMIN") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "ADMIN");
              history("/adminDashboard");
            } else if (category === "DEFAULT" || category === "SUPER_ADMIN") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN");
              history("/superAdminClientDashboard");
            } else if (groupCode != "DEFAULT") {
              localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN");
              history("/superAdminClientDashboard");
            }
          }
        } catch (err) {
          // console.log("Error : " + err);
        }
        setExistingMobileemailOtp("");
      } else {
        console.log("else error");
      }
    } catch (e) {
      console.log("catch error:", e);
    }finally{
      setShowLoader((current) => current + 1);
    }
  };

  document.title = "Login | MotivAI ";
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <ToastContainer style={{ width: "auto" }} />
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex  p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2 mb-md-2 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={MotivaiLogo} alt="" height="40" />
                      </Link>

                      {/* <a href={`/responsiveridelist?username="9342987561"`}>Login</a> */}
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">
                          Log in to proceed with Motiv AI.
                        </p>
                      </div>

                      <Form className="custom-form mt-4 pt-2">
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        {/* User name  input */}

                        {showLoginForm && (
                          <>
                          <div className="mb-3">
                            <Label className="form-label">Username</Label>

                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter username"
                              type="tel"
                              id="username"
                              // size="sm"
                              onChange={userNameValidation.handleChange}
                              onBlur={userNameValidation.handleBlur}
                              value={userNameValidation.values.username}
                              invalid={
                                userNameValidation.touched.username &&
                                userNameValidation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {userNameValidation.touched.username &&
                            userNameValidation.errors.username ? (
                              <FormFeedback type="invalid">
                                {userNameValidation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember-check"
                          >
                            Remember me
                          </label>
                        </div>
                        </>
                        )}

                        {/* mfaPrimaryType is MOBILE_NUMBER or EMAIL_ADDRESS and their status is ACTIVE means this screen */}

                        {((userPrimaryType === "MOBILE_NUMBER" &&
                          primaryMobileNumberStatus === "ACTIVE") ||
                          (userPrimaryType === "EMAIL_ADDRESS" &&
                            primaryEmailAddressStatus === "ACTIVE")) && (
                          <>
                            {showPassField && (
                              <div className="mb-3">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1">
                                    <Label className="form-label">
                                      Password
                                    </Label>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="">
                                      <Link
                                        to="/page-recoverpw"
                                        className="text-muted"
                                      >
                                        Forgot password?
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="existingMobEmailPassword"
                                    value={
                                      ExistingMobEmailPasswordValidation.values
                                        .existingMobEmailPassword
                                    }
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="Enter Password"
                                    onChange={
                                      ExistingMobEmailPasswordValidation.handleChange
                                    }
                                    onBlur={
                                      ExistingMobEmailPasswordValidation.handleBlur
                                    }
                                    invalid={
                                      ExistingMobEmailPasswordValidation.touched
                                        .existingMobEmailPassword &&
                                      ExistingMobEmailPasswordValidation.errors
                                        .existingMobEmailPassword
                                        ? true
                                        : false
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                    className="btn btn-light shadow-none ms-0"
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="mdi mdi-eye-outline"></i>
                                  </button>
                                  {ExistingMobEmailPasswordValidation.touched
                                    .existingMobEmailPassword &&
                                  ExistingMobEmailPasswordValidation.errors
                                    .existingMobEmailPassword ? (
                                    <FormFeedback type="invalid">
                                      {
                                        ExistingMobEmailPasswordValidation
                                          .errors.existingMobEmailPassword
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            )}

                            {primaryMobileNumberStatus === "ACTIVE" &&
                              mobileOtpExistingShow && (
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Mobile OTP
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="existingMobileemailOtp"
                                      placeholder="Enter Mobile OTP"
                                      value={existingMobileemailOtp}
                                      onChange={handleMobileEmailOtpChange}
                                    />
                                  </div>
                                  <Button
                                    className="p-0"
                                    color="link"
                                    onClick={sendOtp}
                                  >
                                    Resend OTP
                                  </Button>
                                </div>
                              )}

                            {primaryEmailAddressStatus === "ACTIVE" &&
                              emailOtpExistingShow && (
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Email OTP
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="existingMobileemailOtp"
                                      placeholder="Enter Email OTP"
                                      value={existingMobileemailOtp}
                                      onChange={handleMobileEmailOtpChange}
                                    />
                                  </div>
                                  <Button
                                    className="p-0"
                                    color="link"
                                    onClick={sendOtp}
                                  >
                                    Resend OTP
                                  </Button>
                                </div>
                              )}

                            {/*
                            <ReCAPTCHA className="g-recaptcha"
                            sitekey="6LeKOgMoAAAAAJ0M4ZGweGsFtXZsfIStnFo3QyYF"
                            name="reCaptcha"
                            onChange={(value) => {
                            if (value != "" && value != null) setVerifyRecaptcha(true);
                            else setVerifyRecaptcha(false);
                            }}
                            /> */}
                            {/* <HCaptcha
                          size="small"
                          sitekey="e66343c2-aa9b-42a6-93df-00554796f170"
                          onVerify={setToken}
                        /> */}
                            {/*
                        <GoogleReCaptchaProvider reCaptchaKey="6LeVbAgpAAAAAEyOkJfPMbHUpcblX07Mq_CVc8x1" useRecaptchaNet={true} useEnterprise={true}>
                        <GoogleReCaptcha onVerify={captchaValue1} />
                        </GoogleReCaptchaProvider> */}
                            {/* <Reaptcha sitekey="6LeVbAgpAAAAAEyOkJfPMbHUpcblX07Mq_CVc8x1" onLoad={captchaValue} onVerify={captchaValue1 } /> */}

                            {/* <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember-check"
                          >
                            Remember me
                          </label>
                        </div> */}

                            {showSubmitButton && (
                              <>
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={handleSubmitButtonClick}
                                  >
                                    Submit & Send OTP
                                  </button>
                                </div>
                              </>
                            )}

                            {showVerifyButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={verifyOtpMobileEmail}
                                >
                                  Verify
                                </button>
                              </div>
                            )}
                          </>
                        )}

                        {/* mfaPrimaryType is MOBILE_NUMBER or EMAIL_ADDRESS and their status is PENDING means this screen */}

                        {((userPrimaryType === "MOBILE_NUMBER" &&
                          primaryMobileNumberStatus === "PENDING") ||
                          (userPrimaryType === "EMAIL_ADDRESS" &&
                            primaryEmailAddressStatus === "PENDING")) && (
                          <>
                            {primaryMobileNumberStatus === "PENDING" &&
                              newMobileOtpField && (
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Mobile OTP
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="newMobileOtp"
                                      placeholder="Enter mobile OTP"
                                      value={newOtpEmailMobile}
                                      onChange={handleNewMobileEmailOtpChange}
                                    />
                                  </div>
                                  <Button
                                    className="p-0"
                                    color="link"
                                    onClick={sendOtp}
                                  >
                                    Resend OTP
                                  </Button>
                                </div>
                              )}

                            {primaryEmailAddressStatus === "PENDING" &&
                              newEmailOtpField && (
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Email OTP
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="newEmailOtp"
                                      placeholder="Enter Email OTP"
                                      value={newOtpEmailMobile}
                                      onChange={handleNewMobileEmailOtpChange}
                                    />
                                  </div>
                                  <Button
                                    className="p-0"
                                    color="link"
                                    onClick={sendOtp}
                                  >
                                    Resend OTP
                                  </Button>
                                </div>
                              )}

                            {setPwd && (
                              <>
                               <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Current Password
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="customPassword"
                                        type={
                                          passwordShow1 ? "text" : "password"
                                        }
                                        placeholder="Enter Current Password"
                                        onChange={
                                          newPasswordValidation.handleChange
                                        }
                                        onBlur={
                                          newPasswordValidation.handleBlur
                                        }
                                        value={
                                          newPasswordValidation
                                            .values.customPassword
                                        }
                                        invalid={
                                          newPasswordValidation
                                            .touched.customPassword &&
                                          newPasswordValidation
                                            .errors.customPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow1(!passwordShow1)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        <i className="mdi mdi-eye-outline"></i>
                                      </button>
                                      {newPasswordValidation
                                        .touched.customPassword &&
                                      newPasswordValidation
                                        .errors.customPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            newPasswordValidation
                                              .errors.customPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                    
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Set New Password
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="newPassword"
                                      type={passwordShow4 ? "text" : "password"}
                                      placeholder="Enter New Password"
                                      onChange={
                                        newPasswordValidation.handleChange
                                      }
                                      onBlur={newPasswordValidation.handleBlur}
                                      value={
                                        newPasswordValidation.values.newPassword
                                      }
                                      invalid={
                                        newPasswordValidation.touched
                                          .newPassword &&
                                        newPasswordValidation.errors.newPassword
                                          ? true
                                          : false
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setPasswordShow4(!passwordShow4)
                                      }
                                      className="btn btn-light shadow-none ms-0"
                                      type="button"
                                      id="password-addon"
                                    >
                                      <i className="mdi mdi-eye-outline"></i>
                                    </button>
                                    {newPasswordValidation.touched
                                      .newPassword &&
                                    newPasswordValidation.errors.newPassword ? (
                                      <FormFeedback type="invalid">
                                        {
                                          newPasswordValidation.errors
                                            .newPassword
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Confirm New Password
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="confirmNewPassword"
                                      type={passwordShow5 ? "text" : "password"}
                                      placeholder="Re-enter New Password"
                                      onChange={
                                        newPasswordValidation.handleChange
                                      }
                                      onBlur={newPasswordValidation.handleBlur}
                                      value={
                                        newPasswordValidation.values
                                          .confirmNewPassword
                                      }
                                      invalid={
                                        newPasswordValidation.touched
                                          .confirmNewPassword &&
                                        newPasswordValidation.errors
                                          .confirmNewPassword
                                          ? true
                                          : false
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setPasswordShow5(!passwordShow5)
                                      }
                                      className="btn btn-light shadow-none ms-0"
                                      type="button"
                                      id="password-addon"
                                    >
                                      <i className="mdi mdi-eye-outline"></i>
                                    </button>
                                    {newPasswordValidation.touched
                                      .confirmNewPassword &&
                                    newPasswordValidation.errors
                                      .confirmNewPassword ? (
                                      <FormFeedback type="invalid">
                                        {
                                          newPasswordValidation.errors
                                            .confirmNewPassword
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                              )}

                            {/* {newLoginButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={handleNewLoginButton}
                                >
                                  Log In
                                </button>
                              </div>
                            )} */}

                            {newSendOTPButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={handleNewSendOTPButton}
                                >
                                  Send OTP
                                </button>
                              </div>
                            )}

                            {newVerifyOTPButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={handleNewVerifyOTPButton}
                                >
                                  Verify
                                </button>
                              </div>
                            )}

                            {newSaveButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={onSubmitHandler}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </>
                        )}

                        {/* mfaPrimaryType is SECURITY_QUESTIONS  and its status is PENDING means this screen */}

                        {userPrimaryType === "SECURITY_QUESTIONS" &&
                          primarySequrityQuestionStatus === "PENDING" && (
                            <div
                              style={{ overflowY: "auto", maxHeight: "400px" }}
                            >
                              {customOtp && (
                                <>
                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Current Password
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="existingCustomPassword"
                                        type={
                                          passwordShow6 ? "text" : "password"
                                        }
                                        placeholder="Enter Current Password"
                                        onChange={
                                          ExistingCustomPasswordValidation.handleChange
                                        }
                                        onBlur={
                                          ExistingCustomPasswordValidation.handleBlur
                                        }
                                        value={
                                          ExistingCustomPasswordValidation
                                            .values.existingCustomPassword
                                        }
                                        invalid={
                                          ExistingCustomPasswordValidation
                                            .touched.existingCustomPassword &&
                                          ExistingCustomPasswordValidation
                                            .errors.existingCustomPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow6(!passwordShow6)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        <i className="mdi mdi-eye-outline"></i>
                                      </button>
                                      {ExistingCustomPasswordValidation.touched
                                        .existingCustomPassword &&
                                      ExistingCustomPasswordValidation.errors
                                        .existingCustomPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            ExistingCustomPasswordValidation
                                              .errors.existingCustomPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              )}

                              {setCustomNewPassword && (
                                <>
                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Set New Password
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="newPassword"
                                        type={
                                          passwordShow2 ? "text" : "password"
                                        }
                                        placeholder="Enter New Password"
                                        onChange={
                                          newPasswordValidationSecurityQuestions.handleChange
                                        }
                                        onBlur={
                                          newPasswordValidationSecurityQuestions.handleBlur
                                        }
                                        value={
                                          newPasswordValidationSecurityQuestions
                                            .values.newPassword
                                        }
                                        invalid={
                                          newPasswordValidationSecurityQuestions
                                            .touched.newPassword &&
                                          newPasswordValidationSecurityQuestions
                                            .errors.newPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow2(!passwordShow2)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        <i className="mdi mdi-eye-outline"></i>
                                      </button>
                                      {newPasswordValidationSecurityQuestions
                                        .touched.newPassword &&
                                      newPasswordValidationSecurityQuestions
                                        .errors.newPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            newPasswordValidationSecurityQuestions
                                              .errors.newPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1">
                                        <Label className="form-label">
                                          Confirm New Password
                                        </Label>
                                      </div>
                                    </div>
                                    <div className="input-group auth-pass-inputgroup">
                                      <Input
                                        name="confirmNewPassword"
                                        type={
                                          passwordShow3 ? "text" : "password"
                                        }
                                        placeholder="Re-enter New Password"
                                        onChange={
                                          newPasswordValidationSecurityQuestions.handleChange
                                        }
                                        onBlur={
                                          newPasswordValidationSecurityQuestions.handleBlur
                                        }
                                        value={
                                          newPasswordValidationSecurityQuestions
                                            .values.confirmNewPassword
                                        }
                                        invalid={
                                          newPasswordValidationSecurityQuestions
                                            .touched.confirmNewPassword &&
                                          newPasswordValidationSecurityQuestions
                                            .errors.confirmNewPassword
                                            ? true
                                            : false
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          setPasswordShow3(!passwordShow3)
                                        }
                                        className="btn btn-light shadow-none ms-0"
                                        type="button"
                                        id="password-addon"
                                      >
                                        <i className="mdi mdi-eye-outline"></i>
                                      </button>
                                      {newPasswordValidationSecurityQuestions
                                        .touched.confirmNewPassword &&
                                      newPasswordValidationSecurityQuestions
                                        .errors.confirmNewPassword ? (
                                        <FormFeedback type="invalid">
                                          {
                                            newPasswordValidationSecurityQuestions
                                              .errors.confirmNewPassword
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              )}

                              {newCustomQuestions && (
                                <>
                                  {/* {getSecurityQuestion.map(
                                    (question, index) => (
                                      <FormGroup key={index}>
                                        <Label
                                          for={`securityQuestion${index + 1}`}
                                        >
                                          {question.question}
                                        </Label>
                                        <Input
                                          type="text"
                                          name={`securityQuestion${index + 1}`}
                                          id={`securityQuestion${index + 1}`}
                                          placeholder={`Enter your answer for question ${
                                            index + 1
                                          }`}
                                          value={question.answer || ""}
                                          onChange={(e) =>
                                            handleSecurityAnswerChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                    )
                                  )} */}
                                  <Modal isOpen={modal} toggle={toggle} backdrop="static" centered>
                                    <ModalHeader
                                      // toggle={toggle}
                                      className="border-0"
                                    >
                                      Security Questions
                                    </ModalHeader>
                                    <ModalBody>
                                      {getSecurityQuestion.map(
                                        (question, index) => (
                                          <FormGroup key={index}>
                                            <Label
                                              for={`securityQuestion${
                                                index + 1
                                              }`}
                                            >
                                              {question.question}
                                            </Label>
                                            <Input
                                              type="text"
                                              name={`securityQuestion${
                                                index + 1
                                              }`}
                                              id={`securityQuestion${
                                                index + 1
                                              }`}
                                              placeholder={`Enter your answer for question ${
                                                index + 1
                                              }`}
                                              value={question.answer || ""}
                                              onChange={(e) =>
                                                handleSecurityAnswerChange(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </FormGroup>
                                        )
                                      )}
                                    </ModalBody>
                                    <ModalFooter className="border-0">
                                      {newCustomSaveButton && (
                                        <div className="mt-3 mb-2 d-grid">
                                          <button
                                            className="btn btn-primary btn-block"
                                            type="button"
                                            onClick={changeAnswer}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </ModalFooter>
                                  </Modal>
                                </>
                              )}

                              {/* {newCustomLoginButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={handleNewCustomLoginButton}
                                >
                                  Log In
                                </button>
                              </div>
                            )} */}
                              {newCustomSubmitButton && (
                                <div className="mt-3 mb-2 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={
                                      handleExistingCustomOtpSubmitButton
                                    }
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                              {setCustomNewPassword && (
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={onSubmitHandlerSequrityQuestions}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                              {/* {newCustomSaveButton && (
                                <div className="mt-3 mb-2 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={changeAnswer}
                                  >
                                    Save
                                  </button>
                                </div>
                              )} */}
                            </div>
                          )}

                        {/* mfaPrimaryType is SECURITY_QUESTIONS  and its status is ACTIVE means this screen */}

                        {userPrimaryType === "SECURITY_QUESTIONS" &&
                          primarySequrityQuestionStatus === "ACTIVE" && (
                            <>
                              {existingCustomPassword && (
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <Label className="form-label">
                                        Enter Password
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="existingCustomPassword"
                                      type={
                                        existingCustomPasswordShow
                                          ? "text"
                                          : "password"
                                      }
                                      placeholder="Enter Password"
                                      onChange={
                                        ExistingCustomPasswordValidation.handleChange
                                      }
                                      onBlur={
                                        ExistingCustomPasswordValidation.handleBlur
                                      }
                                      value={
                                        ExistingCustomPasswordValidation.values
                                          .existingCustomPassword
                                      }
                                      invalid={
                                        ExistingCustomPasswordValidation.touched
                                          .existingCustomPassword &&
                                        ExistingCustomPasswordValidation.errors
                                          .existingCustomPassword
                                          ? true
                                          : false
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setExistingCustomPasswordShow(
                                          !existingCustomPasswordShow
                                        )
                                      }
                                      className="btn btn-light shadow-none ms-0"
                                      type="button"
                                      id="password-addon"
                                    >
                                      <i className="mdi mdi-eye-outline"></i>
                                    </button>
                                    {ExistingCustomPasswordValidation.touched
                                      .existingCustomPassword &&
                                    ExistingCustomPasswordValidation.errors
                                      .existingCustomPassword ? (
                                      <FormFeedback type="invalid">
                                        {
                                          ExistingCustomPasswordValidation
                                            .errors.existingCustomPassword
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              )}

                              {existingCustomQA && (
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {randomQuestion}
                                  </Label>
                                  <Input
                                    placeholder="Enter Your Answer"
                                    className="form-control"
                                    onChange={(e) => setAnswer(e.target.value)}
                                  />
                                </div>
                              )}

                              {/* {existingCustomLoginButton && (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={handleExistingCustomLoginButton}
                                >
                                  Log In
                                </button>
                              </div>
                            )} */}

                              {existingCustomOtpSubmitButton && (
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={
                                      handleExistingCustomOtpSubmitButton
                                    }
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}

                              {existingCustomSubmitButton && (
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={handleModalSubmit}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                            </>
                          )}

                        {/* mfaPrimaryType is NONE means this screen */}
                        {userPrimaryType === "NONE" && (
                          <>
                            <div className="mb-3">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <Label className="form-label">Password</Label>
                                </div>
                                <div className="flex-shrink-0">
                                  <div className="">
                                    <Link
                                      to="/page-recoverpw"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="existingMobEmailPassword"
                                  value={
                                    ExistingMobEmailPasswordValidation.values
                                      .existingMobEmailPassword
                                  }
                                  type={passwordShow ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={
                                    ExistingMobEmailPasswordValidation.handleChange
                                  }
                                  onBlur={
                                    ExistingMobEmailPasswordValidation.handleBlur
                                  }
                                  // onBlur={ExistingMobEmailPasswordValidation.handleBlur}
                                  invalid={
                                    ExistingMobEmailPasswordValidation.touched
                                      .existingMobEmailPassword &&
                                    ExistingMobEmailPasswordValidation.errors
                                      .existingMobEmailPassword
                                      ? true
                                      : false
                                  }
                                />
                                <button
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="btn btn-light shadow-none ms-0"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                                {ExistingMobEmailPasswordValidation.touched
                                  .existingMobEmailPassword &&
                                ExistingMobEmailPasswordValidation.errors
                                  .existingMobEmailPassword ? (
                                  <FormFeedback type="invalid">
                                    {
                                      ExistingMobEmailPasswordValidation.errors
                                        .existingMobEmailPassword
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={handleSubmitButtonClick}
                              >
                                Log In
                              </button>
                            </div>
                          </>
                        )}

                        {/* Login button which is used to check the user type */}
                        {showLoginButton && (
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={handleNewLoginButton}
                            >
                              Log In
                            </button>
                          </div>
                        )}
                      </Form>

                      <>
                        <div className="mt-3 mb-3 text-center">
                          <p className="text-muted mb-0">
                            Don't have an account ?
                            <Link
                              to="/register"
                              className="text-primary fw-semibold"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div>
                        <div
                          className="mb-5"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "90%",
                            position: "absolute",
                            bottom: 35,
                          }}
                        >
                          <a
                            href="https://www.motivai.tech/terms_and_conditions"
                            className="fw-semibold"
                            style={{ marginRight: "2px" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions
                          </a>
                        </div>
                        <div
                          className="mb-5"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "90%",
                            position: "absolute",
                            bottom: 10,
                          }}
                        >
                          <a
                            href="https://www.motivai.tech/privacy_policy"
                            className="fw-semibold"
                            style={{ marginRight: "2px" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy |
                          </a>
                          <a
                            href="https://www.motivai.tech/cookies_policy"
                            className="fw-semibold"
                            style={{ marginRight: "2px" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Cookie Policy |
                          </a>
                          <a
                            href="https://www.motivai.tech/faq"
                            className="fw-semibold"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            FAQ
                          </a>
                        </div>
                      </>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        {new Date().getFullYear()} © Motiv AI. All rights
                        reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

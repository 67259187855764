import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { UDMainDashboardTopRowItems } from "../../../common/data/dashboard";
import { widgetUsersData } from "../../../common/data/dashboarduserdata";
import UserDrivingScoreController from "../../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../../util/ValueFormat.js";
import { useSelector } from "react-redux";
import { layoutTypes } from "../../../constants/layout";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
} from "../../../constant/StorageConstant";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../components/Common/loader";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import OverAllDrivingScore from "../overAllDrivingScore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserDashboardSubMenu.css";
import UserDashboardSubMenu from "./UserDashboardSubMenu.js";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import UserAdminDashboardSubMenu from "./UserAdminDashboardSubMenu.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import UserDashboardColorIndicators from "./UserDashboardColorIndicators.js";
import UserRidesKmHrsScoreCBar from "./UserRidesKmHrsScoreCBar.js";
import ProgressBarUD from "./progressBar.js";
import DriverBehaviourRatingForUser from "./DriverBehaviorRating.js";
import MarketOverview from "../MarketOverview.js";
import Locations from "../Locations";
import CardImg1 from "../../../assets/images/t_animal_crossing.png";
import Caution from "../../../assets/images/t_caution.png";
import Curve from "../../../assets/images/t_curves.png";
import Round from "../../../assets/images/Round-about.svg";
import Hillimg from "../../../assets/images/t_hill.png";
import HillDownWards from "../../../assets/images/t_hill_downwards.png";
import hillUp from "../../../assets/images/t_hill_upwards.png";
import icyConditions from "../../../assets/images/t_icy_conditions.png";
import Intersection from "../../../assets/images/t_intersection.png";
import LaneMerge from "../../../assets/images/t_lane_merge.png";
import LowGearArea from "../../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../../assets/images/t_narrow_road.png";
import NoOverTaking from "../../../assets/images/t_no_overtaking.png";
import PedestrianCrossing from "../../../assets/images/t_pedestrian_crossing.png";
import Priority from "../../../assets/images/t_priority.png";
import RailwayCrossing from "../../../assets/images/t_railway_crossing.png";
import RiskOfGrounding from "../../../assets/images/t_risk_of_grouping.png";
import School from "../../../assets/images/t_school_zone.png";
import SlipperyRoads from "../../../assets/images/t_slippery_road.png";
import Stopimg from "../../../assets/images/t_stop.png";
import TrafficLight from "../../../assets/images/t_traffic_light.png";
import WindImg from "../../../assets/images/t_wind.png";
import WindingRoad from "../../../assets/images/t_winding_road.png";
import YieldImg from "../../../assets/images/t_yield.png";
import { displayText } from "../../../constant/MessageConstant.js";
import ReactEcharts from "echarts-for-react";
import DownloadIcon from "../../../assets/images/adminDashboard/DownloadIconNew.svg";
import "./SectionOne.css";
import { Box, Grid, Typography } from "@mui/material";
import downArroeIcon from "../../../assets/images/common/downArrowImage.svg";
import upArroeIcon from "../../../assets/images/common/upArrowImage.svg";
import AlertsImages from "../../../assets/images/common/alertsImageOne.svg";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const data = [
  {
    type: "ANIMAL_CROSSING",
    title: "Animal Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: CardImg1,
  },
  {
    type: "CAUTION",
    title: "Caution",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Caution,
  },
  {
    type: "CURVE",
    title: "Curve",
    overall: 0,
    currentMonthGraphData: [0],
    image: Curve,
  },
  {
    type: "ROUND",
    title: "Round About",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Round,
  },
  {
    type: "HILL",
    title: "Hill",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Hillimg,
  },
  {
    type: "HILL_DOWN",
    title: "Hill Downwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: HillDownWards,
  },
  {
    type: "HILL_UP",
    title: "Hill Upwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: hillUp,
  },
  {
    type: "ICY",
    title: "Icy Conditions",
    overallData: 0,
    currentMonthGraphData: [0],
    image: icyConditions,
  },
  {
    type: "INTERSECTION",
    title: "Intersection",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Intersection,
  },
  {
    type: "LANE_MERGE",
    title: "Lane Merge",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LaneMerge,
  },
  {
    type: "LOW_GEAR",
    title: "Low Gear Area",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LowGearArea,
  },

  {
    type: "NARROW_ROAD",
    title: "Narrow Road",
    overallData: 3,
    currentMonthGraphData: [0],
    image: NarrowRoad,
  },
  {
    type: "OVERTAKING",
    title: "No Overtaking",
    overallData: 0,
    currentMonthGraphData: [0],
    image: NoOverTaking,
  },

  {
    type: "CROSSING",
    title: "Pedestrian Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: PedestrianCrossing,
  },
  {
    type: "PRIORITY",
    title: "Priority",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Priority,
  },
  {
    type: "RAILWAY",
    title: "Railway Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RailwayCrossing,
  },
  {
    type: "RISK_GROUNDING",
    title: "Risk Of Grounding",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RiskOfGrounding,
  },
  {
    type: "SCHOOL",
    title: "School",
    overallData: 0,
    currentMonthGraphData: [0],
    image: School,
  },
  {
    type: "SLIPPERY_ROADS",
    title: "Slippery Roads",
    overallData: 0,
    currentMonthGraphData: [0],
    image: SlipperyRoads,
  },
  {
    type: "STOP",
    title: "Stop",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Stopimg,
  },

  {
    type: "TRAFFIC_LIGHT",
    title: "Traffic Light",
    overallData: 0,
    currentMonthGraphData: [0],
    image: TrafficLight,
  },
  {
    type: "WIND",
    title: "Wind",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindImg,
  },
  {
    type: "WINDING_ROAD",
    title: "Winding Road",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindingRoad,
  },
  {
    type: "YIELD",
    title: "Yield",
    overallData: 0,
    currentMonthGraphData: [0],
    image: YieldImg,
  },
];

const UserDashboard = () => {
  const option = {
    tooltip: {
      trigger: "item",
      // formatter: (params) => {
      //   return `${params.data.safetyName} (${params.data.safetyName1}) - (${params.data.value}/${totalRides}) ${params.data.percentage}%`;
      // }
    },
    legend: {
      show: true,
      bottom: 30,
      left: "center",
      textStyle: { color: "black", align: "center" },
      data: [],
      itemWidth: 20,
      itemHeight: 14,
      width: 600,
    },
    series: [
      {
        name: "Overall Driving Score",
        type: "pie",
        radius: "50%",
        center: ["50%", "40%"],
        data: [
          {
            value: 335,
            name: "Direct Visit",
          },
          {
            value: 234,
            name: "Union Ad",
          },
          {
            value: 1548,
            name: "Search Engine",
          },
        ],
        selectedMode: "single",
        // label: {
        //   formatter: (params) => {
        //     return `${params.data.safetyName} (${params.data.safetyName1}) : ${params.data.value}`;
        //   },
        //   position: 'outside'
        // },
        labelLine: {
          length: 15,
          length2: 10,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            // shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  let  defaultValueList = [{
    periodSummaryDrivingScore:0,
    period1ResultCalculatedDrivingScore:0,
    period1ResultTravelDuration:0,
    period1ResultTravelDistance:0,
    period2ResultCalculatedDrivingScore:0,
    period2ResultTravelDuration:0,
    period2ResultTravelDistance:0,
    periodSummarySpeedingViolation:0,
    period1ResultOverSpeedPoint:0,
    period1ResultOverSpeedDuration:0,
    period1ResultOverSpeedDistance:0,
    period2ResultOverSpeedPoint:0,
    period2ResultOverSpeedDuration:0,
    period2ResultOverSpeedDistance:0,
    periodSummaryMobileViolation:0,
    mScreenAndMcallPoint: 0,
    mScreenAndMcallDuration: 0,
    mScreenAndMcallDistance: 0,            
    period1ResultMobileScreenPoint:0,
    period1ResultMobileScreenDuration:0,
    period1ResultMobileCallDuration:0,
    period1ResultMobileScreenDistance:0,
    period1ResultMobileCallDistance:0,
    mScreenAndMcallPointPeriod2: 0,
    mScreenAndMcallDurationPeriod2: 0,
    mScreenAndMcallDistancePeriod2: 0,
    period2ResultMobileScreenPoint:0,
    period2ResultMobileCallPoint:0,
    period2ResultMobileScreenDuration:0,
    period2ResultMobileCallDuration:0,
    period2ResultMobileScreenDistance:0,
    period2ResultMobileCallDistance:0,
    period1ResultAccBrakCorPoint:0,
    period2ResultAccBrakCorPoint:0,
    accBrakCorViolation:0,
    periodSummaryDrivingRedAlert:0,
    period1ResultHighRiskCount:0,
    period2ResultHighRiskCount:0
  }];

  const [UDMainDashboardTopRowItemsTemp, setUDMainDashboardTopRowItemsTemp] =
    useState(UDMainDashboardTopRowItems);
  const [overallDataProps, setOverallDataProps] = useState([]);
  const [UDMainDashboardSafetyTableData, setUDMainDashboardSafetyTableData] =
    useState(widgetUsersData);
  const [locationDataPdf, setLocationDataPdf] = useState([]);
  const [topTotalData, setTopTotalData] = useState({
    tRides: 0,
    tKilometers: 0.0,
    tHours: 0.0,
    tRiskAlerts: 0,
    tOtherAlerts: 0,
  });

  const [yearlyDatas, setYearlyDatas] = useState([]);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [rideTotalRide, setRideTotalRide] = useState({
    ride: 0,
    totalRides: 0,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const { pathname } = useLocation();
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [getSelectedYearData, setGetSelectedYearData] = useState([]);
  const [lastOverAllSyData, setLastOverAllSyData] = useState([]);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });
  const [aggregateData, setAggregateData] = useState({
    totalRides: 0,
    totalKilometer: 0,
    totalHighRiskCount: 0,
    totalAlertDataCount: 0,
    totalMediumRiskCount: 0,
    totalTravelTime: 0,
  });
  const [totalDays, setTotalDays] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleOpen = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  const UserDashboardRideBoxCount = {
    totalRides: 0,
    count: [
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 1 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 2 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 3 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 4 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 5 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 6 },
    ],
  };

  const UserDashboardOverallRideBoxList = [
    { totalRides: 0, rideBox: 1, percentage: 0 },
    { totalRides: 0, rideBox: 2, percentage: 0 },
    { totalRides: 0, rideBox: 3, percentage: 0 },
    { totalRides: 0, rideBox: 4, percentage: 0 },
    { totalRides: 0, rideBox: 5, percentage: 0 },
    { totalRides: 0, rideBox: 6, percentage: 0 },
  ];

  const [dataOverallRideBoxList, setDataOverallRideBoxList] = useState(
    UserDashboardOverallRideBoxList
  );
  // console.log(dataOverallRideBoxList,"dataOverallRideBoxList");

  const [USRideBoxCount, setUSRideBoxCount] = useState(
    UserDashboardRideBoxCount
  );

  function splitArrayIntoChunks(arr) {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  const [riskDataList, setRiskDataList] = useState(
    splitArrayIntoChunks(data) || []
  );
  const [riskDataListleng, setRiskDataListleng] = useState(null);
  const [chartWidth, setChartWidth] = useState("98vw");
  const [drivingDataList, setDrivingDataList] = useState(defaultValueList);
  // Date 
  const currentYear = new Date().getFullYear();
  
  const [periodOneValue, setPeriodOneValue] = useState(null);
  const [periodTwoValue, setPeriodTwoValue] = useState(null);



  // const [summaryDataUser, setSummaryDataUser] = useState(summaryData);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460) {
        setChartWidth("88vw");
      } else if (window.innerWidth >= 461 && window.innerWidth <= 1199) {
        setChartWidth("90vw");
      } else {
        setChartWidth("20vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getTotalValues = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await UserDrivingScoreController.totalValuesController(
        filters.durationFilter,
        filters.startDateTime,
        filters.endDateTime
      );
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      var currentMontData = [];

      var status = resultJSON.status;
      console.log(resultJSON, "resultJSONresultJSON");

      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        var totalRide = 0;
        var totalKm = 0;
        var safeRide = 0;
        let overAllRideBoxTotalRidesCount = {
          totalRides: 0,
          count: [
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 1,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 2,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 3,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 4,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 5,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 6,
              totalKm: 0,
              totalTravelTime: 0,
            },
          ],
        };
        let totalDays = 0;
        let totalRides = 0;
        let totalKilometer = 0;
        let totalHighRiskCount = 0;
        let totalAlertDataCount = 0;
        let totalMediumRiskCount = 0;
        let totalTravelTime = 0;
        let overSpeedValue = 0;
        let mobileCallValue = 0;
        let mobileScreenValue = 0;
        let severeAccelerationValue = 0;
        let severeCorneringValue = 0;
        let severeBrakingValue = 0;
        let yearlyData = [];
        // console.log("getTotalValues: " , resultJSONData);

        resultJSONData.map((apiResultTemp) => {
          var overallData = ValueFormat.parseAsObject(
            apiResultTemp.overallData
          );
          var currentYearData = ValueFormat.parseAsObject(
            apiResultTemp.currentYearData
          );
          var currentMonthData = ValueFormat.parseAsObject(
            apiResultTemp.currentMonthData
          );
          var currentWeekData = ValueFormat.parseAsObject(
            apiResultTemp.currentWeekData
          );
          var currentDayData = ValueFormat.parseAsObject(
            apiResultTemp.currentDayData
          );
          var overallRideBoxList = ValueFormat.parseAsArray(
            apiResultTemp.overallRideBoxList
          );
          var drivingScoreList = ValueFormat.roundArrayValue(
            ValueFormat.parseAsArray(currentMonthData.drivingScoreList)
          );
          var deviceNameList = ValueFormat.parseAsArray(
            currentMonthData.deviceNameList
          );
          yearlyData.push(
            overallData,
            currentYearData,
            currentMonthData,
            currentWeekData,
            currentDayData
          );
          setYearlyDatas(yearlyData);
          totalDays = parseInt(ValueFormat.parseAsInt(apiResultTemp.noOfDays));
          for (let i = 0; i < drivingScoreList.length; i++) {
            var driveScoreListTemp = drivingScoreList[i];

            var deviceNameListTemp = deviceNameList[i];
            var deviceName = deviceNameListTemp.name;
            var driveScoreResult = { x: deviceName, y: driveScoreListTemp };
            currentMontData.push(deviceName);
          }

          overallRideBoxList.map((apiData) => {
            var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
            totalRide += ValueFormat.parseAsInt(apiData.totalRides);
            if (rideBox == 1) {
              safeRide = apiData.totalRides;
            }
          });

          const overAllRideBoxCountList = ValueFormat.parseAsArray(
            apiResultTemp.overallRideBoxCountList
          );

          overAllRideBoxCountList.forEach((apiData) => {
            totalRides += ValueFormat.parseAsInt(apiData.totalRides);
            totalKilometer +=
              parseFloat(apiData.totalKilometer) == undefined ||
              parseFloat(apiData.totalKilometer) == null
                ? 0
                : parseFloat(apiData.totalKilometer);
            totalHighRiskCount += ValueFormat.parseAsInt(
              apiData.totalHighRiskCount
            );
            totalAlertDataCount += ValueFormat.parseAsInt(
              apiData.totalAlertDataCount
            );
            totalMediumRiskCount += ValueFormat.parseAsInt(
              apiData.totalMediumRiskCount
            );
            totalTravelTime +=
              parseFloat(apiData.totalTravelTime) == undefined ||
              parseFloat(apiData.totalTravelTime) == null
                ? 0
                : parseFloat(apiData.totalTravelTime);
            overSpeedValue += ValueFormat.parseAsInt(apiData.overSpeedCount);
            mobileCallValue += ValueFormat.parseAsInt(apiData.mobileUseCount);
            mobileScreenValue += ValueFormat.parseAsInt(
              apiData.mobileScreenCount
            );
            severeAccelerationValue += ValueFormat.parseAsInt(
              apiData.severeAccelerationCount
            );
            severeCorneringValue += ValueFormat.parseAsInt(
              apiData.severeCorneringCount
            );
            severeBrakingValue += ValueFormat.parseAsInt(
              apiData.severeBrakingCount
            );
          });

          let overAllTotalRides = 0;
          overAllRideBoxCountList.map((val, i) => {
            overAllTotalRides += ValueFormat.parseAsInt(val.totalRides);
          });
          overAllRideBoxTotalRidesCount.totalRides = overAllTotalRides;

          overAllRideBoxCountList.map((val, i) => {
            const percentageValue =
              (ValueFormat.parseAsInt(val.totalRides) /
                overAllRideBoxTotalRidesCount.totalRides) *
              100;

            // Logic to format the percentage
            let formattedPercentage;
            if (percentageValue % 1 === 0) {
              // If the value is an integer (e.g., 50.00)
              formattedPercentage = percentageValue.toFixed(0);
            } else if ((percentageValue * 10) % 1 === 0) {
              // If the value has one significant decimal place (e.g., 50.10, 50.7)
              formattedPercentage = percentageValue.toFixed(1);
            } else {
              // If the value has more than one significant decimal place (e.g., 50.66)
              formattedPercentage = percentageValue.toFixed(2);
            }

            console.log(
              percentageValue,
              formattedPercentage,
              "formattedPercentage"
            );

            overAllRideBoxTotalRidesCount.count[val.rideBox - 1] = {
              ...overAllRideBoxTotalRidesCount.count[val.rideBox],
              totalRides: val.totalRides,
              totalKm: val.totalKilometer,
              totalTravelTime: val.totalTravelTime,
              rideBox: val.rideBox,
              percentage: formattedPercentage,
            };
          });

          setUSRideBoxCount(overAllRideBoxTotalRidesCount);

          setTotalSafeRidePer(
            ValueFormat.parseAsFloat((safeRide / totalRide) * 100, 0)
          );
          setRideTotalRide({
            ride: safeRide,
            totalRides: totalRide,
          });

          setUDMainDashboardTopRowItemsTemp(
            UDMainDashboardTopRowItemsTemp.map((staticObjectTemp) => {
              var isTypeMatched = false;
              var overallValue = 0;
              let mobCall = 0;
              let mobScreen = 0;
              if (staticObjectTemp.type === "OVER_SPEED") {
                isTypeMatched = true;
                // overallValue = ValueFormat.parseAsInt(overallData.totalRides);
                // localStorage.setItem(SC_TOTAL_RIDE_COUNT, overallValue);
                overallValue = ValueFormat.parseAsInt(overSpeedValue);
              } else if (staticObjectTemp.type === "MOBILE_USAGE") {
                isTypeMatched = true;
                mobCall = ValueFormat.parseAsInt(mobileCallValue);
                mobScreen = ValueFormat.parseAsInt(mobileScreenValue);
                overallValue =
                  ValueFormat.parseAsInt(mobCall) +
                  ValueFormat.parseAsInt(mobScreen);
              } else if (staticObjectTemp.type === "ACCELERATION") {
                isTypeMatched = true;
                overallValue = ValueFormat.parseAsInt(severeAccelerationValue);
              } else if (staticObjectTemp.type === "CORNERING") {
                isTypeMatched = true;
                overallValue = ValueFormat.parseAsInt(severeCorneringValue);
              } else if (staticObjectTemp.type === "BRAKING") {
                isTypeMatched = true;
                overallValue = ValueFormat.parseAsInt(severeBrakingValue);
              }

              if (isTypeMatched === true) {
                let staticObjectTempReplace = {
                  overallData: overallValue,
                };
                return { ...staticObjectTemp, ...staticObjectTempReplace };
              } else {
                return staticObjectTemp;
              }
            })
          );
          setDataOverallRideBoxList(
            overallRideBoxList.map((val) => ({
              totalRides: val.totalRides,
              rideBox: val.rideBox,
              percentage: (
                (ValueFormat.parseAsInt(val.totalRides) / totalRide) *
                100
              ).toFixed(1),
            }))
          );
        });
        setAggregateData({
          totalRides,
          totalKilometer,
          totalHighRiskCount,
          totalAlertDataCount,
          totalMediumRiskCount,
          totalTravelTime,
        });
        setTotalDays(totalDays);
      } else {
        console.log("Status failed");
      }
    } catch (e) {
      console.log("error:", e);
    }
  };

  const getSafetyData = async () => {
    try {
      var result = await UserDrivingScoreController.userSafetyDetailController(
        filters.durationFilter,
        filters.startDateTime,
        filters.endDateTime
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("getSafetyData: ", JSON.stringify(resultJSONData));
        var totalRideDataList = [];
        var totalKmsList = [];
        var totalHrsList = [];
        var totalRiskList = [];
        var totalOtherAlertsList = [];
        let totalData = {
          tRides: 0,
          tKilometers: 0.0,
          tHours: 0.0,
          tRiskAlerts: 0,
          tOtherAlerts: 0,
        };
        if (resultJSONData.length > 0) {
          const rideBoxList = resultJSONData[0].rideBoxList;

          rideBoxList.forEach((rideBoxItem) => {
            totalData.tRides += rideBoxItem.totalRides;
            totalData.tKilometers += rideBoxItem.totalKiloMeters;
            totalData.tHours += rideBoxItem.totalHours;
            totalData.tRiskAlerts += rideBoxItem.totalRiskAlerts;
            totalData.tOtherAlerts += rideBoxItem.totalManualDataCount;
          });

          setTopTotalData(totalData);

          for (let categoryCount = 0; categoryCount < 6; categoryCount++) {
            var totalRides = 0;
            var totalKms = 0;
            var totalHrs = 0;
            var totalRiskAlerts = 0;
            var totalOtherAlert = 0;

            let rideBoxList = ValueFormat.parseAsArray(
              resultJSONData[0].rideBoxList
            );

            for (let rideBox = 0; rideBox < rideBoxList.length; rideBox++) {
              var rideBoxValue = ValueFormat.parseAsInt(
                rideBoxList[rideBox].rideBox
              );

              if (rideBoxValue === categoryCount + 1) {
                totalRides = ValueFormat.parseAsInt(
                  rideBoxList[rideBox].totalRides
                );
                totalKms = ValueFormat.parseAsFloat(
                  rideBoxList[rideBox].totalKiloMeters,
                  2
                );
                totalHrs = ValueFormat.parseAsFloat(
                  rideBoxList[rideBox].totalHours,
                  2
                );
                totalRiskAlerts = ValueFormat.parseAsInt(
                  rideBoxList[rideBox].totalRiskAlerts
                );
                totalOtherAlert = ValueFormat.parseAsInt(
                  rideBoxList[rideBox].totalManualDataCount
                );
              }
            }

            totalRideDataList.push(totalRides);
            totalKmsList.push(totalKms);
            totalHrsList.push(totalHrs);
            totalRiskList.push(totalRiskAlerts);
            totalOtherAlertsList.push(totalOtherAlert);
          }

          setUDMainDashboardSafetyTableData(
            UDMainDashboardSafetyTableData.map((staticObjectTemp) => {
              var isTypeMatched = false;
              var rideDataList = [];

              if (staticObjectTemp.type === "RIDE") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalRideDataList);
              } else if (staticObjectTemp.type === "DISTANCE") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalKmsList);
              } else if (staticObjectTemp.type === "DURATION") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalHrsList);
              } else if (staticObjectTemp.type === "ALERT") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalRiskList);
              } else if (staticObjectTemp.type === "OTHER ALERT") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalOtherAlertsList);
              }

              if (isTypeMatched === true) {
                let staticObjectTempReplace = { dataListValues: rideDataList };
                return { ...staticObjectTemp, ...staticObjectTempReplace };
              } else {
                return staticObjectTemp;
              }
            })
          );
        } else {
          console.log("Data not available");
        }
      } else {
        console.log("Status failed");
      }
    } catch (e) {
      console.log("Catch error: ", e);
    }
  };


  const getDrivingComparisionData = async () => {
    try {
      let period1Date = periodOneValue ? periodOneValue.format("YYYY-MM")+"-01 00:00:00" : "";
      let period2Date = periodTwoValue ? periodTwoValue.format("YYYY-MM")+"-01 00:00:00" : "";
      console.log(period1Date,period2Date,"period2Date")
      var result = await AdminDashboardController.userDashbordDrivingComparisionData(period1Date,period2Date);
      console.log(result,"getDrivingComparisionData")
      var resultJSON = JSON.parse(result);
      console.log(resultJSON,"getDrivingComparisionData")
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        let data = resultJSON?.data;
        let resultJSONData = resultJSON?.data;

        var updatedDrivingDataList = [...drivingDataList];

        resultJSONData.map((apiResultData) => {
                   
          // period1Result

          var periodSummaryDrivingScore = parseFloat(
            apiResultData?.periodSummary?.drivingScore ?? 0
          ).toFixed(0);         
          if (isNaN(periodSummaryDrivingScore)) {
            periodSummaryDrivingScore = 0;  // Use 0 only if the value is not valid
          }          
          var period1ResultCalculatedDrivingScore = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.calculatedDrivingScore,0)
          );
          var period1ResultTravelDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.travelDuration,2)
          );
          var period1ResultTravelDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.travelDistance,2)
          );

          // period2Result

          var period2ResultCalculatedDrivingScore = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.calculatedDrivingScore,0)
          );
          var period2ResultTravelDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.travelDuration,2)
          );
          var period2ResultTravelDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.travelDistance,2)
          );

          // Speeding Violation

          var periodSummarySpeedingViolation = parseFloat(
            apiResultData?.periodSummary?.speedingViolation ?? 0
          ).toFixed(0);
          if (isNaN(periodSummarySpeedingViolation)) {
            periodSummarySpeedingViolation = 0;  // Use 0 only if the value is not valid
          } 
          var period1ResultOverSpeedPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.overSpeedPoint,0)
          );
          var period1ResultOverSpeedDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.overSpeedDuration,2)
          );
          var period1ResultOverSpeedDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.overSpeedDistance,2)
          );
          var period2ResultOverSpeedPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.overSpeedPoint,0)
          );
          var period2ResultOverSpeedDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.overSpeedDuration,2)
          );
          var period2ResultOverSpeedDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.overSpeedDistance,2)
          );

          //  Mobile Violation

          var periodSummaryMobileViolation = parseFloat(
            apiResultData?.periodSummary?.mobileViolation ?? 0
          ).toFixed(0);
          if (isNaN(periodSummaryMobileViolation)) {
            periodSummaryMobileViolation = 0;  // Use 0 only if the value is not valid
          } 
          var period1ResultMobileScreenPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.mobileScreenPoint)
          ) || 0 ;
          var period1ResultMobileCallPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.mobileCallPoint)
          ) || 0;
          var period1ResultMobileScreenDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.mobileScreenDuration)
          );
          var period1ResultMobileCallDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.mobileCallDuration)
          );
          var period1ResultMobileScreenDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.mobileScreenDistance)
          );
          var period1ResultMobileCallDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.mobileCallDistance)
          );

          var period2ResultMobileScreenPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.mobileScreenPoint)
          );
          var period2ResultMobileCallPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.mobileCallPoint)
          );
          var period2ResultMobileScreenDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.mobileScreenDurationt)
          );
          var period2ResultMobileCallDuration = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.mobileCallDuration)
          );
          var period2ResultMobileScreenDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.mobileScreenDistance)
          );
          var period2ResultMobileCallDistance = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.mobileCallDistance)
          );

          //  Acc/Brak/Cor Violation

          var period1ResultAccBrakCorPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.accBrakCorPoint,0)
          );
          var period2ResultAccBrakCorPoint = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.accBrakCorPoint,0)
          );
          var accBrakCorViolation = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.periodSummary?.accBrakCorViolation,0)
          );

          // Driving Red Alert

          var periodSummaryDrivingRedAlert = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.periodSummary?.drivingRedAlert)
          );
          var period1ResultHighRiskCount = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period1Result?.highRiskCount,0)
          );

          var period2ResultHighRiskCount = parseFloat(
            ValueFormat.parseAsFloat(apiResultData?.period2Result?.highRiskCount)
          );

          
          updatedDrivingDataList = [{
            ...updatedDrivingDataList, // Copy existing values
            periodSummaryDrivingScore: periodSummaryDrivingScore,
            period1ResultCalculatedDrivingScore: period1ResultCalculatedDrivingScore,
            period1ResultTravelDuration: period1ResultTravelDuration,
            period1ResultTravelDistance: period1ResultTravelDistance,
            period2ResultCalculatedDrivingScore: period2ResultCalculatedDrivingScore,
            period2ResultTravelDuration: period2ResultTravelDuration,
            period2ResultTravelDistance: period2ResultTravelDistance,
            periodSummarySpeedingViolation: periodSummarySpeedingViolation,
            period1ResultOverSpeedPoint: period1ResultOverSpeedPoint,
            period1ResultOverSpeedDuration: period1ResultOverSpeedDuration,
            period1ResultOverSpeedDistance: period1ResultOverSpeedDistance,
            period2ResultOverSpeedPoint: period2ResultOverSpeedPoint,
            period2ResultOverSpeedDuration: period2ResultOverSpeedDuration,
            period2ResultOverSpeedDistance: period2ResultOverSpeedDistance,
            periodSummaryMobileViolation: periodSummaryMobileViolation,
            mScreenAndMcallPoint: (period1ResultMobileScreenPoint + period1ResultMobileCallPoint).toFixed(0),
            mScreenAndMcallDuration: (period1ResultMobileScreenDuration + period1ResultMobileCallDuration).toFixed(2),
            mScreenAndMcallDistance: (period1ResultMobileScreenDistance + period1ResultMobileCallDistance).toFixed(2),
            period1ResultMobileScreenPoint: period1ResultMobileScreenPoint,
            period1ResultMobileScreenDuration: period1ResultMobileScreenDuration,
            period1ResultMobileCallDuration: period1ResultMobileCallDuration,
            period1ResultMobileScreenDistance: period1ResultMobileScreenDistance,
            period1ResultMobileCallDistance: period1ResultMobileCallDistance,
            mScreenAndMcallPointPeriod2: (period2ResultMobileScreenPoint + period2ResultMobileCallPoint).toFixed(0),
            mScreenAndMcallDurationPeriod2: (period2ResultMobileScreenDuration + period2ResultMobileCallDuration).toFixed(2),
            mScreenAndMcallDistancePeriod2: (period2ResultMobileScreenDistance + period2ResultMobileCallDistance).toFixed(2),
            period2ResultMobileScreenPoint: period2ResultMobileScreenPoint,
            period2ResultMobileCallPoint: period2ResultMobileCallPoint,
            period2ResultMobileScreenDuration: period2ResultMobileScreenDuration,
            period2ResultMobileCallDuration: period2ResultMobileCallDuration,
            period2ResultMobileScreenDistance: period2ResultMobileScreenDistance,
            period2ResultMobileCallDistance: period2ResultMobileCallDistance,
            period1ResultAccBrakCorPoint: period1ResultAccBrakCorPoint,
            period2ResultAccBrakCorPoint: period2ResultAccBrakCorPoint,
            accBrakCorViolation: accBrakCorViolation,
            periodSummaryDrivingRedAlert: periodSummaryDrivingRedAlert,
            period1ResultHighRiskCount: period1ResultHighRiskCount,
            period2ResultHighRiskCount: period2ResultHighRiskCount
          }];
          

        })

        console.log(updatedDrivingDataList,"topUserListDataCards")

        console.log(data,"datadata")
        setDrivingDataList(updatedDrivingDataList);      
      } else {
        console.log("Status failed");
      }
    } catch (e) {
      console.log("Catch error: ", e);
    }
  };

  useEffect(()=> {
    getDrivingComparisionData();
  },[periodOneValue,
    periodTwoValue])

  const [summaryData, setSummaryData] = useState([]);
  const [summaryDataUser, setSummaryDataUser] = useState(summaryData);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };

  useEffect(() => {
    getTotalValues();
    getSafetyData();
    // console.log(periodOneValue ? periodOneValue.format("YYYY-MM") : "vij",periodTwoValue,"periodTwoValue")
  }, [
    filters.durationFilter,
    indexStart,
    filters.startDateTime,
    filters.endDateTime,
    
  ]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const containerIds = ["container1"];

  // useEffect(()=>{
  //   localStorage.removeItem("_grecaptcha")
  // },[])

  //meta title

  const getOverAllDataProps = (data) => {
    setOverallDataProps(data);
  };

  const handleChange = (filterType) => {
    setCount(0);
    setStartDateTime("");
    setEndDateTime("");
    setFilters({
      startDateTime: "",
      endDateTime: "",
      durationFilter: filterType,
    });
  };
  const getSelectedYear = (newData) => {
    setGetSelectedYearData(newData);
  };

  const lastOverAllDataSY = (newData) => {
    setLastOverAllSyData(newData);
  };

  const getLocationDataPdf = (newData) => {
    setLocationDataPdf(newData);
  };

  const handleDateChangeFrom = (date) => {
    if (dayjs(date).isValid()) {
      setPeriodOneValue(date);  // Ensure the date is valid and set it
    } else {
      console.error("Invalid date selected:", date);
    }
    // setPeriodOneValue(date)
  }
  const handleDateChangeTo = (date) => {
    if (dayjs(date).isValid()) {
      setPeriodTwoValue(date);  // Ensure the date is valid and set it
    } else {
      console.error("Invalid date selected:", date);
    }
    // setPeriodTwoValue(date)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-2 pe-2"> */}
        <Container fluid id="division">
          <div id="container1">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            {showLoader > 0 && <Loader />}
            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                className="shadow-sm "
                style={{ position: "fixed", zIndex: 1, background: "#fff" }}
              >
                <Row>
                  <Col xl={5} className="py-2">
                    {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                      <UserAdminDashboardSubMenu />
                    ) : (
                      <UserDashboardSubMenu />
                    )}
                  </Col>
                  <Col
                    xl={7}
                    className="py-2"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div className="ms-auto border-end border-secondary">
                        <div style={{ float: "right" }}>
                          {/* <button
                                type="button"
                                className={
                                  filters.durationFilter == ""
                                    ? "btn btn-primary btn-sm me-2"
                                    : "btn btn-soft-secondary btn-sm me-2"
                                }
                                onClick={() => handleChange("")}
                                data-tooltip-id="allBl"
                              >
                                All
                                <ReactTooltip
                                  id="allBl"
                                  place="top"
                                  content="Showing zone summary for all the time"
                                  style={{ background: "black", zIndex: 100 }}
                                />
                              </button> */}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CURRENT_DAY" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-2"
                                : "btn btn-soft-secondary btn-sm me-2"
                            }
                            onClick={() => handleChange("CURRENT_DAY")}
                            data-tooltip-id="cd"
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Showing zone summary for current day"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CURRENT_WEEK" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_WEEK")}
                            data-tooltip-id="cw"
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Showing zone summary for current week"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CURRENT_MONTH" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_MONTH")}
                            data-tooltip-id="cm"
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Showing zone summary for current month"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CURRENT_YEAR" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_YEAR")}
                            data-tooltip-id="cy"
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Showing zone summary for current year"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CUSTOM_DURATION" ||
                              modalIsOpen
                                ? "btn btnColorStyles btn-sm me-2"
                                : "btn btn-soft-secondary btn-sm me-2"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="custom duration"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="custom duration"
                              place="bottom"
                              content="Showing zone summary for custom duration"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>
                        </div>
                      </div>

                      {(loginType == "ADMIN" ||
                        loginType == "SUPER_ADMIN" ||
                        loginType == "SUPER_ADMIN_AMAZON") && (
                        <div className="border-end border-secondary pe-2">
                          <Link to={"/myRides"}>
                            <button
                              // data-tooltip-id="gtr"
                              className="btn btn-sm"
                              style={{ backgroundColor: "#FFCF23" }}
                            >
                              Go To Rides
                              {/* <ReactTooltip
                                id="gtr"
                                place="bottom"
                                content="Go To Rides"
                                style={{
                                  zIndex: 1000,
                                  backgroundColor: "black",
                                }}
                              /> */}
                            </button>
                          </Link>
                        </div>
                      )}

                      <div className="d-flex justify-content-end">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleOpen}>
                          <DropdownToggle
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                              boxShadow: "none",
                            }}
                          >
                            <img
                              src={DownloadIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                              }}
                            />
                          </DropdownToggle>

                          <DropdownMenu>
                            {/* Export PDF Option */}
                            <DropdownItem
                              onClick={() => {
                                ExportScreenDownloadPdf.generatePDF(
                                  containerIds,
                                  loginType == "ADMIN" ||
                                    loginType == "SUPER_ADMIN"
                                    ? userFirstName + "'s" + " Overall Summary"
                                    : userName + "'s" + " Overall Summary"
                                );
                              }}
                            >
                              Export Screen
                            </DropdownItem>

                            {/* View PDF Option */}
                            <DropdownItem
                              onClick={() => {
                                ViewPdf({
                                  // safetyPieData={safetyPieData}
                                  // osMcuMsuProps={osMcuMsuProps}
                                  // aCBProps={aCBProps}
                                  // riskAlertPdf={riskAlertPdf}
                                  // leaderBoardProps={leaderBoardProps}

                                  overallDataProps,
                                  UDMainDashboardTopRowItemsTemp,
                                  totalSafeRidePer,
                                  UDMainDashboardSafetyTableData,
                                  yearlyDatas,
                                  USRideBoxCount,
                                  getSelectedYearData,
                                  lastOverAllSyData,
                                  aggregateData,
                                  totalDays,
                                  filters,
                                  summaryDataUser,
                                  locationDataPdf,
                                  // totalSafeRidePer={totalSafeRidePer}
                                  // rccProps={rccProps}
                                  // rideNamesPerCat={rideNamesPerCat}
                                  // aCBDataProps={aCBDataProps}
                                  // osDataProps={osDataProps}
                                  // msuPdfDataProps={msuPdfDataProps}
                                  // mcuPdfDataProps={mcuPdfDataProps}
                                  // locationDataPdf={locationDataPdf}
                                });
                              }}
                            >
                              Download PDF
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                      {/* <div>
                        <ExportScreenPdf
                          containerIds={containerIds}
                          fileName={
                            loginType == "ADMIN" || loginType == "SUPER_ADMIN"
                              ? userFirstName + "'s" + " Overall Summary"
                              : userName + "'s" + " Overall Summary"
                          }
                        />
                      </div> */}
                      {/* <div>
                        <ViewPdf
                          

                          overallDataProps={overallDataProps}
                          UDMainDashboardTopRowItemsTemp={
                            UDMainDashboardTopRowItemsTemp
                          }
                          usertotalSafeRidePer={totalSafeRidePer}
                          UDMainDashboardSafetyTableData={
                            UDMainDashboardSafetyTableData
                          }
                          yearlyDatas={yearlyDatas}
                          USRideBoxCount={USRideBoxCount}
                          getSelectedYearData={getSelectedYearData}
                          lastOverAllSyData={lastOverAllSyData}
                          aggregateData={aggregateData}
                          totalDays={totalDays}
                          filtersData={filters}
                          summaryDataUser={summaryData}
                          locationDataPdf={locationDataPdf}
                         
                        />
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="adminmenu-mt">
          
              <div style={{display:"flex"}}>
            
              <div style={{display:"flex"}}>
                <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>Period 1{" "}&nbsp;
                </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DemoContainer components={['DatePicker']}>
          
        
        <DatePicker 
        // className="custom-mobile-date-picker"
        value={periodOneValue}
        onChange={handleDateChangeFrom}
        sx={{"& .MuiInputBase-input": {
          width:"50px",
          fontSize: "14px"
        }}}
        slotProps={{
          textField: {
            size: "small",            
            sx: {
              "& .MuiInputBase-input": {
                padding: "12px 0px 7.5px 12px", fontSize:'14px'// Adjust padding as needed
              },
            },
            inputProps: {
              placeholder: 'YYYY-MM'  // Set the custom placeholder here
            }
          },
        }}
        // label={'"year" and "month"'} 
        format="YYYY-MMM"
        views={['year','month']} 
        />
      </DemoContainer>
    </LocalizationProvider>
    </div>
    
    <div  style={{display:"flex",marginLeft:"10px"}}>
    <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>Period 2{" "}&nbsp;
    </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DemoContainer components={['DatePicker']}>
        
        <DatePicker 
        // className="custom-mobile-date-picker"
        value={periodTwoValue}
        onChange={handleDateChangeTo}
        sx={{"& .MuiInputBase-input": {
          width:"50px",
          fontSize: "14px"
        }}}
        slotProps={{
          textField: {
            size: "small",            
            sx: {
              "& .MuiInputBase-input": {
                padding: "12px 0px 7.5px 12px", fontSize:'14px'// Adjust padding as needed
              },
            },
            inputProps: {
              placeholder: 'YYYY-MM'  // Set the custom placeholder here
            }
          },
        }}
        // label={'"year" and "month"'} 
        format="YYYY-MMM"
        views={['year','month']} 
        />
      </DemoContainer>
    </LocalizationProvider>
    </div>
    
    </div>
            </Row>

            {/* <Row className="adminmenu-mt">
              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 8px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Summary
                  </CardHeader>
                  <CardBody className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {UDMainDashboardTopRowItemsTemp.map((item, index) => (
                            <tr
                              key={index}
                              className=""
                            >
                              <td
                                style={{ padding: "4.7px", paddingLeft: '8px' }}
                              >
                                {item.title}
                                <AiOutlineExclamationCircle
                                  style={{
                                    marginLeft: "5px",
                                    width:"15px",
                                    height:"15px",
                                  }}
                                  data-tooltip-id={
                                    "snpTooltipContenttt" + index
                                  }
                                />
                                <ReactTooltip
                                  id={"snpTooltipContenttt" + index}
                                  place="top"
                                  content={<span>{item.decription}</span>}
                                  style={{ backgroundColor: "black" }}
                                />
                              </td>
                              <td
                                style={{
                                
                                  padding: "4.7px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right" }}
                                >
                                  {item.overallData}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Green Zone
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px" }}
                    className="body_text"
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{textAlign: "center" }}
                          >
                            C1
                          </span>
                          <span>
                            {USRideBoxCount.count[0].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[0].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Safe Driving
                        <br />
                        <span>
                          {USRideBoxCount.count[0].totalRides} /{" "}
                          {USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C2
                          </span>
                          <span >
                            {USRideBoxCount.count[1].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[1].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Sportive Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[1].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Amber Zone
                    
                  </CardHeader>

                  <CardBody
                    style={{ padding: "10px" }}
                    className="body_text"
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C3
                          </span>
                          <span >
                            {USRideBoxCount.count[2].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[2].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Quite Safe Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[2].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C4
                          </span>
                          <span >
                            {USRideBoxCount.count[3].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[3].percentage}
                          
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Reckless Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[3].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Red Zone
                    
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px" }}
                    className="body_text"
                  >
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          position: "absolute",
                          height: 60,
                          width: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          C5
                        </span>
                        <span >
                          {USRideBoxCount.count[4].percentage}%
                        </span>
                      </span>
                      <div style={{ height: 70, width: 70 }}>
                        <CircularProgressbar
                          value={USRideBoxCount.count[4].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Quite Dangerous Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[4].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C6
                          </span>
                          <span >
                            {USRideBoxCount.count[5].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[5].percentage}
                          
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Dangerous Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[5].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

            {/* <UserRidesKmHrsScoreCBar
              aggregateData={aggregateData}
              totalDays={totalDays}
            /> */}
            {/* <div > */}
            {/* <Row> */}
              {/* <Col xs={4}> */}
              
              {/* </Col> */}
            {/* </Row> */}
            {drivingDataList.map((comparisonData,ind)=> (  
           <Row key={ind} style={{marginTop:"15px"}}>
           

          
            
           <Col xl={2} lg={2} md={6}>
                <Card >
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Driving Score
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "5px"}}
                    className="body_text"
                  > 
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <p style={{fontSize:"16px",textAlign:"center",display:"flex",justifyContent:"center",flexDirection:"column",color:comparisonData?.periodSummaryDrivingScore > 0 ? "#24B200" : "#EB0000",fontWeight:700}}>
                      <div style={{display:"flex"}}>
                      {comparisonData?.periodSummaryDrivingScore > 0 ? <img src={upArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/> : <img src={downArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/>}{comparisonData?.periodSummaryDrivingScore}%</div>
                      </p>
                  </div>
                  <div style={{width:"100%"}}>
                   <div>
                  <span style={{fontSize:"12px",fontWeight:400,color:"#333333"}}>{periodOneValue ? periodOneValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex",marginTop:"10px" }}>
                   
                      <div style={{ height: 60, width: 60,}}>       
                                         
                        <CircularProgressbar
                          value={comparisonData?.period1ResultCalculatedDrivingScore}
                          text={comparisonData?.period1ResultCalculatedDrivingScore || "-"}
                          styles={buildStyles({
                            width: "10px",
                            textSize: "27px",
                            fontWeight: 700,
                            pathColor:"green",
                            trailColor: "#0095FF",
                            textColor: "#5156BE",
                          })}
                          className="progress-bar-rideList"
                        ></CircularProgressbar>
                      </div>
                      
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                    <span style={{fontSize:"11px",fontWeight:400,color:"#333333",}}>{comparisonData?.period1ResultTravelDuration}{"Hrs"}</span>
                      </div>
                      <div style={{display:"flex"}}>
                       <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period1ResultTravelDistance}{"Kms"}</span>
                      </div>
                      </div>
                       
                    </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                    <span style={{fontSize:"12px",fontWeight:400,color:"#333333",}}>{periodTwoValue ? periodTwoValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div style={{ height: 60, width: 60 }}>
                        
                        <CircularProgressbar
                         value={comparisonData?.period2ResultCalculatedDrivingScore || 0}
                         text={comparisonData?.period2ResultCalculatedDrivingScore || "-"}
                         styles={buildStyles({
                           width: "10px",
                           textSize: "27px",
                           fontWeight: 700,
                           pathColor:"green",
                           trailColor: "#0095FF",
                           textColor: "#5156BE",
                         })}
                         className="progress-bar-rideList"
                         
                        
                        ></CircularProgressbar>
                      </div>
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period2ResultTravelDuration}{"Hrs"}</span>
                      </div>
                      <div style={{display:"flex"}}>
                     <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period2ResultTravelDistance}{"Kms"}</span>
                      </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={6}>
                <Card >
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Speeding Violation
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "5px" }}
                    className="body_text"
                  > 
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <p style={{fontSize:"16px",textAlign:"center",display:"flex",justifyContent:"center",flexDirection:"column",color:comparisonData?.periodSummarySpeedingViolation > 0 ? "#24B200" : "red",fontWeight:700}}>
                      <div style={{display:"flex"}}>
                      {comparisonData?.periodSummarySpeedingViolation > 0 ? (<img src={upArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/>) : (<img src={downArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/>)}{comparisonData?.periodSummarySpeedingViolation}%</div>
                      </p>
                  </div>
                  <div style={{width:"100%"}}>
                   <div>
                  <span style={{fontSize:"12px",fontWeight:400,color:"#333333"}}>{periodOneValue ? periodOneValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex",marginTop:"10px" }}>
                   
                      <div style={{ height: 60, width: 60,}}>       
                                         
                        <CircularProgressbar
                          value={comparisonData?.period1ResultOverSpeedPoint}
                          text={comparisonData?.period1ResultOverSpeedPoint || "-"}
                          styles={buildStyles({
                            width: "10px",
                            textSize: "27px",
                            fontWeight: 700,
                            pathColor:"green",
                            trailColor: "#0095FF",
                            textColor: "#5156BE",
                          })}
                          className="progress-bar-rideList"
                        ></CircularProgressbar>
                      </div>
                      
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period1ResultOverSpeedDuration}{"Hrs"}</span>
                      </div>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period1ResultOverSpeedDistance}{"Kms"}</span>
                      </div>
                      </div>
                       
                    </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                    <span style={{fontSize:"12px",fontWeight:400,color:"#333333",}}>{periodTwoValue ? periodTwoValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div style={{ height: 60, width: 60 }}>
                        
                        <CircularProgressbar
                         value={comparisonData?.period2ResultOverSpeedPoint}
                         text={comparisonData?.period2ResultOverSpeedPoint || "-"}
                         styles={buildStyles({
                           width: "10px",
                           textSize: "27px",
                           fontWeight: 700,
                           pathColor:"green",
                           trailColor: "#0095FF",
                           textColor: "#5156BE",
                         })}
                         className="progress-bar-rideList"
                         
                        
                        ></CircularProgressbar>
                      </div>
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period2ResultOverSpeedDuration}{"Hrs"}</span>
                      </div>
                      <div style={{display:"flex"}}>
                     <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.period2ResultOverSpeedDistance}{"Kms"}</span>
                      </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={6}>
                <Card >
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                   Mobile Violation
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "5px" }}
                    className="body_text"
                  > 
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <p style={{fontSize:"16px",textAlign:"center",display:"flex",justifyContent:"center",flexDirection:"column",color:comparisonData?.periodSummaryMobileViolation > 0 ? "green" : "#EB0000",fontWeight:700}}>
                      <div style={{display:"flex"}}>
                      {comparisonData?.periodSummaryMobileViolation > 0 ? <img src={upArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/> : <img src={downArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/>} {comparisonData?.periodSummaryMobileViolation}</div>
                      </p>
                  </div>
                  <div style={{width:"100%"}}>
                   <div>
                  <span style={{fontSize:"12px",fontWeight:400,color:"#333333"}}>{periodOneValue ? periodOneValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex",marginTop:"10px" }}>
                   
                      <div style={{ height: 60, width: 60,}}>       
                                         
                        <CircularProgressbar
                          value={comparisonData?.mScreenAndMcallPoint}
                          text={comparisonData?.mScreenAndMcallPoint || "-"}
                          styles={buildStyles({
                            width: "10px",
                            textSize: "27px",
                            fontWeight: 700,
                            pathColor:"green",
                            trailColor: "#0095FF",
                            textColor: "#5156BE",
                          })}
                          className="progress-bar-rideList"
                        ></CircularProgressbar>
                      </div>
                      
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.mScreenAndMcallDuration}{"Hrs"}</span>
                      </div>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.mScreenAndMcallDistance}{"Kms"}</span>
                      </div>
                      </div>
                       
                    </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                    <span style={{fontSize:"12px",fontWeight:400,color:"#333333",}}>{periodTwoValue ? periodTwoValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div style={{ height: 60, width: 60 }}>
                        
                        <CircularProgressbar
                         value={comparisonData?.mScreenAndMcallPointPeriod2}
                         text={comparisonData?.mScreenAndMcallPointPeriod2 || "-"}
                         styles={buildStyles({
                           width: "10px",
                           textSize: "27px",
                           fontWeight: 700,
                           pathColor:"green",
                           trailColor: "#0095FF",
                           textColor: "#5156BE",
                         })}
                         className="progress-bar-rideList"
                         
                        
                        ></CircularProgressbar>
                      </div>
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                     <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.mScreenAndMcallDurationPeriod2}{"Hrs"}</span>
                      </div>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>{comparisonData?.mScreenAndMcallDistancePeriod2}{"Kms"}</span>
                      </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={6}>
                <Card >
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Acc/Brak/Cor Violation
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "5px" }}
                    className="body_text"
                  > 
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <p style={{fontSize:"16px",textAlign:"center",display:"flex",justifyContent:"center",flexDirection:"column",color:comparisonData?.accBrakCorViolation > 0 ? "#24B200" : "red",fontWeight:700}}>
                      <div style={{display:"flex"}}>
                      {comparisonData?.accBrakCorViolation > 0 ? <img src={upArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/> : <img src={downArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/>}{comparisonData?.accBrakCorViolation}%</div>
                      </p>
                  </div>
                  <div style={{width:"100%"}}>
                   <div>
                  <span style={{fontSize:"12px",fontWeight:400,color:"#333333"}}>{periodOneValue ? periodOneValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex",marginTop:"10px" }}>
                   
                      <div style={{ height: 60, width: 60,}}>       
                                         
                        <CircularProgressbar
                          value={comparisonData?.period1ResultAccBrakCorPoint}
                          text={comparisonData?.period1ResultAccBrakCorPoint || "-"}
                          styles={buildStyles({
                            width: "10px",
                            textSize: "27px",
                            fontWeight: 700,
                            pathColor:"green",
                            trailColor: "#0095FF",
                            textColor: "#5156BE",
                          })}
                          className="progress-bar-rideList"
                        ></CircularProgressbar>
                      </div>
                      
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      {/* <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>24 Hrs</span>
                      </div>
                      <div style={{display:"flex"}}>
                     <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>30 Kms</span>
                      </div> */}
                      </div>
                       
                    </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                    <span style={{fontSize:"12px",fontWeight:400,color:"#333333",}}>{periodTwoValue ? periodTwoValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div style={{ height: 60, width: 60 }}>
                        
                        <CircularProgressbar
                        value={comparisonData?.period2ResultAccBrakCorPoint}
                        text={comparisonData?.period2ResultAccBrakCorPoint || "-"}
                         styles={buildStyles({
                           width: "10px",
                           textSize: "27px",
                           fontWeight: 700,
                           pathColor:"green",
                           trailColor: "#0095FF",
                           textColor: "#5156BE",
                         })}
                         className="progress-bar-rideList"
                         
                        
                        ></CircularProgressbar>
                      </div>
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      {/* <div style={{display:"flex"}}>
                     <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>24 Hrs</span>
                      </div>
                      <div style={{display:"flex"}}>
                      <span style={{fontSize:"11px",fontWeight:400,color:"#333333"}}>30 Kms</span>
                      </div> */}
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={6}>
                <Card >
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Driving Red Alert
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "5px" }}
                    className="body_text"
                  > 
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <p style={{fontSize:"16px",textAlign:"center",display:"flex",justifyContent:"center",flexDirection:"column",color:comparisonData?.periodSummaryDrivingRedAlert > 0 ? "#24B200" : "red",fontWeight:700}}>
                      <div style={{display:"flex"}}>
                      {comparisonData?.periodSummaryDrivingRedAlert > 0 ? <img src={upArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/> : <img src={downArroeIcon} alt="downArrow" style={{height:"15px",marginTop:"6px"}}/>}{comparisonData?.periodSummaryDrivingRedAlert}%</div>
                      </p>
                  </div>
                  <div style={{width:"100%"}}>
                   <div>
                  <span style={{fontSize:"12px",fontWeight:400,color:"#333333"}}>{periodOneValue ? periodOneValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex",marginTop:"10px" }}>
                   
                      <div style={{ height: 60, width: 60,}}>       
                                         
                        <CircularProgressbar
                          value={comparisonData?.period1ResultHighRiskCount}
                          text={comparisonData?.period1ResultHighRiskCount || "-"}
                          styles={buildStyles({
                            width: "10px",
                            textSize: "27px",
                            fontWeight: 700,
                            pathColor:"red",
                            trailColor: "#0095FF",
                            textColor: "#5156BE",
                          })}
                          className="progress-bar-rideList"
                        ></CircularProgressbar>
                      </div>
                      
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                      {/* <span style={{fontSize:"11px",fontWeight:600,color:"#333333"}}>Hrs</span> <span style={{fontSize:"11px",fontWeight:400,color:"#333333",marginLeft:"9px"}}>24 Hrs</span> */}
                      </div>
                      <div style={{display:"flex"}}>
                      {/* <span style={{fontSize:"11px",fontWeight:600,color:"#333333"}}>Kms</span> <span style={{fontSize:"11px",fontWeight:400,color:"#333333",marginLeft:"5px"}}>30 Kms</span> */}
                      </div>
                      </div>
                       
                    </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                    <span style={{fontSize:"12px",fontWeight:400,color:"#333333",}}>{periodTwoValue ? periodTwoValue.format('MMMM') : 'No Month Selected'}</span>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div style={{ height: 60, width: 60 }}>
                        
                        <CircularProgressbar
                         value={comparisonData?.period2ResultHighRiskCount}
                         text={comparisonData?.period2ResultHighRiskCount || "-"}
                         styles={buildStyles({
                           width: "10px",
                           textSize: "27px",
                           fontWeight: 700,
                           pathColor:"red",
                           trailColor: "#0095FF",
                           textColor: "#5156BE",
                         })}
                         className="progress-bar-rideList"
                         
                        
                        ></CircularProgressbar>
                      </div>
                      <div style={{display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"}}>
                      <div style={{display:"flex"}}>
                     
                      </div>
                      <div style={{display:"flex"}}>
                      
                      </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={6}>
                <Card style={{height:250}} >
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Top Violation Alerts
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "5px" }}
                    className="body_text"
                  > 
                  <div style={{display:"flex",justifyContent:"center", height: "100%", alignItems: "center"}}>
                  {/* <div  >
                   <img src={AlertsImages}/>
                  </div>
                  <div >           
                  <img src={AlertsImages}/>
                    
                    </div> */}
                    <p style={{color:"red",fontSize:"11px",textAlign:"center"}}>Data not available</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            
               
           </Row>
          ))}
             
            
            <DriverBehaviourRatingForUser
              receiveSummaryData={handleSummaryData}
              filter={filters}
            />
            {/* </div> */}

            <Row style={{ marginTop: "-27px" }}>
              <MarketOverview filter={filters} loaderCount={setShowLoader} />
              <Locations getLocationDataPdf={getLocationDataPdf} />
            </Row>

            <div id="container4" style={{ marginTop: "-5px" }}>
              <Row>
                <Col xl={12} lg={12}>
                  <Card>
                    <CardHeader
                      className="cardHeader"
                      style={{ padding: "5px" }}
                    >
                      <h4 className="card-title">
                        Risk Alerts{" "}
                        <span style={{ fontWeight: 400, fontSize: "12px" }}>
                          {/* (Total Alerts - {totalAlerts}) */}
                        </span>
                      </h4>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>

              <Row>
                {/* {riskDataListleng === true ? (
                ""
              ) : riskDataListleng === false ? (
                <p className="data_not_available mb-2">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
              ) : riskDataListleng === "failed" ? (
                <p className="data_not_available mb-2">{displayText.ER_1206}</p>
              ) : (
                <p className="fetch_data mb-2">{displayText.FETCHING_DATA}</p>
              )} */}
                {(riskDataList || []).map((widgets, key) => (
                  <Col xl={3} md={4} sm={6} xs={12} key={key}>
                    <Row style={{ marginBottom: "-11px" }}>
                      {widgets.map((widget) => (
                        <Col xl={6} md={6} sm={6} xs={12} key={key}>
                          <Card
                            className="card-h-100"
                            style={{ width: "100%" }}
                          >
                            <CardBody style={{ padding: "5px" }}>
                              <Row className="align-items-center">
                                <Col xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="text-nowrap"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={widget.image}
                                        alt="cardOneImg"
                                        height="25px"
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        // className="text-muted mb-3 d-block text-truncate"
                                        style={{
                                          fontSize: "10px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {widget.title}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {/* <h4> */}

                                      <span
                                        className="counter-value"
                                        style={{
                                          textAlign: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CountUp
                                          start={0}
                                          end={widget.overallData}
                                          duration={1}
                                        />
                                      </span>

                                      {/* </h4> */}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Row>
                <Col xl={3} md={3}>
                  <Card style={{ backgroundColor: "white" }}>
                    <CardHeader
                      className="cardHeader"
                      style={{ padding: "5px" }}
                      // style={{ height: "58.19px" }}
                    >
                      <h5 className="card-title">Rides Category Summary</h5>
                      <ReactTooltip
                        id="All year"
                        place="top"
                        content="Showing for all year"
                      />
                    </CardHeader>
                    <CardBody className=" border-0 p-1" style={{ height: 310 }}>
                      <ReactEcharts
                        style={{ height: "100%", width: chartWidth }}
                        option={option}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={3}>
                  <Card style={{ backgroundColor: "white" }}>
                    <CardHeader
                      className="cardHeader"
                      style={{ padding: "5px" }}
                      // style={{ height: "58.19px" }}
                    >
                      <h5 className="card-title">Rides Category Summary</h5>
                      <ReactTooltip
                        id="All year"
                        place="top"
                        content="Showing for all year"
                      />
                    </CardHeader>
                    <CardBody className=" border-0 p-1" style={{ height: 310 }}>
                      <ReactEcharts
                        style={{ height: "100%", width: chartWidth }}
                        option={option}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={3}>
                  <Card style={{ backgroundColor: "white" }}>
                    <CardHeader
                      className="cardHeader"
                      style={{ padding: "5px" }}
                      // style={{ height: "58.19px" }}
                    >
                      <h5 className="card-title">Rides Category Summary</h5>
                      <ReactTooltip
                        id="All year"
                        place="top"
                        content="Showing for all year"
                      />
                    </CardHeader>
                    <CardBody className=" border-0 p-1" style={{ height: 310 }}>
                      <ReactEcharts
                        style={{ height: "100%", width: chartWidth }}
                        option={option}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={3}>
                  <Card style={{ backgroundColor: "white" }}>
                    <CardHeader
                      className="cardHeader"
                      style={{ padding: "5px" }}
                      // style={{ height: "58.19px" }}
                    >
                      <h5 className="card-title">Rides Category Summary</h5>
                      <ReactTooltip
                        id="All year"
                        place="top"
                        content="Showing for all year"
                      />
                    </CardHeader>
                    <CardBody className=" border-0 p-1" style={{ height: 310 }}>
                      <ReactEcharts
                        style={{ height: "100%", width: chartWidth }}
                        option={option}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            <div style={{ marginTop: "10px" }}>
              <ProgressBarUD
                UDMainDashboardSafetyTableData={UDMainDashboardSafetyTableData}
              />
            </div>

            <OverAllDrivingScore
              getOverAllDataProps={getOverAllDataProps}
              getSelectedYear={getSelectedYear}
              totalSafeRidePer={totalSafeRidePer}
              rideTotalRide={rideTotalRide}
              lastOverAllDataSY={lastOverAllDataSY}
              USRideBoxCount={dataOverallRideBoxList}
            />
            {/* </Col>
                <Col xl={4} lg={4} md={12} className="mbb">
                  <Card
                    className="sidebar-alert border-0 text-center mb-0 mt-3 pt-3"
                    style={{ height: "220px" }}
                  >
                    <CardBody className="mt-4">
                      <Row className="mt-2">
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "red",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates HIGH risk
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "#FFBF00",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates MEDIUM risk
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "#19543E",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates LOW risk.
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
            {/* </Row> */}
            {/* ) : (
              ""
            )} */}
            {/* <UserDashboardColorIndicators /> */}
          </div>
          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setFilter={setFilter}
            filter={filters.durationFilter}
          />
          {/* </div> */}
        </Container>
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default UserDashboard;

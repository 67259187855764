import React from "react";
import { NavItem, Nav } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./Submenu.css";

const AdminDashboardSubMenu = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          margin: 5,
        }}
      >
        <h5 className="heading mb-0">Dashboard</h5>&nbsp;&nbsp;&nbsp;
        <div style={{ marginTop: "0px" }}>
          <Nav justified pills>
            <Link to="/adminDashboard">
              <button
                type="button"
                className={
                  pathname === "/adminDashboard"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Overall Summary
              </button>
            </Link>
            <Link to="/adminDashboard/CategorySummary">
              <button
                type="button"
                className={
                  pathname === "/adminDashboard/CategorySummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Category Summary
              </button>
            </Link>
            <Link to="/adminDashboard/RiskSummary">
              <button
                type="button"
                className={
                  pathname === "/adminDashboard/RiskSummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Risk Summary
              </button>
            </Link>
            <Link to="/adminDashboard/ActivityUsageSummary">
              <button
                type="button"
                className={
                  pathname === "/adminDashboard/ActivityUsageSummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Activity & Usage Summary
              </button>
            </Link>
            <Link to="/adminDashboard/LowestPerformanceDrivers">
              <button
                type="button"
                className={
                  pathname === "/adminDashboard/LowestPerformanceDrivers"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Lowest Performance Drivers
              </button>
            </Link>
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboardSubMenu;

import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
  ButtonGroup
} from "reactstrap";

import CountUp from "react-countup";

/** import Mini Widget data */
import { UDMainDashboardTopRowItems } from "../../common/data/dashboard";
import { widgetUsersData } from "../../common/data/dashboarduserdata";
import WalletBalance from "./WalletBalance";
import InvestedOverview from "./InvestedOverview";
import MarketOverview from "./MarketOverview";
import Locations from "./Locations";
import Trading from "./Trading";
import Transactions from "./Transactions";
import RecentActivity from "./RecentActivity";
import NewSlider from "./NewSlider";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../util/ValueFormat.js";
import { useSelector } from "react-redux";
import giftBox from "../../assets/images/giftbox.png";
import {
  layoutTheme,
  layoutTypes,
  layoutWidthTypes,
  layoutPositions,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";
import {
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
  SC_LOCK_STATUS,
} from "../../constant/StorageConstant";
import { Link } from "react-router-dom";
import RiskCountChart from "./RiskCountChart";
import OneRiskAlertChart from "../Admin/Charts/oneRiskeChart";
import StreesStrainChart from "../Admin/Charts/streesStrainChart";
import Loader from "../../components/Common/loader";
import ExportScreenPdf from "../../components/ExportScreenPdf/index.js";
import OverSpeedLocations from "./OverSpeedLocation";
import Converter from "../../components/Common/EncodeDecode";
import { overSpeedDataTemps, riskAlertsDataTemps } from "./RiskAlertTableData";
import LineBars from "../Admin/Charts/weekWiseBehaviourChart";
import ApexChartScore from "./scoreChart";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import OverAllDrivingScore from "./overAllDrivingScore";
import ToastService from "../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayText } from "../../constant/MessageConstant";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf.js";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup.js";
import UserRidesKmHrsScoreCBar from "./UserRidesKmHrsScoreCBar.js";

const options0 = {
  chart: {
    type: "line",
    width: 100,
    height: 80,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#5156be"],
  stroke: {
    curve: "straight",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const options1 = {
  chart: {
    type: "line",
    width: 100,
    height: 80,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#1A9558"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const options2 = {
  chart: {
    type: "line",
    width: 100,
    height: 0,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#F09E18"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const options3 = {
  chart: {
    type: "line",
    width: 100,
    height: 0,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#FA4656"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },

  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const percentage = 70;

const Dashboard = () => {
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);

  const [UDMainDashboardTopRowItemsTemp, setUDMainDashboardTopRowItemsTemp] =
    useState(UDMainDashboardTopRowItems);
  const [overSpeedDataList, setOverSpeedDataList] =
    useState(riskAlertsDataTemps);
  const [UDMainDashboardSafetyTableData, setUDMainDashboardSafetyTableData] =
    useState(widgetUsersData);

    // console.log("UDMainDashboardTopRowItemsTemp",UDMainDashboardSafetyTableData)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [aggregateData, setAggregateData] = useState({
    totalRides: 0,
    totalKilometer: 0,
    totalHighRiskCount: 0,
    totalAlertDataCount: 0,
    totalMediumRiskCount: 0,
    totalTravelTime: 0
  });
  const [totalDays, setTotalDays] = useState(0);
  
  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [isToggled, setisToggled] = useState(false); // true = overall & false = CY
  const handleToggled = () => {
    setisToggled(!isToggled)
  }
  
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [option0, setOption0] = useState(options0);
  const [option1, setOption1] = useState(options1);
  const [option2, setOption2] = useState(options2);
  const [option3, setOption3] = useState(options3);
  const [topWidgetGraphOptions, setTopWidgetGraphOptions] = useState({
    options0: options0,
    options1: options1,
    options2: options2,
    options3: options3,
  });
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const [safetyPieData, setSafetyPieData] = useState([]);
  const [osMcuMsuProps, setOsMcuMsuProps] = useState([]);
  const [aCBProps, setACBProps] = useState([]);
  const [riskAlertPdf, setRiskAlertPdf] = useState([]);
  const [leaderBoardProps, setLeaderBoardProps] = useState([]);
  const [overallDataProps, setOverallDataProps] = useState([]);
  const [rccProps, setRccProps] = useState({});
  const [rideNamesPerCat, setRideNamesPerCat] = useState([]);
  const [aCBDataProps, setACBDataProps] = useState([]);
  const [osDataProps, setOsDataProps] = useState([]);
  const [msuPdfDataProps, setMsuPdfDataProps] = useState([]);
  const [mcuPdfDataProps, setMcuPdfDataProps] = useState([]);
  const [locationDataPdf, setLocationDataPdf] = useState([]);


  const UserDashboardRideBoxCount = {
    totalRides: 0,
    count: [
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 1 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 2 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 3 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 4 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 5 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 6 },
    ],
  };

  const [USRideBoxCount, setUSRideBoxCount] = useState(
    UserDashboardRideBoxCount
  );
  const getTotalValues = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.totalValuesController(filter,startDateTime,endDateTime);
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var currentMontData = [];

    var status = resultJSON.status;

    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("resultJSONData",resultJSONData);

      var totalRide = 0;
      var safeRide = 0;
      let overAllRideBoxTotalRidesCount = {
        totalRides: 0,
        count: [
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 1 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 2 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 3 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 4 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 5 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 6 },
        ],
      };
      let totalDays = 0;
      let totalRides = 0;
      let totalKilometer = 0;
      let totalHighRiskCount = 0;
      let totalAlertDataCount = 0;
      let totalMediumRiskCount = 0;
      let totalTravelTime = 0;
      let overSpeedValue = 0;
      let mobileCallValue = 0;
      let mobileScreenValue = 0;
      let severeAccelerationValue = 0;
      let severeCorneringValue = 0;
      let severeBrakingValue = 0
      // console.log("getTotalValues: " + JSON.stringify(resultJSONData));

      resultJSONData.map((apiResultTemp) => {
        var overallData = ValueFormat.parseAsObject(apiResultTemp.overallData);
        var currentYearData = ValueFormat.parseAsObject(
          apiResultTemp.currentYearData
        );
        var currentMonthData = ValueFormat.parseAsObject(
          apiResultTemp.currentMonthData
        );
        var overallRideBoxList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxList
        );
        var drivingScoreList = ValueFormat.roundArrayValue(
          ValueFormat.parseAsArray(currentMonthData.drivingScoreList)
        );
        var deviceNameList = ValueFormat.parseAsArray(
          currentMonthData.deviceNameList
        );
        totalDays = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.noOfDays)
        );
        for (let i = 0; i < drivingScoreList.length; i++) {
          var driveScoreListTemp = drivingScoreList[i];

          var deviceNameListTemp = deviceNameList[i];
          var deviceName = deviceNameListTemp.name;
          var driveScoreResult = { x: deviceName, y: driveScoreListTemp };
          currentMontData.push(deviceName);
        }

        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });


        const overAllRideBoxCountList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxCountList
        );
        overAllRideBoxCountList.forEach(apiData => {
          totalRides += ValueFormat.parseAsInt(apiData.totalRides);
          totalKilometer += parseFloat(apiData.totalKilometer) == undefined || parseFloat(apiData.totalKilometer) == null ? 0 : parseFloat(apiData.totalKilometer);
          totalHighRiskCount += ValueFormat.parseAsInt(apiData.totalHighRiskCount);
          totalAlertDataCount += ValueFormat.parseAsInt(apiData.totalAlertDataCount);
          totalMediumRiskCount += ValueFormat.parseAsInt(apiData.totalMediumRiskCount);
          totalTravelTime += parseFloat(apiData.totalTravelTime) == undefined || parseFloat(apiData.totalTravelTime) == null ? 0 : parseFloat(apiData.totalTravelTime);
          overSpeedValue += ValueFormat.parseAsInt(apiData.overSpeedCount);
          mobileCallValue += ValueFormat.parseAsInt(apiData.mobileUseCount);
          mobileScreenValue += ValueFormat.parseAsInt(apiData.mobileScreenCount);
          severeAccelerationValue += ValueFormat.parseAsInt(apiData.severeAccelerationCount);
          severeCorneringValue += ValueFormat.parseAsInt(apiData.severeCorneringCount);
          severeBrakingValue += ValueFormat.parseAsInt(apiData.severeBrakingCount);
        });
        let overAllTotalRides = 0;
        overAllRideBoxCountList.map((val, i) => {
          overAllTotalRides += ValueFormat.parseAsInt(val.totalRides);
        });
        overAllRideBoxTotalRidesCount.totalRides = overAllTotalRides;
        overAllRideBoxCountList.map((val, i) => {
          overAllRideBoxTotalRidesCount.count[val.rideBox - 1] = {
            ...overAllRideBoxTotalRidesCount.count[val.rideBox],
            totalRides: val.totalRides,
            rideBox: val.rideBox,
            percentage: (
              (ValueFormat.parseAsInt(val.totalRides) /
                overAllRideBoxTotalRidesCount.totalRides) *
              100
            ).toFixed(1),
          };
        });
        setUSRideBoxCount(overAllRideBoxTotalRidesCount);

        setTotalSafeRidePer(
          ValueFormat.parseAsFloat((safeRide / totalRide) * 100, 0)
        );

        setUDMainDashboardTopRowItemsTemp(
          UDMainDashboardTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var overallValue = 0;
            let mobCall = 0;
            let mobScreen = 0;
            if (staticObjectTemp.type === "OVER_SPEED") {
              isTypeMatched = true;
              // overallValue = ValueFormat.parseAsInt(overallData.totalRides);
              // localStorage.setItem(SC_TOTAL_RIDE_COUNT, overallValue);
              overallValue = ValueFormat.parseAsInt(overSpeedValue);
            } else if (staticObjectTemp.type === "MOBILE_USAGE") {
              isTypeMatched = true;
              mobCall = ValueFormat.parseAsInt(mobileCallValue);
              mobScreen = ValueFormat.parseAsInt(mobileScreenValue);
              overallValue = ValueFormat.parseAsInt(mobCall) + ValueFormat.parseAsInt(mobScreen);
            } else if (staticObjectTemp.type === "ACCELERATION") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsInt(severeAccelerationValue);
            } else if (staticObjectTemp.type === "CORNERING") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsInt(severeCorneringValue);
            } else if (staticObjectTemp.type === "BRAKING") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsInt(severeBrakingValue);
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                overallData: overallValue,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );
        setOption0((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
        setOption1((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
        setOption2((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
        setOption3((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
      });
      setAggregateData({
        totalRides,
        totalKilometer,
        totalHighRiskCount,
        totalAlertDataCount,
        totalMediumRiskCount,
        totalTravelTime
      });
      setTotalDays(totalDays);
    } else {
      // var resultJSONCode = resultJSON.error.code;
      // if (resultJSONCode == "E1206" && userType == "USER") {
      //   ToastService.errormsg(displayText.ER_1206);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
      //   ToastService.errormsg(displayText.ER_1206);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
      console.log("Status failed");
    }
  };

  // temporary variables 
  const [startDateTimee, setStartDateTimee] = useState("");
  const [endDateTimee, setEndDateTimee] = useState("");
  const [filterr, setFilterr] = useState("");

  const getSafetyData = async () => {
    var result = await UserDrivingScoreController.userSafetyDetailController(filterr,startDateTimee,endDateTimee);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    // console.log("getSafetyData: " , JSON.stringify(resultJSONData));
    var totalRideDataList = [];
    var totalKmsList = [];
    var totalHrsList = [];
    var totalRiskList = [];
    var totalOtherAlertsList = [];

    for (let categoryCount = 0; categoryCount < 6; categoryCount++) {
      var totalRides = 0;
      var totalKms = 0;
      var totalHrs = 0;
      var totalRiskAlerts = 0;
      var totalOtherAlert = 0;

      let rideBoxList = ValueFormat.parseAsArray(resultJSONData[0].rideBoxList);

      for (let rideBox = 0; rideBox < rideBoxList.length; rideBox++) {
        var rideBoxValue = ValueFormat.parseAsInt(rideBoxList[rideBox].rideBox);

        if (rideBoxValue === categoryCount + 1) {
          totalRides = ValueFormat.parseAsInt(rideBoxList[rideBox].totalRides);
          totalKms = ValueFormat.parseAsFloat(
            rideBoxList[rideBox].totalKiloMeters,
            2
          );
          totalHrs = ValueFormat.parseAsFloat(
            rideBoxList[rideBox].totalHours,
            2
          );
          totalRiskAlerts = ValueFormat.parseAsInt(
            rideBoxList[rideBox].totalRiskAlerts
          );
          totalOtherAlert = ValueFormat.parseAsInt(
            rideBoxList[rideBox].totalManualDataCount
          );
        }
      }

      totalRideDataList.push(totalRides);
      totalKmsList.push(totalKms);
      totalHrsList.push(totalHrs);
      totalRiskList.push(totalRiskAlerts);
      totalOtherAlertsList.push(totalOtherAlert);
    }

    setUDMainDashboardSafetyTableData(
      UDMainDashboardSafetyTableData.map((staticObjectTemp) => {
        var isTypeMatched = false;
        var rideDataList = [];

        if (staticObjectTemp.type === "RIDE") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalRideDataList);
        } else if (staticObjectTemp.type === "DISTANCE") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalKmsList);
        } else if (staticObjectTemp.type === "DURATION") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalHrsList);
        } else if (staticObjectTemp.type === "ALERT") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalRiskList);
        } else if (staticObjectTemp.type === "OTHER ALERT") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalOtherAlertsList);
        }

        if (isTypeMatched === true) {
          let staticObjectTempReplace = { dataListValues: rideDataList };
          return { ...staticObjectTemp, ...staticObjectTempReplace };
        } else {
          return staticObjectTemp;
        }
      })
    );
  };

  useEffect(() => {
    getTotalValues();
  }, [filter,startDateTime,endDateTime]);

  useEffect(() => {
    getSafetyData();
  }, []);
  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const [overSpeedData, setOverSpeedData] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData2, setOverSpeedData2] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData3, setOverSpeedData3] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData4, setOverSpeedData4] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData5, setOverSpeedData5] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  // const [overSpeedData6, setOverSpeedData6] = useState({overSpeedData:[0],alertData:0,mobileScreen:[0],stressStrainData:[0]});
  const [month, setMonth] = useState("Aug");
  const [shouldBreak, setShouldBreak] = useState(
    window.innerWidth >= 1200 && window.innerWidth <= 1375
  );
  const getWeek1Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week1BehaviourController({
      startDate: month == "Aug" ? "2023-08-01 00:00:00" : "2023-09-01 00:00:00",
      endDate: month == "Aug" ? "2023-08-05 23:59:59" : "2023-09-02 23:59:59",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };

  const getWeek2Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week2BehaviourController({
      startDate: month == "Aug" ? "2023-08-06 00:00:00" : "2023-09-03 00:00:00",
      endDate: month == "Aug" ? "2023-08-12 23:59:59" : "2023-09-09 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData2({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };
  const getWeek3Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week3BehaviourController({
      startDate: month == "Aug" ? "2023-08-13 00:00:00" : "2023-09-10 00:00:00",
      endDate: month == "Aug" ? "2023-08-19 23:59:59" : "2023-09-16 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData3({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };

  const containerIds = ["container1", "container2"];

  const getWeek4Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week4BehaviourController({
      startDate: month == "Aug" ? "2023-08-20 00:00:00" : "2023-09-17 00:00:00",
      endDate: month == "Aug" ? "2023-08-26 23:59:59" : "2023-09-23 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData4({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };
  const getWeek5Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week5BehaviourController({
      startDate: month == "Aug" ? "2023-08-27 00:00:00" : "2023-09-24 00:00:00",
      endDate: month == "Aug" ? "2023-08-31 23:59:59" : "2023-09-30 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData5({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };

  useEffect(() => { }, [month]);

  // useEffect(()=>{
  //   localStorage.removeItem("_grecaptcha")
  // },[])

  //meta title

  useEffect(() => {
    function handleResize() {
      setShouldBreak(window.innerWidth >= 1200 && window.innerWidth <= 1375);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSafetyDataProps = (data) => {
    setSafetyPieData(data);
  };
  const getOsMcuMsuProps = (data) => {
    setOsMcuMsuProps(data);
  };
  const getACBProps = (data) => {
    setACBProps(data);
  };

  const getRiskAlertPdf = (data) => {
    setRiskAlertPdf(data);
  }
  const getLeaderboardProps = (data) => {
    setLeaderBoardProps(data);
  };

  const getOverAllDataProps = (data) => {
    setOverallDataProps(data);
  };

  const getRCCProps = (data) => {
    setRccProps(data);
  }

  const getRideNameList = (data) => {
    setRideNamesPerCat(data);
  }

  const getACBDataProps = (data) => {
    setACBDataProps(data);
  }

  const getOSDataProps = (data) => {
    setOsDataProps(data);
  }

  const getMcuDataProps = (data) => {
    setMsuPdfDataProps(data);
  }

  const getMsuDataProps = (data) => {
    setMcuPdfDataProps(data);
  }

  const getLocationDataPdf = (newData) => {
    setLocationDataPdf(newData);
  }

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <div className="page-content">
        <div className="ps-2 pe-2">
          <div id="container1">
            {/* <Container fluid> */}
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            
            <Row>
              <Col sm={6}>
                {(loginType == "ADMIN" || loginType == "SUPER_ADMIN") ? (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    Welcome, You are viewing{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 800,
                      }}
                    >
                      {userFirstName}'s
                    </span>{" "}
                    dashboard summary.
                  </span>
                ) : (
                      <h5>Dashboard</h5>
                      )}
              </Col>
              <Col sm={6}>
                <div className="d-flex justify-content-end pb-2">
                  <div className="d-flex justify-content-between ">
                  <button
                    type="button"
                    className={
                      filter == ""
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("")}
                    data-tooltip-id="allBl"
                  >
                    All
                    <ReactTooltip
                      id="allBl"
                      place="top"
                      content="Showing zone summary for all the time"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_DAY"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_DAY")}
                    data-tooltip-id="cd"
                  >
                    CD
                    <ReactTooltip
                      id="cd"
                      place="top"
                      content="Showing zone summary for current day"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_WEEK"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_WEEK")}
                    data-tooltip-id="cw"
                  >
                    CW
                    <ReactTooltip
                      id="cw"
                      place="top"
                      content="Showing zone summary for current week"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>{" "}
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_MONTH"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_MONTH")}
                    data-tooltip-id="cm"
                  >
                    CM
                    <ReactTooltip
                      id="cm"
                      place="top"
                      content="Showing zone summary for current month"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>{" "}
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_YEAR"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_YEAR")}
                    data-tooltip-id="cy"
                  >
                    CY
                    <ReactTooltip
                      id="cy"
                      place="top"
                      content="Showing zone summary for current year"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>{" "}
                  <button
                    type="button"
                    className={
                      filter == "CUSTOM_DURATION"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    data-tooltip-id="custom duration"
                  >
                    <FontAwesomeIcon icon={faClock} />
                    <ReactTooltip
                      id="custom duration"
                      place="top"
                      content="Showing zone summary for custom duration"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>
                    {(loginType == "ADMIN" ||
                      loginType == "SUPER_ADMIN" ||
                      loginType == "SUPER_ADMIN_AMAZON") && (
                        <Link to={"/myRides"} className="pe-2">
                          <button
                            data-tooltip-id="gtr"
                            className="btn btn-secondary btn-sm"
                          >
                            Go To Rides
                            <ReactTooltip
                              id="gtr"
                              place="top"
                              content="Go To Rides"
                              style={{ zIndex: "100", backgroundColor: "black" }}
                            />
                          </button>
                        </Link>
                      )}
                    <div className="">
                      <ExportScreenPdf
                        containerIds={containerIds}
                        fileName={
                          loginType == "ADMIN" || loginType == "SUPER_ADMIN"
                            ? userFirstName + "'s" + " dashboard"
                            : userName + "'s" + " dashboard"
                        }
                      />
                    </div>
                    {/* <div
                      className="p-2 pt-0 d-flex"
                    >
                      <ViewPdf
                        safetyPieData={safetyPieData}
                        osMcuMsuProps={osMcuMsuProps}
                        aCBProps={aCBProps}
                        riskAlertPdf={riskAlertPdf}
                        leaderBoardProps={leaderBoardProps}
                        overallDataProps={overallDataProps}
                        UDMainDashboardTopRowItemsTemp={
                          UDMainDashboardTopRowItemsTemp
                        }
                        usertotalSafeRidePer={totalSafeRidePer}
                        UDMainDashboardSafetyTableData={
                          UDMainDashboardSafetyTableData
                        }
                        rccProps={rccProps}
                        rideNamesPerCat={rideNamesPerCat}
                        aCBDataProps={aCBDataProps}
                        osDataProps={osDataProps}
                        msuPdfDataProps={msuPdfDataProps}
                        mcuPdfDataProps={mcuPdfDataProps}
                        locationDataPdf={locationDataPdf}
                      />
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row>
              {(UDMainDashboardTopRowItemsTemp || []).map((widget, key) => (
                <Col xl={3} lg={6} md={6} key={key}>
                  <Card className="card-h-100">
                    <CardBody style={{ padding: "10px" }}>
                      <Row className="align-items-center">
                        <Col xs={6}>
                          <div>
                            <span
                              data-tooltip-id={key + 201}
                              className="text-muted mb-3 lh-1 d-block text-truncate fw-semibold"
                            >
                              <ReactTooltip
                                id={key + 201}
                                place="top"
                                content={widget.title}
                              />
                              {widget.title}
                            </span>
                            <h4 className="mb-0">
                              <span className="counter-value">
                                <CountUp
                                  start={0}
                                  end={widget.overallData}
                                  decimals={key === 1 || key === 2 ? 2 : 0}
                                  decimal="."
                                  duration={1}
                                />
                                &nbsp;{widget.postFix}
                              </span>
                              <span
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                }}
                              >
                                {widget.row1Title}
                              </span>
                            </h4>
                            <h4 className="mb-2">
                              <span className="counter-value">
                                {key === 3 && (
                                  <CountUp
                                    start={0}
                                    end={widget.secondaryData}
                                    decimals={key === 1 || key === 2 ? 2 : 0}
                                    decimal="."
                                    duration={1}
                                  />
                                )}

                                &nbsp;{widget.postFix}
                              </span>
                              <span
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                }}
                              >
                                {widget.row2Title}
                              </span>
                            </h4>
                          </div>
                        </Col>

                        <Col xs={6}>
                          <ReactApexChart
                            options={
                              key === 0
                                ? option0
                                : key === 1
                                  ? option1
                                  : key === 2
                                    ? option2
                                    : option3
                            }
                            series={[
                              { data: [...widget.currentMonthGraphData] },
                            ]}
                            type="line"
                            className="apex-charts"
                            dir="ltr"
                          />
                        </Col>
                        <ReactTooltip
                          id={key + 1}
                          place="top"
                          content={widget.title}
                          style={{ backgroundColor: "black" }}
                        />
                      </Row>
                      <Row>
                        <Col xl={12} lg={12}>
                          <div
                            className="text-nowrap"
                            style={{ display: "flex" }}
                          >
                            <span
                              className={
                                "badge badge-soft-" +
                                widget.statusColor +
                                " text-" +
                                widget.statusColor
                              }
                            >
                              {widget.currentYearValue}&nbsp;{widget.postFix}
                            </span>
                            {shouldBreak && <br />}
                            <span className="ms-1 text-muted font-size-13">
                              Since this year
                            </span>
                          </div>
                        </Col >
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row> */}


            
            <Row>
            <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Summary
                  </CardHeader>
                  <CardBody style={{}} className="p-0 m-0">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                        {UDMainDashboardTopRowItemsTemp.map((item, index) => (
                              <tr
                                key={index}
                                style={{ backgroundColor: "#74788D1A" }}
                              >
                                <td
                                  style={{ fontSize: "12px", padding: "7.8px" }}
                                >
                                  {item.title}
                                  <AiOutlineExclamationCircle
                                    style={{
                                      marginLeft: "5px",
                                      marginTop: "-1px",
                                    }}
                                    data-tooltip-id={
                                      "snpTooltipContenttt" + index
                                    }
                                  />
                                  <ReactTooltip
                                    id={"snpTooltipContenttt" + index}
                                    place="top"
                                    content={<span>{item.decription}</span>}
                                    style={{ backgroundColor: "black" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    padding: "7.8px 20px",
                                  }}
                                >
                                  <span
                                    style={{ float: "right", fontWeight: 600 }}
                                  >
                                    {item.overallData}
                                  </span>
                                </td>
                              </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
            </Col>
              
            <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Green Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px", backgroundColor: "#74788D1A" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C1
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {USRideBoxCount.count[0].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[0].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Safe Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {USRideBoxCount.count[0].totalRides} /{" "}
                          {USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C2
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {USRideBoxCount.count[1].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[1].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            textSize: "40px",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Sportive Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {USRideBoxCount.count[1].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
            </Col>

            <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Amber Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>

                  <CardBody
                    style={{ padding: "10px", backgroundColor: "#74788D1A" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C3
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {USRideBoxCount.count[2].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[2].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Quite Safe Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {USRideBoxCount.count[2].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C4
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {USRideBoxCount.count[3].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[3].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            textSize: "40px",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Reckless Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {USRideBoxCount.count[3].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
            </Col>

            <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Red Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px", backgroundColor: "#74788D1A" }}
                  >
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          position: "absolute",
                          height: 60,
                          width: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontSize: "12px", textAlign: "center" }}>
                          C5
                        </span>
                        <span style={{ fontSize: "15px" }}>
                          {USRideBoxCount.count[4].percentage}%
                        </span>
                      </span>
                      <div style={{ height: 70, width: 70 }}>
                        <CircularProgressbar
                          value={USRideBoxCount.count[4].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Quite Dangerous Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {USRideBoxCount.count[4].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C6
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {USRideBoxCount.count[5].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[5].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            textSize: "40px",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Dangerous Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {USRideBoxCount.count[5].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
            </Col>
            </Row>

            <UserRidesKmHrsScoreCBar aggregateData={aggregateData} totalDays={totalDays}/>

            <Row>
              {(UDMainDashboardSafetyTableData || []).map((widgetdata, key) => (
                <Col xl={3} lg={6} md={6} key={key}>
                  <div style={{ marginBottom: "20px" }}>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {(widgetdata.dataListKeys || []).map((value, i) => (
                            <tr
                              key={i}
                              // className={classs[i].name}
                              style={{ backgroundColor: "#74788D1A" }}
                            >
                              <td
                                className="text-muted  text-truncate"
                                style={{
                                  fontSize: "12px",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                              >
                                {value}
                              </td>
                              <td
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "1px !important",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <span
                                  style={{ float: "right", fontWeight: 600 }}
                                >
                                  {widgetdata.dataListValues[i]}&nbsp;
                                  <span style={{ fontSize: "10px" }}>
                                    {widgetdata.postFix}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {layoutType === layoutTypes.HORIZONTAL ? (
              <Row>
                <Col xl={2} lg={2} md={12}>
                  <Card
                    className="sidebar-alert border-0 text-center  mb-0 mt-3 mb-3"
                    style={{ height: "220px" }}
                  >
                    <CardBody>
                      {/* <img src={giftBox} alt="giftBox" className="mt-4" /> */}
                      <div style={{ marginTop: "30px" }}>
                        <span
                          style={{
                            fontSize: "25px",
                            fontWeight: 900,
                          }}
                          data-tooltip-id="categoryrides"
                        >
                          {" "}
                          {totalSafeRidePer}%
                          <ReactTooltip
                            id="categoryrides"
                            place="top"
                            style={{ fontSize: "9px" }}
                            content={
                              <span>
                                Only {totalSafeRidePer}% of your rides are
                                <br /> Safe Driver Category (C1)
                              </span>
                            }
                          />
                        </span>{" "}
                        <div className="mt-2">
                          <h5 className="alertcard-title font-size-16">
                            {totalSafeRidePer >= 75 ? "Congrats" : "Sorry"}
                          </h5>
                          <p className="font-size-11 ">
                            {totalSafeRidePer < 75 ? "Only " : ""}
                            {totalSafeRidePer}% of your rides are in Safe Driver
                            Category (C1)
                          </p>
                          {/* <a href="#!" className="btn btn-primary mt-2">Upgrade Now</a> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6} lg={6} md={12}>
                  <OverAllDrivingScore
                    getOverAllDataProps={getOverAllDataProps}
                  />
                </Col>
                <Col xl={4} lg={4} md={12} className="mbb">
                  <Card
                    className="sidebar-alert border-0 text-center mb-0 mt-3 pt-3"
                    style={{ height: "220px" }}
                  >
                    <CardBody className="mt-4">
                      <Row className="mt-2">
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "red",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates HIGH risk
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "#FFBF00",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates MEDIUM risk
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "#19543E",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates LOW risk.
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row>
              <Col xl={12}>
                <RiskCountChart
                  getRCCProps={getRCCProps}
                  getOsMcuMsuProps={getOsMcuMsuProps}
                  getACBProps={getACBProps}
                  getRiskAlertPdf={getRiskAlertPdf}
                  getACBDataProps={getACBDataProps}
                  getOSDataProps={getOSDataProps}
                  getMcuDataProps={getMcuDataProps}
                  getMsuDataProps={getMsuDataProps}
                  loaderCount={setShowLoader}
                />
              </Col>
            </Row>
          </div>
          <div id="container2">
            <Row>
              <OverSpeedLocations />
            </Row>

            <Row>
              <WalletBalance
                getSafetyDataProps={getSafetyDataProps}
                loaderCount={setShowLoader}
              />
              <Col>
                <Row>
                  <InvestedOverview
                    getLeaderboardProps={getLeaderboardProps}
                    loaderCount={setShowLoader}
                  />
                  <NewSlider />
                </Row>
              </Col>
            </Row>
            {/* <Row>
              <MarketOverview loaderCount={setShowLoader} />
              <Locations
                getLocationDataPdf={getLocationDataPdf}
              />
            </Row> */}

            <Trading getRideNameList={getRideNameList} loaderCount={setShowLoader} />

            <br />
            <Row>
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px !important",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Anticipation
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "12px !important",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability not to enter into the red risk
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={5}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Self-Confidence
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "12px",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability to maintain a range of risk for majority time
                          of the ride
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px !important",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Driving Skill
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "12px !important",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability to maintain the same level of risk
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <DateTimePickerPopup
              modalOpen={modalIsOpen}
              modalClose={setIsOpen}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
              setFilter={setFilter}
            />
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default Dashboard;
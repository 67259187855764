import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import UtilDateTime from "../../util/UtilDateTime.js";
import { trimValue } from "../../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_GROUP_ID,
  SC_MODULE_ID,
  // SC_DIVISION_ID,
  // SC_USER_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let MyRideDetailController = {
  async myRideDetailTopItems(rideId, divisionId, userId) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    // var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    // var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        dashboardType: "USER_DASHBOARD_TOP_LINE_ITEM",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getDashboardRiskRideDataList(rideId, divisionId, userId, rideCategory) {
    rideId = trimValue(rideId);
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    // var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    // var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var queryFields = {travelType: rideCategory}
    if (rideId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        category: "DISTANCE_DATA,ALERT_DATA,END_DATA",
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "10000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
        queryFields: JSON.stringify(queryFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getTripProfileRiskAlertDataList(rideId, divisionId, userId) {
    rideId = trimValue(rideId);
    var queryFields = {showLastData : "YES"};

    if (rideId.length > 0) {
      var reqParams = {
        companyId: UserSessionController.getLocalSessionValue(SC_COMPANY_ID),
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        category:
          "START_DATA,ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA,END_DATA",
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
        queryFields: JSON.stringify(queryFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getTripProfileRiskAlertDataListWithRideCateory(rideId, divisionId, userId, rideCategory) {
    rideId = trimValue(rideId);
    var queryFields = {showLastData : "YES", travelType: rideCategory};

    if (rideId.length > 0) {
      var reqParams = {
        companyId: UserSessionController.getLocalSessionValue(SC_COMPANY_ID),
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        category:
          "START_DATA,ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA,END_DATA",
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
        queryFields: JSON.stringify(queryFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getErrorData(rideId, divisionId, userId) {
    rideId = trimValue(rideId);

    if (rideId.length > 0) {
      var reqParams = {
        companyId: UserSessionController.getLocalSessionValue(SC_COMPANY_ID),
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        category:
          "ERROR_DATA",
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getCrash1KmErrorData(rideId, divisionId, userId) {
    rideId = trimValue(rideId);

    if (rideId.length > 0) {
      var reqParams = {
        companyId: UserSessionController.getLocalSessionValue(SC_COMPANY_ID),
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        category:
          "START_DATA,DISTANCE_DATA,ERROR_DATA,END_DATA",
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async myRideDetailUserLeaderBoard(rideId, divisionId, userId) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    // var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var queryFields = {
      deviceDataCategory: "END_DATA",
      scoreValidationStatus: "SUCCESS",
    };
    if (companyId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: "0",
        limit: "1000",
        // status: "ALL",
        queryType: "AD_SINGLE_USER_RIDE_LIST",
        queryFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async myRideDetailUserLeaderBoardWithRideCategory(rideId, divisionId, userId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    // var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var queryFields = {
      deviceDataCategory: "END_DATA",
      scoreValidationStatus: "SUCCESS",
      travelType: rideCategory
    };
    if (companyId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: "0",
        limit: "1000",
        // status: "ALL",
        queryType: "AD_SINGLE_USER_RIDE_LIST",
        queryFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async rideDtailTopApi(rideId, divisionId, userId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);

    var dashboardFields = {
      deviceDataCategory: "END_DATA",
      scoreValidationStatus: "SUCCESS",
      travelType: rideCategory
    };
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: "0",
        limit: "1000",
        dashboardType: "UD_SINGLE_USER_CALCULATED_RIDE_LIST",
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },

  async riskAlertController(riskAlertType, rideId, divisionId, userId) {
    var dashboardFields = {
      riskAlertType: riskAlertType,
    };
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE",
        status: "ALL",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: 0,
        limit: "100",
        dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  // ---------------------------------- New api -------------------------------------------------- //

    async manualAlertController(rideId, divisionId, userId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var reportFields = {travelType : rideCategory};
    console.log("divisionId: ",divisionId + " " +"userId: ",userId);
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        groupId: groupId,
        divisionId: divisionId,
        deviceId: rideId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        // status: "ALL",
        // sortBy: "createdAt",
        // sortOrder: "ASC",
        // offset: 0,
        // limit: "100",
        reportType: "SAD_SINGLE_DIVISION_DEVICE_SUMMARY_LIST",
        reportFields: JSON.stringify(reportFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  // --------------------------------------------------------------------------------------------- //

  async stressStrainController(rideId, divisionId, userId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var queryFields = {travelType: rideCategory}

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE",
        category: "STRESS_STRAIN_DATA",
        status: "ALL",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: 0,
        limit: "100",
        queryType: "UD_SINGLE_USER_RIDE_DATA_SUB_CATEGORY_LEVEL_COUNT",
        queryFields: JSON.stringify(queryFields)
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getCSVFile(rideId, divisionId, userId) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var moduleId = UserSessionController.getLocalSessionValue(SC_MODULE_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        moduleId: moduleId,
        groupId: groupId,
        type: "RIDE_RAW_CSV",
      };
      // console.log("ride raw csv: " + JSON.stringify(reqParams))

      return await ApiService.callGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.FILE + apiEndPoint.DEVICE + apiEndPoint.RAWDATA,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};

export default MyRideDetailController;
